import { Selector } from "./Selector";
import { RegistrationNumber } from './RegistrationNumber';
import Agree from "./Agree";
import styled from "styled-components";
import axios from "axios";
import { FormEvent, useEffect, useState } from "react";
import {
    BookingType
} from '../../../types';

import {
    SelectButton
} from './SelectButton';

import {
    leadingZeros    
} from '../../../utilities/Convert'

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 0px 15px;
    padding-bottom: 40px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #1E1E1E;
`;

const ColWrapper = styled.div<{top: string}>`
    display: grid;
    justify-content: flex-start;
    align-items: flex-start;
    margin-top: ${(props) => props.top}px;
    flex-direction: column;
    width: 100%;
    grid-template-columns: 1fr;
    gap: 15px;
`;

const Title = styled.p`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 33px;
    span{
        font-weight: 700;
    }
`;

const Line = styled.div`
    width: 100%;
    border: 1px solid #EDEEF0;
    margin-top: 33px;
`;

const SelectTimeButton = styled.div`
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid #E8EAEE;
    border-radius: 6px;
    padding: 0 16px;
`;

const SelectLabel = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #50A0E0;
`;

const UnselectLabel = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #A3AAB6;
`;

const DepartmentButton  = styled.div`
    width: 104px;
    height: 110px;
    background: #EFF6FC;
    border: 1px solid #50A0E0;
    border-radius: 6px;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-direction: column;
    padding: 8px 0;
`;

const Image = styled.img`

`;



const Arrow = styled.img`
    position: absolute;
    right: 5px;
    top: 55%;
`;

const Input = styled.input`
    font-family: 'Spoqa Han Sans Neo';
    width: 100%;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid #E8EAEE;
    border-radius: 6px;
    padding-left: 16px;
    font-size: 16px;
    line-height: 15px;
    font-weight: 700;
    ::placeholder{
        color: #A3AAB6;
    }
`;

const RegistrationWrapper = styled.div`
    display: grid;
    /* justify-content: center;
    align-items: center; */
    width: 100%;
    gap: 20px;
    grid-template-columns: repeat(auto-fit, minmax(0%, auto));
`;

const BirthInput = styled.input`
    width: 100%;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid #E8EAEE;
    border-radius: 6px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    padding-left: 16px;
    color: #000000;
    font-weight: 700;
    ::placeholder{
        color: #A3AAB6;
    }
`;

const SmallInput = styled.input`
    width: 100%;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid #E8EAEE;
    border-radius: 6px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-size: 16px;
    line-height: 19px;
    font-weight: 700;
    color: #000000;
    padding-left: 16px;
    ::placeholder{
        color: #A3AAB6;
    }
`;

const Unit = styled.span`
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const RegistrationButtonWrapper = styled.div`
    width: 100%;
    height: 55px;
    background: #FFFFFF;
    border: 1px solid #E8EAEE;
    border-radius: 6px;
    cursor: pointer;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #A3AAB6;
`;

const RegistrationButton = styled.input`
    width: 100%;
    height: 100%;
    border: 0;
    border-radius: 6px;
    cursor: pointer;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 19px;
    color: #000000;
    padding-left: 16px;
`;

const SubmitButton = styled.div<{active: boolean}>`
    width: 100%;
    height: 55px;
    background: ${(props) => props.active ? '#1E1E1E' : '#E8EAEE'};
    color: ${(props) => props.active ? '#FFFFFF' : '#B0B0B0'};
    border-radius: 6px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    line-height: 22px;
    cursor: pointer;
`;

const PolicyButton = styled.div`
    width: 100%;
    position: relative;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #A3AAB6;
    padding-top: 30px;
    padding-right: 20px;
    cursor: pointer;
`;

interface ScheduleDataType {
    time: string;
    slot: number;
    active: boolean;
}

interface InputInfoProps{
    onSubmit: (data: BookingType) => void;
    bookingInfo: BookingType;
    session: string;
}

function InputInfo({
    onSubmit,
    bookingInfo,
    session
}: InputInfoProps){
    // 예약 시간 날짜
    const [ isViewSelectModal, setIsViewSelectModal ] = useState<boolean>(false);
    const [ selectedTime, setSelectedTime ] = useState<string>("");
    const [ selectedDate, setSelectedDate ] = useState<string>("");
    const [ scheduleData, setScheduleData ] = useState<ScheduleDataType[]>();
    const [ termsNPrivacy, setTermsNPrivacy ] = useState(false);

    // 주민등록 번호
    const [ buttonEnable, setButtonEnable ] = useState<boolean>(false);
    const [ isRegistrationInputModal, setIsRegistrationInputModal ] = useState<boolean>(false);
    const [ birth, setBirth ] = useState<string>("");
    const [ registrationNumber, setRegistrationNumber ] = useState<string>("");

    // 성별 
    const [ isWoman, setIsWoman ] = useState<boolean>(false);

    // 이름
    const [ name, setName ] = useState<string>("");

    // 전화번호 
    const [ phone, setPhone ] = useState<string>("");

    // 키 
    const [ height, setHeight ] = useState<string>("");

    // 몸무게
    const [ weight, setWeight ] = useState<string>("");

    // 음주여부 
    const [ drink, setDrink ] = useState<number>(0);

    // 흡연여부 
    const [ smoke, setSmoke ] = useState<number>(0);
    
    // 임신여부 
    const [ pregnant, setPregnant ] = useState<number>(0);

    // submit button
    const [ isSubmitButtonActive, setIsSubmitButtonActive ] = useState<boolean>(false);

    const num = /^[0-9]*$/;
    const special = /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@\#$%&\\\=\(\'\"]/g;
    const checkBirth = /([0-9]{2}(0[1-9]|1[0-2])(0[1-9]|[1,2][0-9]|3[0,1]))/;
    const phoneExp = /^01([0|1|6|7|8|9]?)?([0-9]{3,4})?([0-9]{4})$/;

    const onHeightChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;
        //validataion

        if(num.test(value))
        {
            if(Number(value) < 250)
            {
                setHeight(value);              
            }          
            checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                selectedTime, selectedDate, value, weight,
                drink, smoke, pregnant);   
        }
        else
        {
            setHeight(value.slice(0, value.length -1));     
            checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                selectedTime, selectedDate, value.slice(0, value.length -1), weight,
                drink, smoke, pregnant);      
        }

    }

    const onWeightChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;
        //validataion

        if(num.test(value))
        {
            if(Number(value) < 300)
            {
                setWeight(value);  
            }
            checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                selectedTime, selectedDate, height, value,
                drink, smoke, pregnant);
        }
        else
        {
            setWeight(value.slice(0, value.length -1));       
            checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                selectedTime, selectedDate, height, value.slice(0, value.length -1),
                drink, smoke, pregnant);     
        }

    }

    const onBirthChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;
        //validataion
        if(num.test(value))
        {            
            if(value.length < 7)
            {
                setBirth(value);   
                if(value.length === 6)
                {
                    if(checkBirth.test(value))
                    {
                        setButtonEnable(true);
                        setIsRegistrationInputModal(true);       
                        checkSubmitButtonActive(phone, name, value, registrationNumber, 
                            selectedTime, selectedDate, height, weight,
                            drink, smoke, pregnant);                
                    }                    
                }
                else
                {
                    setButtonEnable(false);
                    setIsRegistrationInputModal(false);      
                    checkSubmitButtonActive(phone, name, '', registrationNumber, 
                        selectedTime, selectedDate, height, weight,
                        drink, smoke, pregnant);             
                }
            }         
        }
        else
        {
            setBirth(value.slice(0, value.length -1));
            checkSubmitButtonActive(phone, name, '', registrationNumber, 
                selectedTime, selectedDate, height, weight,
                drink, smoke, pregnant);
            
        }
       
    }

    const onNameChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;
        //validataion
  
        if(!num.test(value) && !special.test(value))
        {
            // alert("??")
            setName(value)
            checkSubmitButtonActive(phone, value, birth, registrationNumber, 
                selectedTime, selectedDate, height, weight,
                drink, smoke, pregnant);
        }
        else
        {
            setName(value.slice(0, value.length -1));
            checkSubmitButtonActive(phone, value.slice(0, value.length -1), birth, registrationNumber, 
                selectedTime, selectedDate, height, weight,
                drink, smoke, pregnant);
        }       

    }

    const onPhoneChange = (event: FormEvent<HTMLInputElement>) => {
        let {
            currentTarget: {value},
        } = event;
        //validataion
        if(num.test(value))
        {            
            if(value.length < 12)
            {
                setPhone(value);   
                if(value.length === 11)
                {
                    if(phoneExp.test(value))
                    {
                        const convert= value.slice(0, 3) + "-" + value.slice(3, 7) + "-" + value.slice(7, 11)
                        setPhone(convert); 
                        checkSubmitButtonActive(value, name, birth, registrationNumber, 
                            selectedTime, selectedDate, height, weight,
                            drink, smoke, pregnant);                          
                    }
                    else
                    {
                        //err
                        checkSubmitButtonActive('', name, birth, registrationNumber, 
                            selectedTime, selectedDate, height, weight,
                            drink, smoke, pregnant);
                    }
                }                     
            }         
        }
        else
        {
            value = value.replace(/-/gi, '');   
            setPhone(value.slice(0, value.length));
            checkSubmitButtonActive('', name, birth, registrationNumber, 
                selectedTime, selectedDate, height, weight,
                drink, smoke, pregnant);
           
        }

    }

    const checkSubmitButtonActive = (
        phone: string | undefined, name: string | undefined, birth: string | undefined, registrationNumber: string | undefined, 
        selectedTime: string | undefined, selectedDate: string | undefined, height: string | undefined, weight: string | undefined,
        drink: number, smoke: number, pregnant: number
    ) => {

        if(phone !== '' && name !== '' && birth !== '' && registrationNumber !== '' &&
        selectedTime !== '' && selectedDate !== '' && height !== '' && weight !== '' &&
        drink !== -1 && smoke !== -1 && pregnant !== -1)
        {
            setIsSubmitButtonActive(true);
        }
        else
        {
            setIsSubmitButtonActive(false);
        }
        

    }


    const onSubmitButton = () => {

        
        // 채우기 
        const retData:BookingType = {
            data: {
                detail: '추가 사항 정보 없음',
                hospital_ids: [1607405803, 1937465460],
                user: {
                    add_info: {
                        birth: birth as string,
                        drink: drink === 0 ? '거의 안함' : drink === 1 ? '주 1~2회' : '주 3회 이상',
                        gender_type: Number((registrationNumber as string)[0]),
                        height: height as string,
                        name: name as string,
                        phone_number: phone as string,
                        registration_number: birth as string + registrationNumber as string,
                        smoke: smoke === 0 ? '비흡연' : drink === 1 ? '5개비 내외' : '10개비 이상',
                        type: 1,
                        weight: weight as string,
                        pregnancy: pregnant === 0 ? '있음' : '없음'
                    },
                    name: name as string,
                    phone_number: phone as string,
                }
            }, 
            time: selectedDate + " " + selectedTime
        }

        onSubmit(retData);
        //return 
    } 

    

    

    useEffect(() => {
        async function getSchedule () {
            const url = `/v1/survey/booking/schedule/`;
            axios.defaults.withCredentials = true;
            let cur = new Date();
            console.log(cur.getFullYear() + "-" + leadingZeros((cur.getMonth() + 1).toString(), 2) + "-" + leadingZeros(cur.getDate().toString(), 2));
            // console.log(leadingZeros(cur.getHours().toString(), 2) + ":" + leadingZeros(cur.getMinutes().toString(), 2) + ":" + leadingZeros(cur.getSeconds().toString(), 2));
            cur.setHours(cur.getHours() + 1);
            const from = cur.getFullYear() + "-" + leadingZeros((cur.getMonth() + 1).toString(), 2) + "-" + leadingZeros(cur.getDate().toString(), 2) + " " + leadingZeros(cur.getHours().toString(), 2) + ":" + leadingZeros(cur.getMinutes().toString(), 2) + ":" + leadingZeros(cur.getSeconds().toString(), 2);
            let post = cur;
            post.setDate(post.getDate() + 3);
            const to = post.getFullYear() + "-" + leadingZeros((post.getMonth() + 1).toString(), 2) + "-" + leadingZeros(post.getDate().toString(), 2) + " " + 23+ ":" + 59 + ":" + 59;
            // console.log(post.getFullYear() + "-" + leadingZeros((post.getMonth() + 1).toString(), 2) + "-" + leadingZeros(post.getDate().toString(), 2));
            // console.log(23+ ":" + 59 + ":" + 59);
    
            try {
                const data = {
                    department_id: "1",
                    hospital_id: ["1607405803", "1937465460"],
                    from: from,
                    to: to
                }
                const res = await axios.post(url, data);
                setScheduleData(res.data);
                // console.log(res.data);   
                // return res.data; 
            }
            catch(err)
            {
                //에러 처리 
            }
        }

        getSchedule();
   

        let tDate = '';
        let tTime = '';
        let tName = '';
        let tBirth = '';
        let tRegistartionNumber = '';
        let tPhone = '';
        let tHeight = '';
        let tWeight = '';
        let tDrink = -1;
        let tSmoke = -1;
        let tPregnancy = -1;
       
        if(bookingInfo.time.length === 19)
        {
            setSelectedDate(bookingInfo.time.slice(0, 13));
            setSelectedTime(bookingInfo.time.slice(14, 19));

            tDate = bookingInfo.time.slice(0, 13);
            tTime = bookingInfo.time.slice(14, 19);
            
        }

        setName(bookingInfo.data.user.add_info.name);
        tName = bookingInfo.data.user.add_info.name;
        setBirth(bookingInfo.data.user.add_info.birth);
        tBirth = bookingInfo.data.user.add_info.birth;
        
        if(bookingInfo.data.user.add_info.registration_number.length === 13)
        {
            setRegistrationNumber(bookingInfo.data.user.add_info.registration_number.slice(6, 13));
            tRegistartionNumber = bookingInfo.data.user.add_info.registration_number.slice(6, 13);
            if(tRegistartionNumber[0] === '0' || tRegistartionNumber[0] === '2' || tRegistartionNumber[0] === '4' || tRegistartionNumber[0] === '6' || tRegistartionNumber[0] === '8')
            {
                setIsWoman(true);
            }
            else
            {
                setIsWoman(false);
            }
        }
        
        setPhone(bookingInfo.data.user.add_info.phone_number);
        tPhone = bookingInfo.data.user.add_info.phone_number;

        setHeight(bookingInfo.data.user.add_info.height);
        tHeight = bookingInfo.data.user.add_info.height;
        
        setWeight(bookingInfo.data.user.add_info.weight);
        tWeight = bookingInfo.data.user.add_info.weight;

        if(bookingInfo.data.user.add_info.drink === '거의 안함')
        {
            setDrink(0);
            tDrink = 0;
        }
        else if(bookingInfo.data.user.add_info.drink === '주 1~2회')
        {
            setDrink(1);
            tDrink = 1;
        }
        else if(bookingInfo.data.user.add_info.drink === '주 3회 이상')
        {
            setDrink(2);
            tDrink = 2;
        }
        if(bookingInfo.data.user.add_info.smoke === '비흡연')
        {
            setSmoke(0);
            tSmoke = 0;
        }
        else if(bookingInfo.data.user.add_info.smoke === '5개비 내외')
        {
            setSmoke(1);
            tSmoke = 1;
        }
        else if(bookingInfo.data.user.add_info.smoke === '10개비 이상')
        {
            setSmoke(2);
            tSmoke = 2;
        }
        if(bookingInfo.data.user.add_info.pregnancy === '있음')
        {
            setPregnant(0);
            tPregnancy = 0;
        }
        else if(bookingInfo.data.user.add_info.pregnancy === '없음')
        {
            setPregnant(1);
            tPregnancy = 1;
        }

        checkSubmitButtonActive(tPhone, tName, tBirth, tRegistartionNumber, 
            tTime, tDate, tHeight, tWeight,
            tDrink, tSmoke, tPregnancy);

    }, [])

    return (
        <Container>
            {
                termsNPrivacy &&            
                <Agree 
                    
                    onBack={() => {
                        setTermsNPrivacy(false);
                    }}
                />
            }
            {
                isViewSelectModal && scheduleData && <Selector 
                    session={session}
                    datas={scheduleData as ScheduleDataType[]}
                    onCloseButton={() => {setIsViewSelectModal(false)}}
                    onSelectButton={(year: string, month: string, day: string, time: string) => {
                        setSelectedDate(year + "-" + month+ "-" + day);
                        setSelectedTime(time);
                        checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                            time, year + "-" + month+ "-" + day, height, weight,
                            drink, smoke, pregnant)
                        setIsViewSelectModal(false)
                    }}
                />
            }
            {
                isRegistrationInputModal && <RegistrationNumber 
                    onCloseButton={() => {setIsRegistrationInputModal(false)}}
                    onSubmit={(number) => {
                        setIsRegistrationInputModal(false);
                        setRegistrationNumber(number);
                        if(number[0] === '0' || number[0] === '2' || number[0] === '4' || number[0] === '6' || number[0] === '8')
                        {
                            setIsWoman(true);
                        }
                        else 
                        {
                            setPregnant(1);
                            setIsWoman(false);
                        }
                        checkSubmitButtonActive(phone, name, birth, number, 
                            selectedTime, selectedDate, height, weight,
                            drink, smoke, pregnant);
                    }}
                />
            }
            <ColWrapper top="30">
                <Title><span>현대인의 건강관리</span><br/>언제 어디서나<br/>표준화된 진료를 경험하세요</Title>
            </ColWrapper>
            <ColWrapper top="30">
                <DepartmentButton>
                    <Image src={require('../../../styles/assets/landing/selected.png')} />
                    <Label>피부</Label>
                </DepartmentButton>
            </ColWrapper>
            <ColWrapper top="30">
                <Label>진료 시간 선택</Label>                
                <SelectTimeButton
                    onClick={() => {
                        setIsViewSelectModal(true);
                    }}
                >
                    {
                        selectedTime ? (
                            <SelectLabel>{selectedDate + " | " + selectedTime}</SelectLabel>
                        ) : (
                            <UnselectLabel>진료 시간 선택을 위해 이곳을 눌러주세요.</UnselectLabel>
                        )
                    }
                    
                    <Image src={require('../../../styles/assets/common/arrow_right_new.png')} />
                </SelectTimeButton>
            </ColWrapper>
            
            <Line />
            <ColWrapper top="30">
                <Label>이름</Label>
                <Input 
                    placeholder="이름을 적어주세요." 
                    value={name} 
                    onChange={onNameChange}
                />
            </ColWrapper>
            <ColWrapper top="30">
                <Label>주민등록번호</Label>
                <RegistrationWrapper>
                    <BirthInput 
                        placeholder="990101" 
                        value={birth}
                        onChange={onBirthChange}
                    />
                    <Label>-</Label>
                    <RegistrationButtonWrapper
                        onClick={() => {    
                            if(buttonEnable)
                            {
                                setIsRegistrationInputModal(true);
                            }               
                        }}
                    >
                        <RegistrationButton type="password" value={registrationNumber} disabled placeholder="*******"/>
                    </RegistrationButtonWrapper>                    
                </RegistrationWrapper>
            </ColWrapper>
            <ColWrapper top="30">
                <Label>연락처</Label>
                <Input 
                    // type="number" pattern="\d*"  모바일
                    placeholder="- 없이 숫자만 입력해주세요" 
                    value={phone}
                    onChange={onPhoneChange}
                />                
            </ColWrapper>
            <ColWrapper top="30">
                <Label>키 & 몸무게</Label>
                <RegistrationWrapper>
                    <SmallInput placeholder="160" value={height} onChange={onHeightChange} /><Unit>cm</Unit> 
                    <SmallInput placeholder="59" value={weight} onChange={onWeightChange} /><Unit>kg</Unit> 
                </RegistrationWrapper>
            </ColWrapper>
            <Line />
            <PolicyButton
                onClick={() => {
                    setTermsNPrivacy(true)           
                }}
            >
                위 솔닥 비대면 진료 서비스 시작을 위해 자사 정보 제공 및 이용약관 동의(개인정보 수집 동의, 위치기반 서비스 이용약관)해요. 
                <Arrow src={require('../../../styles/assets/common/btn_reservation_expand_less_arrow.png')} />
            </PolicyButton>
            {/* <ColWrapper top="30">
                <Label>음주여부</Label>
                <RegistrationWrapper>
                    <SelectButton 
                        title="거의 안함"
                        selected={drink === 0 ? true : false}
                        width= "100"
                        onClick={() => {
                            setDrink(0);
                            checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                                selectedTime, selectedDate, height, weight,
                                0, smoke, pregnant);
                        }}
                    />
                    <SelectButton 
                        title="주 1~2회"
                        selected={drink === 1 ? true : false}
                        width= "100"
                        onClick={() => {
                            setDrink(1);
                            checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                                selectedTime, selectedDate, height, weight,
                                1, smoke, pregnant);
                        }}
                    />
                    <SelectButton 
                        title="주 3회 이상"
                        selected={drink === 2 ? true : false}
                        width= "100"
                        onClick={() => {
                            setDrink(2);
                            checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                                selectedTime, selectedDate, height, weight,
                                2, smoke, pregnant);
                        }}
                    />
                </RegistrationWrapper>
            </ColWrapper>
            <ColWrapper top="30">
                <Label>흡연여부</Label>
                <RegistrationWrapper>
                    <SelectButton 
                        title="비흡연"
                        selected={smoke === 0 ? true : false}
                        width= "100"
                        onClick={() => {
                            setSmoke(0);
                            checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                                selectedTime, selectedDate, height, weight,
                                drink, 0, pregnant);
                        }}
                    />
                    <SelectButton 
                        title="5개비 내외"
                        selected={smoke === 1 ? true : false}
                        width= "100"
                        onClick={() => {
                            setSmoke(1);
                            checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                                selectedTime, selectedDate, height, weight,
                                drink, 1, pregnant);
                        }}
                    />
                    <SelectButton 
                        title="10개비 이상"
                        selected={smoke === 2 ? true : false}
                        width= "100"
                        onClick={() => {
                            setSmoke(2);
                            checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                                selectedTime, selectedDate, height, weight,
                                drink, 2, pregnant);
                        }}
                    />
                </RegistrationWrapper>
            </ColWrapper>
            
            {
                isWoman &&               
                <ColWrapper top="30">
                    <Label>임신 (가능성) 여부</Label>
                    <RegistrationWrapper>
                        <SelectButton 
                            title="있음"
                            selected={pregnant === 0 ? true : false}
                            width= "100"
                            onClick={() => {
                                setPregnant(0);
                                checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                                    selectedTime, selectedDate, height, weight,
                                    drink, smoke, 0);
                            }}
                        />
                        <SelectButton 
                            title="없음"
                            selected={pregnant === 1 ? true : false}
                            width= "100"
                            onClick={() => {
                                setPregnant(1);
                                checkSubmitButtonActive(phone, name, birth, registrationNumber, 
                                    selectedTime, selectedDate, height, weight,
                                    drink, smoke, 1);
                            }}
                        />               
                    </RegistrationWrapper>
                </ColWrapper>
            } */}
            <ColWrapper top="30">
                <SubmitButton
                    active={isSubmitButtonActive}
                    onClick={() => {
                        if(isSubmitButtonActive)
                        {
                            onSubmitButton();
                        }                      
                    }}
                >위 정보로 예약할게요!</SubmitButton>
            </ColWrapper>
            
        </Container>
    );
}

export default InputInfo;