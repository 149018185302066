// service에서 사용하는 Image Button 
// props image path, onclick 

import { useEffect, useState } from "react";
import styled from "styled-components";

export enum ServiceTypeEnum {
    "SKIN",
    "DIET",
    "SOLDOC", 
    "KIDOC"
}

const ImageButtonWrapper = styled.div`
    height: 680px;
    min-width: 590px;
    display: flex;
    position: relative;
    flex-direction: column;
    padding: 45px 41px;
    gap: 19px;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
`;

// felix titling font 사용으로 button을 atom에 빼지 않음 
const Title = styled.div`
    font-family: 'felixTitling';
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    color: ${(props) => props.theme.textColor};
    z-index: 2;
`;

const SubTitle = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: ${(props) => props.theme.textColor};
    z-index: 2;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 6px;
    flex-direction: column;
`;

const StartButton = styled.div`
    width: 160px;
    height: 48px;
    top: 140px;
    left: 40px;
    background: #000000;
    border-radius: 54px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
`;

const DetailButton = styled.div`
    width: 160px;
    height: 48px;
    top: 201px;
    left: 40px;
    background: #ffffff;
    border-radius: 54px;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    cursor: pointer;
`;

const ButtonText = styled.div<{color: string}>`
    font-size: 20px;
    line-height: 20px;
    font-weight: 400;
    font-family: 'felixTitling';
    color: ${(props) => props.color};
`;

const ButtonIcon = styled.img`
    width: 18px;
    height: 6px;

`;

const ComingSoon = styled.div`
    width: 204px;
    height: 48px;
    border: 1px solid #FFFFFF;
    border-radius: 54px;
    font-family: 'FelixTitling';
    font-size: 20px;
    line-height: 20px;
    color: #FFFFFF;
    z-index: 2;
    display: flex;
    align-items: center;
    justify-content: center;
`;

interface ServiceImageButtonProps{
    title: string;
    type: ServiceTypeEnum;
    description: string;
    onSurveyClick: () => void;
    onDetailClick: () => void;
}


export function ServiceImageButton({title, description, type, onSurveyClick, onDetailClick}: ServiceImageButtonProps){
    
    const [serviceType, setSerivceType] = useState(type);
    const [background, setBackground] = useState("");

    return (       
        <ImageButtonWrapper>
            { type === ServiceTypeEnum.SKIN &&  (
                <>
                    <Image src={require('../../../styles/assets/landing/skin.png')} /> 
                    <Title>SKIN HONEST</Title>
                    <SubTitle dangerouslySetInnerHTML={{__html: "서베이를 통한 문진을 시작으로<br/>내 피부를 관리하는 참 쉬운 방법"}} />
                    <ButtonWrapper>
                        <StartButton onClick={onSurveyClick}>
                            <ButtonText color="#ffffff">START</ButtonText>
                            <ButtonIcon src={require('../../../styles/assets/common/rightArrow.png')} />
                        </StartButton>
                        <DetailButton onClick={onDetailClick}>
                            <ButtonText color="#000000">Detail</ButtonText>
                            <ButtonIcon src={require('../../../styles/assets/common/rightArrowBlack.png')} />
                        </DetailButton>
                    </ButtonWrapper>
                </>
            )}
            { type === ServiceTypeEnum.DIET &&  (
                <>
                    <Image src={require('../../../styles/assets/landing/line.png')} /> 
                    <Title>LINE HONEST</Title>
                    <SubTitle dangerouslySetInnerHTML={{__html: "다이어트, 비만 고민을 해결하는<br/>슬기로운 생활의 시작"}} />
                    <ButtonWrapper>
                        <ComingSoon>COMING SOON</ComingSoon>
                    </ButtonWrapper>
                    {/* <StartButton onClick={onDetailClick}>
                        <ButtonText color="#ffffff">START</ButtonText>
                        <ButtonIcon src={require('../../../styles/assets/rightArrow.png')} />
                    </StartButton> */}
                </>
            )}
            { type === ServiceTypeEnum.SOLDOC &&  (
                <>
                    <Image src={require('../../../styles/assets/landing/soldoc.png')} /> 
                    <Title>SOLDOC</Title>
                    <SubTitle dangerouslySetInnerHTML={{__html: "비대면 진료의 표준,<br/>솔직한 닥터, 솔닥입니다."}} />
                    <ButtonWrapper>                     
                        <StartButton onClick={onSurveyClick}>
                            <ButtonText color="#ffffff">DETAIL</ButtonText>
                            <ButtonIcon src={require('../../../styles/assets/common/rightArrow.png')} />
                        </StartButton>
                    </ButtonWrapper>
                    {/* <StartButton onClick={onDetailClick}>
                        <ButtonText color="#ffffff">Detail</ButtonText>
                        <ButtonIcon src={require('../../../styles/assets/rightArrow.png')} />
                    </StartButton> */}
                </>
            )}
            { type === ServiceTypeEnum.KIDOC &&  (
                <>
                    <Image src={require('../../../styles/assets/landing/hair.png')} /> 
                    <Title>HAIR HONEST</Title>
                    <SubTitle dangerouslySetInnerHTML={{__html: "내 소중한 헤어,<br/>더 이상 스트레스가 되지 않도록"}} />
                    <ButtonWrapper>
                        <ComingSoon>COMING SOON</ComingSoon>
                    </ButtonWrapper>
                    {/* <StartButton onClick={onDetailClick}>
                        <ButtonText color="#ffffff">Detail</ButtonText>
                        <ButtonIcon src={require('../../../styles/assets/common/rightArrow.png')} />
                    </StartButton> */}
                </>
            )}
            
           
            
     
        </ImageButtonWrapper> 
    );
};
