import styled from "styled-components";
import axios from "axios";

import { 
    useEffect, 
    useState 
} from "react";

import { 
    useNavigate, 
    useParams 
} from "react-router-dom";

import { 
    useRecoilState 
} from "recoil";

import {
    SurveyHeaderTitle,
    NextButton
} from '../../../components/atoms';

import {
    ProgressBar
} from '../../../components/molecules';

import { 
    SurveyImageSelect, 
    SurveyTextSelect,
    SurveyRangeSelect
} from "../../../components/organisms";

import { 
    userState,
    surveyAtom,
    surveyAnswerState,
    surveyState,
} from "../../../store";

import { 
    getLocalStorage,
    setLocalStorage, 
    getUserLocalStorage
} from "../../../utilities/localstorage";

import { 
    ImageButtonProps, 
    TextButtonProps, 
    RangeButtonProps, 
    ButtonTypeEnum,
    QuestionType,
    SurveyStateType,
    ConditionTypeEnum,
    JumpTypeEnum,
    TargetTypeEnum,
    SurveyCategoryEnum,
    SurveyAnswerType,
    SurveyAnswerQuestionType,
    SurveyAnswerButtonType,
    UserStateType
} from "../../../types";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    min-height: calc(100vh - 90px);
    width: 100%;
    background-color: ${(props) => props.theme.bgColor};
    padding: 29px 0 45px 0;
    gap: 30px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 29px;
`;


function Question(){
    const { type, category, question } = useParams();
    const [ surveys, setSurveys ] = useRecoilState(surveyState);    
    const [ user, setUser ] = useRecoilState(userState);
    let lUser = user;
    // const [ surveyIndex, setSurveyIndex ] = useState(0);
    const [ questionTitle, setQuestionTitle ] = useState<string>();
    const [ questionSubTitle, setQuestionSubTitle ] = useState<string>();
    const [ grid, setGrid ] = useState<number>(1);
    const [ endLetter, setEndLetter ] = useState<string>();
    const [ rangeStartLabel, setRangeStartLabel ] = useState<string>();
    const [ rangeEndLabel, setRangeEndLabel ] = useState<string>();
   
    const [ buttons, setButtons ] = useState<ImageButtonProps []| TextButtonProps[]| RangeButtonProps[]>();    
    const [ buttonType, setButtonType ] = useState<ButtonTypeEnum>();    
    const [ nextButton, setNextButton ] = useState(false);

    const [ totalTaskCount, setTotalTaskCount ] = useState<number>(0);
    const [ completeCount, setCompleteCount ] = useState<number>(0);
    // const [ selectedButtonIndex, setSelectedButtonIndex ] = useState<number []>([]);

    const [ sv, setSv ] = useRecoilState(surveyAtom);  
    //setting만 사용 
    const [ answer, setAnswer ] = useRecoilState(surveyAnswerState);

    const navigate = useNavigate();
    // 다음 버튼 눌렀을때
    const onNextButtonClick = () => {
        // atom state 변경 
        // const tsurveys:SurveyStateType[] = JSON.parse(JSON.stringify(surveys));      
        // tsurveys[surveyIndex].question[Number(question)].buttons = buttons as ImageButtonProps []| TextButtonProps[]| RangeButtonProps[];
        // setSurveys(tsurveys);
        // setLastIndex([...lastIndex, Number(question)]);
        const tSurvey:SurveyStateType = JSON.parse(JSON.stringify(sv));
        tSurvey.question[Number(question)].buttons = buttons as ImageButtonProps []| TextButtonProps[]| RangeButtonProps[];
        setSv(tSurvey);

        // answer recoil setting 
        const tAnswer:SurveyAnswerType = setUserAnswer(tSurvey);
        setAnswer(tAnswer);
        //local storage에 저장
        setLocalStorage(`answer_${category}`, JSON.stringify(tAnswer));

        //로그인 되어있다면 서버에 저장 

        //survey를 local storage에 저장 
        // setSurveyLocalStorage(tsurveys);

        // 전달 타입 확인 
        jumpQuestion(tSurvey.question[Number(question)]);
    }

    const setUserAnswer = (survey: SurveyStateType) => {
        let tAnswer: SurveyAnswerType = {
            category: `${category}`,
            question: []
        }

        survey.question.map((q, qindex) => {
            let questionAnswer: SurveyAnswerQuestionType = {
                questionIndex: -1,
                answer: []
            };
            q.buttons.map((b, bindex) => {                
                if(b.activate)
                {
                    let buttonAnswer: SurveyAnswerButtonType = {
                        answerText: b.text,
                        buttonIndex: bindex
                    };
                    questionAnswer.questionIndex = qindex;
                    questionAnswer.answer.push(buttonAnswer); 
                }
            })

            if(questionAnswer.questionIndex !== -1)
            {
                tAnswer.question.push(questionAnswer);
            }
        })

        return tAnswer;
    }

    //이동 방법
    const jumpQuestion = (check_question: QuestionType) => {

        if(check_question.jump === JumpTypeEnum.NOP)
        {            
            let next =  Number(question) + 1;
            if(sv.question.length > next)
            {
                navigate(`/${type}/detail/${category}/${next}`)
            }
            else
            {
                // result로 이동 
                navigate(`/${type}/health/${category}/0`)
            }

            return;
        }
        else if(check_question.jump === JumpTypeEnum.CONDITIONAL_JUMP)
        {
            let isJump = false;
            let isForceJump = false;
            //foce jump check
            check_question.buttons.map((button) => {
                if(button.activate && button.forceJump)
                {
                   
                    //multi 에서 jump를 위해 0을 체크 (0이면 conditionjump라도 다음으로 이동 )
                    let next = button.nextQuestionIndex as number;         
                 
                    if(next !== 0)
                    {
                        if(sv.question.length > next)
                        {
                            navigate(`/${type}/detail/${category}/${next}`)   
                            console.log("강제 점프: " + Number(next));                        
                        }
                        else
                        {
                            // result로 이동 
                            navigate(`/${type}/health/${category}/0`)                          
                        }
                        isForceJump = true;
                    }   
                }             
            })
            
            if(!isForceJump)
            {
                check_question.buttons.map((button) => {
                    if(button.activate)
                    {
                        //multi 에서 jump를 위해 0을 체크 (0이면 conditionjump라도 다음으로 이동 )
                        let next = button.nextQuestionIndex as number;         
                        if(next !== 0)
                        {
                            if(sv.question.length > next)
                            {
                                console.log("강제 점프2: " + Number(next));     
                                navigate(`/${type}/detail/${category}/${next}`)                            
                            }
                            else
                            {
                                // result로 이동 
                                navigate(`/${type}/health/${category}/0`)                            
                            }
                            isJump = true;
                        }   
                    }             
                })
                
                if(!isJump)
                {
                    //multi 에서 jump를 위해 0을 체크 (0이면 conditionjump라도 다음으로 이동 )
                    let next2 =  Number(question) + 1;
    
                    if(sv.question.length > next2)
                    {
                        navigate(`/${type}/detail/${category}/${next2}`)
                    }
                    else
                    {
                        // result로 이동 
                        navigate(`/${type}/health/${category}/0`)
                    }
    
                    return;
                }
            }
        }
        else if(check_question.jump === JumpTypeEnum.SURVEY_JUMP)
        {
            check_question.buttons.map((button) => {
                if(button.activate)
                {
                    if(button.jumpSurvey === SurveyCategoryEnum.ACNE)
                    {
                        navigate(`/${type}/detail/acne`)
                    }
                    else if(button.jumpSurvey === SurveyCategoryEnum.DRY)
                    {
                        navigate(`/${type}/detail/dry`)
                    }
                    else if(button.jumpSurvey === SurveyCategoryEnum.BROWN)
                    {
                        navigate(`/${type}/detail/brown`)
                    }
                    else if(button.jumpSurvey === SurveyCategoryEnum.ANTIAGING)
                    {
                        navigate(`/${type}/detail/antiaging`)
                    }
                    else if(button.jumpSurvey === SurveyCategoryEnum.BLUSH)
                    {
                        navigate(`/${type}/detail/blush`)
                    }
                    else
                    {
                        //survey jump가 아닐경우 원하는 index로 이동 
                        navigate(`/${type}/detail/${category}/` + button.nextQuestionIndex);
                    }
                    return;
                }             
            })
        }
    }

    // 단일 선택 
    // 앞에 답이 바뀌면 뒤에 답이 모두 지워져야함 
    const onSingleSelectResult = (selected: number, text?: string) => {
        // activate 수정 
        // deep copy

        //multi select true라면 다른 select를 false 시키지 않는다

        const tbutton:ImageButtonProps[] = JSON.parse(JSON.stringify(buttons));

        const selectButtonMultiSelectOption = tbutton[selected].multiSelect;

        tbutton.map((button, index) => {
            if(selectButtonMultiSelectOption)  // multiselectoption이 켜있는경우 해당버튼 활성화 나머지 상태 변경할 필요없음
            {
                if(index === selected)
                {
                    button.activate = !button.activate;
                    if(text !== undefined)
                    {
                        if(text === '')
                        {
                            button.activate = false;
                        }
                        button.text = text;
                    }           
                }
            }
            else
            {
                if(index !== selected)
                {
                    if(!button.multiSelect)  // multi select option이 없는 선택한 버튼 외의 버튼은 false 
                    {
                        button.activate = false;
                    }
                }
                else  //선택됨
                {
                    button.activate = true;
                    if(text !== undefined)
                    {
                        if(text === '')
                        {
                            button.activate = false;
                        }
                        button.text = text;
                    }           
                }
            }
        })        
        
        //아이디값이 변화해야 rerendering 
        setButtons([...tbutton]);
        setNextButton(false);
        tbutton.map(button => {
            if(button.activate)
            {
                setNextButton(true);
            }
        })
    }

    // 중복 선택 
    // 앞에 답이 바뀌면 뒤에 답이 모두 지워져야함 
    const onMultiSelectResult = (selected: number, text?: string) => {
        const tbutton:ImageButtonProps[] = JSON.parse(JSON.stringify(buttons));
        let selecteds:number[] = [];

        //선택된 버튼이 multiSelect false라면 나머지 모든값 false 
        // 선택된 버튼이 multiSelect true라면 multiselect false인 값 false
        const selectButtonMultiSelectOption = tbutton[selected].multiSelect;
        const selectValue = !tbutton[selected].activate;

        if(selectButtonMultiSelectOption)  //선택한 버튼의 multi가 true라면 
        {
            tbutton.map((button, index) => {
                if(index !== selected && !button.multiSelect && selectValue)  // multi가 true인 버튼은 선택하면 multi가 false인 버튼이 disable 되어야함
                {
                    button.activate = false;
                }

                if(index === selected)
                {
                    button.activate = !button.activate;
                    if(text)
                    {
                        button.text = text;
                    }
                }
    
                if(button.activate)
                {
                    selecteds.push(index);
                }
            })
        }
        else //선택한 버튼의 multi가 false라면 activate일때 모두 false가 되어야함 
        {
            tbutton.map((button, index) => {
                if(index !== selected && selectValue)  // multi가 true인 버튼은 선택하면 multi가 false인 버튼이 disable 되어야함
                {
                    button.activate = false;
                }

                if(index === selected)
                {
                    button.activate = !button.activate;
                    if(text !== undefined)
                    {
                        if(text === '')
                        {
                            button.activate = false;
                        }
                        button.text = text;
                    }           
                }
    
                if(button.activate)
                {
                    selecteds.push(index);
                }
            })
        }
        

        // 아이디 값이 변화해야 rerendering 
        setButtons([...tbutton])
        // setSelectedButtonIndex(selecteds)
        if(selecteds.length > 0)
        {
            setNextButton(true);
        }
        else
        {
            setNextButton(false);
        }
    }

    // question view 조건 체크
    const checkQuestionView = (question: QuestionType) => {

        if(question.viewConditionType === ConditionTypeEnum.NOP)
        {
            return true;
        }
        else if(question.viewConditionType === ConditionTypeEnum.GREATER)
        {
            if(question.viewConditionTarget === TargetTypeEnum.AGE)
            {
                if(lUser.age > question.viewConditionValue)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }            
            else
            {
                return true;
            }
        }
        else if(question.viewConditionType === ConditionTypeEnum.LESS)
        {
            if(question.viewConditionTarget === TargetTypeEnum.AGE)
            {
                if(lUser.age < question.viewConditionValue)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }          
            else
            {
                return true;
            }
        }
        else if(question.viewConditionType === ConditionTypeEnum.EQUAL)
        {            
            if(question.viewConditionTarget === TargetTypeEnum.AGE)
            {
                if(lUser.age === question.viewConditionValue)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }
            else if(question.viewConditionTarget === TargetTypeEnum.GENDER)
            {
                
                if(lUser.gender === question.viewConditionValue)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }
            else
            {
                return true;
            }
        }
        else if(question.viewConditionType === ConditionTypeEnum.RANGE)
        {
            //viewConditionEndValue 포함   
            if(question.viewConditionTarget === TargetTypeEnum.AGE)
            {
                if(lUser.age > question.viewConditionValue && lUser.age < (question.viewConditionEndValue as number))
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }          
            else
            {
                return true;
            }
        }
    }


    const checkUser = async () => {
        //recoil 확인
        //local storage 확인  로그인되어있다면 kakao 확인 
        if(user === undefined)
        {          
            const userLocalStorageString = getUserLocalStorage()
            if(userLocalStorageString)
            {
                const userLocalStorage: UserStateType = JSON.parse(userLocalStorageString);
                setUser(userLocalStorage);
                lUser = userLocalStorage;
                // console.log(userLocalStorage)
            }
            else
            {
                // 처음으로 이동 
                navigate(`/${type}/start/init`);
                return;
            }
        }
        else
        {            
            if(user.select === '' || user.birth === '' || user.gender === '')
            {
                navigate(`/${type}/start/init`);
                return;
            }
            else
            {
                setUser(user);
            }
        }
    }


    //서베이 세팅 
    const initalizeSurvey = (survey:  SurveyStateType) => {

        if(!checkQuestionView(survey.question[Number(question)]))
            {
                const next = Number(question) + 1;
                if(survey.question.length > next)
                {
                    navigate(`/${type}/detail/${category}/${next}`)
                }
                else
                {
                    // health로 이동 
                    navigate(`/${type}/health/${category}/0`)
                }
            }           
            else
            {
                setNextButton(false);
                survey.question[Number(question)].buttons.map((button) => {
                    if(button.activate)
                    {
                        setNextButton(true);
                    }
                })
                setQuestionTitle(survey.question[Number(question)].questionTitle)
                setQuestionSubTitle(survey.question[Number(question)].questionSubTitle);
                setGrid(survey.question[Number(question)].grid);
                setButtons(survey.question[Number(question)].buttons);
                setButtonType(survey.question[Number(question)].buttonType);
                setEndLetter(survey.question[Number(question)].endLetter);
                setRangeStartLabel(survey.question[Number(question)].rangeStartLabel);
                setRangeEndLabel(survey.question[Number(question)].rangeEndLabel);
                setTotalTaskCount(survey.question.length + 4);
                setCompleteCount(Number(question) + 1 + 4);
            }
    }

    // 답변 세팅 
    const settingAnswer = (survey: SurveyStateType, answer: SurveyAnswerType) => {
        let tsurvey = survey;

        answer.question.map(q => {
            q.answer.map(a => {
                tsurvey.question[Number(q.questionIndex)].buttons[a.buttonIndex].activate = true;
                tsurvey.question[Number(q.questionIndex)].buttons[a.buttonIndex].text = a.answerText;
            })
            
        })

        return tsurvey;
    }

    useEffect(() => {

        // user state 확인해서 없으면 앞으로 이동 
        checkUser();
        const gg:SurveyStateType = surveys.find(function(data){
            return data.category === category;
        }) as SurveyStateType
        // console.log(JSON.stringify(gg))

        if(sv === undefined || sv.category !== category)
        {      
            //template loading 
            //survey template loading
            axios({
                method: 'get',
                url: `/v1/template/pc_${category}/`,
                withCredentials: true
            }).then((response) => {
                // console.log("res" + response.data.template as string)
                let tsurvey:SurveyStateType = JSON.parse(response.data.template);
                const tAnswer:SurveyAnswerType = JSON.parse(getLocalStorage(`answer_${category}`) as string);

                //if not login
                if(tAnswer)
                {
                    const lastIndex = tAnswer.question[tAnswer.question.length - 1].questionIndex ;
                    //마지막 대답으로 이동 
                    if(lastIndex !== Number(question))
                    {
                        navigate(`/${type}/detail/${category}/${lastIndex}`);
                        return;
                    }
                    else
                    {
                        setAnswer(tAnswer);
                        tsurvey = settingAnswer(tsurvey, tAnswer);
                    }
                }
                else
                {
                    // 0질문으로 이동 
                    if(Number(question) !== 0)
                    {
                        navigate(`/${type}/detail/${category}/0`);
                        return;
                    }                  
                }

                // else login시 server에서 로딩
                // 로딩 후 local storage, recoil setting 

                initalizeSurvey(tsurvey);
                setSv(tsurvey);

            }).catch((err) => {    
                console.log(err)
            })
        }
        else
        {
            //답변은 모두 세팅되어있음
            initalizeSurvey(sv);            
        }
    }, [question])

    return (
        <Container>         
            <HeaderWrapper>
                <ProgressBar 
                    leftTotalTaskCount={totalTaskCount} 
                    leftCompleteCount={completeCount}
                    rightTotalTaskCount={10}
                    rightCompleteCount={0}
                />     
                <SurveyHeaderTitle /> 
            </HeaderWrapper>
  
            {buttonType === ButtonTypeEnum.IMAGE_MULTI && (
                <SurveyImageSelect 
                    surveyTitle="기본정보"
                    questionTitle={questionTitle as string}
                    questionSubTitle={questionSubTitle}
                    endLetter={endLetter as string}
                    grid={grid}
                    buttons={buttons as ImageButtonProps[]}
                    onClick={onMultiSelectResult}
                    buttonActive={nextButton}
                    onNextButtonClick={onNextButtonClick}
                />
            )}
            {buttonType === ButtonTypeEnum.IMAGE_SINGLE && (
                <SurveyImageSelect 
                    surveyTitle="기본정보"
                    questionTitle={questionTitle as string}
                    questionSubTitle={questionSubTitle}
                    endLetter={endLetter as string}
                    grid={grid}
                    buttons={buttons as ImageButtonProps[]}
                    onClick={onSingleSelectResult}
                    buttonActive={nextButton}
                    onNextButtonClick={onNextButtonClick}
                />
            )}
            {buttonType === ButtonTypeEnum.TEXT_MULTI && (
                <SurveyTextSelect 
                    surveyTitle="기본정보"
                    questionTitle={questionTitle as string}
                    questionSubTitle={questionSubTitle}
                    endLetter={endLetter as string}
                    grid={grid}
                    buttons={buttons as TextButtonProps[]}
                    onClick={onMultiSelectResult}
                    buttonActive={nextButton}
                    onNextButtonClick={onNextButtonClick}
                />
            )}
            {buttonType === ButtonTypeEnum.TEXT_SINGLE && (
                <SurveyTextSelect 
                    surveyTitle="기본정보"
                    questionTitle={questionTitle as string}
                    questionSubTitle={questionSubTitle}
                    endLetter={endLetter as string}
                    grid={grid}
                    buttons={buttons as TextButtonProps[]}
                    onClick={onSingleSelectResult}
                    buttonActive={nextButton}
                    onNextButtonClick={onNextButtonClick}
                />
            )}               
            {buttonType === ButtonTypeEnum.RANGE && (
                <SurveyRangeSelect 
                    surveyTitle="기본정보"
                    questionTitle={questionTitle as string}
                    questionSubTitle={questionSubTitle}
                    endLetter={endLetter as string}
                    grid={grid}
                    rangeStartLabel={rangeStartLabel as string}
                    rangeEndLabel={rangeEndLabel as string}
                    buttons={buttons as RangeButtonProps[]}
                    onClick={onSingleSelectResult}
                    buttonActive={nextButton}
                    onNextButtonClick={onNextButtonClick}
                />
            )}     
            <NextButton activate={nextButton} name="다음" onClick={onNextButtonClick} />       
        </Container>
    );
}

export default Question;