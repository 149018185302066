import styled from "styled-components";
import { motion } from "framer-motion";
import { useState } from "react";

const Container = styled.div`
    width: 100%;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: #ffffff;
    z-index: 10;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 51px 16px;
    flex-direction: column;

`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 33px;
    color: #1E1E1E;

`;

const Back = styled.img`
    margin-right: 8px;
    cursor: pointer;
`;

const SubTitle = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 17px;
    color: #A3AAB6;
    margin-top: 30px;
`;

const Line = styled.div`
    border-bottom: 1px solid #EDEEF0;
    width: 100%;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const Button = styled.div`
    cursor: pointer;
    margin-bottom: 30px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1E1E1E;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
`;

const Expand = styled(motion.img)`

`;

const Contents = styled(motion.div)`
    background-color: #F3F5F8;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 25px;
    overflow-y: hidden;
    height: 0;
`

const variants = {
    start: {
        height: '0',
    },
    end: {
        height: 'auto',
        overflow: 'auto',
    }
}

const imgVariants = {
    start: {

    },
    end: {
        transform: 'rotate(-180deg)'
    }
}

interface AgreeProps {
    onBack: () => void;
}

function Agree({
    onBack
}:AgreeProps){

    const [terms, setTerms] = useState(false);
    const [privacy, setPrivacy] = useState(false);

    return (
        <Container>
            <Title>
                <Back
                    onClick={onBack}
                src={require('../../../styles/assets/common/arrow_left.png')} />
                약관 및 정책
            </Title>
            <SubTitle>솔닥 서비스 이용에 대한 약관 및 정책 확인 가능합니다.</SubTitle>
            <Line />
            <Button
                onClick={() => {
                    setTerms(prev => !prev)
                }}
            >
                서비스 이용약관
                <Expand src={require('../../../styles/assets/common/btn_expand_less.png')} 
                    variants={terms ? imgVariants : undefined}
                    initial="start"
                    animate="end"
                    transition={{delay: 0, duration: 0.3}}
                />
            </Button>
            <Contents
                variants={terms ? variants : undefined}
                initial="start"
                animate="end"
                transition={{delay: 0, duration: 0.3}}
            >[솔닥 운영원칙]<br/>
            (주)아이케어닥터에서 운영하는 솔직한닥터, ‘솔닥’ 서비스는 건강지식 공유 플랫폼으로서 의료 전문가들이 유저와 소통하며 바른 의학정보를 텍스트, 영상 형식의 컨텐츠로 전달합니다.<br/>
            솔닥은 유저들의 일상속 건강증진을 위해 적극적인 소통과 바른 정보를 제공할 것을 약속드립니다.<br/><br/>            
            [중개사업자 면책 및 책임범위]<br/>
            사이버몰 내 판매되는 상품 중에는 솔닥에 등록한 개별 판매자가 판매하는 상품이 포함되어 있습니다.<br/>
            개별판매자가 판매하는 상품의 경우 솔닥은 통신판매중개자이며 통신판매의 당사자가 아닙니다.<br/>
            솔닥은 개별판매자의 상품, 거래정보 및 거래 등에 대하여 책임을 지지 않습니다.<br/>
            솔닥은 소비자 보호와 안전거래를 위해 고객센터를 운영하고 있으며, 관련 분쟁이 발생할 경우 별도의 분쟁처리절차에 의거 분쟁이 처리됩니다.<br/><br/>            
            [이용약관]<br/><br/>            
            표준약관 제10023호<br/><br/>            
            제1조(목적)<br/><br/>            
            이 약관은 주식회사 아이케어닥터(이하 “회사”)가 운영하는 솔닥 플랫폼 서비스(이하 “서비스”)의 이용과 관련하여 회사와 이용자의 권리·의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.<br/><br/>            
            제2조(정의)<br/><br/>            
            ① "플랫폼"이란 회사가 재화 또는 용역을 이용자에게 제공하기 위하여 컴퓨터 등 정보통신설비를 이용하여 재화 또는 용역을 거래할 수 있도록 설정한 가상의 영업장을 말하며, 아울러 플랫폼을 운영하는 사업자의 의미로도 사용합니다.<br/>
            ② "이용자"란 "플랫폼"에 접속하여 이 약관에 따라 "플랫폼"이 제공하는 서비스를 받는 회원 및 비회원을 말합니다.<br/>
            ③ ‘회원’이라 함은 "플랫폼"에 개인정보를 제공하여 회원등록을 한 자로서, "플랫폼"의 정보를 지속적으로 제공받으며, "플랫폼"이 제공하는 서비스를 계속적으로 이용할 수 있는 자를 말합니다.<br/>
            ④ ‘비회원’이라 함은 회원에 가입하지 않고 "플랫폼"이 제공하는 서비스를 이용하는 자를 말합니다.<br/><br/>            
            제3조(약관등의 명시와 설명 및 개정)<br/><br/>            
            ① "플랫폼"은 이 약관의 내용과 상호 및 대표자 성명, 영업소 소재지 주소(소비자의 불만을 처리할 수 있는 곳의 주소를 포함), 전화번호·모사전송번호·전자우편주소, 사업자등록번호, 통신판매업신고번호, 개인정보 보호책임자등을 이용자가 쉽게 알 수 있도록 솔닥의 초기 서비스화면(전면)에 게시합니다. 다만, 약관의 내용은 이용자가 연결화면을 통하여 볼 수 있도록 할 수 있습니다.<br/>
            ② "플랫폼"은 이용자가 약관에 동의하기에 앞서 약관에 정하여져 있는 내용 중 청약철회·배송책임·환불조건 등과 같은 중요한 내용을 이용자가 이해할 수 있도록 별도의 연결화면 또는 팝업화면 등을 제공하여 이용자의 확인을 구하여야 합니다.<br/>
            ③ "플랫폼"은 전자상거래등에서의소비자보호에관한법률, 약관의규제에관한법률, 전자문서및전자거래기본법, 전자금융거래법, 전자서명법, 정보통신망이용촉진등에관한법률, 방문판매등에관한법률, 소비자기본법 등 관련법을 위배하지 않는 범위에서 이 약관을 개정할 수 있습니다.<br/>
            ④ "플랫폼"이 약관을 개정할 경우에는 적용일자 및 개정사유를 명시하여 현행약관과 함께 플랫폼의 초기화면에 그 적용일자 7일 이전부터 적용일자 전일까지 공지합니다.<br/>
            다만, 이용자에게 불리하게 약관내용을 변경하는 경우에는 최소한 30일 이상의 사전 유예기간을 두고 공지합니다. 이 경우 "플랫폼"은 개정전 내용과 개정후 내용을 명확하게 비교하여 이용자가 알기 쉽도록 표시합니다.<br/>
            ⑤ "플랫폼"이 약관을 개정할 경우에는 그 개정약관은 그 적용일자 이후에 체결되는 계약에만 적용되고 그 이전에 이미 체결된 계약에 대해서는 개정전의 약관조항이 그대로 적용됩니다. 다만 이미 계약을 체결한 이용자가 개정약관 조항의 적용을 받기를 원하는 뜻을 제3항에 의한 개정약관의 공지기간내에 "플랫폼"에 송신하여 "플랫폼"의 동의를 받은 경우에는 개정약관 조항이 적용됩니다.<br/>
            ⑥ 이 약관에서 정하지 아니한 사항과 이 약관의 해석에 관하여는 전자상거래등에서의 소비자보호에 관한 법률, 약관의 규제 등에 관한 법률, 공정거래위원회가 정하는 전자상거래 등에서의 소비자보호지침 및 관계법령 또는 상관례에 따릅니다.<br/><br/>            
            제4조(서비스의 제공 및 변경)<br/><br/>            
            ① "플랫폼"은 다음과 같은 업무를 수행합니다.<br/>
            1. 재화 또는 용역에 대한 정보 제공 및 구매계약의 체결<br/>
            2. 구매계약이 체결된 재화 또는 용역의 배송<br/>
            3. 이용자의 원격의료진료처방의 예약, 진료 내역 관리 및 맞춤형 건강정보 제공관리<br/>
            4. 기타 "플랫폼"이 정하는 업무<br/>
            ② "플랫폼"은 재화 또는 용역의 품절 또는 기술적 사양의 변경 등의 경우에는 장차 체결되는 계약에 의해 제공할 재화 또는 용역의 내용을 변경할 수 있습니다. 이 경우에는 변경된 재화 또는 용역의 내용 및 제공일자를 명시하여 현재의 재화 또는 용역의 내용을 게시한 곳에 즉시 공지합니다.<br/>
            ③ "플랫폼"이 제공하기로 이용자와 계약을 체결한 서비스의 내용을 재화등의 품절 또는 기술적 사양의 변경 등의 사유로 변경할 경우에는 그 사유를 이용자에게 통지 가능한 주소로 즉시 통지합니다.<br/>
            ④ 전항의 경우 "플랫폼"은 이로 인하여 이용자가 입은 손해를 배상합니다. 다만, "플랫폼"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.<br/>
            ⑤ 건강정보와 관련된 내용은 대한민국 의료법과 보건복지부의 가이드라인에 따른 전문가의 지식의 기반한 의견 또는 개인적 생각으로 이것이 진단 등과 같은 의료적 행위가 아닌 전문가의 조언이므로 참고 목적으로만 사용 되어야 하며, 솔닥은 그 책임을 지지 않습니다. 이용자가 병원을 직접 방문하여 의사와 진찰과정을 통하여 취득한 진료결과나 건강진단결과, 처방결과를 대신하지 못합니다.<br/><br/>            
            제5조(서비스의 중단)<br/><br/>            
            ① "플랫폼"은 컴퓨터 등 정보통신설비의 보수점검·교체 및 고장, 통신의 두절 등의 사유가 발생한 경우에는 서비스의 제공을 일시적으로 중단할 수 있습니다.<br/>
            ② "플랫폼"은 제1항의 사유로 서비스의 제공이 일시적으로 중단됨으로 인하여 이용자 또는 제3자가 입은 손해에 대하여 배상합니다. 단, "플랫폼"이 고의 또는 과실이 없음을 입증하는 경우에는 그러하지 아니합니다.<br/>
            ③ 사업종목의 전환, 사업의 포기, 업체간의 통합 등의 이유로 서비스를 제공할 수 없게 되는 경우에는 "플랫폼"은 제8조에 정한 방법으로 이용자에게 통지하고 당초 "플랫폼"에서 제시한 조건에 따라 소비자에게 보상합니다. 다만, "플랫폼"이 보상기준 등을 고지하지 아니한 경우에는 이용자들의 마일리지 또는 적립금 등을 "플랫폼"에서 통용되는 통화가치에 상응하는 현물 또는 현금으로 이용자에게 지급합니다.<br/><br/>            
            제6조(회원가입)<br/><br/>            
            ① 이용자는 "플랫폼"이 정한 가입 양식에 따라 회원정보를 기입한 후 이 약관에 동의한다는 의사표시를 함으로서 회원가입을 신청합니다.<br/>
            ② "플랫폼"은 제1항과 같이 회원으로 가입할 것을 신청한 이용자 중 다음 각호에 해당하지 않는 한 회원으로 등록합니다.<br/>
            1. 가입신청자가 이 약관 제7조제3항에 의하여 이전에 회원자격을 상실한 적이 있는 경우, 다만 제7조제3항에 의한 회원자격 상실 후 3년이 경과한 자로서 "플랫폼"의 회원재가입 승낙을 얻은 경우에는 예외로 한다.<br/>
            2. 등록 내용에 허위, 기재누락, 오기가 있는 경우<br/>
            3. 기타 회원으로 등록하는 것이 "플랫폼"의 기술상 현저히 지장이 있다고 판단되는 경우<br/>
            ③ 회원가입계약의 성립시기는 "플랫폼"의 승낙이 회원에게 도달한 시점으로 합니다.<br/>
            ④ 회원은 회원 가입시 등록한 사항에 변경이 있는 경우, 상당한 기간 이내에 "플랫폼"에 대하여 회원정보 수정 등의 방법으로 그 변경사항을 알려야 합니다.<br/><br/>            
            제7조(회원 탈퇴 및 자격 상실 등)<br/><br/>            
            ① 회원은 "플랫폼"에 언제든지 탈퇴를 요청할 수 있으며 "플랫폼"은 즉시 회원탈퇴를 처리합니다.<br/>
            ② 회원이 다음 각호의 사유에 해당하는 경우, "플랫폼"은 회원자격을 제한 및 정지시킬 수 있습니다.<br/>
            1. 가입 신청시에 허위 내용을 등록한 경우<br/>
            2. "플랫폼"을 이용하여 구입한 재화 등의 대금, 기타 "플랫폼" 이용에 관련하여 회원이 부담하는 채무를 기일에 지급하지 않는 경우<br/>
            3. 다른 사람의 "플랫폼" 이용을 방해하거나 그 정보를 도용하는 등 전자상거래 질서를 위협하는 경우<br/>
            4. "플랫폼"을 이용하여 법령 또는 이 약관이 금지하거나 공서양속에 반하는 행위를 하는 경우<br/>
            ③ "플랫폼"이 회원 자격을 제한·정지 시킨 후, 동일한 행위가 2회 이상 반복되거나 30일 이내에 그 사유가 시정되지 아니하는 경우 "플랫폼"은 회원자격을 상실시킬 수 있습니다.<br/>
            ④ "플랫폼"이 회원자격을 상실시키는 경우에는 회원등록을 말소합니다. 이 경우 회원에게 이를 통지하고, 회원등록 말소 전에 최소한 30일 이상의 기간을 정하여 소명할 기회를 부여합니다.<br/><br/>            
            제8조(회원에 대한 통지)<br/><br/>            
            ① "플랫폼"이 회원에 대한 통지를 하는 경우, 회원이 "플랫폼"과 미리 약정하여 지정한 전자우편 주소로 할 수 있습니다.<br/>
            ② "플랫폼"은 불특정다수 회원에 대한 통지의 경우 1주일이상 "플랫폼" 게시판에 게시함으로서 개별 통지에 갈음할 수 있습니다. 다만, 회원 본인의 거래와 관련하여 중대한 영향을 미치는 사항에 대하여는 개별통지를 합니다.<br/><br/>            
            제9조(구매신청)<br/><br/>            
            "플랫폼"이용자는 "플랫폼"상에서 다음 또는 이와 유사한 방법에 의하여 구매를 신청하며, "플랫폼"은 이용자가 구매신청을 함에 있어서 다음의 각 내용을 알기 쉽게 제공하여야 합니다. 단, 회원인 경우 제2호 내지 제4호의 적용을 제외할 수 있습니다.<br/>
            1. 재화 등의 검색 및 선택<br/>
            2. 성명, 주소, 전화번호, 전자우편주소(또는 이동전화번호) 등의 입력<br/>
            3. 약관내용, 청약철회권이 제한되는 서비스, 배송료·설치비 등의 비용부담과 관련한 내용에 대한 확인<br/>
            4. 이 약관에 동의하고 위 3.호의 사항을 확인하거나 거부하는 표시(예, 마우스 클릭)<br/>
            5. 재화등의 구매신청 및 이에 관한 확인 또는 "플랫폼"의 확인에 대한 동의<br/>
            6. 결제방법의 선택<br/><br/>            
            제10조 (계약의 성립)<br/><br/>            
            ① "플랫폼"은 제9조와 같은 구매신청에 대하여 다음 각 호에 해당하면 승낙하지 않을 수 있습니다. 다만, 미성년자와 계약을 체결하는 경우에는 법정대리인의 동의를 얻지 못하면 미성년자 본인 또는 법정대리인이 계약을 취소할 수 있다는 내용을 고지하여야 합니다.<br/>
            1. 신청 내용에 허위, 기재누락, 오기가 있는 경우<br/>
            2. 미성년자가 담배, 주류 등 청소년보호법에서 금지하는 재화 및 용역을 구매하는 경우<br/>
            3. 기타 구매신청에 승낙하는 것이 "플랫폼" 기술상 현저히 지장이 있다고 판단하는 경우<br/>
            ② "플랫폼"의 승낙이 제12조제1항의 수신확인통지형태로 이용자에게 도달한 시점에 계약이 성립한 것으로 봅니다.<br/>
            ③ "플랫폼"의 승낙의 의사표시에는 이용자의 구매 신청에 대한 확인 및 판매 가능 여부, 구매신청의 정정 취소 등에 관한 정보 등을 포함하여야 합니다.<br/><br/>            
            제11조(지급방법) "플랫폼"에서 구매한 재화 또는 용역에 대한 대금지급방법은 다음 각호의 방법 중 가용한 방법으로 할 수 있습니다. 단, "플랫폼"은 이용자의 지급방법에 대하여 재화 등의 대금에 어떠한 명목의 수수료도 추가하여 징수할 수 없습니다.<br/>
            1. 폰뱅킹, 인터넷뱅킹, 메일 뱅킹 등의 각종 계좌이체<br/>
            2. 선불카드, 직불카드, 신용카드 등의 각종 카드 결제<br/>
            3. 온라인무통장입금<br/>
            4. 전자화폐에 의한 결제<br/>
            5. 수령시 대금지급<br/>
            6. 마일리지 등 "플랫폼"이 지급한 포인트에 의한 결제<br/>
            7. "플랫폼"과 계약을 맺었거나 "플랫폼"이 인정한 상품권에 의한 결제<br/>
            8. 기타 전자적 지급 방법에 의한 대금 지급 등<br/><br/>            
            제12조(수신확인통지·구매신청 변경 및 취소)<br/><br/>            
            ① "플랫폼"은 이용자의 구매신청이 있는 경우 이용자에게 수신확인통지를 합니다.<br/>
            ② 수신확인통지를 받은 이용자는 의사표시의 불일치 등이 있는 경우에는 수신확인통지를 받은 후 즉시 구매신청 변경 및 취소를 요청할 수 있고 "플랫폼"은 배송 전에 이용자의 요청이 있는 경우에는 지체없이 그 요청에 따라 처리하여야 합니다. 다만 이미 대금을 지불한 경우에는 제15조의 청약철회 등에 관한 규정에 따릅니다.<br/><br/>            
            제13조(재화등의 공급)<br/><br/>            
            ① "플랫폼"은 이용자와 재화등의 공급시기에 관하여 별도의 약정이 없는 이상, 이용자가 청약을 한 날부터 7일 이내에 재화 등을 배송할 수 있도록 주문제작, 포장 등 기타의 필요한 조치를 취합니다. 다만, "플랫폼"이 이미 재화 등의 대금의 전부 또는 일부를 받은 경우에는 대금의 전부 또는 일부를 받은 날부터 2영업일 이내에 조치를 취합니다. 이때 "플랫폼"은 이용자가 재화 등의 공급 절차 및 진행 사항을 확인할 수 있도록 적절한 조치를 합니다.<br/>
            ② "플랫폼"은 이용자가 구매한 재화에 대해 배송수단, 수단별 배송비용 부담자, 수단별 배송기간 등을 명시합니다. 만약 "플랫폼"이 약정 배송기간을 초과한 경우에는 그로 인한 이용자의 손해를 배상하여야 합니다. 다만 "플랫폼"이 고의·과실이 없음을 입증한 경우에는 그러하지 아니합니다.<br/><br/>            
            제14조(환급)<br/><br/>            
            "플랫폼"은 이용자가 구매신청한 재화등이 품절 등의 사유로 인도 또는 제공을 할 수 없을 때에는 지체없이 그 사유를 이용자에게 통지하고 사전에 재화 등의 대금을 받은 경우에는 대금을 받은 날부터 2영업일 이내에 환급하거나 환급에 필요한 조치를 취합니다.<br/><br/>            
            제15조(청약철회 등)<br/><br/>            
            ① "플랫폼"과 재화등의 구매에 관한 계약을 체결한 이용자는 수신확인의 통지를 받은 날부터 7일 이내에는 청약의 철회를 할 수 있습니다.<br/>
            ② 이용자는 재화 등을 배송받은 경우 다음 각호의 1에 해당하는 경우에는 반품 및 교환을 할 수 없습니다.<br/>
            1. 이용자에게 책임 있는 사유로 재화 등이 멸실 또는 훼손된 경우(다만, 재화 등의 내용을 확인하기 위하여 포장 등을 훼손한 경우에는 청약철회를 할 수 있습니다)<br/>
            2. 이용자의 사용 또는 일부 소비에 의하여 재화 등의 가치가 현저히 감소한 경우<br/>
            3. 시간의 경과에 의하여 재판매가 곤란할 정도로 재화 등의 가치가 현저히 감소한 경우<br/>
            4. 같은 성능을 지닌 재화 등으로 복제가 가능한 경우 그 원본인 재화 등의 포장을 훼손한 경우<br/>
            ③ 제2항제2호 내지 제4호의 경우에 "플랫폼"이 사전에 청약철회 등이 제한되는 사실을 소비자가 쉽게 알 수 있는 곳에 명기하거나 시용상품을 제공하는 등의 조치를 하지 않았다면 이용자의 청약철회등이 제한되지 않습니다.<br/>
            ④ 이용자는 제1항 및 제2항의 규정에 불구하고 재화등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행된 때에는 당해 재화 등을 공급받은 날부터 3월 이내, 그 사실을 안 날 또는 알 수 있었던 날부터 30일 이내에 청약철회 등을 할 수 있습니다.<br/><br/>            
            제16조(청약철회 등의 효과)<br/><br/>            
            ① "플랫폼"은 이용자로부터 재화 등을 반환받은 경우 3영업일 이내에 이미 지급받은 재화 등의 대금을 환급합니다. 이 경우 "플랫폼"이 이용자에게 재화 등의 환급을 지연한 때에는 그 지연기간에 대하여 공정거래위원회가 정하여 고시하는 지연이자율을 곱하여 산정한 지연이자를 지급합니다.<br/>
            ② "플랫폼"은 위 대금을 환급함에 있어서 이용자가 신용카드 또는 전자화폐 등의 결제수단으로 재화등의 대금을 지급한 때에는 지체없이 당해 결제수단을 제공한 사업자로 하여금 재화 등의 대금의 청구를 정지 또는 취소하도록 요청합니다.<br/>
            ③ 청약철회등의 경우 공급받은 재화 등의 반환에 필요한 비용은 이용자가 부담합니다. "플랫폼"은 이용자에게 청약철회등을 이유로 위약금 또는 손해배상을 청구하지 않습니다. 다만 재화 등의 내용이 표시·광고 내용과 다르거나 계약내용과 다르게 이행되어 청약철회 등을 하는 경우 재화 등의 반환에 필요한 비용은 "플랫폼"이 부담합니다.<br/>
            ④ 이용자가 재화 등을 제공받을때 발송비를 부담한 경우에 "플랫폼"은 청약철회시 그 비용을 누가 부담하는지를 이용자가 알기 쉽도록 명확하게 표시합니다.<br/><br/>            
            제17조(개인정보보호)<br/><br/>            
            ① "플랫폼"은 이용자의 정보수집시 구매계약 이행, 서비스 제공을 위하여 필요한 최소한의 정보를 수집합니다. 다음 사항을 필수사항으로 하며 그 외 사항은 선택사항으로 합니다.<br/>
            1. 성명<br/>
            2. 주소<br/>
            3. 전화번호<br/>
            4. 희망ID(회원의 경우)<br/>
            5. 비밀번호(회원의 경우)<br/>
            6. 전자우편주소(또는 이동전화번호)<br/>
            ② "플랫폼"이 이용자의 개인식별이 가능한 개인정보를 수집하는 때에는 반드시 당해 이용자의 동의를 받습니다.<br/>
            ③ 제공된 개인정보는 당해 이용자의 동의없이 목적 외의 이용이나 제3자에게 제공할 수 없으며, 이에 대한 모든 책임은 플랫폼이 집니다. 다만, 다음의 경우에는 예외로 합니다.<br/>
            1. 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우<br/>
            2. 계약의 체결 및 이행을 위하여 불가피하게 필요한 경우<br/>
            3. 재화, 서비스 등의 거래에 따른 대금 정산을 위하여 필요한 경우<br/>
            4. 배송 업무 상 배송업체에게 배송에 필요한 최소한의 이용자의 정보(성명, 주소, 전화번호)를 알려주는 경우<br/>
            5. 수사기관이 수사 목적을 위해 관계법령이 정한 절차를 거쳐 요구하는 경우<br/>
            6. 통계작성, 학술연구 또는 시장조사를 위하여 필요한 경우로서 특정 개인을 식별할 수 없는 형태로 제공하는 경우<br/>
            ④ "플랫폼"이 제2항과 제3항에 의해 이용자의 동의를 받아야 하는 경우에는 개인정보 보호책임자의 신원(소속, 성명 및 전화번호, 기타 연락처), 정보의 수집목적 및 이용목적, 제3자에 대한 정보제공 관련사항(제공받은 자, 제공목적 및 제공할 정보의 내용) 등 개인정보보호법 제17조제2항, 제18조제3항이 규정한 사항을 알려야 하며 이용자는 언제든지 이 동의를 철회할 수 있습니다.<br/>
            ⑤ 이용자는 언제든지 "플랫폼"이 가지고 있는 자신의 개인정보에 대해 열람 및 오류정정을 요구할 수 있으며 "플랫폼"은 이에 대해 지체없이 필요한 조치를 취할 의무를 집니다. 이용자가 오류의 정정을 요구한 경우에는 "플랫폼"은 그 오류를 정정할 때까지 당해 개인정보를 이용하지 않습니다.<br/>
            ⑥ "플랫폼"은 개인정보 보호를 위하여 이용자의 개인정보 관리자를 한정하여 그 수를 최소화하며 신용카드, 은행계좌 등을 포함한 이용자의 개인정보의 분실, 도난, 유출, 동의 없는 제3자 제공, 변조 등으로 인한 이용자의 손해에 대하여 모든 책임을 집니다.<br/>
            ⑦ "플랫폼" 또는 그로부터 개인정보를 제공받은 제3자는 개인정보의 수집목적 또는 제공받은 목적을 달성한 때에는 당해 개인정보를 지체없이 파기합니다.<br/><br/>           
            제18조("플랫폼"의 의무)<br/><br/>            
            ① "플랫폼"은 법령과 이 약관이 금지하거나 공서양속에 반하는 행위를 하지 않으며 이 약관이 정하는 바에 따라 지속적이고, 안정적으로 재화·용역을 제공하는데 최선을 다하여야 합니다.<br/>
            ② "플랫폼"은 이용자가 안전하게 인터넷 서비스를 이용할 수 있도록 이용자의 개인정보(신용정보 포함)보호를 위한 보안 시스템을 갖추어야 합니다.<br/>
            ③ "플랫폼"이 상품이나 용역에 대하여 「표시·광고의공정화에관한법률」 제3조 소정의 부당한 표시·광고행위를 함으로써 이용자가 손해를 입은 때에는 이를 배상할 책임을 집니다.<br/>
            ④ "플랫폼"은 이용자가 원하지 않는 영리목적의 광고성 전자우편을 발송하지 않습니다.<br/><br/>            
            제19조(회원의 ID 및 비밀번호에 대한 의무)<br/><br/>            
            ① 제17조의 경우를 제외한 ID와 비밀번호에 관한 관리책임은 회원에게 있습니다.<br/>
            ② 회원은 자신의 ID 및 비밀번호를 제3자에게 이용하게 해서는 안됩니다.<br/>
            ③ 회원이 자신의 ID 및 비밀번호를 도난당하거나 제3자가 사용하고 있음을 인지한 경우에는 바로 "플랫폼"에 통보하고 "플랫폼"의 안내가 있는 경우에는 그에 따라야 합니다.<br/><br/>            
            제20조(이용자의 의무) 이용자는 다음 행위를 하여서는 안됩니다.<br/>
            1. 신청 또는 변경시 허위 내용의 등록<br/>
            2. 타인의 정보 도용<br/>
            3. "플랫폼"에 게시된 정보의 변경<br/>
            4. "플랫폼"이 정한 정보 이외의 정보(컴퓨터 프로그램 등) 등의 송신 또는 게시<br/>
            5. "플랫폼" 기타 제3자의 저작권 등 지적재산권에 대한 침해<br/>
            6. "플랫폼" 기타 제3자의 명예를 손상시키거나 업무를 방해하는 행위<br/>
            7. 외설 또는 폭력적인 메시지, 화상, 음성, 기타 공서양속에 반하는 정보를 플랫폼에 공개 또는 게시하는 행위<br/><br/>            
            제21조(연결"플랫폼"과 피연결"플랫폼" 간의 관계)<br/><br/>            
            ① 상위 "플랫폼"과 하위 "플랫폼"이 하이퍼링크(예: 하이퍼 링크의 대상에는 문자, 그림 및 동화상 등이 포함됨)방식 등으로 연결된 경우, 전자를 연결 "플랫폼"(웹 사이트)이라고 하고 후자를 피연결 "플랫폼"(웹사이트)이라고 합니다.<br/>
            ② 연결"플랫폼"은 피연결"플랫폼"이 독자적으로 제공하는 재화 등에 의하여 이용자와 행하는 거래에 대해서 보증책임을 지지 않는다는 뜻을 연결"플랫폼"의 초기화면 또는 연결되는 시점의 팝업화면으로 명시한 경우에는 그 거래에 대한 보증책임을 지지 않습니다.<br/><br/>            
            제22조("플랫폼" 제공 컨텐츠의 저작권 보호)<br/><br/>            
            ① 솔닥에서 제공하는 각종 서비스의 내용을 보호하고, 저작권 등 타인의 권리를 침해하지 않도록 하기 위하여 다음과 같은 저작권 정책을 운영하고 있으니, 회원 및 솔닥 이용 고객 분들은 이를 살펴보시고 숙지하시어 타인의 권리를 침해하지 않도록 하시기 바랍니다.<br/><br/>            
            1. 게시물의 저작권 귀속 및 책임<br/>
            - 회원이 작성하여 서비스에 게재 또는 등록한 답변, 자료, 사진 등에 대한 저작권은 회원 본인에게 있는 것으로 추정되며, 게시물이 타인의 저작권 등 지적재산권을 침해하는 경우 그에 대한 책임은 회원 본인이 부담합니다.<br/>
            - 회사가 저작권을 가지고 있는 게시물을 이용하는 경우에는 학술, 연구 등 개인적 목적으로만 이용하여야 하며, 회사의 명시적 동의 없이 게시물의 전부 또는 일부를 복제, 배포, 전송 등의 방법으로 이용하거나 영리적 목적으로 이용하는 경우에는 민사상의 책임뿐만 아니라 형사처벌을 받을 수도 있습니다.<br/><br/>            
            2. 회원 및 제3자의 저작권 보호<br/>
            - 저작권자의 명시적 동의 없이 타인의 저작물(컴퓨터프로그램 포함. 이하 같습니다)의 전부 또는 일부를 복제, 배포, 전송 등의 방법으로 이용하는 것은 저작권자의 권리를 침해하는 행위이므로, 복제/배포/전송 등의 방법으로 타인의 저작권을 침해하는 게시물을 “플랫폼”에 게재 또는 등록하여서는 아니 됩니다.<br/>
            - 타인의 저작물을 인용한 게시물인 경우 그 인용 부분이 회원 개인의 의견을 뒷받침하기 위해 일부 인용된 것이 아니라 게시물의 핵심적인 부분을 구성하고 있다면 출처를 밝히더라도 저작권 침해에 해당되므로, 저작권자의 명시적 동의 없이 이러한 게시물을 “플랫폼”에 게재 또는 등록하여서는 아니 됩니다.<br/>
            - “플랫폼” 서비스에 게재 또는 등록된 회원의 게시물을 제3자가 사용하고자 할 경우에는 반드시 해당 회원의 명시적 동의를 얻은 뒤 사용하여야 합니다.<br/>
            - “플랫폼”은 회원이 서비스 이용과정에서 타인의 저작권, 상표권, 의장권 등 지적 재산권을 침해하는 경우 어떠한 책임도 부담하지 않습니다.<br/>
            - 서비스 상의 게시물은 회원들간의 지식공유를 위해 회원이 게재한 것이므로 회사는 게시물에 대한 정확성에 대해서는 보증을 하지 않습니다.<br/>
            - “플랫폼”은 회원들의 게시물 이용에 따른 동의절차를 중개하거나 그 과정에 개입하지 않습니다.<br/><br/>            
            3. 게시물의 중단 및 재개 요청<br/>
            - 저작권 침해 신고에 관한 사항은 고객센터 또는 메일을 통해 연락 주시기 바랍니다.<br/><br/>            
            제23조(분쟁해결)<br/>
            ① "플랫폼"은 이용자가 제기하는 정당한 의견이나 불만을 반영하고 그 피해를 보상처리하기 위하여 피해보상처리기구를 설치·운영합니다.<br/>
            ② "플랫폼"은 이용자로부터 제출되는 불만사항 및 의견은 우선적으로 그 사항을 처리합니다. 다만, 신속한 처리가 곤란한 경우에는 이용자에게 그 사유와 처리일정을 즉시 통보해 드립니다.<br/>
            ③ "플랫폼"과 이용자간에 발생한 전자상거래 분쟁과 관련하여 이용자의 피해구제신청이 있는 경우에는 공정거래위원회 또는 시·도지사가 의뢰하는 분쟁조정기관의 조정에 따를 수 있습니다.<br/>          
            제24조(재판권 및 준거법)<br/>
            ① "플랫폼"과 이용자간에 발생한 전자상거래 분쟁에 관한 소송은 제소 당시의 이용자의 주소에 의하고, 주소가 없는 경우에는 거소를 관할하는 지방법원의 전속관할로 합니다. 다만, 제소 당시 이용자의 주소 또는 거소가 분명하지 않거나 외국 거주자의 경우에는 민사소송법상의 관할법원에 제기합니다.<br/>
            ② "플랫폼"과 이용자간에 제기된 전자상거래 소송에는 한국법을 적용합니다.<br/>
            부칙<br/>            
            1. 이 약관은 2022년 1월 1일부터 적용됩니다.<br/>
            </Contents>
            <Button
                onClick={() => {
                    setPrivacy(prev => !prev)
                }}
            >
                개인정보 처리방침
                <Expand src={require('../../../styles/assets/common/btn_expand_less.png')} 
                    variants={privacy ? imgVariants : undefined}
                    initial="start"
                    animate="end"
                    transition={{delay: 0, duration: 0.3}}
                />
            </Button>
            <Contents
                 variants={privacy ? variants : undefined}
                 initial="start"
                 animate="end"
                 transition={{delay: 0, duration: 0.3}}
            >
                (주)아이케어닥터(이하 "회사")는 정보주체의 자유와 권리 보호를 위해 「개인정보보호법」 및 관계 법령이 정한 바를 준수하며, 적법하게 개인정보를 처리하고 안전하게 관리하고 있습니다. 이에 「개인정보보호법」 제30조에 따라 정보주체에게 개인정보 처리에 관한 절차 및 기준을 안내하고, 이와 관련한 고충을 신속하고 원활하게 처리할 수 있도록 하기 위하여 다음과 같이 개인정보 처리방침을 수립·공개합니다. 본 개인정보처리방침은 회사가 제공하는 “홈페이지(www.soldoc.co.kr)” 및 “카카오챗봇(솔닥채널)” (이하 홈페이지 및 카카오챗봇을 통칭하여 “서비스”)이용에 적용되며, 이를 개정하는 경우 회사가 제공하는 서비스의 공지사항(또는 개별공지)을 통하여 공지합니다.<br/>
                <br/>
                <br/>
                개인정보의 처리 목적<br/>
                회사는 다음의 목적을 위하여 개인정보를 처리합니다. 처리하고 있는 개인정보는 다음의 목적 이외의 용도로는 이용되지 않으며, 이용 목적이 변경되는 경우에는 개인정보보호법 제18조에 따라 별도의 동의를 받는 등 필요한 조치를 이행할 예정입니다.<br/>
                <br/>
                1. 회원가입 및 관리<br/>
                - 회원 가입의사 확인, 회원제 서비스 제공에 따른 본인 식별·인증, 회원자격 유지·관리, 서비스 부정이용 방지, 만 14세 미만 아동 개인정보 수집 시 법정대리인 동의 여부 확인, “솔닥” 이용약관 위반 회원에 대한 이용제한 조치, 가입 및 가입 횟수 제한, 각종 고지·통지, 고충처리, 분쟁 조정을 위한 기록 보존, 민원사무 처리, 서비스 이용에 대한 통계 수집 목적으로 개인정보를 처리합니다.<br/>
                2. 서비스 제공에 관한 계약 이행 및 요금 정산<br/>
                - 비대면 진료 예약, 예약 조회 및 예약 일정에 대한 고지, 서비스 요금 결제, 약배달 서비스 예약, 처방전 발행 또는 배달 서비스 제공, 물품 배송 또는 청구서 등 발송, 금융거래 본인 인증 및 결제 서비스 등 계약 체결 및 이행을 위한 목적으로 개인정보를 처리합니다.<br/>
                3. 신규 서비스 개발 및 마케팅, 광고 제공<br/>
                - 신규 서비스 개발 및 맞춤 서비스 제공, 통계학적 특성에 따른 서비스 제공 및 광고 게재, 서비스의 유효성 확인, 이벤트 정보 및 참여기회 제공, 광고성 정보 제공, 접속빈도 파악, 회원의 서비스이용에 대한 통계<br/>
                4. 기타 문의, 서비스 요청에 대한 응대 및 처리<br/>
                - 병원 제휴 문의에 대한 처리, 컨텐츠 요청에 대한 응대 및 안내, 병원 상담 요청에 대한 내용 전달<br/>
                <br/>
                개인정보의 처리 및 보유 기간<br/>
                ① 회사는 법령에 따른 개인정보 보유·이용 기간 또는 정보주체로부터 개인정보를 수집 시에 동의받은 개인정보 보유·이용 기간 내에서 개인정보를 처리·보유합니다.<br/>
                ② 각각의 개인정보 처리 및 보유 기간은 다음과 같습니다.<br/>
                <br/>
                1. 서비스 이용자의 개인정보는 원칙적으로 수집일로부터 6개월 간[또는 회원 탈퇴 시까지] 보관 후 폐기하되, 개인정보의 수집 및 이용 목적이 달성되거나 이용자가 이용계약 해지(회원탈퇴)를 요청한 경우 지체 없이 파기됩니다. 단, 다음의 정보에 대해서는 아래의 이유로 명시한 기간까지 보존합니다.<br/>
                2. 회사 내부 방침에 의한 정보 보관 사유<br/>
                - 회사는 불량 이용자의 부정한 이용의 재발을 방지하기 위해 이용계약 해지일(회원 탈퇴일)로부터 6개월간 해당 이용자의 개인정보를 보관합니다.<br/>
                - 회사는 관계 법령 위반에 따른 수사·조사 등이 진행 중인 경우에는 다른 이용자를 보호하고 사법기관 수사의뢰 시 증거자료로 활용하기 위해 이용계약 해지일(회원 탈퇴일)로부터 6개월간 해당 이용자의 개인정보를 보관합니다.<br/>
                - 회사는 홈페이지 이용에 따른 채권·채무관계 잔존 시 해당 채권·채무관계 정산 시까지 해당 이용자의 개인정보를 보관합니다.<br/>
                <br/>
                3. 관련 법령에 의한 정보 보관 사유<br/>
                근거 보관 정보 보존 기간<br/>
                전자상거래 등에서의 소비자보호에 관한 법률 표시·광고에 관한 기록 6개월<br/>
                계약 또는 청약철회 등에 관한 기록 5년<br/>
                대금결제 및 재화 등의 공급에 관한 기록 5년<br/>
                소비자의 불만 또는 분쟁처리에 관한 기록 3년<br/>
                의료법 처방전 2년<br/>
                진단서 등의 부본 3년<br/>
                환자 명부, 검사소견기록, 간호기록부, 5년<br/>
                방사선 사진 및 그 소견서, 조산기록부 5년<br/>
                진료기록부, 수술기록 10년<br/>
                약사법 비급여 처방전 2년<br/>
                의료급여법 의료급여 관련 처방전 3년<br/>
                <br/>
                * 정보주체로부터 별도로 진료 기록, 처방 관련 문서를 제공받은 경우 본 개인정보 처리방침에 따라 6개월 간 보관 후 폐기합니다. 다만, 병원 및 약국과의 개인정보 처리 위수탁 계약에 따라 수탁자로서 진료기록부, 처방전 등을 보관하는 경우 위수탁 계약 기간 동안 보유하고 계약 종료 시 이를 폐기함을 원칙으로 합니다. 다만, 회사가 병원 및 약국의 개인정보처리 수탁자로서 위 각 정보를 보관하는 경우 각 법령에 정한 기간 동안 각 정보를 보관합니다.<br/>
                <br/>
                ③ 2020년 4월 1일 이후 가입한 이용자가 1년간 서비스 거래기록이 없을 시, 서비스 미이용자의 개인정보는 개인정보보호법 제39조의6, 같은 법 시행령 제48조의5에 근거하여 이용자의 개인정보를 해당기간 경과 후 즉시 파기하거나 다른 이용자의 개인정보와 분리하여 별도로 저장 관리합니다. 고객의 요청이 있을 경우에는 위 기간을 달리 정할 수 있습니다. 단, 통신비밀보호법, 전자상거래 등에서의 소비자보호에 관한 법률 등 다른 법령의 규정에 의하여 이용자의 개인정보를 보존할 필요가 있는 경우 다른 법령에서 규정한 일정한 기간 동안 이용자 개인정보를 보관합니다. 회사는 위의 기간 만료 30일 전까지 1) 개인정보가 파기되는 사실, 기간 만료일 및 파기되는 개인정보의 항목 또는 2) 개인정보가 분리되어 저장·관리되는 사실, 기간 만료일 및 분리·저장되어 관리되는 개인정보의 항목을 전자우편, 서면 등의 방법으로 고객에게 알립니다. 이를 위해 고객은 정확한 연락처 정보를 제공 및 변경 시 이를 수정하여야 합니다.<br/>
                <br/>
                <br/>
                처리하는 개인정보의 항목<br/>
                ① 회사는 서비스 운영에 필요한 최소한의 개인정보만 수집하며, 서비스 이용을 위하여 개인정보가 추가로 필요한 경우 회원에게 별도의 동의를 받은 후 개인정보를 수집할 예정입니다.<br/>
                <br/>
                <br/>
                구분 : 회원 가입 및 관리<br/>
                처리항목 : 일반 회원<br/>
                세부내용 : 이름, 아이디, 비밀번호, 이메일, 휴대폰 번호, 생년월일, 카카오톡 ID, 회원 탈퇴 사유, 암호화된 동일인 식별정보(CI), 중복가입 확인정보(DI)<br/>
                <br/>
                처리항목 : 제휴 의사/약사 회원<br/>
                세부내용 : 이름, 생년월일, 사진, 이메일, 휴대폰 번호, 면허번호, 근무처 정보(병원/약국명, 주소, 연락처), 결제정보(계좌정보, 진료비용, 조제비), 학력, 경력, 진료과목, 배달가능과목, 전문분야, 요양기관번호, 사업자등록번호, 암호화된 동일인 식별정보(CI), 중복가입 확인정보(DI)<br/>
                <br/>
                구 분 : 진료예약, 원격진료 등 서비스 제공<br/>
                처리항목 : 원격진료 예약, 알림, 원격진료 // 세부내용 : 카카오톡 ID, 휴대폰 번호, 이름, 주소, 생년월일, 이메일, 초·재진여부, 진료시간(원격진료 가능한 날짜·시간), 진료과목, 진료신청서, 선호의약품, 화상진료 영상, 처방전 및 처방정보<br/>
                처리항목 : 진료를 위한 기본정보 // 세부내용 : 성별, 신장, 체중, 혈액형, 이메일<br/>
                처리항목 : 진료를 위한 생활방식 정보// 세부내용 : 흡연, 음주, 활동량<br/>
                처리항목 : 진료를 위한 사전 문진 정보 // 세부내용 : 증상에 대한 설명, 증상 사진<br/>
                처리항목 : 결제카드 정보 // 세부내용 : 카드사, 카드종류, 카드번호, 카드비밀번호 앞 2자리, CVC 번호, 유효기간, 생년월일, 이름<br/>
                처리항목 : 배송 정보 // 세부내용 : 수령인 이름, 휴대폰 번호, 배송주소<br/>
                처리항목 : 이용 관련 정보 // 세부내용 : 서비스 이용기록, 접속로그, 접속 IP 정보, 의사 정보, 진료과목, 전문분야, 진료예약 내역<br/>
                처리항목 : 서비스 후기 수집 // 세부내용 : 사용후기<br/>
                <br/>
                구 분 : 기타 정보<br/>
                처리항목 : 상담 서비스 // 세부내용 : 서비스 이용 내역, 상담 내역<br/>
                처리항목 : 계좌이체 이용 결제, 환불 이용 시 // 세부내용 : 예금주, 은행명, 계좌번호<br/>
                처리항목 : 서비스 이용 관련 // 세부내용 : 접속 기기 정보, 접속 로그, IP 주소, 쿠키, 기기고유번호, 방문일시, 불량 이용 기록, 사용 이동통신사, 광고식별자<br/>
                <br/>
                ② 회사는 「개인정보보호법」과 「정보통신망법」상 개인정보 처리방침 및 개인정보보호위원회가 정한 「표준 개인정보 보호지침」을 준수하고 있습니다. 회사는 개인정보처리방침을 통하여 귀하가 제공하는 개인정보가 어떠한 용도와 방식으로 이용되고 있으며, 개인정보보호를 위해 어떠한 조치가 취해지고 있는지 알려 드립니다.<br/>
                ③ 회사는 개인정보 처리방침을 사이트 첫 화면과 고객센터의 ‘개인정보 처리방침’ 항목에서 공개함으로써 이용자들이 언제나 용이하게 볼 수 있도록 조치하고 있습니다.<br/>
                ④ 회사는 개인정보 처리방침의 지속적인 개선을 위하여 개인정보 처리방침을 개정하는데 필요한 절차를 정하고 있습니다. 그리고 개인정보 처리방침을 개정하는 경우 버전번호 등을 부여하여 개정된 사항을 이용자가 쉽게 알아볼 수 있도록 하고 있습니다.<br/>
                ⑤ 서비스 아이디를 이용한 부가 서비스 및 맞춤식 서비스 이용 또는 이벤트 응모 과정에서 해당 서비스의 이용자에 한하여, 추가적으로 개인정보 처리가 발생할 수 있습니다. 해당 개인정보 처리 시점에서 이용자에게 처리하는 개인정보 항목(이름, 휴대폰 번호, 이벤트 응모 및 상담 내용), 처리목적, 개인정보 보유 및 이용기간에 대해 안내 드리고 동의를 받습니다.<br/>
                <br/>
                <br/>   
                개인정보의 제3자 제공<br/>
                ① 회사는 정보주체의 개인정보를 개인정보의 처리 목적에서 명시한 범위 내에서만 처리하며, 정보주체의 동의, 법률의 특별한 규정 등 「개인정보보호법」 제17조, 제18조에 해당하는 경우에만 개인정보를 제3자에게 제공하고 그 이외에는 정보주체의 개인정보를 제3자에게 제공하지 않습니다.<br/>
                ② 회사는 원활한 서비스 제공을 위해 다음의 경우 정보주체의 동의를 얻어 필요 최소한의 범위로만 개인정보를 제공합니다.<br/>
                <br/>
                제공받는 자 : “솔닥” 파트너 의료기관 전체[*1]<br/>
                제공 목적 : 원격진료, 처방, 건강상담, 예약 접수 서비스 제공 및 내역 확인<br/>
                제공 항목 : 본인 여부 확인 정보, 이름, 생년월일, 성별, 휴대폰 번호, 환자 기본정보, 생활방식 정보, 사전 문진 정보, 증상 관련 사진, 과거 진료내역, 진료 영상 기록<br/>
                보유 및 이용기간: 수집일로부터 6개월[*2]. 단, 진료 목적 달성 또는 이용 계약 종료, 회원 탈퇴 시 파기함.<br/>
                <br/>
                [*1] “솔닥” 진료 매칭은 랜덤으로 이루어지므로, 제휴 의료기관 전체에 대한 제공 동의를 수집합니다.<br/>
                [*2] 단, 다른 관련 법령에 따라 별도 보관되는 정보는 다른 법령에서 정한 기간 동안 보관<br/>
                [*3] 동의 거부에 따른 불이익: “솔닥” 원격진료 서비스 이용 제한<br/>
                <br/>
                ③ 회사는 예외적으로 아래의 경우 이용자의 개인정보를 제3자에게 제공, 공유할 수 있습니다.<br/>
                - 법률에 특별한 규정이 있거나 법령상 의무를 준수하기 위하여 불가피한 경우<br/>
                - 정보주체와의 계약의 체결 및 이행을 위하여 불가피하게 필요한 경우<br/>
                - 서비스의 제공에 따른 요금 정산을 위하여 필요한 경우<br/>
                - 수사기관이 수사 목적을 위해 관계법령이 정한 절차를 거쳐 요구하는 경우<br/>
                - 통계작성 및 학술연구 등의 목적을 위해 필요한 경우로서 특정 개인을 알아볼 수 없는 형태로 개인정보를 제공하는 경우<br/> 
                <br/>
                개인정보 처리의 위탁<br/>
                ① 회사는 원활한 개인정보 업무처리를 위하여 다음과 같이 개인정보 처리업무를 위탁하고 있습니다.<br/>
                <br/>
                위탁받는 자(수탁자) 위탁하는 업무 보유 및 이용기간<br/>
                엔에이치엔고도㈜ : 주문을 위한 주문자, 주문정보 등 주문 및 취소에 따른 관련 정보<br/>
                NHN한국사이버결제 주식회사 : 주문을 위한 주문자 주문정보 등 주문 및 취소에 따른 관련 정보<br/>
                ㈜페이플 : 주문을 위한 주문자 주문정보 등 주문 및 취소에 따른 관련 정보<br/>
                Amazon Web Services, Inc. : 서비스 제공 및 분석을 위한 인프라 관리<br/>
                ㈜카카오 : 카카오톡 채널을 이용한 메세지 발송, 서비스 제공 및 분석을 위한 인프라 관리<br/>
                <br/>
                ② 회사는 위탁계약 체결 시 「개인정보보호법」 제26조, 같은 법 시행령 제28조에 따라 위탁업무 수행 목적 외 개인정보 처리 금지, 개인정보의 기술적·관리적 보호조치, 위탁업무의 목적 및 범위, 재위탁 제한, 개인정보에 대한 접근 제한 등 안정성 확보 조치, 위탁업무와 관련하여 보유하고 있는 개인정보의 관리 현황 점검 등 감독, 수탁자가 준수하여야 할 의무를 위반한 경우의 손해배상 책임에 관한 사항을 계약서 등 문서(전자문서)에 명시하고, 수탁자가 개인정보를 안전하게 처리하는지를 감독하고 있습니다.<br/>
                ③ 위탁업무의 내용이나 수탁자가 변경될 경우, 지체없이 본 개인정보 처리방침을 통하여 공개하도록 하겠습니다.<br/>
                <br/>
                개인정보의 파기<br/>
                ① 회사는 개인정보 보유기간의 경과, 개인정보의 처리 목적 달성 등 개인정보가 불필요하게 되었을 때에는 지체 없이 해당 개인정보를 파기합니다.<br/>
                ② 회사는 정보주체로부터 동의받은 개인정보 보유기간이 경과하거나 처리 목적이 달성되었음에도 불구하고 다른 법령에 따라 개인정보를 계속 보존하여야 하는 경우에는, 해당 개인정보를 다른 개인정보와 별도의 데이터베이스(DB)로 옮겨 분리하여 저장, 관리합니다. 회사는 별도 DB로 옮긴 개인정보를 법률에 의한 경우가 아니고서는 보유하는 외 다른 목적으로 이용하지 않습니다.<br/>
                ③ 개인정보 파기의 절차 및 방법은 다음과 같습니다.<br/>
                <br/>
                1. 파기절차<br/>
                - 회사는 파기 사유가 발생한 개인정보를 선정하고, 회사의 개인정보 보호책임자의 책임 하 개인정보를 파기합니다.<br/>
                2. 파기방법<br/>
                - 회사는 전자적 파일 형태로 기록·저장된 개인정보는 복원이 불가능한 방법 내지 기록을 재생할 수 없는 기술적 방법으로 영구히 삭제합니다. 종이나 그 밖의 기록 매체에 저장된 개인정보는 분쇄기로 분쇄하거나 소각하여 파기합니다.<br/>
                <br/>
                <br/>
                개인정보주체와 법정대리인의 권리·의무 및 행사방법<br/>
                ① 정보주체는 회사에 대해 언제든지 “솔닥” 서비스를 통하여 등록된 개인정보의 열람, 정정, 삭제, 처리정지 요구 등의 권리를 행사할 수 있습니다.<br/>
                [*] 만 14세 미만 아동에 관한 개인정보의 열람 등의 요구는 법정대리인이 직접 해야 하며, 만 14세 이상의 미성년자인 정보주체는 정보주체의 개인정보에 관하여 미성년자 본인이 권리를 행사하거나 법정대리인을 통하여 권리를 행사할 수도 있습니다.<br/>
                ② 이용자 혹은 만 14세 미만 아동의 개인정보 조회, 수정을 위해서는 ‘개인정보변경’(또는 ‘이용자정보수정’ 등)을, 가입해지(동의철회)를 위해서는 “이용자탈퇴”를 클릭하여 본인 확인 절차를 거친 후 직접 열람, 정정 또는 탈퇴가 가능합니다.<br/>
                ③ 권리 행사는 개인정보 보호책임자 및 담당자에 대하여 서면, 전화, 이메일 등을 통하여 하실 수 있으며, 이 경우 회사는 지체 없이 그 개인정보를 조사하여 이용자의 요구에 따라 지체없이 필요한 조치를 한 후 그 결과를 이용자에게 알리겠습니다.<br/>
                ④ 개인정보 열람 및 처리정지 요구의 경우, 「개인정보보호법」 제35조 제4항, 제37조 제2항에 의하여 정보주체의 권리가 제한될 수 있습니다.<br/>
                ⑤ 개인정보의 정정 및 삭제 요구는 다른 법령에서 그 개인정보가 수집 대상으로 명시되어 있는 경우에는 그 삭제를 요구할 수 없습니다.<br/>
                ⑥ 회사는 정보주체 권리에 따른 열람의 요구, 정정·삭제의 요구, 처리 정지의 요구 시 열람 등 요구를 한 자가 본인이거나 정당한 대리인인지를 확인합니다.<br/>
                ⑦ “모바일 접수/예약 서비스”의 경우, 회원(병/의원) 혹은 법정 대리인의 요청에 의해 해지 또는 삭제된 개인정보는 “모바일 접수/예약 서비스”가 수집하는 개인정보의 보유 및 이용기간”에 명시된 바에 따라 처리하고 그 외의 용도로 열람 또는 이용할 수 없도록 처리합니다. “모바일 접수/예약 서비스”의 회원탈퇴는 “솔닥”의 다른 서비스의 탈퇴와 무관합니다.<br/>
                <br/>
                <br/>
                개인정보의 안전성 확보 조치<br/>
                ① 회사는 개인정보의 안전성 확보를 위하여 다음과 같은 조치를 취하고 있습니다.<br/>
                <br/>
                1. 기술적 조치<br/>
                - 이용자의 개인정보는 비밀번호에 의해 보호되며 파일 및 전송데이터를 암호화하거나 파일 잠금기능(Lock)을 사용하여 중요한 데이터는 별도의 보안기능을 통해 보호되고 있습니다.<br/>
                - 회사는 백신프로그램을 이용하여 컴퓨터바이러스에 의한 피해를 방지하기 위한 조치를 취하고 있습니다. 백신프로그램은 주기적으로 업데이트되며 갑작스런 바이러스가 출현할 경우 백신이 나오는 즉시 이를 제공함으로써 개인정보가 침해되는 것을 방지하고 있습니다.<br/>
                - 해킹 등 외부침입에 대비하여 각 서버마다 침입차단시스템 및 취약점 분석시스템 등을 이용하여 보안에 만전을 기하고 있습니다.<br/>
                2. 관리적 조치<br/>
                - 회사는 이용자의 개인정보에 대한 접근권한을 최소한의 인원으로 제한하고 있으며 이용자를 직접 상대로 하여 마케팅 업무를 수행하는 자, 개인정보 보호책임자 및 담당자 등 개인정보관리업무를 수행하는 자, 기타 업무상 개인정보의 취급이 불가피한 자 외에는 접근을 엄격히 제한하고 담당직원에 대한 수시 교육을 통하여 본 정책의 준수를 강조하고 있습니다.<br/>
                3. 물리적 조치<br/>
                - 전산실, 자료보관실 등의 접근 통제<br/>
                <br/>
                ② 회사는 이용자 본인의 부주의나 기본적인 네트워크 상의 위험성으로 인해 ID, 비밀번호, 주민등록번호 등 개인정보가 유출되어 발생한 문제에 대해 일체의 책임을 지지 않습니다. 이용자 개개인이 본인의 개인정보 보호를 위하여 ID와 비밀번호를 적절하게 관리하고, 제3자에게 노출되지 않도록 주의하셔야 합니다.<br/>
                <br/>   
                <br/>
                개인정보 자동 수집 장치의 설치·운영 및 거부에 관한 사항<br/>
                ① 회사는 이용자에게 특화된 맞춤서비스를 제공하기 위해서 이용 정보를 저장하고 수시로 불러오는 ’쿠키(cookie)’를 사용합니다.<br/>
                ② 쿠키는 웹사이트를 운영하는데 이용되는 서버(HTTP)가 이용자의 컴퓨터 브라우저에게 보내는 소량의 정보이며 이용자들의 PC 컴퓨터 내의 하드디스크에 저장되기도 합니다.<br/>
                <br/>
                1. 쿠키의 사용 목적<br/>
                - 이용자들이 방문한 “솔닥”의 각 서비스와 웹 사이트들에 대한 방문 및 이용형태, 인기 검색어, 보안접속 여부, 이용자 규모 등을 파악하여 이용자에게 최적화된 정보 제공을 위하여 사용합니다.<br/>
                2. 쿠키의 설치/운영 및 거부<br/>
                - 이용자는 쿠키 설치에 대한 선택권을 가지고 있습니다. 따라서, 이용자는 웹브라우저에서 옵션을 설정함으로써 모든 쿠키를 허용하거나, 쿠키가 저장될 때마다 확인을 거치거나, 아니면 모든 쿠키의 저장을 거부할 수도 있습니다.<br/>
                - 쿠키 설정을 거부하는 방법으로는 이용자가 사용하는 웹 브라우저의 옵션을 선택함으로써 모든 쿠키를 허용하거나 쿠키를 저장할 때마다 확인을 거치거나, 모든 쿠키의 저장을 거부할 수 있습니다.<br/>
                - 설정방법 예(구글 크롬의 경우): 웹 브라우저 상단의 ‘︙’ &gt; 설정 &gt; 개인정보 및 보안 &gt; 쿠키 및 기타 사이트 데이터 &gt; 쿠키 차단<br/>
                - 다만, 쿠키의 저장을 거부할 경우에는 맞춤형 서비스 이용에 어려움이 있을 수 있습니다.<br/>
                <br/>
                <br/>
                개인정보 보호책임자 및 개인정보 열람청구<br/>
                ① 회사는 개인정보 처리에 관한 업무를 총괄해서 책임지고, 개인정보 처리와 관련한 정보주체의 불만처리 및 피해구제 등을 위하여 아래와 같이 개인정보 보호책임자를 지정하고 있습니다.<br/>
                ② 정보주체는 「개인정보보호법」 제35조에 따른 개인정보의 열람 청구를 아래의 부서에 할 수 있습니다. 회사는 정보주체의 개인정보 열람청구가 신속하게 처리되도록 노력하겠습니다.<br/>
                ③ 정보주체는 회사의 서비스를 이용하면서 발생한 모든 개인정보 보호 관련 문의, 불만처리, 피해구제 등에 관한 사항을 개인정보 보호책임자 및 담당부서로 문의할 수 있습니다.<br/>
                구 분 성명 등 연락처<br/>
                <br/>
                개인정보 보호책임자 직위: 경영대표 이름: 김민승 전화번호: 02-6954-7676 이메일: info@soldoc.co.kr<br/>
                <br/>   
                <br/>
                개인정보의 수집·이용 동의<br/>
                1. - 목적 : 이용자 식별 및 본인여부 확인<br/>
                - 항목 : 이름, 아이디, 비밀번호<br/>
                - 보유 및 이용기간 : 개인정보 처리방침에 따라 수집일로부터 6개월<br/>
                <br/>
                2. - 목적 : 민원상담, 고충처리<br/>
                - 항목 : 이메일, 휴대전화번호<br/>
                - 보유 및 이용기간 : 개인정보 처리방침에 따라 수집일로부터 6개월<br/>
                <br/>
                3. - 목적 : 만 14세 미만 아동 확인<br/>
                - 항목 : 법정 생년월일<br/>
                - 보유 및 이용기간 : 개인정보 처리방침에 따라 수집일로부터 6개월<br/>
                <br/>
                4. – 목적 : 원격진료 예약 및 알림<br/>
                - 항목 : 카카오톡 아이디, 휴대전화번호, 이름, 이메일, 진료 시간(날짜, 시간)<br/>
                - 보유 및 이용기간 : 개인정보 처리방침에 따라 수집일로부터 6개월<br/>
                <br/>
                5. - 목적 : 진료 전 사전 건강문진정보<br/>
                - 항목 : 키, 몸무게, 임신 여부, 흡연/음주량, 활동량, 증상에 대한 설명, 증상에 대한 사진<br/>
                - 보유 및 이용기간 : 개인정보 처리방침에 따라 수집일로부터 6개월<br/>
                <br/>
                6. - 목적 : 원격의료진료처방의 내역 관리 및 맞춤형 건강정보 제공관리<br/>
                - 항목 : 진료과목, 선호의약품, 진료시간, 진료신청서, 증상내용, 진료 예약일시, 진료 취소, 진료를 위한 기본정보 입력내용, 진료를 위한 건강정보 입력내용, 진료를 위한 생활방식 입력내용, 진료 내역, 처방 정보[법률 리스크를 낮추기 위해서는 관계기관을 통해 공식 질의 회신을 받아 진행 필요함]<br/>
                - 보유 및 이용기간 : 개인정보 처리방침에 따라 수집일로부터 6개월<br/>
                <br/>
                7. - 목적 : 원격의료*[병원 지정된 후, 해당 병원과 체결한 위수탁계약에 따라 해당 병원이 개인정보처리자가 되는 아래 사항이 담긴 개인정보수집동의서(의료기관 개인정보수집동의서 양식에 따라 수정 필요)를 수탁자로서 대신 징구하여 해당 병원에 전달]<br/>
                - 항목 : 이름, 생년월일, 성별, 휴대폰번호, 진료과목, 선호의약품, 진료시간, 진료신청서, 증상내용, 진료 예약일시, 진료 취소, 진료를 위한 기본정보 입력내용, 진료를 위한 건강정보 입력내용, 진료를 위한 생활방식 입력내용<br/>
                - 보유 및 이용기간 : 개인정보 처리방침에 따라 수집일로부터 6개월<br/>
                <br/>
                8. – 목적 : 비대면 진료, 온라인 처방을 위한 전달용 자료 수집, 재진 알림 발송<br/>
                - 항목 : 처방날짜, 처방전 교부 번호, 진료과목, 질병코드, 조제약, 복약 안내사항(투약량, 횟수, 일수)<br/>
                - 보유 및 이용기간 : 개인정보 처리방침에 따라 수집일로부터 6개월<br/>
                <br/>
                9. – 목적 : 상품 배송, 처방 약품 배송<br/>
                - 항목 : 수령인 이름, 휴대전화번호, 주소<br/>
                - 보유 및 이용기간 : 개인정보 처리방침에 따라 수집일로부터 6개월<br/>
                <br/>   
                10. – 목적 : 결제수단 등록<br/>
                - 항목 : 카드사, 카드종류, 카드번호, 카드비밀번호 앞 2자리, CVC 번호, 유효기간, 생년월일, 이름<br/>
                - 보유 및 이용기간: 개인정보 처리방침에 따라 수집일로부터 6개월<br/>
                <br/>
                11. – 목적 : 서비스 후기 수집<br/>
                - 항목 : 사용후기<br/>
                - 보유 및 이용기간 : 회원탈퇴 시까지<br/>
                민감정보의 수집 및 이용<br/>
                1. 수집, 이용 목적: 원격진료 예약 및 관리, 맞춤형 건강정보 제공·관리<br/>
                2. 수집 항목: 진료병원, 진료과목, 진료의사, 진료일시, 진료기록, 선호의약품, 증상 내용, 진료를 위한 기본정보/건강정보/생활방식 입력 내용<br/>
                3. 보유 및 이용기간: 개인정보 처리방침에 따라 수집일로부터 6개월. 단, 관련 법령에 따라 별도 보관되는 정보의 경우 해당 법령에 정한 기간까지.<br/>
                4. 동의 거부에 따른 불이익: 솔닥 원격진료 서비스 이용 제한, 맞춤형 건강정보 이용 제한. 동의 거부에 따른 불이익은 개인정보 처리방침에 의함.<br/>
                <br/>
                개인정보의 제3자 제공<br/>
                1. 개인정보를 제공받는 자: 솔닥 제휴 파트너 병원(솔닥 진료 매칭은 랜덤으로 이루어지므로, 부득이 제휴 병원 전체에 대한 제공 동의를 수집함)<br/>
                2. 제공 목적: 비대면 진료 처방, 건강상담, 예약 접수 서비스 제공 및 확인<br/>
                3. 제공 내용: 환자 본인 여부 정보(본인/가족/기타) 1) 이름 2) 생년월일 3) 성별 4) 휴대전화번호 5) 환자 기본정보 6) 환자 건강정보 7) 환자 생활정보 8) 환자의 증상정보 9) 환자의 증상과 관련된 첨부 사진 정보 10) 환자의 과거 진료내역 11) 진료영상 기록<br/>
                4. 보유 및 이용기간: 개인정보 처리방침에 따라 수집일로부터 6개월. 단, 관련 법령에 따라 별도 보관되는 정보의 경우 해당 법령에 정한 기간까지<br/>
                5. 동의 거부에 따른 불이익: 솔닥 원격진료 서비스 이용 제한. 동의 거부에 따른 불이익은 개인정보 처리방침에 의함.<br/>
            </Contents>

        </Container>
    );
}

export default Agree;