import styled from "styled-components";

const GoalWrapper = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.textColor};
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: start;
    position: relative;
    gap: 22px;
    padding-top: 150px;
    position: relative;
`;

const Title = styled.p`
    font-family: 'felixTitling';
    font-size: 80px;
    font-weight: 400;
    line-height: 108px;
`;

const SubTitle = styled.p`
    font-size: 20px;
    font-weight: 400;
    line-height: 30px;
`;

const ImageWrapper = styled.div`
    width: 1110px;
    height: 245px;
    position: relative;
`;

const Image1 = styled.img`
    position: absolute;
    bottom: -30px;
    left: 5%;
    transform: translate(-5%, 0%);
`;

const Image2 = styled.img`
    position: absolute;
    bottom: -20px;
    left: 75%;
    transform: translate(-75%, 0%);
`;

const Image3 = styled.img`
    position: absolute;
    bottom: 80px;
    left: 100%;
    transform: translate(-100%, 0%);
`;

function Goal(){
    return (
        <GoalWrapper>
            <Title>SINCE 2013</Title>
            <SubTitle>솔직함 하나로 쌓은 시간, 지금 시작하는 당신 그대로를 응원합니다.</SubTitle>
            <ImageWrapper>
                <Image1 src={require('../../styles/assets/landing/jump1.png')} />
                <Image2 src={require('../../styles/assets/landing/jump2.png')} />
                <Image3 src={require('../../styles/assets/landing/jump3.png')} />
            </ImageWrapper>

        </GoalWrapper>
    );
}

export default Goal;