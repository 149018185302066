import styled from "styled-components";

const Text = styled.div<{fontSize: string}>`
    font-family: 'felixTitling';
    font-size: ${(props) => props.fontSize}px;
    color: ${(props) => props.theme.bgColor};
    font-weight: 400;
    line-height: 60px;
`; 

interface FelixTitlingLabel {
    text: string;
    fontSize: string;
}

export function FelixTitlingLabel({
    text,
    fontSize
}: FelixTitlingLabel){
    return <Text fontSize={fontSize}>{text}</Text>
}