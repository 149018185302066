import styled from "styled-components";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

import {
    ReviewCard
} from '../../components/organisms'

// const Container = styled.div`
//     display: flex;
//     justify-content: flex-start;
//     align-items: center;
//     flex-direction: column;
//     position: relative;
//     height: 1304px;
//     background-color: #1a1a1a;
//     width: 100%;
//     gap: 142px;
//     padding-top: 100px;
// `;




const Container = styled.div`
    width: 100%;
    height: 1114px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    background-color: #1A1A1A;
    padding-bottom: 100px;
`;

const Wrapper = styled.div`
    width: 2024px;
    height: 100%;
`;


const Box = styled.div`
    height: 750px; 
    position: relative;
    z-index: 2;
    margin-left: -270px;
`;

const Text = styled.span`
    position: relative;
    color: ${(props) => props.theme.textColor};
    font-size: 72px;
    line-height: 100px;
    text-align: center;
    font-family: 'Apple SD Gothic Neo Bold';
    margin-bottom: 129px;
`;

const ReveiwCardWrapper = styled.div`
    display: flex;
    gap: 15px;
`;

function WhyClubHonest(){

    const settings = {
        centerMode: true,
        dots: true,
        infinite: true,
        autoplay: true,
        autoplaySpeed: 2000,
        speed: 1000,
        slidesToShow: 2,
        slidesToScroll: 1,
        centerPadding: "320px",
        nextArrow: <></>,
        prevArrow: <></>
    };

    return (
        <Container>
            <Text>압도적인 간편함<br/>클럽어니스트여야 하는 이유</Text>
            <Wrapper>
                <Slider {...settings}>
                    <Box>                
                        <ReviewCard 
                            buttonText="SKIN"
                            title="시간과 기회가 없었어요"
                            subTitle="피부를 관리할 시간이 없어서 늘 미뤄만 왔는데,<br/>이제라도 인생 고민을 해결하게 되어서 너무 행복해요."
                            writer="서울시 동대문구 35세 여성"
                            image="https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/landing/review_card_img1.png"
                        />
                    </Box>
                    <Box>
                        <ReviewCard 
                            buttonText="SKIN"
                            title="내게 꼭 필요했던 서비스"
                            subTitle="나에게 필요한 피부 솔루션이 무엇인지 솔직하게 알려줘서 너무 좋았어요.<br/>피부 분석부터 개별 리포트까지 전부 가입도 없이 무료라서 더 수월했고요."
                            writer="경기도 일산동구 31세 여성"
                            image="https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/landing/review_card_img2.png"
                        />
                    </Box>
                    <Box>
                        <ReviewCard 
                            buttonText="SKIN"
                            title="간편하게 시작할 수 있어요"
                            subTitle="서베이 하나로 시작하는 거, 너무 좋아요. 대면으로 상담 받고<br/>시간 내고, 방문하고, 일단 시작 자체가 너무 부담스럽잖아요."
                            writer="서울시 서초구 29세 여성"
                            image="https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/landing/review_card_img3.png"
                        />
                    </Box>
                </Slider>
            </Wrapper>            
            {/* <TempImg src={require("../../styles/assets/04.preview.png")} /> */}
        </Container>
    );
}

export default WhyClubHonest;