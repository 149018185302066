import styled from "styled-components";
import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";

import {
    SurveyHeaderTitle,
    NextButton
} from '../../../components/atoms';

import {
    ProgressBar
} from '../../../components/molecules';

import { 
    SurveyImageSelect, 
    SurveyTextSelect,
    SurveyRangeSelect
} from "../../../components/organisms";

import { 
    lifeState,
    userState,
    surveyAtom,
    lifeStyleAnswerState,
    resultLoadingState
} from "../../../store";

import { 
    getUserLocalStorage,
    getLocalStorage,
    setLocalStorage,
} from "../../../utilities/localstorage";

import { 
    ImageButtonProps, 
    TextButtonProps, 
    RangeButtonProps, 
    ButtonTypeEnum,
    QuestionType,
    SurveyStateType,
    ConditionTypeEnum,
    JumpTypeEnum,
    TargetTypeEnum,
    SurveyCategoryEnum,
    UserStateType,
    SurveyAnswerType,
    SurveyAnswerQuestionType,
    SurveyAnswerButtonType
} from "../../../types";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    min-height: calc(100vh - 90px);
    width: 100%;
    background-color: ${(props) => props.theme.bgColor};
    padding: 29px 0 45px 0;
    gap: 30px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 29px;
`;

function Question(){
    const { type, category, question } = useParams();
    // const [ surveys, setSurveys ] = useRecoilState(surveyState);   

    // const [ survey, setSurvey ] = useRecoilState(lifeState);
    const [ questionTitle, setQuestionTitle ] = useState<string>();
    const [ questionSubTitle, setQuestionSubTitle ] = useState<string>();
    const [ grid, setGrid ] = useState<number>(1);
    const [ endLetter, setEndLetter ] = useState<string>();
    const [ rangeStartLabel, setRangeStartLabel ] = useState<string>();
    const [ rangeEndLabel, setRangeEndLabel ] = useState<string>();

    const [ buttons, setButtons ] = useState<ImageButtonProps []| TextButtonProps[]| RangeButtonProps[]>();    
    const [ buttonType, setButtonType ] = useState<ButtonTypeEnum>();    
    const [ nextButton, setNextButton ] = useState(false);

    const [ totalTaskCount, setTotalTaskCount ] = useState<number>(0);
    const [ completeCount, setCompleteCount ] = useState<number>(0);
    // const [ selectedButtonIndex, setSelectedButtonIndex ] = useState<number []>([]);

    const [ user, setUser ] = useRecoilState(userState);
    let lUser = user;
    const [ sv, setSv ] = useRecoilState(surveyAtom);
    let lSurvey = sv;
    const [ life, setLife ] = useRecoilState(lifeState);
    const [ answer, setAnswer ] = useRecoilState(lifeStyleAnswerState);

    const [ resultLoading, setResultLoading ] = useRecoilState(resultLoadingState);

    const navigate = useNavigate();

    const onNextButtonClick = () => {
        // atom state 변경 
        const tsurvey:SurveyStateType = JSON.parse(JSON.stringify(life));       

        tsurvey.question[Number(question)].buttons = buttons as ImageButtonProps []| TextButtonProps[]| RangeButtonProps[] ;
        setLife(tsurvey);
        const tAnswer:SurveyAnswerType = setUserAnswer(tsurvey);              
        setAnswer(tAnswer);
        const tSurveyAnswer:SurveyAnswerType = setUserSurveyAnswer(lSurvey);
        setLocalStorage(`answer_${category}`, JSON.stringify(tSurveyAnswer));
        setLocalStorage('user', JSON.stringify(lUser));
        setLocalStorage('answer_lifestyle', JSON.stringify(tAnswer));
        // setLastIndex([...lastIndex, Number(question)]);
        // 전달 타입 확인 
        //survey를 local storage에 저장 
        // setLifeLocalStorage(tsurvey);
        jumpQuestion(tsurvey.question[Number(question)]);
    }

    const convertUserInfoToSaveObject = () => {
        // user 정보 saveObject 형태로 
        let selected = 0;
        if(lUser.select === '여드름')
        {
            selected = 0;
        }
        else if(lUser.select === '피부건조증')
        {
            selected = 1;
        }
        else if(lUser.select === '갈색 색소')
        {
            selected = 2;
        }
        else if(lUser.select === '안티에이징')
        {
            selected = 3;
        }
        else if(lUser.select === '홍조')
        {
            selected = 4;
        }

        const userSaveObject: SurveyAnswerType = {
            category: 'user',
            question:  [
                {
                    questionIndex: 0,
                    answer: [
                        {
                            buttonIndex: 0,
                            answerText: lUser.birth
                        }
                    ]
                },
                {
                    questionIndex: 1,
                    answer: [
                        {
                            buttonIndex: 0,
                            answerText: lUser.gender
                        }
                    ]
                },
                {
                    questionIndex: 2,
                    answer: [
                        {
                            buttonIndex: selected,
                            answerText: lUser.select
                        }
                    ]
                }
            ]
        }

        return userSaveObject;
    }

    const setUserSurveyAnswer = (survey: SurveyStateType) => {
        let tAnswer: SurveyAnswerType = {
            category: `${category}`,
            question: []
        }

        survey.question.map((q, qindex) => {
            let questionAnswer: SurveyAnswerQuestionType = {
                questionIndex: -1,
                answer: []
            };
            q.buttons.map((b, bindex) => {                
                if(b.activate)
                {
                    let buttonAnswer: SurveyAnswerButtonType = {
                        answerText: b.text,
                        buttonIndex: bindex
                    };
                    questionAnswer.questionIndex = qindex;
                    questionAnswer.answer.push(buttonAnswer); 
                }
            })

            if(questionAnswer.questionIndex !== -1)
            {
                tAnswer.question.push(questionAnswer);
            }
        })

        return tAnswer;
    }

    const setUserAnswer = (survey: SurveyStateType) => {
        let tAnswer: SurveyAnswerType = {
            category: 'lifestyle',
            question: []
        }

        survey.question.map((q, qindex) => {
            let questionAnswer: SurveyAnswerQuestionType = {
                questionIndex: -1,
                answer: []
            };
            q.buttons.map((b, bindex) => {                
                if(b.activate)
                {
                    let buttonAnswer: SurveyAnswerButtonType = {
                        answerText: b.text,
                        buttonIndex: bindex
                    };
                    questionAnswer.questionIndex = qindex;
                    questionAnswer.answer.push(buttonAnswer); 
                }
            })

            if(questionAnswer.questionIndex !== -1)
            {
                tAnswer.question.push(questionAnswer);
            }
        })

        return tAnswer;
    }

    // 서버에 저장 및 SurveyId
    const saveUserAnwserToServer = () => { 
        const userAnswer = convertUserInfoToSaveObject();
        const surveyLocalStorageString = getLocalStorage(`answer_${category}`)
        const surveyAnswer: SurveyAnswerType = JSON.parse(surveyLocalStorageString as string);
        const lifestyleLocalStorageString = getLocalStorage('answer_lifestyle')
        const lifestyleAnswer: SurveyAnswerType = JSON.parse(lifestyleLocalStorageString as string);

        axios({
            method: 'post',
            url: '/v1/answer/',
            withCredentials: true,
            data: {
                user: userAnswer,
                survey: surveyAnswer,
                lifestyle: lifestyleAnswer
            }
        }).then((response) => {
            setResultLoading(true);
            console.log("결과페이지로 " + response.data.index);
            navigate(`/${type}/result/${category}?surveyId=${response.data.key}`);

        }).catch((err) => {
            alert("error");
            navigate('/');
        })

    }
        
    const jumpQuestion = (check_question: QuestionType) => {

        if(check_question.jump === JumpTypeEnum.NOP)
        {            
            let next =  Number(question) + 1;
            if(life.question.length > next)
            {
                navigate(`/${type}/health/${category}/${next}`)
            }
            else
            {
                // result로 이동 
                // 모두 저장 및  surveyId 받기
                saveUserAnwserToServer();
        
                // navigate(`/${type}/result/${category}`)
            }
            return;
        }
        else if(check_question.jump === JumpTypeEnum.CONDITIONAL_JUMP)
        {
            let isJump = false;
            let isForceJump = false;
            check_question.buttons.map((button) => {
                if(button.activate && button.forceJump)
                {
                    //multi 에서 jump를 위해 0을 체크 (0이면 conditionjump라도 다음으로 이동 )
                    let next = button.nextQuestionIndex as number;             
                    if(next !== 0)
                    {
                        if(life.question.length > next)
                        {
                            navigate(`/${type}/health/${category}/${next}`)
                        }
                        else
                        {
                            // result로 이동 
                            saveUserAnwserToServer();
                            // navigate(`/${type}/result/${category}`)
                        }                       
                        isForceJump = true;
                    }   
                }             
            })

            if(!isForceJump)
            {
                check_question.buttons.map((button) => {
                    if(button.activate)
                    {
                        //multi 에서 jump를 위해 0을 체크 (0이면 conditionjump라도 다음으로 이동 )
                        let next = button.nextQuestionIndex as number;             
                        if(next !== 0)
                        {
                            if(life.question.length > next)
                            {
                                navigate(`/${type}/health/${category}/${next}`)
                            }
                            else
                            {
                                // result로 이동 
                                saveUserAnwserToServer();
                                // navigate(`/${type}/result/${category}`)
                            }                       
                            isJump = true;
                        }   
                    }             
                })
                if(!isJump)
                {
                    // (0이면 conditionjump라도 다음으로 이동 )
                    let next2 =  Number(question) + 1;
                    if(life.question.length > next2)
                    {
                        navigate(`/${type}/health/${category}/${next2}`)
                    }
                    else
                    {
                        // result로 이동 
                        saveUserAnwserToServer();
                        // navigate(`/${type}/result/${category}`)
                    }
                    return;
                }
            }
        }
        else if(check_question.jump === JumpTypeEnum.SURVEY_JUMP)
        {
            check_question.buttons.map((button) => {
                if(button.activate)
                {
                    if(button.jumpSurvey === SurveyCategoryEnum.ACNE)
                    {
                        navigate(`/${type}/detail/acne`)
                    }
                    else if(button.jumpSurvey === SurveyCategoryEnum.DRY)
                    {
                        navigate(`/${type}/detail/dry`)
                    }
                    else if(button.jumpSurvey === SurveyCategoryEnum.BROWN)
                    {
                        navigate(`/${type}/detail/brown`)
                    }
                    else if(button.jumpSurvey === SurveyCategoryEnum.ANTIAGING)
                    {
                        navigate(`/${type}/detail/antiaging`)
                    }
                    else if(button.jumpSurvey === SurveyCategoryEnum.BLUSH)
                    {
                        navigate(`/${type}/detail/blush`)
                    }
                    return;
                }             
            })
        }
    }

    // single 선택
    const onSingleSelectResult = (selected: number, text?: string) => {
        // activate 수정 
        // deep copy
        const tbutton:ImageButtonProps[] = JSON.parse(JSON.stringify(buttons));

        const selectButtonMultiSelectOption = tbutton[selected].multiSelect;

        tbutton.map((button, index) => {
            if(selectButtonMultiSelectOption)  // multiselectoption이 켜있는경우 해당버튼 활성화 나머지 상태 변경할 필요없음
            {
                if(index === selected)
                {
                    button.activate = !button.activate;
                    if(text !== undefined)
                    {
                        if(text === '')
                        {
                            button.activate = false;
                        }
                        button.text = text;
                    }
                }
            }
            else
            {
                if(index !== selected)
                {
                    if(!button.multiSelect)  // multi select option이 없는 선택한 버튼 외의 버튼은 false 
                    {
                        button.activate = false;
                    }
                }
                else  //선택됨
                {
                    button.activate = true;
                    if(text !== undefined)
                    {
                        if(text === '')
                        {
                            button.activate = false;
                        }
                        button.text = text;
                    }                   
                   
                }
            }
        })           
        
        //아이디값이 변화해야 rerendering 
        setButtons([...tbutton]);
        setNextButton(false);
        tbutton.map(button => {
            if(button.activate)
            {
                setNextButton(true);
            }
        })
    }

    // multi 선택 
    const onMultiSelectResult = (selected: number, text?: string) => {
        const tbutton:ImageButtonProps[] = JSON.parse(JSON.stringify(buttons));
        let selecteds:number[] = [];

        const selectButtonMultiSelectOption = tbutton[selected].multiSelect;
        const selectValue = !tbutton[selected].activate;

         if(selectButtonMultiSelectOption)  //선택한 버튼의 multi가 true라면 
        {
            tbutton.map((button, index) => {
                if(index !== selected && !button.multiSelect && selectValue)  // multi가 true인 버튼은 선택하면 multi가 false인 버튼이 disable 되어야함
                {
                    button.activate = false;
                }

                if(index === selected)
                {
                    button.activate = !button.activate;
                    if(text !== undefined)
                    {
                        if(text === '')
                        {
                            button.activate = false;
                        }
                        button.text = text;
                    }        
                }
    
                if(button.activate)
                {
                    selecteds.push(index);
                }
            })
        }
        else //선택한 버튼의 multi가 false라면 activate일때 모두 false가 되어야함 
        {
            tbutton.map((button, index) => {
                if(index !== selected && selectValue)  // multi가 true인 버튼은 선택하면 multi가 false인 버튼이 disable 되어야함
                {
                    button.activate = false;
                }

                if(index === selected)
                {
                    button.activate = !button.activate;
                    if(text !== undefined)
                    {
                        if(text === '')
                        {
                            button.activate = false;
                        }
                        button.text = text;
                    }        
                }
    
                if(button.activate)
                {
                    selecteds.push(index);
                }
            })
        }

        // 아이디 값이 변화해야 rerendering 
        setButtons([...tbutton])
        if(selecteds.length > 0)
        {
            setNextButton(true);
        }
        else
        {
            setNextButton(false);
        }
    }

    // question view 조건 체크
    const checkQuestionView = (question: QuestionType) => {
        if(question.viewConditionType === ConditionTypeEnum.NOP)
        {
            return true;
        }
        else if(question.viewConditionType === ConditionTypeEnum.GREATER)
        {
            if(question.viewConditionTarget === TargetTypeEnum.AGE)
            {
                if(lUser.age > question.viewConditionValue)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }            
            else
            {
                return true;
            }
        }
        else if(question.viewConditionType === ConditionTypeEnum.LESS)
        {
            if(question.viewConditionTarget === TargetTypeEnum.AGE)
            {
                if(lUser.age < question.viewConditionValue)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }          
            else
            {
                return true;
            }
        }
        else if(question.viewConditionType === ConditionTypeEnum.EQUAL)
        {            
            
            if(question.viewConditionTarget === TargetTypeEnum.AGE)
            {
                if(lUser.age === question.viewConditionValue)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }
            else if(question.viewConditionTarget === TargetTypeEnum.GENDER)
            {
                // console.log(lUser.gender)
                if(lUser.gender === question.viewConditionValue)
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }
            else
            {
                return true;
            }
        }
        else if(question.viewConditionType === ConditionTypeEnum.RANGE)
        {
            //viewConditionEndValue 포함   
            if(question.viewConditionTarget === TargetTypeEnum.AGE)
            {
                if(lUser.age > question.viewConditionValue && lUser.age < (question.viewConditionEndValue as number))
                {
                    return true;
                }
                else
                {
                    return false;
                }
            }          
            else
            {
                return true;
            }
        }
    }

    const initializeSurvey = (survey: SurveyStateType) => {
     
        if(!checkQuestionView(survey.question[Number(question)]))
        {

            const next = Number(question) + 1;
            if(survey.question.length > next)
            {
                navigate(`/${type}/health/${category}/${next}`)
            }
            else
            {
                //result 이동
                navigate(`/${type}/result/${category}`)
            }
        }           
        else
        {
            setNextButton(false);
            survey.question[Number(question)].buttons.map((button) => {
                if(button.activate)
                {
                    setNextButton(true);
                }
            })    
            setQuestionTitle(survey.question[Number(question)].questionTitle)
            setQuestionSubTitle(survey.question[Number(question)].questionSubTitle);
            setGrid(survey.question[Number(question)].grid);
            setButtons(survey.question[Number(question)].buttons);
            setButtonType(survey.question[Number(question)].buttonType);
            setEndLetter(survey.question[Number(question)].endLetter);
            setRangeStartLabel(survey.question[Number(question)].rangeStartLabel);
            setRangeEndLabel(survey.question[Number(question)].rangeEndLabel);
            setTotalTaskCount(survey.question.length);
            setCompleteCount(Number(question) + 1);
        }
    }
    
    // 답변 세팅 
    const settingAnswer = (survey: SurveyStateType, answer: SurveyAnswerType) => {
        let tsurvey = survey;

        answer.question.map(q => {
            q.answer.map(a => {
                tsurvey.question[Number(q.questionIndex)].buttons[a.buttonIndex].activate = true;
                tsurvey.question[Number(q.questionIndex)].buttons[a.buttonIndex].text = a.answerText;
            })
            
        })

        return tsurvey;
    }

    const getSurveyTemplate = async () => {
        const url = `/v1/template/pc_${category}/`;
        axios.defaults.withCredentials = true;
        try{
            const response = await axios.get(url);
            const tsurvey:SurveyStateType = JSON.parse(response.data.template);
            return tsurvey;
        }
        catch(err)
        {
            return null;
        }

    } 

    const checkUserAndSurvey = async () => {
        //recoil 확인
        //local storage 확인  로그인되어있다면 kakao 확인 
        if(user === undefined)
        {          
            const userLocalStorageString = getUserLocalStorage()
            if(userLocalStorageString)
            {
                const userLocalStorage: UserStateType = JSON.parse(userLocalStorageString);
                setUser(userLocalStorage);
                lUser = userLocalStorage;
                // console.log(userLocalStorage)
            }
            else
            {
                // 처음으로 이동 
                navigate(`/${type}/start/init`);
                return;
            }
        }
        else
        {
            
            if(user.select === '' || user.birth === '' || user.gender === '')
            {
                navigate(`/${type}/start/init`);
                return;
            }
            else
            {
                setUser(user);
            }
        }


        //  survey
        if(sv === undefined)
        {
            const surveyLocalStorageString = getLocalStorage(`answer_${category}`)
            let tSurvey = await getSurveyTemplate();
           
            if(surveyLocalStorageString)
            {
                // const survey
                const surveyLocalStorage: SurveyAnswerType = JSON.parse(surveyLocalStorageString);
                let isLast = false;
                
                if(tSurvey)
                {   
                    tSurvey = settingAnswer(tSurvey, surveyLocalStorage);

                    tSurvey.question.map(q => {
                        q.buttons.map(b => {
                            if(b.activate)
                            {
                                if(q.isLast)
                                {
                                    isLast = true;
                                }
                            }
                        })
                    })

                    if(!isLast)
                    {
                        // 질문 처음으로 이동 
                        navigate(`/${type}/detail/${category}/0`);
                        return;
                    }
                    else
                    {

                        setSv(tSurvey);
                    }
                }
                else
                {
                    navigate(`/${type}/detail/${category}/0`);
                }                
            }   
            else
            {
                // 질문 처음으로 이동 
                navigate(`/${type}/detail/${category}/0`);
            }  
        }

    }

    useEffect(() => {
        // user, survey 검사 
        checkUserAndSurvey();

        if(life === undefined)
        {
            axios({
                method: 'get',
                url: `/v1/template/pc_lifestyle/`,
                withCredentials: true
            }).then((response) => {
                let tsurvey:SurveyStateType = JSON.parse(response.data.template);
                const tAnswer:SurveyAnswerType = JSON.parse(getLocalStorage('answer_lifestyle') as string);
               
                //if not login
                if(tAnswer)
                {
                    const lastIndex = tAnswer.question[tAnswer.question.length - 1].questionIndex ;
                    //마지막 대답으로 이동 
                    if(lastIndex !== Number(question))
                    {
                        navigate(`/${type}/health/${category}/${lastIndex}`);
                        return;
                    }
                    else
                    {
                        setAnswer(tAnswer);
                        tsurvey = settingAnswer(tsurvey, tAnswer);
                    }
                }
                else
                {
                   
                    // 0질문으로 이동 
                    if(Number(question) !== 0)
                    {
                        navigate(`/${type}/health/${category}/0`);
                        return;
                    }                  
                }
               
                // else login시 server에서 로딩
                // 로딩 후 local storage, recoil setting 
                // console.log(tsurvey)
                initializeSurvey(tsurvey);
                setLife(tsurvey);
            }).catch((err) => {

            })
        }
        else
        {
            initializeSurvey(life);
        }

        // console.log(JSON.stringify(survey));
        //노출타입 확인
        // loadLocalStorage();
        
    }, [question])


    return (
        <Container>
           <HeaderWrapper>
                <ProgressBar 
                    leftTotalTaskCount={1} 
                    leftCompleteCount={1}
                    rightTotalTaskCount={totalTaskCount}
                    rightCompleteCount={completeCount}
                />     
                <SurveyHeaderTitle /> 
            </HeaderWrapper>              
            {buttonType === ButtonTypeEnum.IMAGE_MULTI && (
                <SurveyImageSelect 
                    surveyTitle="라이프스타일"
                    questionTitle={questionTitle as string}
                    questionSubTitle={questionSubTitle}
                    endLetter={endLetter as string}
                    grid={grid}
                    buttons={buttons as ImageButtonProps[]}
                    onClick={onMultiSelectResult}
                    buttonActive={nextButton}
                    onNextButtonClick={onNextButtonClick}
                />
            )}
            {buttonType === ButtonTypeEnum.IMAGE_SINGLE && (
                <SurveyImageSelect 
                    surveyTitle="라이프스타일"
                    questionTitle={questionTitle as string}
                    questionSubTitle={questionSubTitle}
                    endLetter={endLetter as string}
                    grid={grid}
                    buttons={buttons as ImageButtonProps[]}
                    onClick={onSingleSelectResult}
                    buttonActive={nextButton}
                    onNextButtonClick={onNextButtonClick}
                />
            )}
            {buttonType === ButtonTypeEnum.TEXT_MULTI && (
                <SurveyTextSelect 
                    surveyTitle="라이프스타일"
                    questionTitle={questionTitle as string}
                    questionSubTitle={questionSubTitle}
                    endLetter={endLetter as string}
                    grid={grid}
                    buttons={buttons as TextButtonProps[]}
                    onClick={onMultiSelectResult}
                    buttonActive={nextButton}
                    onNextButtonClick={onNextButtonClick}
                />
            )}
            {buttonType === ButtonTypeEnum.TEXT_SINGLE && (
                <SurveyTextSelect 
                    surveyTitle="라이프스타일"
                    questionTitle={questionTitle as string}
                    questionSubTitle={questionSubTitle}
                    endLetter={endLetter as string}
                    grid={grid}
                    buttons={buttons as TextButtonProps[]}
                    onClick={onSingleSelectResult}
                    buttonActive={nextButton}
                    onNextButtonClick={onNextButtonClick}
                />
            )}               
            {buttonType === ButtonTypeEnum.RANGE && (
                <SurveyRangeSelect 
                    surveyTitle="라이프스타일"
                    questionTitle={questionTitle as string}
                    questionSubTitle={questionSubTitle}
                    endLetter={endLetter as string}
                    grid={grid}
                    rangeStartLabel={rangeStartLabel as string}
                    rangeEndLabel={rangeEndLabel as string}
                    buttons={buttons as RangeButtonProps[]}
                    onClick={onSingleSelectResult}
                    buttonActive={nextButton}
                    onNextButtonClick={onNextButtonClick}
                />
            )}         
            <NextButton activate={nextButton} name="다음" onClick={onNextButtonClick} />
        </Container>
    );
}

export default Question;