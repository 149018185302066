import styled from "styled-components";
import { motion } from "framer-motion";

const Image = styled(motion.img)`
    position: absolute;
    top: -100px;
    right: -200px;
    z-index: 10;
`;

const rotateVariant = {
    start: {
        rotateZ: 0
    },
    end: {
        rotateZ: 360
    }
}

export function ZRotateImage(){
    return (
        <Image 
            src={require("../../styles/assets/result/solution_image.png")} 
            variants={rotateVariant}
            initial="start"
            animate="end"
            transition={{ ease: "linear", duration: 50, repeat: Infinity }}
        />
    );
}