import styled from "styled-components";

const BannerWrapper = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.textColor};
    position: relative;
    /* z-index: -1; */
`;

const BannerBackImg = styled.img`
    width: 100%;
`;

const Title = styled.div`
    font-size: 80px;
    line-height: 100px;
    font-weight: 400;
    text-align: center;
    z-index: 2;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 1200px;
    color: ${(props) => props.theme.textColor};
    font-family: FelixTitling;
`;

const SubTitle = styled.p`
    position: absolute;
    top: 60%;
    left: 50%;
    transform: translate(-50%, -60%);
    font-size: 20px;
    line-height: 30px;
    font-weight: 400;
    min-width: 1200px;
    text-align: center;
    color: ${(props) => props.theme.textColor};
`;

function Banner(){

    return (
        <BannerWrapper>
            <BannerBackImg src={require("../../styles/assets/landing/banner.png")} />
            <Title>PERSONALIZED CARE PLAN</Title>
            <SubTitle>서베이부터 진단과 상담, 처방과 케어까지, 당신에게 필요한 최고의 효율, 우리에게 필요한 올인원 프로그램</SubTitle>
        </BannerWrapper>
    );
}

export default Banner;