import { REDIRECT_URI, REST_API_KEY } from '../../../constant';
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRecoilValue } from "recoil";
import styled from "styled-components";
import qs from 'qs';
import Booking from "../booking";

import {
    ArrowButton,
    ZRotateImage,
    LinearGradientLabel,
    ChangableGradientLabel
} from '../../../components/atoms'

import {
    SolutionCard,
    ServerSaveModal
} from '../../../components/organisms';

import {
    loginState,
    solutionState,
    SolutionStateType,
    gradientState
} from "../../../store";

import { 
    setLocalStorage, 
    getLocalStorage 
} from "../../../utilities/localstorage";

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    background: #020620;
    position: relative;
    gap: 322px;
    padding: 200px 0 202px 0;
`;


const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
    z-index: 1;
`;

const Title = styled.div`
    width: 883px;
    height: 157px;
    background: #020620;
    border: 1px solid #7A7A7A;
    box-shadow: 0px 4px 11px #000000;
    border-radius: 100px;
    font-weight: 700;
    font-size: 70px;
    line-height: 60px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const CardWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding-top: 200px;
`;

const Line = styled.img`
    position: absolute;
    top: 10%;
    left: 50%;
    z-index: 0;
`;

const BackLeft = styled.img`
    position: absolute;
    top: 50%;
    left: 0%;
    z-index: 0;
    transform: translate(0, -50%);
`;

const BackRight = styled.img`
    position: absolute;
    bottom: -500px;
    right: -100px;
    z-index: 0;
`;

interface SolutionProps {
    solutions: SolutionStateType[]
}


export function Solution({
    solutions
}:SolutionProps){
    const isGradient = useRecoilValue(gradientState);
    const login = useRecoilValue(loginState);
    const location = useLocation();
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const [ isViewBooking, setIsViewBooking ] = useState(false);
    const [ isBookingModalOpen, setIsBookingModalOpen ] = useState(false);
    
    const onBookingButtonClick = (index: number) => {
        if(index === 0)
        {
            setIsBookingModalOpen(true);
        }
        else
        {
            window.open('https://soldoc.co.kr/', '_blank')
        }
    }


    const booking = (value: boolean) => {
        if(value)
        {
            // 테스트 코드 
            // setIsViewBooking(true);
            // setIsBookingModalOpen(false)
     
            // setLocalStorage('booking', query.surveyId as string);
            if(!login.isLogin)
            {
                const path = `${location.pathname}?surveyId=${query.surveyId}`;       
                window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&prompt=login&state=${path}`;
            }
            else
            {
                // alert("사용자에게 예약 카카오톡 보내기")
                setIsViewBooking(true);
                setIsBookingModalOpen(false)
            }
        }
    }

    useEffect(() => {
        const booking = getLocalStorage('booking')
        if(login.isLogin) 
        {
            if(booking)
            {
                alert("사용자에게 예약 카카오톡 보내기")
            }
     
            setLocalStorage('booking')
        }

    }, [login.isLogin])

    return (
        <Container> 
            {
                isViewBooking ? (               
                    <Booking
                        surveyId={query.surveyId as string}
                        onClose={() => {
                            document.body.style.overflowY = 'auto';
                            setIsViewBooking(false);
                        }}
                    />
                 
                ) : (null)
            }

            <BackLeft src={require('../../../styles/assets/result/solution_back_ellipse.png')} />
            <BackRight src={require('../../../styles/assets/result/solution_back_ellipse_right.png')} />
            <ServerSaveModal 
                open={isBookingModalOpen}
                onCloseButton={() => {setIsBookingModalOpen(false)}}
                onConfirm={booking}
            />
            {/* <Line src={require("../../../styles/assets/dotline.png")} /> */}
            <TitleWrapper>
                {
                    login.isLogin && <ChangableGradientLabel isGradient={isGradient} title={`${login.userName}님을 위한 맞춤 플랜`} fontSize="30"/>
                }                
                <Title>어니스트 솔루션</Title>
            </TitleWrapper>
            <CardWrapper>
                {
                    solutions.map((solution, index) => {
                        return(
                            <SolutionCard key={index} solution={solution} onButtonClick={() => onBookingButtonClick(index)}/>
                        );
                    })
                }
            </CardWrapper>
            
        </Container>
    );
}