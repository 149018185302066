import { useEffect, useState } from "react";
import styled from "styled-components";

const CircleButton = styled.div<{bgColor: string}>`
    width: 142px;
    height: 142px;
    border-radius: 50%;
    background-color: ${(props) => props.bgColor};
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    z-index: 10;
`;

const Image = styled.img`
`;

interface RoundImageButtonProps{
    bgColor: string;
    url: string;
    onClick: () => void;
}

export function RoundImageButton({
    bgColor,
    url,
    onClick
}: RoundImageButtonProps){    

    return (
        <CircleButton bgColor={bgColor} onClick={onClick}>
            <Image src={url} />
        </CircleButton>
    );
}