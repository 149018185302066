import { atom } from "recoil";

export enum SolutionButtonEnum {
    "TELE",
    "HOMECARE",
    "CONSULTING"
} 

export interface SolutionStateType{
    order: number;
    title: string;
    solutionName: string[];
    solutionDetail: string;
    buttonType: SolutionButtonEnum,
    buttonText: string;
}

export const solutionState = atom<SolutionStateType []>({
    key: 'solutionState',
    default: [
        {
            order: 1, 
            title: "soldoc<br/>비대면진료 & 상담",
            solutionName: ["피부과 의료진과 함께하는", "전문적인 진료와 상담"],
            solutionDetail: "피부 전문 <strong>young & smart 의료진</strong><br/><strong>영상 통화</strong>로 꼼꼼한 진료 및 상담 실시<br/>처방전 등 필요 서류는 <strong>전자문서로 즉시 발급</strong>",
            buttonType: SolutionButtonEnum.TELE,
            buttonText: "비대면 진료 예약하기"
        },
        {
            order: 2, 
            title: "홈케어<br/>제품 구성",
            solutionName: ["피부 고민 해결을 위한", "제품 솔루션"],
            solutionDetail: "<strong>MD보습제 라인</strong>, soldoc Energy A 라인 등<br/> 개인별 최적화된 <strong>제품 맞춤 처방 & 제공</strong><br/> 모든 제품 전액 <strong>무료로 배송</strong>",
            buttonType: SolutionButtonEnum.HOMECARE,
            buttonText: "제품 구경하러 가기"
        },
        // {
        //     order: 3, 
        //     title: "코치와<br/>시술 상담",
        //     solutionName: ["대면 시술을 위한", "솔직한 조언 및 상담"],
        //     solutionDetail: "어니스트 코치와의 <strong>시술 상담</strong><br/>예약을 통해 <strong>비대면으로 진행</strong> <br/><strong>전화 또는 카톡 1:1 상담 가능</strong>",
        //     buttonType: SolutionButtonEnum.CONSULTING,
        //     buttonText: "상담 예약하러 가기"
        // }
    ]
})