// input을 입력하기 전에 활성화 하면 안됨
// input이 있으면 버튼 누르기 가능 

import styled from "styled-components";
import { motion } from "framer-motion";
import React, { useState, useRef, useLayoutEffect } from "react";

const Container = styled(motion.div)<ButtonProps>`
    border-radius: 30px;
    padding: 20px 40px;
    width: ${(props) => props.activate === 'false' ? props.width : Number(props.width) + 60}px;
    background: ${(props) => props.activate ==='false' ? "#000000" : "linear-gradient(52.08deg, #76BCC5 22.58%, #709EE0 55.66%, #075BD4 93.67%)"};
    border: 1px solid #7a7a7a;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const Text = styled.input`
    font-size: 30px;
    width: 100%;
    font-weight: 700;
    line-height: 60px;
    color: rgba(122, 122, 122, 1);   
    border: none; 
    background: transparent;
    &:focus{
        outline: none;
    }
    text-align: center;
    caret-color: red;
    border-bottom: 1px solid #7A7A7A;
`;

const ActiveText = styled.input`
    font-size: 35px;
    width: 100%;
    font-weight: 700;
    line-height: 60px;
    color: rgba(30, 30, 30, 1);  
    border: none; 
    background: transparent;
    &:focus{
        outline: none;
    } 
    text-align: center;
    border-bottom: 1px solid #1E1E1E;
    caret-color: red;
`;

interface ButtonProps {
    width: string;
    activate: string; // motion에 boolean을 전달 할 수 없음 
}

interface AnswerButtonProps {   
    text: string; 
    activate: boolean; 
    width: string;
    onClick: (text: string) => void;
}

export function AnswerTextInputButton({ text, activate, width, onClick}: AnswerButtonProps){
    const [ hovered, setHovered ] = useState(false);
    const [ textValue, setTextValue ] = useState(text);
    const [ isActive, setIsActive ] = useState<boolean>(activate);
    const inputRef = useRef<HTMLInputElement>(null);
    // console.log("Text Input " + text)
    const onTextChange = (event: React.FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value}
        } = event;

        setTextValue(value);     
        
        if(value === '' && activate)
        {
            onClick(value);
        }   
    }

    const onButtonClick = () => {
        if(textValue !== "")
        {
            onClick(textValue);
        }
    }

    return (
        <Container
            width={width}
            activate={ (textValue === "" || !activate) ? 'false' : 'true'}
            onClick={onButtonClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {textValue !== '' ? 
                (
                    !activate ?
                    (<Text value={textValue} onChange={onTextChange} placeholder="직접 입력" />)
                    :
                    (<ActiveText value={textValue} onChange={onTextChange} placeholder="직접 입력" autoFocus />)
                ) : (             
                    <Text value={textValue} onChange={onTextChange} placeholder="직접 입력"/>                  
                )
            }
        </Container>
    );
}