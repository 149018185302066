import styled from "styled-components";

import {
    FelixTitlingLabel
} from "../../atoms"


const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Section = styled.div`
    width: 1413px;
    height: 753px;
    border-radius: 50px;
    background-color: #fff;
    color: #000000;
    padding: 93px 52px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    gap: 35px;
    position: relative;
`;

const CloseButton = styled.img`
    position: absolute;
    right: 50px;
    top: 61px;
    width: 30px;
    height: 30px;
    cursor: pointer;
`;

const ContentsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 35px;
`;


const Title = styled.span`
    font-weight: 400;
    font-size: 65px;
    line-height: 80px;
    font-family: 'Apple SD Gothic Neo Bold';
    color: ${(props) => props.theme.bgColor};
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 30px;
    margin-top: 120px;
`;

const Button = styled.div`
    width: 446px;
    height: 139px;
    background: #000217;
    border-radius: 30px;
    font-family: 'Apple SD Gothic Neo';
    font-weight: 400;
    font-size: 45px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

interface ModalProps {
    open: boolean;
    onCloseButton: () => void;
    onConfirm: (value: boolean) => void;
}
//불러오기 처음부터
export function ContinueModal({
    open,
    onCloseButton,
    onConfirm
}: ModalProps){
    return (
        <>
            {open ? (
                <Container>
                    <Section>
                        <CloseButton src={require("../../../styles/assets/common/btn_close_black.png")} 
                            onClick={onCloseButton}
                        />
                        <FelixTitlingLabel text="CLUB HONEST" fontSize="60" />
                        <Title>작성중인 서베이가 있어요<br/>이어서 답변 하시겠어요?</Title>
                        <ButtonWrapper>
                            <Button 
                                onClick={
                                    () => {
                                        onConfirm(true);
                                    }
                                }
                            >불러오기</Button>
                            <Button
                                 onClick={
                                    () => {
                                        onConfirm(false);
                                    }
                                }
                            >처음부터</Button>
                        </ButtonWrapper>                       
                    </Section>
                </Container>

            ) : (
                null
            )}
        </>
    );
}