import styled from "styled-components";

const Title = styled.div`
    font-size: 15px;
    font-weight: 400;
    color: ${(props) => props.theme.textColor};
`;

interface QuestionSubTitleProps {
    title: string;
}

export function QuestionSubTitle({title}: QuestionSubTitleProps)
{   
    return<Title>{title}</Title>
};
