//Hero
import { Hero } from './Hero';
import { CheerUp } from './CheerUp';
import { Step1 } from './Step1';
import { Step2 } from './Step2';
import { Slider } from './Slider';
import { HowTo } from './HowTo';
import { Step3 } from './Step3';
import { FaQ } from './FaQ';
import { Review } from './Review';
import { Suggestion } from './Suggestion';
import Header from '../Header';
import Footer from '../Footer';
import { useEffect } from 'react';

function SkinDetail(){

    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [])

    return (
        <>
            <Header />
            <div style={{height: '90px'}}></div>
            <Hero />
            <CheerUp />
            <Step1 />
            <Step2 />
            <Slider />
            <HowTo />
            <Step3 />
            <FaQ />
            {/* <Review /> */}
            <Suggestion />
            <Footer />
        </>
    );
}
export default SkinDetail;