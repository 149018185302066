/**
 * init에서 시작하기 진입 
 * user에게 생년월일을 입력받아 나이를 계산하고 저장(state) 
 * 
 * @ param: x
 * @ input: birth  
 * @ return: 다음 버튼 -> Gender로 이동 
 * @ exception: x
 * @ update: 2022.06.14
*/

import { 
    FormEvent, 
    useEffect, 
    useRef, 
    useState 
} from "react";

import { 
    useNavigate, 
    useParams 
} from "react-router-dom";

import { useRecoilState, useRecoilValue } from "recoil";

import styled from "styled-components";

import { 
    QuestionTitle, 
    QuestionSubTitle,
    NextButton, 
    LinearGradientLabel,
    SurveyHeaderTitle 
} from '../../../components/atoms';

import {
    ProgressBar
} from '../../../components/molecules';

import { 
    userState
} from "../../../store";

import { 
    setUserLocalStorage, 
    getUserLocalStorage 
} from "../../../utilities/localstorage";


import {
    UserStateType
} from "../../../types";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100vh - 90px);
    width: 100%;
    gap: 30px;
    background-color: ${(props) => props.theme.bgColor};
    padding: 29px 0;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 29px;
`;

const QuestionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
`;

// module로 이동 
const BirthWrapper = styled.div`
    color: #4C4C4C;
    font-weight: 400;
    font-size: 50px;
`;
const YyyyInput = styled.input`
    text-align: center;
    border: 0;
    font-size: 50px;
    width: 220px;
    &:focus{
        outline: none;
    }
    caret-color: red;
    background: linear-gradient(90deg, rgba(118,188,197,1)  33%, rgba(112,158,224,1) 50%, rgba(7,91,212,1) 63%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
`;

const MmInput = styled.input`
    text-align: center;
    border: 0;
    font-size: 50px;
    width: 220px;
    &:focus{
        outline: none;
    }
    caret-color: red;
    background: linear-gradient(90deg, rgba(118,188,197,1)  43%, rgba(112,158,224,1) 50%, rgba(7,91,212,1) 58%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
`;

const DdInput = styled.input`
    text-align: center;
    border: 0;
    font-size: 50px;
    width: 220px;
    &:focus{
        outline: none;
    }
    caret-color: red;
    background: linear-gradient(90deg, rgba(118,188,197,1)  43%, rgba(112,158,224,1) 50%, rgba(7,91,212,1) 58%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
`;

function Birth(){
    const { type } = useParams();
    const navigate = useNavigate();

    const [ year, setYear] = useState("");
    const [ month, setMonth] = useState("");
    const [ day, setDay] = useState("");
    const [ buttonActive, setButtonActive ] = useState(false);
    const [ user, setUser ] = useRecoilState(userState);

    const yearFocus = useRef<HTMLInputElement>(null);
    const monthFocus = useRef<HTMLInputElement>(null);
    const dayFocus = useRef<HTMLInputElement>(null); 
    var num = /^[0-9]*$/;

    const onNextButtonClick = () => {
        const today = new Date();
        const birthDate = new Date(Number(year), Number(month), Number(day)); // 2000년 8월 10일

        let age = today.getFullYear() - birthDate.getFullYear() + 1;        
        setUser({...user, birth: `${year}.${month}.${day}`, age: age, year: year, month: month, day: day})

        setUserLocalStorage({...user, birth: `${year}.${month}.${day}`, age: age, year: year, month: month, day: day});

        navigate(`/${type}/start/gender`);
    }

    //input validation 
    const onYearChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

   
        if(num.test(value))
        {
            setYear(value);
            if(value.length === 4)
            {
                if(monthFocus.current !== null)
                {
                    monthFocus.current.focus()
                }

            }
            checkButtonAcitve(value, month, day);
        }   
      
    } 

    const onMonthChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;


        if(num.test(value))
        {
            setMonth(value);
            if(value.length === 2)
            {
                if(dayFocus.current !== null)
                {
                    dayFocus.current.focus()
                }

            }
            else if(value.length === 0)
            {
                if(yearFocus.current !== null)
                {
                    yearFocus.current.focus()
                }
            }

            checkButtonAcitve(year, value, day);
        }   
      
    }

    const onDayChange = (event: FormEvent<HTMLInputElement>) => {
        const {
            currentTarget: {value},
        } = event;

        if(num.test(value))
        {
            setDay(value);
            if(value.length === 0)
            {
                if(monthFocus.current !== null)
                {
                    monthFocus.current.focus()
                }
            }
            
            checkButtonAcitve(year, month, value);
        }   
      
    }

    const checkButtonAcitve = (syear: string, smonth: string, sday: string) => {

        if(syear.length === 4 && smonth.length === 2 && sday.length === 2)
        {
            setButtonActive(true);
        }
        else
        {
            setButtonActive(false);
        }
    }

    const loadUserLocalStorage = () => {
        const localUserString = getUserLocalStorage();
        if(localUserString)
        {
            const userLocal:UserStateType = JSON.parse(localUserString);
            setUser(userLocal);
            settingBirth(userLocal);
        }       
    }

    const settingBirth = (userInfo: UserStateType) => {
        setYear(userInfo.year);
        setMonth(userInfo.month);
        setDay(userInfo.day);
        checkButtonAcitve(userInfo.year, userInfo.month, userInfo.day);
    }

    useEffect(() => {
        loadUserLocalStorage();
    }, [])

    return (
        <Container>
            <HeaderWrapper>
                <ProgressBar 
                    leftTotalTaskCount={30} 
                    leftCompleteCount={1}
                    rightTotalTaskCount={10}
                    rightCompleteCount={0}
                />
                <SurveyHeaderTitle />
            </HeaderWrapper>
            <QuestionWrapper>                
                <LinearGradientLabel fontSize="15" title="기본정보"/>
                <QuestionTitle title="태어난 날을 알려주세요"  endLetter="."/>
                <QuestionSubTitle title="나이는 피부 문제의 원인을 파악할 때 사용되는 중요한 정보입니다." />
                <BirthWrapper>
                    <YyyyInput ref={yearFocus} onChange={onYearChange} value={year} maxLength={4} placeholder="YYYY" autoFocus />/
                    <MmInput ref={monthFocus} onChange={onMonthChange} value={month} maxLength={2} placeholder="MM"/>/
                    <DdInput ref={dayFocus} onChange={onDayChange} value={day} maxLength={2} placeholder="DD"/>
                </BirthWrapper>
            </QuestionWrapper>            
            <NextButton activate={buttonActive} name="다음" onClick={onNextButtonClick} />
        </Container>
    );
}

export default Birth;