import styled from "styled-components";

const Circle = styled.div<{size: string}>`
    width: ${(props) => props.size};
    height: ${(props) => props.size};
    border-radius: 50%;
`;

const Image = styled.img`
    width: 100%;
    height: 100%;
`;

interface CircleImage {
    size: string;
    url: string;
}

export function CircleImage({
    size,
    url
}: CircleImage){
    return (
        <Circle size={size}>
            <Image src={url} />
        </Circle>
    );
}