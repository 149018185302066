import { useEffect, useState } from "react";
import { Outlet, useParams, useLocation } from "react-router-dom";

import {
    Header
} from '../../components/organisms'

function Survey(){  
    const { type } = useParams();
    const location = useLocation();
    const [ isStart, setIsStart ] = useState(false);
    
    useEffect(() => {
        if(location.pathname === `/${type}/start/init`)
        {
            setIsStart(true);
        }
        else
        {
            setIsStart(false);
        }

    }, [location])
    
    return (
        <>     
            <Header start={isStart} />
            <Outlet />
        </>
    )
}

export default Survey;