import styled from "styled-components";
import { useNavigate } from "react-router-dom";

import {
    DetailTable
} from '../../../components/organisms'

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #F6F6F6;
    flex-direction: column;
    gap: 71px;
    padding: 179px 0 80px 0;
    z-index: 1;
    position: relative;
`;

const Button = styled.div`
    border: 1px solid #5471FF;
    border-radius: 120px;
    width: 120px;
    height: 48px;
    display: flex;  
    justify-content: center;
    align-items: center;
    color: #5471FF;
    font-family: 'felixTitling';
    font-size: 20px;
    line-height: 30px;
`;

const Wrapper = styled.div<{gap: string}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.gap}px;
`;

const Title = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 70px;
    color: #1A1A1A;
`;

const SubTitle = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #1A1A1A;
`;

const SubTitleCenter= styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #1A1A1A;
    text-align: center;
`;

const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
`;

const Card = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 20px;
    flex-direction: column;
`;

const Image = styled.img`

`;

const RowWrapper = styled.div<{gap: string}>`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: ${(props) => props.gap}px;
`;

const FelixTitlingLabel = styled.span`
    font-family: 'felixTitling';
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: #1A1A1A;
`;

const SubLabel = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
`;

const SurveyButton = styled.div`
    width: 374px;
    height: 88px;
    background: #1A1A1A;
    border-radius: 44px;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 24px;
    line-height: 30px;
    color: #FFFFFF;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const BackGradient = styled.img`
    position: absolute;
    z-index: -1;
    bottom: -10px;
`;

export function Step3(){

    const navigate = useNavigate();

    const onSurveyStart = () => {
        navigate('/skin/start/init')
    }

    return (
        <Container>
            <Wrapper gap="30">
                <Button>STEP 03</Button>
                <Wrapper gap="17">
                    <Title>아름다움을 찾는 여정</Title>
                    <SubTitleCenter>클럽 어니스트의 상담, 시술 추천, 제품 추천으로<br/>당신만의 특별함을 찾는 여정에 함께 할게요</SubTitleCenter>
                </Wrapper>
            </Wrapper>
            <Wrapper gap="175">
                <CardWrapper>
                    <Card>
                        <Wrapper gap="40">
                            <Image src={require('../../../styles/assets/skindetail/dr1.png')}/>
                            <RowWrapper  gap="8">
                                <FelixTitlingLabel>MEDICAL DOCTOR.</FelixTitlingLabel>
                                <SubTitle>김창경</SubTitle>
                            </RowWrapper>
                        </Wrapper>
                        <SubLabel>피부 관리도 새 국면을 맞이하고 있습니다.<br/>클럽 어니스트의 프로그램으로 전문화된<br/>지식과 노하우, 홈케어 팁들을 모두<br/>공유하고자 노력하겠습니다.</SubLabel>
                    </Card>
                    <Card>
                        <Wrapper gap="40">
                            <Image src={require('../../../styles/assets/skindetail/dr2.png')}/>
                            <RowWrapper  gap="8">
                                <FelixTitlingLabel>MEDICAL DOCTOR.</FelixTitlingLabel>
                                <SubTitle>이호익</SubTitle>
                            </RowWrapper>
                        </Wrapper>
                        <SubLabel>피부는 정확한 진단에 따른 의사결정,<br/>상황에 맞는 최적화된 솔루션 등이<br/>뒷받침되어야 올바른 케어가 가능합니다.<br/>클럽 어니스트로 피부 정복, 시작하세요!</SubLabel>
                    </Card>
                    <Card>
                        <Wrapper gap="40">
                            <Image src={require('../../../styles/assets/skindetail/dr3.png')}/>
                            <RowWrapper gap="8">
                                <FelixTitlingLabel>MEDICAL DOCTOR.</FelixTitlingLabel>
                                <SubTitle>이재우</SubTitle>
                            </RowWrapper>
                        </Wrapper>
                        <SubLabel>병원을 다년간 운영하며 습득한 노하우를<br/>비롯한, 환자의 라이프스타일에 따른<br/>치료효과 등에 관한 인사이트를 나누고<br/>싶습니다. 클럽어니스트에서 만나요!</SubLabel>
                    </Card>
                    <Image src={require('../../../styles/assets/skindetail/phone.png')}/>
                </CardWrapper>
                <RowWrapper  gap="80">   
                    <DetailTable 
                        title="클럽 어니스트에 있는 것"
                        datas={["피부 관리에 특화된 전문 닥터들", "평균 30대, 영 앤 스마트 메디컬 닥터", "친구같은 닥터, 꼼꼼한 상담", "언제 어디서나 가능한 접근성"]}
                        activate={true}
                    />            
                    <DetailTable 
                        title="클럽 어니스트에 없는 것"
                        datas={["불필요한 병원 방문 ZERO", "쓸데없는 관리 추가 ZERO", "쓸데없는 상담 ZERO", "불안함 ZERO"]}
                        activate={false}
                    />
                </RowWrapper>     
            </Wrapper>
            <SurveyButton
                onClick={onSurveyStart}
            >피부 진단 서베이 시작하기</SurveyButton>
            <BackGradient src={require('../../../styles/assets/skindetail/btn_back_gradient.png')} />
        </Container>
    );
}