import styled from "styled-components";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Section = styled.div`
    width: 440px;
    height: 314px;
    border-radius: 15px;
    background-color: #fff;
    color: #000000;
    padding: 37px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    position: relative;
`;

const Logo = styled.img`

`;

const Line = styled.div`
    width: 156px;
    height: 0px;
    border: 1px solid #000000;
    margin-top: 11.92px;
    margin-bottom: 30px;
`;

const Title = styled.div`
    font-family: 'Apple SD Gothic Neo Bold';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 23px;
    color: #000000;
`;

const SubTitle = styled.div`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 23px;
    color: #000000;
    margin-top: 9px;;
`;

const Button = styled.div`
    width: 129px;
    height: 48px;
    background: #000217;
    border-radius: 15px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 60px;
    cursor: pointer;
`;

interface CompleteProps {
    message: string;
    subMessage: string;
    onClick: () => void;
}

function CompleteModal({
    message,
    subMessage,
    onClick
}: CompleteProps){
    return (
        <Container>
            <Section>
                <Logo src={require('../../../../styles/assets/common/soldoclogo.png')} />
                <Line />
                <Title>{message}</Title>
                <SubTitle dangerouslySetInnerHTML={{__html: subMessage}}></SubTitle>
                <Button
                    onClick={onClick}
                >확인</Button>
            </Section>
        </Container>
    );
}

export default CompleteModal;