import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

const Container = styled.div`  
    background-color: #ffffff;
    width: 100%;
    position: relative;
`;

const Box = styled.div`
    position: relative;
    z-index: 2;
`;

const Card = styled.div`
    width: 100%;
    height: 150px;
    background-color: #F3F5F8;
    position: relative;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 34px 16px;
    flex-direction: column;
`;

const CardTitle = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 16px;
    line-height: 19px;
    color: #1E1E1E;
`;

const CardContents = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 29px;
    color: #1E1E1E;
    margin-top: 15px;
    span {
        color: #FF000F;
    }
    strong{
        font-weight: 700;
    }
`;

function BookingCardSlider () {

    const settings = {
        centerMode: true,
        dots: false,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        centerPadding: "-20px",
        autoplay: true,
        autoplaySpeed: 2000,
        nextArrow: <></>,
        prevArrow: <></>
    };

    return (
        <Container>
            <Slider {...settings}>
                <Box>
                    <Card>
                        <CardTitle>
                            안심하세요! 🙂
                        </CardTitle>
                        <CardContents>
                            예약 변경/취소는 [진료 내역보기]에서 가능해요.<br/>
                            <span>예약시간 15분 전까지 변경 및 취소 가능</span>해요.
                        </CardContents>
                    </Card>
                </Box>
                <Box>
                    <Card>
                        <CardTitle>
                            안심하세요! 🙂
                        </CardTitle>
                        <CardContents>
                            신원 확인 후 진료가 진행됩니다. <strong>(신분증 지참 필)</strong><br/>
                            진료비는 진료 후 결제되며, 대면 진료비와 동일합니다.
                        </CardContents>
                    </Card>
                </Box>
                <Box>
                    <Card>
                        <CardTitle>
                            안심하세요! 🙂
                        </CardTitle>
                        <CardContents>
                            고객님의 진료 기록은 일절 저장되지 않습니다.<br/>
                            개인 정보 또한 본인만 조회가 가능합니다.
                        </CardContents>
                    </Card>
                </Box>
            </Slider>
        </Container>
    );
}

export default BookingCardSlider;