// 사용자의 답을 기록 
// survey selector 
// answer state 변경 

//{userId: '', curstep: 0, curquestion: 0 }
import { atom } from "recoil";

import {
    UserAnswerType,
    SurveyAnswerType
} from '../types';

export const answerState = atom<UserAnswerType []>({
    key: 'answerState',
    default: [
        {
            category: "PROFILE",
            answers: []
        }       
    ]
})

// local storage 및 카카오 저장용으로만 사용함 
export const surveyAnswerState = atom<SurveyAnswerType>({
    key: 'surveyAnswerState',
    default: undefined
})

export const lifeStyleAnswerState = atom<SurveyAnswerType>({
    key: 'lifeAnswerState',
    default: undefined
})