import styled from "styled-components";
import { useNavigate } from "react-router-dom";
import { motion } from 'framer-motion';

const Container = styled.div`
    width: 100%;
    background-color: #1A1A1A;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 1100px;
`;

const WhiteSpace = styled.div`
    background-color: #ffffff;
    height: 200px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
`;

const Wrapper = styled.div`
    width: 100%;
    height: 790px;
    position: relative;
    background-color:#1A1A1A;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const Symbol = styled(motion.img)`    
    position: fixed;
    width: 172px;
    height: 172px;
    bottom: 30px;
    right: 30px;
    z-index: 999;
    cursor: pointer;
    /* transform: translate(20%, 0); */
`;

const RunnerImg1 = styled.img`
    position: absolute;
    top: 15px;
    left: 55%;
    z-index: 2;
`;

const RunnerImg2 = styled.img`
    position: absolute;
    top: 15px;
    right: 15%;
    z-index: 2;
`;

const TextWrapperLine = styled.div`
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    font-size: 72px;
    color: ${(props) => props.theme.textColor};
    width: 100%;
    text-align: center;
    padding: 15px 0 15px 0;
    font-family: 'Apple SD Gothic Neo Bold';
`;

const TextWrapperNoLine = styled.div`
    font-size: 72px;
    color: ${(props) => props.theme.textColor};
    width: 100%;
    text-align: center;
    padding: 15px 0 15px 0;
    font-family: 'Apple SD Gothic Neo Bold';
`;

const buttonVariants = {
    hover: {
        rotateZ: 25,
    }
}


function Introduction(){
    const navigate = useNavigate();
   
    return (
        <Container>
            <WhiteSpace />
            <RunnerImg1 src={require("../../styles/assets/landing/runner1.png")} />
            <RunnerImg2 src={require("../../styles/assets/landing/runner2.png")} />
            <Wrapper>
                <TextWrapperLine>우리 모두가 더 쉽고 편하게</TextWrapperLine>
                <TextWrapperNoLine>자신을 케어하는 순간</TextWrapperNoLine>
                <TextWrapperLine>더 건강한 일상이 시작</TextWrapperLine>
                <Symbol src={require("../../styles/assets/survey/survey-start.png")}
                    variants={buttonVariants}
                    whileHover="hover"
                    transition={{ type: "tween", duration: 0.5, delay: 0}}
                    onClick={
                        () => {
                            navigate("/skin/start/init")
                        }
                    }
                />
            </Wrapper>
            {/* <TempImg src={require("../../styles/assets/03.png")} /> */}
        </Container>
    );
}

export default Introduction