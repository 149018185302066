// order에 따라 색변환
import styled from "styled-components";

import {
    SolutionStateType
} from "../../../store"

import {
    ZRotateImage,
    ArrowButton
} from "../../atoms";

const Container = styled.div<{order: number}>`
    width: 841px;
    padding: 93px 114px 186px 114px;
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    background-color: ${(props) => (props.order === 1 && "#000000") || (props.order === 2 && "#010633") || (props.order === 3 && "#005B83")};
    gap: 80px;
    box-shadow: 0px 30px 25px rgba(0, 0, 0, 0.32);
    border-bottom-left-radius: 50px;
    margin-top: -48px;
    z-index: ${(props) => (props.order === 1 && 3) || (props.order === 2 && 2) || (props.order === 3 && 1)};
    position: relative;
`;

const TitleWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
`;

const Order = styled.span`
    font-size: 50px;
    line-height: 70px;
    font-family: 'felixTitling';
    color: ${(props) => props.theme.textColor};
`;

const Title = styled.span`
    font-weight: 700;
    font-size: 70px;
    line-height: 73px;
    color: ${(props) => props.theme.textColor};
    margin-top: -10px;
`;

const UserLineWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    gap: 0;
    flex-direction: column;
`;

const UnderLineText = styled.span<{order: number}>`
    width: 522px;
    font-weight: 700;
    font-size: 50px;
    line-height: 76px;
    color: ${(props) => (props.order === 1 && "#FBD952") || (props.order === 2 && "#6DEEFF") || (props.order === 3 && "#4692FF")};
    border-bottom: 1px solid #FFFFFF;

`;

const DetailText= styled.div`
    font-family: 'Apple SD Gothic Neo';
    font-size: 30px;
    line-height: 41px;
    color: ${(props) => props.theme.textColor};
    font-weight: 300;

    strong {
        font-size: 30px;
        font-weight: 700;
    }
`;

interface SolutionCardProps {
    solution: SolutionStateType
    onButtonClick: () => void
}

export function SolutionCard({
    solution,
    onButtonClick
}: SolutionCardProps){
    return (
        <Container order={solution.order}>
            {
                solution.order === 1 && <ZRotateImage />
            }            
            <TitleWrapper>
                <Order>{solution.order}</Order>
                <Title dangerouslySetInnerHTML={{__html: solution.title}}></Title>
            </TitleWrapper>
            <UserLineWrapper>
            {
                solution.solutionName.map((list, index) => {
                    return (
                        <UnderLineText key={index} order={solution.order}>{list}</UnderLineText>
                    );
                })                
            }
             </UserLineWrapper>
             <DetailText dangerouslySetInnerHTML={{__html:solution.solutionDetail}} />
             <ArrowButton text={solution.buttonText} onClick={onButtonClick} />
        </Container>
    );
}