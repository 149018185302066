import styled from "styled-components";
import { HeroSlider } from "../../components/organisms";

const HeroWrapper = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.textColor};
    display: flex;
    align-items: center;
    justify-content: start;
    flex-direction: column;
    padding: 0 0 100px 0;
    gap: 180px;
    position: relative;
`;

const HeroImage = styled.img`
    width: 100%;
`;

const HeroText = styled.div`
    font-family: 'Apple SD Gothic Neo Bold';
    color: ${(props) => props.theme.bgColor};
    font-size: 72px;
    /* font-weight: 700; */
    text-align: center;
    line-height: 108px;
`;

const SubText = styled.div`
    color: ${(props) => props.theme.bgColor};
    font-size: 20px;
    font-weight: 400;
    text-align: center;
    line-height: 30px;
    margin-top: -130px;
`;

function Hero(){
    return(
        <HeroWrapper >
            <HeroSlider />
            <HeroText>솔직함에는 끝이 없다<br/>
                솔직한 닥터의 새로운 도전<br/>
                클럽 어니스트
            </HeroText>
            <SubText>
                클럽 어니스트는 비대면 진료 서비스 솔닥이 만든 온라인 스킨 케어 서비스 (registered by soldoc) 입니다.<br/>
                모두가 자신의 일상을 건강하게 가꿀 수 있도록, 솔직한 닥터, 솔닥이 동행하겠습니다.
            </SubText>

        </HeroWrapper>
    );
}

export default Hero;
