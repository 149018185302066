import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #1A1A1A;
    flex-direction: column;
    gap: 40px;
    padding: 100px 0px 0px 0px;
    overflow: hidden;
`;

const Button = styled.div`
    border: 1px solid #5471FF;
    border-radius: 120px;
    width: 120px;
    height: 48px;
    display: flex;  
    justify-content: center;
    align-items: center;
    color: #5471FF;
    font-family: 'felixTitling';
    font-size: 20px;
    line-height: 30px;
`;

const Wrapper = styled.div<{gap: string}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.gap}px;
`;

const Title = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 70px;
    color: ${(props) => props.theme.textColor};
`;


const SubTitle = styled.span<{fontFamily: string}>`
    font-family: ${(props) => props.fontFamily};
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: ${(props) => props.theme.textColor};
`;

const ImageWrapper = styled.div`
    display: flex;
`;

const Image = styled.img`


`;

export function Step2(){
    return (
        <Container>
            <Button>STEP 02</Button>
            <Wrapper gap="16">
                <Title>당신에게 적합한 케어 플랜</Title>
                <Wrapper gap="64">
                    <Wrapper gap="0">
                        <SubTitle fontFamily="felixTitling">PERSONALIZED VIRTUAL CARE PLAN</SubTitle>
                        <SubTitle fontFamily="Apple SD Gothic Neo">클럽 어니스트가 선사하는 피부 프로그램을 만나보세요</SubTitle>
                    </Wrapper>
                    <Wrapper gap="0"> 
                        <ImageWrapper>
                            <Image src={require('../../../styles/assets/skindetail/black_clubhonest_line_01.png')} />
                            <Image src={require('../../../styles/assets/skindetail/black_clubhonest_line_01.png')} />
                        </ImageWrapper>  
                        
                        <ImageWrapper>
                            <Image src={require('../../../styles/assets/skindetail/black_clubhonest_line_02.png')} />
                            <Image src={require('../../../styles/assets/skindetail/black_clubhonest_line_02.png')} />                    
                        </ImageWrapper>
                        <ImageWrapper>
                            <Image src={require('../../../styles/assets/skindetail/black_clubhonest_line_03.png')} />
                            <Image src={require('../../../styles/assets/skindetail/black_clubhonest_line_03.png')} />
                        </ImageWrapper>                    
                    </Wrapper>
                </Wrapper>               
            </Wrapper>

        </Container>
    );
}