import { useState } from "react";
import styled from "styled-components";

import {
    Accordion
} from '../../molecules'

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 0px;
    padding: 100px 0;
    background-color: #F6F6F6;
`;

interface AccorionData {
    title: string;
    contents: string;
}

export function AccordionList(){

    const [datas, setDatas] = useState<AccorionData[]>([
        { title: '클럽 어니스트는 무엇인가요?', contents: '클럽 어니스트는 비대면 진료 서비스 \'솔닥\'이 만든 개인화된 건강 관리 프로그램입니다. 세부적으로 피부, 다이어트, 헤어, 멘탈 관리 분야에서 순차적으로 론칭될 예정입니다.'},
        { title: '클럽 어니스트 가입비가 있나요?', contents: '현재는 별도의 가입비 없이 운영되고 있으며, 솔닥과의 협업을 통해 진료 발생 시 진료비와 약제비는 솔닥이 청구합니다. 이는 대면과 동일한 진료 프로세스로 진행되며 불필요한 처방이 발생하거나, 제품추천으로 인한 연계되는 추가적인 납부 의무는 없습니다.'},
        // { title: '클럽 어니스트에 가입 자격이 있나요?', contents: '클럽 어니스트는 서베이를 통해 가입에 적합한지 AI 추천을 받게 되며, 그 이후 가입을 희망하시는 분들은 JOIN 버튼을 눌러 가입해 주시면 됩니다.'},
        { title: '클럽 어니스트를 통해 제공받는 것이 무엇인가요?', contents: '현재는 별도의 가입비 없이 운영되고 있습니다. 다만 비대면 진료를 원하시는 경우에 한하여 솔닥 비대면 진료실을 통해 진료가 이루어집니다.'}
    ]);

    return (
        <Container>
            {
                datas.map((data, index) => {
                    return (
                        <Accordion key={index}
                            {...data}
                        />
                    );
                })
            }
        </Container>
    );
}