/**
 * Progress Bar
 * 전체 count, 진행count, width % 조절하여 표시 
 * 
 * @ param: totalTaskCount, completeCount
 * @ input: x  
 * @ return: x
 * @ exception: x
 * @ update: 2022.06.15 leeleou
*/

import { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 13px;  
`;
const BarWrapper = styled.div`
    position: relative;
    width: 593px;
    height: 9px;
`;

const Bar = styled.div`
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: #01275E;
    border: 1px solid black;
    border-radius: 10px;
    top: 0;
    left: 0;
`;

const InnerBar = styled.div<{width: string}>`
    position: absolute;
    width: ${(props) => props.width}%;
    height: 100%;
    background: linear-gradient(to right, #76BCC5, #709EE0, #075BD4);
    border-radius: 10px;
    top: 0;
    left: 0;
`;

interface ProgressBarProps {
    leftTotalTaskCount: number;
    leftCompleteCount: number;
    rightTotalTaskCount: number;
    rightCompleteCount: number;
}

export function ProgressBar({
    leftTotalTaskCount, 
    leftCompleteCount,
    rightTotalTaskCount,
    rightCompleteCount
}: ProgressBarProps){

    const [ lWidth, setLWith ] = useState(0);
    const [ rWidth, setRWith ] = useState(0);
    useEffect(() => {   
        setLWith(leftCompleteCount/leftTotalTaskCount*100)
        setRWith(rightCompleteCount/rightTotalTaskCount*100)
    }, [leftTotalTaskCount, leftCompleteCount, rightTotalTaskCount, rightCompleteCount])

    return (
        <Container>
            <BarWrapper>                        
                <Bar />      
                <InnerBar width={lWidth.toString()} />            
            </BarWrapper>
            <BarWrapper>                        
                <Bar />      
                <InnerBar width={rWidth.toString()} />            
            </BarWrapper>
        </Container>
  
    );
}