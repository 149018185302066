import styled from "styled-components";

interface ArrowButtonProps {
    text: string;
    onClick: () => void
}

const Button = styled.div`
    width: 286px;
    height: 89px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #FFFFFF;
    color: ${(props) => props.theme.textColor};
    border-radius: 100px;
    gap: 10px;
    font-size: 25px;
    cursor: pointer;
`;

const ArrowImg = styled.img``;

export function ArrowButton({
    text,
    onClick
}: ArrowButtonProps){
    
    return (
        <Button onClick={onClick}>
            {text}
            <ArrowImg src={require("../../styles/assets/common/arrow_right.png")} />
        </Button>
    );
}