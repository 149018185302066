import styled from "styled-components";
import { motion } from "framer-motion";
import { useState } from "react";

const Container = styled(motion.div)<ButtonProps>`
    border-radius: 30px;
    width: ${(props) => props.activate === 'false' ? props.width : Number(props.width) + 60}px;
    padding: 20px 40px;
    background: ${(props) => props.activate ==='false' ? "#000000" : "linear-gradient(52.08deg, #76BCC5 22.58%, #709EE0 55.66%, #075BD4 93.67%)"};
    border: 1px solid #7a7a7a;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    :hover {
        width: ${(props) => Number(props.width) + 60}px;
    }
`;

const Text = styled.span`
    font-size: 30px;
    font-weight: 700;
    line-height: 60px;
    color: rgba(122, 122, 122, 1);    
`;

const ActiveText = styled.span`
    font-size: 35px;
    font-weight: 700;
    line-height: 60px;
    color: rgba(30, 30, 30, 1);    
`;

const HoverText = styled.span`
    font-weight: 700;
    line-height: 60px;
    color: #075BD4;
    font-size: 35px;
`;

const containerVariants = {
    hover: {
        border: "1.5px solid #FFFFFF",
    }
};

interface ButtonProps {
    width: string;
    activate: string;
}

interface AnswerButtonProps {
    text: string;
    activate: boolean;
    width: string;
    onClick: () => void;
}

export function AnswerTextButton({text, activate, width, onClick}: AnswerButtonProps){
    const [ hovered, setHovered ] = useState(false);
    
    return (
        <Container
            width={width}
            activate={!activate ? 'false' : 'true'}  // motion은 boolean 으로 절달할 수 없음
            variants={containerVariants}
            whileHover={!activate ? "hover" : "none"}
            transition={{
                type: "Inertia"
            }}
            onClick={onClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            {
                hovered ? 
                (
                    !activate ? 
                    <HoverText>{text}</HoverText>
                    :
                    <ActiveText>{text}</ActiveText>
                )
                :
                (
                    !activate ? 
                    <Text>{text}</Text>
                    :
                    <ActiveText>{text}</ActiveText>
                )
            }
        </Container>
    );
}