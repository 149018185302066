// Question, Answer가 같이 있는 Label

import styled from "styled-components";

interface QALabelProps {
    question: string;
    answer: string;
}

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    width: 300px;
    gap: 23px;
`;

const Question = styled.span`
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    color: #C1C1C1;
`;

const Answer = styled.span`
    font-weight: 700;
    font-size: 30px;
    line-height: 35px;
    color: #FFFFFF;
    height: 62px;
`;

export function QALabel({
    question,
    answer
}: QALabelProps){

    return (
        <Wrapper>
            <Question>{question}</Question>
            <Answer>{answer}</Answer>
        </Wrapper>
    );
}