import styled from "styled-components";

const Button = styled.div<ButtonProps>`
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    font-family: ${(props) => props.fontFamily};
    font-size: ${(props) => props.fontSize}px;
    color: ${(props) => props.color};
    background-color: ${(props) => props.backgroundColor};
    border-radius: ${(props) => props.borderRadius}px;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 36px;
    font-weight: 400;
`;

interface ButtonProps {
    width: string;
    height: string;
    fontFamily: string;
    fontSize: string;
    color: string;
    backgroundColor: string;
    borderRadius: string;
}

interface RoundButtonProps {
    width: string;
    height: string;
    fontFamily: string;
    fontSize: string;
    color: string;
    backgroundColor: string;
    borderRadius: string;
    text: string;
    onClick: () => void;
}

export function RoundButton({
    width,
    height,
    fontFamily, 
    fontSize,
    color,
    backgroundColor, 
    borderRadius,
    text,
    onClick
}: RoundButtonProps){
    return (
        <Button 
            width={width}
            height={height}
            fontFamily={fontFamily}
            fontSize={fontSize}
            color={color}
            backgroundColor={backgroundColor}
            borderRadius={borderRadius}
            onClick={onClick}
        >{text}</Button>
    );
}