import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #F6F6F6;
    flex-direction: column;
    gap: 71px;
    padding: 179px 0 80px 0;
`;

const Wrapper = styled.div<{gap: string}>`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: ${(props) => props.gap}px;
`;

const Button = styled.div`
    border: 1px solid #5471FF;
    border-radius: 120px;
    width: 143px;
    height: 48px;
    display: flex;  
    justify-content: center;
    align-items: center;
    color: #5471FF;
    font-family: 'felixTitling';
    font-size: 20px;
    line-height: 30px;
`;

const Title = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 70px;
    color: #1A1A1A;
`;

const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 107px;
`;

const Image = styled.img`
    
`;

const Text = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #000000;
`;

const SubText = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 26px;
    text-align: center;
    color: #666666;
`;

export function HowTo(){
    return (
        <Container>
            <Wrapper gap="30">
                <Button>HOW TO</Button>
                <Title>클럽 어니스트, 이렇게 진행됩니다</Title>
            </Wrapper>
            <CardWrapper>
                <Wrapper gap="17">
                    <Wrapper gap="65">
                        <Image src={require('../../../styles/assets/skindetail/howto1.png')} />
                        <Text>서베이 시작 및 가입</Text>
                    </Wrapper>
                    <SubText>서베이를 통한 피부 상태 진단 및<br/>클럽 어니스트 가입 진행</SubText>
                </Wrapper>
                <Wrapper gap="17">
                    <Wrapper gap="65">
                        <Image src={require('../../../styles/assets/skindetail/howto2.png')} />
                        <Text>의료진 상담</Text>
                    </Wrapper>
                    <SubText>서베이 내용을 토대로한<br/>의료진의 체계적 상담</SubText>
                </Wrapper>
                <Wrapper gap="17">
                    <Wrapper gap="65">
                        <Image src={require('../../../styles/assets/skindetail/howto3.png')} />
                        <Text>진료 혹은 시술 / 제품 추천</Text>
                    </Wrapper>
                    <SubText>진료, 시술, 제품 추천 등<br/>맞춤 서비스 진행</SubText>
                </Wrapper>
                <Wrapper gap="17">
                    <Wrapper gap="65">
                        <Image src={require('../../../styles/assets/skindetail/howto4.png')} />
                        <Text>안전 배송</Text>
                    </Wrapper>
                    <SubText>의약품, 제품 등<br/>모두 안전배송</SubText>
                </Wrapper>
            </CardWrapper>
     
        </Container>    
    );
}