// 레벨을 표시

import styled from "styled-components";

interface LeveTitleProps {
    level: number;
    title: string;
}

const Container = styled.div`
    height: 157px;
    background: #020620;
    border: 1px solid #7A7A7A;
    box-shadow: 0px 4px 11px #000000;
    border-radius: 100px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 0 76px;
`;

const LevelText = styled.span`
    font-family: 'FelixTitling';
    font-size: 60px;
    line-height: 60px;
    color: #FFFFFF;
    width: 135px;
    border-right: 2px solid #FFFFFF;
    margin-right: 35px;

`;

const Description = styled.span`
    font-size: 60px;
    line-height: 60px;
    color: #FFFFFF;
    margin-top: -10px;
`;

export function LevelTitle({level, title}: LeveTitleProps){
    return (
        <Container>
            <LevelText>LV.{level} </LevelText>
            <Description>{title}</Description>
        </Container>
    );
}