import styled from "styled-components";

const Title = styled.div`
    font-size: 50px;
    font-weight: 700;
    color: ${(props) => props.theme.textColor};
    height: 120px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    line-height: 60px;
`;

const EndLetter = styled.span`
    color: #4C4C4C;
`;

interface SurveyTitleProps {
    title: string;
    endLetter: string;
}

export function QuestionTitle({title, endLetter}: SurveyTitleProps)
{   
    return<Title>{title}<EndLetter>{endLetter}</EndLetter></Title>
};
