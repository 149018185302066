// router
import { BrowserRouter, Route, Routes } from "react-router-dom";
import Landing from "./landing";
import SkinDetail from "./landing/skindetail";
import Survey from './survey'
// coming soon
import CSLandingPc from './comingsoon/pc';
import CSLandingMobile from './comingsoon/mobile';

// clubhonest
import DoctorView from './doctor';

//login
import Login from './login/Login';
//start
import Start from "./survey/start";
import Init from "./survey/start/Init";
import Birth from "./survey/start/Birth";
import Gender from './survey/start/Gender';
import Select from "./survey/start/Select";

//survey step1 detail
import Detail from "./survey/detail";
import DetailQuestion from "./survey/detail/Question";

// survey step2 health
import Health from './survey/health';
import HealthQuestion from './survey/health/Question';
import { useEffect } from "react";

// survey step3 result
import { SurveyResult } from './survey/result';

// survey step4 booking

// /:type/start/init
// /:type/start/brith
// /:type/start/gender
// /:type/start/select
// /:type/detail/:category/:question
// /:type/health/:category/:question
// /:type/result/:category
// /:type/booking/:category

function Router(){

    useEffect(() => {
        var userAgent = navigator.userAgent;
        if (userAgent.match(/iPhone|iPod|iPad|Android|Windows CE|BlackBerry|Symbian|Windows Phone|webOS|Opera Mini|Opera Mobi|POLARIS|IEMobile|lgtelecom|nokia|SonyEricsson/i) != null || userAgent.match(/LG|SAMSUNG|Samsung/) != null)
        {
            //pc 
            // window.location.href = "https://m.clubhonest.net";
        }
        else
        {
            //mobile
            // window.location.href = "https://clubhonest.net";
        }
    })

    return (
        <BrowserRouter>
            <Routes>
                <Route path="/clubhonest/view/:reception_number" element={<DoctorView></DoctorView>} />
                <Route path="/" element={<Landing></Landing>} />
                <Route path="/auth/callback" element={<Login></Login>}/>
                <Route path="/skinhonest" element={<SkinDetail></SkinDetail>} />
                <Route path="/:type" element={<Survey></Survey>}>
                    <Route path="start" element={<Start></Start>}>
                        <Route path="init" element={<Init></Init>} />
                        <Route path="birth" element={<Birth></Birth>} />
                        <Route path="gender" element={<Gender></Gender>} />
                        <Route path="select" element={<Select></Select>} />
                    </Route>
                    <Route path="detail/:category" element={<Detail></Detail>}>
                        <Route path=":question" element={<DetailQuestion></DetailQuestion>} />
                    </Route>
                    <Route path="health/:category" element={<Health></Health>}>
                        <Route path=":question" element={<HealthQuestion></HealthQuestion>} />
                    </Route>
                    <Route path="result/:category" element={<SurveyResult></SurveyResult>} />
                        
                    {/* <Route path=":category" element={<SurveyCategory></SurveyCategory>}>
                        <Route path=":question" element={<SurveyQuestion></SurveyQuestion>} />
                    </Route> */}
                </Route>
            </Routes>
        </BrowserRouter>   
    );
}

export default Router;