import styled from "styled-components";

import {
    RoundButton
} from '../../atoms'

const CardContainer = styled.div`
    width: 590px;
    height: 614px;
    border-radius: 22px;
    background: #F8F8F8;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40.58px 38px;
    position: relative;
    gap: 29px;
    position: relative;
`;

const TitleWrapper = styled.div`
    display: flex;
    gap: 12px;
    flex-direction: column;
`;

const Title = styled.span`
    font-family: 'Apple SD Gothic Neo ';
    font-style: normal;
    font-weight: 600;
    font-size: 34px;
    line-height: 40px;
    color: ${(props) => props.theme.bgColor};
`;

const SubTitle = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: ${(props) => props.theme.bgColor};
`;

const Writer = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 30px;
    color: #999999;
`;

const Image = styled.img`
    position: absolute;
    bottom: 0;
    left: 0;
    border-bottom-left-radius: 22px;
    border-bottom-right-radius: 22px;
`;

interface ReviewCardProps {
    buttonText: string;
    title: string;
    subTitle: string;
    writer: string;
    image: string;
}

export function ReviewCard({
    buttonText,
    title,
    subTitle,
    writer,
    image
}: ReviewCardProps){
    const onClick = () => {

    }

    return (
        <CardContainer>
            <RoundButton 
                width="96"
                height="31.76"
                fontFamily="felixTitling"
                fontSize="20"
                color="#FFFFFF"
                backgroundColor="#000000"
                borderRadius="40"
                text={buttonText}
                onClick={onClick}
            />
            <TitleWrapper>
                <Title>{title}</Title>
                <SubTitle dangerouslySetInnerHTML={{__html: subTitle}} />
                <Writer>{writer}</Writer>
            </TitleWrapper>     
            <Image src={image} />
        </CardContainer>
    );
}