// landing은 요소 활용 가능?
// header 
// hero
// services
// footer
import Header from './Header';
import Footer from './Footer';
import Goal from './Goal';
import Hero from './Hero';
import WhyClubHonest from './WhyClubHonest';
import Banner from './Banner'
import Introduction from './Introduction';
import Services from './Services';


import { useEffect, useState } from 'react';

function Landing(){
    useEffect(() => {
        window.scrollTo({
            top: 0
        });
    }, [])
    return (
        <div id="nc-main" className='no-main bg-cover bg-cc'>
            <div className="bg-animation">
                <div id='stars'></div>
                <div id='stars2'></div>
                <div id='stars3'></div>
                <div id='stars4'></div>
            </div>
            <Header />
            <div style={{height: '90px'}}></div>
            <Hero />
            <Introduction />
            <WhyClubHonest />
            <Banner />
            <Services />
            <Goal />
            <Footer />
        </div>
    );
}

export default Landing;