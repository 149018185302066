import React, { Component } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";
import styled from "styled-components";

const Wrapper = styled.div<{gap: string}>`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.gap}px;
`;


const Container = styled.div`
    background-color: white;
    width: 1650px;
`;

const Box = styled.div`
    height: 750px; 
    position: relative;
    z-index: 2;
`;

const Card = styled.div`
    position: relative;
    width: 426px;
    height: 650px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 45px;
    margin-left: -100px;
`;

const ImageBack= styled.div`
    position: relative;
    width: 426px;
    height: 340px;
`;
const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
`;
const MDButton = styled.div`
    width: 60px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 15px;
    position: absolute;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    top: 20px;
`;

const Text = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
`;

const SubText =styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #666666;
`;

const BottomButton = styled.div`
    width: 426px;
    height: 70px;
    background: #1A1A1A;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
    cursor: pointer;
`;


function SampleNextArrow(props:any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, display: "block"}}
        onClick={onClick}
      />
    );
  }
  
  function SamplePrevArrow(props:any) {
    const { className, style, onClick } = props;
    return (
      <div
        className={className}
        style={{ ...style, backgroundColor: "black", borderRadius: '50%', color: 'black' }}
        onClick={onClick}
      />
    );
  }

export function CardList () {
  
    const settings = {
        centerMode: true,
        dots: true,
        infinite: true,
        speed: 1000,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerPadding: "120px",
        nextArrow: <></>,
        prevArrow: <></>
    };

    return (
      <Container>        
        <Slider {...settings}>
            <Box>
                <Card>
                    <ImageBack>
                        <Image src={require("../../../styles/assets/skindetail/product1.png")} />
                        <MDButton>MD</MDButton>
                    </ImageBack>
                    <Wrapper gap="18">
                        <Text>에스트라 아토베리어 MD</Text>
                        <SubText>의료기기 | 점착성 투명 창상 피복재<br/>에스트라 크림, 아토베리어 로션, 크림 등</SubText>
                    </Wrapper>
                    <BottomButton
                        onClick={() => {
                            window.open('https://soldoc.co.kr/goods/md.php', '_blank')
                        }}
                    >처방 및 상담 요청</BottomButton>
                </Card>
            </Box>
            <Box>
                <Card>
                    <ImageBack>
                        <Image src={require("../../../styles/assets/skindetail/product2.png")} />
                        <MDButton>MD</MDButton>
                    </ImageBack>
                    <Wrapper gap="18">
                        <Text>제로이드 MD</Text>
                        <SubText>의료기기 | 점착성 투명 창상 피복재<br/>제로이드 로션, 아토베리어 크림, 리치 크림 등</SubText>
                    </Wrapper>
                    <BottomButton
                        onClick={() => {
                            window.open('https://soldoc.co.kr/goods/md.php', '_blank')
                        }}
                    >처방 및 상담 요청</BottomButton>
                </Card>
            </Box>
            <Box>
                <Card>
                    <ImageBack>
                        <Image src={require("../../../styles/assets/skindetail/product3.png")} />
                    </ImageBack>
                    <Wrapper gap="18">
                        <Text>솔닥 에너지 클렌저 A</Text>
                        <SubText>솔닥 레스큐 센터와 피부과 의사의 공동 연구로 개발된<br/>논코메도제닉 저자극 약산성 클렌저</SubText>
                    </Wrapper>
                    <BottomButton
                        onClick={() => {
                            window.open('https://www.soldoc.co.kr/goods/goods_view.php?goodsNo=1000000123', '_blank')
                        }}
                    >구매 및 상담 요청</BottomButton>
                </Card>
            </Box>       
            <Box>
                <Card>
                    <ImageBack>
                        <Image src={require("../../../styles/assets/skindetail/product4.png")} />
                    </ImageBack>
                    <Wrapper gap="18">
                        <Text>솔닥 에너지 모이스처라이저 A</Text>
                        <SubText>솔닥 레스큐 센터와 피부과 의사의 공동 연구로 개발된<br/>유수분 밸런스, 피부장벽 강화에 효과적인 로션</SubText>
                    </Wrapper>
                    <BottomButton
                        onClick={() => {
                            window.open('https://www.soldoc.co.kr/goods/goods_view.php?goodsNo=1000000122', '_blank')
                        }}
                    >구매 및 상담 요청</BottomButton>
                </Card>
            </Box> 
        </Slider>
      </Container>
    );
}