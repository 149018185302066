import styled from "styled-components";

import {CardList} from './CardList';

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${(props) => props.theme.textColor};
    padding: 137px 0 220px 0;   
    flex-direction: column;
    gap: 82px;
`;

const Wrapper = styled.div<{gap: string}>`
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: ${(props) => props.gap}px;
`;

const RowWrapper = styled.div<{gap: string}>`
    display: flex;
    gap: ${(props) => props.gap}px;
`;

const Button = styled.div`
    border: 1px solid #5471FF;
    border-radius: 120px;
    width: 176px;
    height: 48px;
    display: flex;  
    justify-content: center;
    align-items: center;
    color: #5471FF;
    font-family: 'felixTitling';
    font-size: 20px;
    line-height: 30px;
`;

const Title = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 70px;
    color: #1A1A1A;
`;

const SubTitle = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    text-align: center;
    color: #1A1A1A;
`;

const CardWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const Box = styled.div`
    position: relative;
`;

const Card = styled.div`
    position: absolute;
    top: 50%;
    left: 50%;
    width: 200px;
    height: 500px;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 45px;
`;

const ImageBack= styled.div`
    position: relative;
    width: 426px;
    height: 340px;
`;
const Image = styled.img`
    position: absolute;
    top: 0;
    left: 0;
`;
const MDButton = styled.div`
    width: 60px;
    height: 30px;
    background: #FFFFFF;
    border-radius: 15px;
    position: absolute;
    font-family: 'Montserrat';
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 28px;
    color: #1A1A1A;
    display: flex;
    justify-content: center;
    align-items: center;
    right: 20px;
    top: 20px;
`;

const Text = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 30px;
    color: #000000;
`;

const SubText =styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 28px;
    color: #666666;
`;

const BottomButton = styled.div`
    width: 426px;
    height: 70px;
    background: #1A1A1A;
    border-radius: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: #FFFFFF;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 28px;
`;

export function Suggestion(){
    return (
        <Container>
            <Wrapper gap="30">
                <Button>SUGGESTION</Button>
                <Wrapper gap="18">
                    <Title>클럽 어니스트 - 스킨 제품 라인</Title>
                    <SubTitle>클럽 어니스트 - 스킨 라인에서는 아래와 같은 상품들을 처방 및 구매 추천드려요</SubTitle>
                </Wrapper>
            </Wrapper>
            <CardWrapper>
                <CardList />
            </CardWrapper>
            
        </Container>
    );
}