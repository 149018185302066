import styled from "styled-components";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import axios from "axios";

import {  
    useRecoilValue, 
    useRecoilState 
} from "recoil";

import { 
    lastSelectedLifeIndexState, 
    lastSelectedSurveyIndexState,
    surveyState,
    lifeState,
    surveyAtom,
    loginState
} from "../../../store";

import { 
    getLocalStorage 
} from "../../../utilities/localstorage";

import {
    ServerJumpModal
} from '../../organisms'

import {
    LoginStateType
} from '../../../types'

import { useEffect, useState } from "react";

const BarWrapper = styled.div<{start: string}>`
    background-color: ${(props) => props.start === 'true'? props.theme.bgColor : props.theme.textColor};
    color: ${(props) => props.start === 'true' ? props.theme.textColor : props.theme.bgColor};
    height: 90px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

const Left = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
`;

const Logo = styled.span`
    font-family: 'felixTitling';
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
`;

const Right = styled.div`
    width: 50%;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 16px;
    cursor: pointer;
`;

const TextButton = styled.div`
    margin-top: -2px;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
`;

const ImgButton = styled.img`

`;

interface HeaderProps {
    start: boolean;
}

export function Header({
    start
}: HeaderProps){
    const { type, category, question } = useParams();
    const [ lastSurveyIndexs, setLastSurveyIndexs ] = useRecoilState(lastSelectedSurveyIndexState)
    const [ lastLifeIndexs, setLastLifeIndexs ] = useRecoilState(lastSelectedLifeIndexState);
    const surveys = useRecoilValue(surveyState); 
    const survey = useRecoilValue(surveyAtom);
    const life = useRecoilValue(lifeState); 

    const [login, setLogin] = useRecoilState(loginState);
    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const navigate = useNavigate();
    const location = useLocation();

    const onRightButtonClick = () => {
        navigate('/');
    }

    const onLeftButtonClick = () => {
        if(location.pathname === `/${type}/start/init`)
        {
            navigate('/');
        }
        else if(location.pathname === `/${type}/start/birth`)
        {
            navigate(`/${type}/start/init`);
        }
        else if(location.pathname === `/${type}/start/gender`)
        {
            navigate(`/${type}/start/birth`);
        }
        else if(location.pathname === `/${type}/start/select`)
        {
            navigate(`/${type}/start/gender`);
        }
        else if(location.pathname === `/${type}/detail/${category}`)
        {
            navigate(`/${type}/start/select`);
        }
        else if(location.pathname === `/${type}/detail/${category}/${question}`)
        {
            // const survey = surveys.find(function(data){
            //     return data.category === category;
            // })

            if(Number(question) > 0)
            {
                let jumpQuestionNumber = 0;
                survey?.question.map((q, index) => {                    
                    q.buttons.map((button) => {
                        if(button.activate)
                        {                                                  
                            if(index < Number(question))
                            {                                
                                jumpQuestionNumber = index;
                            }                                                    
                        }                        
                    })
                })                               
                
                navigate(`/${type}/detail/${category}/${jumpQuestionNumber}`);
                
            }
            else
            {
                navigate(`/${type}/detail/${category}`);
            }
        }
        else if(location.pathname === `/${type}/health/${category}/${question}`)
        {
            // console.log(location.pathname)

            if(Number(question) > 0)
            {
                let jumpQuestionNumber = 0;
                life?.question.map((q, index) => {                    
                    q.buttons.map((button) => {
                        if(button.activate)
                        {                                                  
                            if(index < Number(question))
                            {                                
                                jumpQuestionNumber = index;
                            }                                                    
                        }                        
                    })
                })   

                // if(jumpQuestionNumber === 0)
                // {
                    // const survey = surveys.find(function(data){
                    //     return data.category === category;
                    // })
                    // let jumpQuestionNumber = 0;
                    // survey?.question.map((q, index) => {                    
                    //     q.buttons.map((button) => {
                    //         if(button.activate)
                    //         {                                                  
                    //             if(index < Number(survey?.question.length - 1))
                    //             {                                
                    //                 jumpQuestionNumber = index;
                    //             }                                                    
                    //         }                        
                    //     })
                    // })                               
                    
                //     navigate(`/${type}/detail/${category}/${jumpQuestionNumber}`);
                // }
                // else
                // {
                navigate(`/${type}/health/${category}/${jumpQuestionNumber}`);
                // }
                
            }
            else
            {
                let jumpQuestionNumber = 0;
                survey?.question.map((q, index) => {                    
                    q.buttons.map((button) => {
                        if(button.activate)
                        {                                
                            jumpQuestionNumber = index;                 
                        }                        
                    })
                })                               
                console.log("??? " + jumpQuestionNumber)
                navigate(`/${type}/detail/${category}/${jumpQuestionNumber}`);
            }         
        }
        else if(location.pathname === `/${type}/result/${category}`)
        {            
            //마지막 번호
            let jumpQuestionNumber = -1;
            life?.question.map((q, index) => {                    
                q.buttons.map((button) => {
                    if(button.activate)
                    {                                                  
                        if(index <  Number(life?.question.length - 1))
                        {                                
                            jumpQuestionNumber = index;
                        }                                                    
                    }                        
                })
            }) 
            // console.log(jumpQuestionNumber)
            if(jumpQuestionNumber === -1)
            {
                // const survey = surveys.find(function(data){
                //     return data.category === category;
                // })

                let jumpQuestionNumber2 = 0;
                survey?.question.map((q, index) => {                    
                    q.buttons.map((button) => {
                        if(button.activate)
                        {                                                  
                            if(index < Number(survey?.question.length - 1))
                            {                                
                                jumpQuestionNumber2 = index;
                            }                                                    
                        }                        
                    })
                })     
                navigate(`/${type}/detail/${category}/${jumpQuestionNumber2}`);
            }
            else
            {
                navigate(`/${type}/health/${category}/${jumpQuestionNumber}`);
            }
        }
       
    }

    const goResultPage = () => {
        setIsModalOpen(false);
        navigate(`/${type}/result/${category}?surveyId=${login.key}`)
    }

    const checkSurveyFromServer = async (kakao_access_token: string) => {
        axios.defaults.withCredentials = true;
        const url = `/v1/survey/check/`;
        try{
            const data = {
                kakao_access_token: kakao_access_token,
                category: `${category}`
            }
            const response = await axios.post(url, data);
            // console.log(response.data)
            return response.data;
    
        }
        catch(err)
        {
            return {status: 'fail'}
        }

    }

    const checkKakaoToken = async (kakao_access_token: string) => {
       
        axios({
            method: 'post',                 
            url: '/v1/auth/check/',
            withCredentials: true,
            data: {
                access_token: kakao_access_token
            }

        }).then(async (response) => {

            let tlogin: LoginStateType = JSON.parse(JSON.stringify(login));
            tlogin.accessToken = kakao_access_token;          
            tlogin.isLogin = true;            
            tlogin.userName = response.data.nickName;           
            const check_response = await checkSurveyFromServer(kakao_access_token);
            if(check_response.status === 'success')
            {
                tlogin.key = check_response.key;

                if(check_response.key !== '')
                {
                    //jump
                    if(location.pathname !== `/${type}/result/${category}`)
                    {
                        setIsModalOpen(true);
                    }                
                }
                else
                {
                    //그냥 진행 
                }
                setLogin(tlogin);
            }
            else
            {
                //로그아웃 
                let tlogin: LoginStateType = JSON.parse(JSON.stringify(login));
                tlogin.accessToken = '';          
                tlogin.isLogin = false;
                tlogin.key = '';
                tlogin.userName = '';
                setLogin(tlogin);
            }
            // key 확인 



        }).catch((err) => {

            let tlogin: LoginStateType = JSON.parse(JSON.stringify(login));
            tlogin.accessToken = '';          
            tlogin.isLogin = false;
            tlogin.key = '';
            tlogin.userName = '';
            setLogin(tlogin);
        })
    }

    useEffect(() => {
        const kko = getLocalStorage('kakao_access_token');

        if(kko)
        {
            checkKakaoToken(kko);
        }

    }, [category])


    return (
        <BarWrapper start={start ? 'true' : 'false'}>
            <ServerJumpModal open={isModalOpen}
                onCloseButton={() => {setIsModalOpen(false)}}
                onConfirm={goResultPage}
            />
            <Left>
                {start ? <Logo>CLUB HONEST</Logo> : 
                    <ButtonWrapper onClick={onLeftButtonClick}>
                        <ImgButton src={require('../../../styles/assets/common/arrow_left.png')} />
                        <TextButton>                    
                            뒤로가기
                        </TextButton>
                    </ButtonWrapper>
                }
            </Left>
            <Right>
                <ButtonWrapper onClick={onRightButtonClick}>
                    <ImgButton src={require('../../../styles/assets/common/btn_close.png')} />
                    <TextButton>                    
                        이 창 닫고 메인으로 돌아가기
                    </TextButton>
                </ButtonWrapper>               
            </Right>
        </BarWrapper>
    );
}