import { 
    atom 
} from "recoil";

import {
    UserStateType,
    LoginStateType
} from '../types'

export const userState = atom<UserStateType>({
    key: 'userState',
    default: undefined
})



export const loginState = atom<LoginStateType>({
    key: 'loginState',
    default: {
        accessToken: '',
        refreshToken: '',
        isLogin: false,
        key: '',
        userName: ''
    }
})