import { atom } from "recoil";

import {
    AddInfoTypeEnum
} from '../types';

export interface ModalContents {
    title: string;
    contents: string;
}

export interface ModalImageInfo {
    image: string;
    infoList?: string[];
}

export interface ModalImageContents {
    title: string;
    imageInfos: ModalImageInfo[];
    contents?: string;
}

export interface DoctorAdviceType{
    doctorImage: string;
    title: string;
    isName: boolean;
    contents: string;
    modalButtonText?: string;
    modalContents?: ModalContents[];
}

export interface HonestAdviceType {
    title: string;
    contents: string;
    addInfoType: AddInfoTypeEnum;
    precent?: number;
    imgUrl?: string;
    modalButtonText?: string;
    modalContents?: ModalImageContents[];
}

export interface AdviceType {
    category: string;
    userType: string;
    doctor: DoctorAdviceType;
    honest: HonestAdviceType [];
}

export const adviceState = atom<AdviceType[]>({
    key: 'adviceState',
    default: [
        {
            category: 'acne',
            userType: 'C',
            doctor: {
                doctorImage: '',
                title: "현재 상태를 꾸준히 유지해 주세요!",
                isName: true,
                contents: "건강한 피부를 잘 유지하고 있는 것 같아요. 칭찬합니다! 현재 여드름이 잘 올라오지 않고, 얼굴에 잡티나 붉기만 조금씩 남아있는 것이 고민이라면 피부과 상담 또는 시술을 받아보시는 것을 추천해 드려요. 가끔 한두 개씩 트러블이 올라올 때는 여드름 연고를 사용하거나, 스스로 압출하기보다는 병원에서 압출을 받고 염증 주사를 맞는 것이 좋아요. 갑자기 전체적으로 여드름이 심해질 때는 약물 치료를 미루지 않고 시작하는 것이 좋다는 사실을 꼭 기억하세요. 일찍 올바른 약물 치료를 시작할수록 치료의 효과도 우수하고, 흉터나 색소 침착의 가능성이 작아지기 때문에 이후에 피부과에 투자하는 시간과 비용을 확실하게 절약할 수 있어요.",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '피부 보습은 아침/저녁 하루에 2회 규칙적으로 하는 것을 권장해요. 세안 또는 샤워 직후에 보습해주세요. 지금의 건강한 피부를 잘 유지하기 위해서는 병원용 보습제나 솔닥의 클렌저, 로션처럼 저자극 제품을 꾸준히 사용하시는 게 좋아요. 외출을 하는 등 자외선이 노출되는 상황에서는 항상 자외선 차단제를 꼼꼼히 발라주세요. 세안이나 샤워, 사우나를 너무 오래 하는 것은 현재의 건강한 피부 장벽에 손상을 줄 수 있으니 주의해 주세요.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '규칙적이고 균형 잡힌 식사와 양질의 수면은 피부의 회복과 건강에 많은 도움이 돼요. 수면 패턴이 불규칙하거나 양질의 수면이 잘 유지가 되지 않는다면, 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
                    }
                ]
            },
            honest: [
                {
                    title: '10대 청소년 절반 이상은 여드름으로 고민하고 있습니다.',
                    contents: '축하드려요. 건강한 피부를 가지고 계시네요. 지금처럼 건강한 피부를 꾸준히 유지할 수 있도록 클럽 어니스트가 제안하는 올바른 피부 습관을 참고해주세요. 신경이 쓰이는 잡티나 붉기는 피부과 비대면 상담을 통한 대면 진료를 추천해요. 올바른 화장품의 사용과 피부과의 시술/관리들은 보다 탄력 있고 생기있는 피부를 유지하는 데 도움이 될 수 있을 거예요.',
                    addInfoType: AddInfoTypeEnum.GRAPH,
                    precent: 50
                },
                {
                    title: '20대는 전 연령 중 가장 적극적으로 여드름 치료를 진행하고 있습니다.',
                    contents: '축하드려요. 건강한 피부를 가지고 계시네요. 지금처럼 건강한 피부를 꾸준히 유지할 수 있도록 클럽 어니스트가 제안하는 올바른 피부 습관을 참고해주세요. 신경이 쓰이는 잡티나 붉기는 피부과 비대면 상담을 통한 대면 진료를 추천해요. 올바른 화장품의 사용과 피부과의 시술/관리들은 보다 탄력 있고 생기있는 피부를 유지하는 데 도움이 될 수 있을 거예요.',
                    addInfoType: AddInfoTypeEnum.IMAGE,  // 확인 필요함
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_blush_honest_lv1.png',
                },
                {
                    title: '여드름 진료 중 과반수 이상은 성인 여드름에 관한 치료입니다.',
                    contents: '축하드려요. 건강한 피부를 가지고 계시네요. 지금처럼 건강한 피부를 꾸준히 유지할 수 있도록 클럽 어니스트가 제안하는 올바른 피부 습관을 참고해주세요. 신경이 쓰이는 잡티나 붉기는 피부과 비대면 상담을 통한 대면 진료를 추천해요. 올바른 화장품의 사용과 피부과의 시술/관리들은 보다 탄력 있고 생기있는 피부를 유지하는 데 도움이 될 수 있을 거예요.',
                    addInfoType: AddInfoTypeEnum.IMAGE,  // 확인 필요함
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png',
                }
            ]
        },
        {
            category: 'acne',
            userType: 'A',
            doctor: {
                doctorImage: '',
                title: "여드름 치료, 더 이상 미루지 마세요",
                isName: true,
                contents: "현재 여드름이 주기적으로 올라오는 상태로 꾸준한 관리와 조절이 필요합니다. 여드름이 오래 지속되면 씻어내기 어려운 상처가 되는 경우가 많아요. 특히, 염증성 여드름의 경우 흉터와 결절을 만들 수 있고, 좁쌀 여드름 또한 역시 염증성 여드름으로 발전할 수 있어 두 가지 모두 조기에 치료를 시작하는 것이 좋아요. 일찍 올바른 약물 치료를 시작할수록 치료의 효과도 우수하고, 흉터나 색소 침착의 가능성이 적어지기 때문에 이후에 피부과에 투자하는 시간과 비용을 확실하게 절약할 수 있어요.",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '여드름 전용 화장품(논코메도제닉, non-comedogenic)과 병원용 보습제를 사용하는 것이 좋아요. 보습제는 꾸덕꾸덕한 크림 제형보다는 발림성이 좋은 로션이나 겔 타입을 추천해요. 자외선 차단제는 유기자차보다는 혼합자차 또는 무기자차 성분을 사용하는 것을 권장해요. 뜨거운 물보다는 미지근한 물로 여드름 전용 클렌저를 이용하여 세안이나 샤워를 하는 것이 좋고, 샤워나 사우나를 너무 오래 하는 것은 피부 장벽에 손상을 줄 수 있기 때문에 자제해 주세요.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '기름지고 단 음식을 피하고, 규칙적인 식사 패턴을 유지하고, 잠을 깊이 자야 하는 건 이미 다들 아시죠? 어떻게 그렇게 하냐고 물으신다면, 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
                    }
                ]
            },
            honest: [
                {
                    title: '10대 청소년 절반 이상은 여드름으로 고민하고 있습니다.',
                    contents: '많은 환자분이 여드름의 약물 치료가 꼭 필요한 상황임에도 불구하고 각종 SNS에 떠돌아다니는 잘못된 정보에 노출되어 현혹당하고야 맙니다. 여러 가지 부작용을 심각하게 걱정하며 약물 치료를 시작조차 해보지 못하고 값비싼 시술과 관리에 의존하시는 분들이 많습니다. 여드름은 반드시 의사와 환자와의 꼼꼼한 상담 후에 약물 치료가 먼저 고려되어야 합니다.',
                    addInfoType: AddInfoTypeEnum.GRAPH,
                    precent: 50
                },
                {
                    title: '20대는 전 연령 중 가장 적극적으로 여드름 치료를 진행하고 있습니다.',
                    contents: '많은 환자분이 여드름의 약물 치료가 꼭 필요한 상황임에도 불구하고 각종 SNS에 떠돌아다니는 잘못된 정보에 노출되어 현혹당하고야 맙니다. 여러 가지 부작용을 심각하게 걱정하며 약물 치료를 시작조차 해보지 못하고 값비싼 시술과 관리에 의존하시는 분들이 많습니다. 여드름은 반드시 의사와 환자와의 꼼꼼한 상담 후에 약물 치료가 먼저 고려되어야 합니다.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_blush_honest_lv1.png',
                },
                {
                    title: '여드름 진료 중 과반수 이상은 성인 여드름에 관한 치료입니다.',
                    contents: '많은 환자분이 여드름의 약물 치료가 꼭 필요한 상황임에도 불구하고 각종 SNS에 떠돌아다니는 잘못된 정보에 노출되어 현혹당하고야 맙니다. 여러 가지 부작용을 심각하게 걱정하며 약물 치료를 시작조차 해보지 못하고 값비싼 시술과 관리에 의존하시는 분들이 많습니다. 여드름은 반드시 의사와 환자와의 꼼꼼한 상담 후에 약물 치료가 먼저 고려되어야 합니다.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_blush_honest_lv2.png',
                }
            ]
        },
        {
            category: 'acne',
            userType: 'B',
            doctor: {
                doctorImage: '',
                title: "전문적인 치료가 시급합니다",
                isName: true,
                contents: "현재 여드름이 반복적으로 올라오고 있거나, 여드름이 지나간 흔적들이 같이 보이는 상태에요. 의사의 전문적인 치료가 꼭 필요한 상태입니다. 그렇다고 섣불리 이런저런 시술이나 관리를 무턱대고 받는 것은 금물이에요. 잘못된 시술이나 관리를 받게 되면 오히려 여드름이 악화할 수도 있기 때문이죠. 시술이나 관리는 올바른 약물 치료가 더해져야만 더욱 큰 효과를 내어 빠른 회복에 도움이 될 수 있습니다.",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '여드름 전용 화장품(논코메도제닉, non-comedogenic)과 병원용 보습제를 사용하는 것이 좋아요. 보습제는 꾸덕꾸덕한 크림 제형보다는 발림성이 좋은 로션이나 겔 타입을 추천해요. 자외선 차단제는 유기자차보다는 혼합자차 또는 무기자차 성분을 사용하는 것을 권장해요. 뜨거운 물보다는 미지근한 물로 여드름 전용 클렌저를 이용하여 세안이나 샤워를 하는 것이 좋고, 샤워나 사우나를 너무 오래 하는 것은 피부 장벽에 손상을 줄 수 있기 때문에 자제해 주세요.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '기름지고 단 음식을 피하고, 규칙적인 식사 패턴을 유지하고, 잠을 깊이 자야 하는 건 이미 다들 아시죠? 어떻게 그렇게 하냐고 물으신다면, 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
                    }
                ]
            },
            honest: [
                {
                    title: '10대 청소년 절반 이상은 여드름으로 고민하고 있습니다.',
                    contents: '여드름 영역에서 새로운 의료 기기와 연구가 꾸준히 발표되면서 무분별한 정보의 홍수를 만들고, 이는 고스란히 환자들의 혼란을 가중하고 있습니다. 무분별한 시술이나 관리, 혹은 약물이 오히려 환자들에게 부작용을 일으키는 경우도 있죠. 때로는 다른 약물 치료가 필요한 때도 있으며, 통상적인 약물 치료가 아닌 전문 치료나 시술이 필요한 경우도 있습니다. 솔직한 닥터와의 전문적인 상담과 치료가 필요합니다.',
                    addInfoType: AddInfoTypeEnum.GRAPH,
                    precent: 50
                },
                {
                    title: '20대는 전 연령 중 가장 적극적으로 여드름 치료를 진행하고 있습니다.',
                    contents: '여드름 영역에서 새로운 의료 기기와 연구가 꾸준히 발표되면서 무분별한 정보의 홍수를 만들고, 이는 고스란히 환자들의 혼란을 가중하고 있습니다. 무분별한 시술이나 관리, 혹은 약물이 오히려 환자들에게 부작용을 일으키는 경우도 있죠. 때로는 다른 약물 치료가 필요한 때도 있으며, 통상적인 약물 치료가 아닌 전문 치료나 시술이 필요한 경우도 있습니다. 솔직한 닥터와의 전문적인 상담과 치료가 필요합니다.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_blush_honest_lv1.png',
                },
                {
                    title: '여드름 진료 중 과반수 이상은 성인 여드름에 관한 치료입니다.',
                    contents: '여드름 영역에서 새로운 의료 기기와 연구가 꾸준히 발표되면서 무분별한 정보의 홍수를 만들고, 이는 고스란히 환자들의 혼란을 가중하고 있습니다. 무분별한 시술이나 관리, 혹은 약물이 오히려 환자들에게 부작용을 일으키는 경우도 있죠. 때로는 다른 약물 치료가 필요한 때도 있으며, 통상적인 약물 치료가 아닌 전문 치료나 시술이 필요한 경우도 있습니다. 솔직한 닥터와의 전문적인 상담과 치료가 필요합니다.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_blush_honest_lv2.png',
                }
            ]
        },  // 여드름 
        {
            category: 'blush',
            userType: 'C',
            doctor: {
                doctorImage: '',
                title: "꾸준한 관리로 홍조를 개선해 나가세요",
                isName: true,
                contents: "현재 경미한 홍조가 있으나, 일상생활에는 지장이 없는 피부 상태인 것 같아요. 현재 피부 상태를 개선하고 또 유지해 나가기 위해 클럽 어니스트가 제안하는 올바른 피부 습관을 꾸준히 실천해보세요!",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '자외선 차단에 항상 신경 써주세요. 세안은 미지근한 물로 1~2분 이내로 마무리하는 것이 모두에게 좋지만, 홍조 피부에는 더욱 중요해요. 세안할 때는 약산성 계열의 성분이 순한 클렌저를 사용해 주세요. 클렌징 후 바로 무색무향의 순한 보습제 도포는 필수! 여러 가지 화장품을 섞어서 바르기보다는, 개수를 줄여주시는 것이 홍조 완화에는 더 도움이 됩니다. 짙은 화장이나, 이로 인한 이중 세안이나 필링도 삼가세요. 샤워나 목욕, 사우나도 10분 이내로 최대한 짧게 하는 것이 좋아요.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '홍조는 오랜 기간 잘못된 피부 관리 습관 때문에 악화가 되는 경우도 많아요. 이제부터라도 홍조를 더 심해지게 하는 생활 습관은 피해주세요. 맵거나 자극적인 음식은 홍조를 악화시키는 대표적인 식습관이므로 자제하시길 바랄게요. 술과 담배, 커피도 피해주시는 것이 좋아요. 급격한 온도 변화가 있는 환경에 자주 노출되지 않도록 주의해주세요. 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
                    }
                ]
            },
            honest: [
                {
                    title: '홍조는 전문적이고 올바른 치료로 개선될 수 있습니다.',
                    contents: '피부 건강을 지키는 제1원칙은 바로 올바른 피부 관리 습관을 지키기라는 것, 말 안 해도 다들 아시지요? 말씀드린 홈케어 습관을 잘 지켜주세요. 만약 홍조가 오래 지속되었거나, 맨눈으로 보이는 혈관으로 인한 홍조가 있다면 일련의 레이저 치료가 필요할 수도 있어요. 만약 홍조, 붉기와 더불어 다른 증상이 수반되고 있다면 정확한 원인 진단을 위해 반드시 의료진과 상담해주세요. 이외에도 더 궁금한 점이 있다면, 의사 선생님과 영상통화로 진료 보고 피부에 대한 여러 가지 이야기를 나눠보세요!',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_blush_honest_lv1.png'
                },
                {
                    title: '홍조 질환을 가진 사람 10명 중 2명은 20대입니다.',
                    contents: '피부 건강을 지키는 제1원칙은 바로 올바른 피부 관리 습관을 지키기라는 것, 말 안 해도 다들 아시지요? 말씀드린 홈케어 습관을 잘 지켜주세요. 만약 홍조가 오래 지속되었거나, 맨눈으로 보이는 혈관으로 인한 홍조가 있다면 일련의 레이저 치료가 필요할 수도 있어요. 만약 홍조, 붉기와 더불어 다른 증상이 수반되고 있다면 정확한 원인 진단을 위해 반드시 의료진과 상담해주세요. 이외에도 더 궁금한 점이 있다면, 의사 선생님과 영상통화로 진료 보고 피부에 대한 여러 가지 이야기를 나눠보세요!',
                    addInfoType: AddInfoTypeEnum.GRAPH,
                    precent: 20,
                },
                {
                    title: '피부과에서 홍조를 치료하는 사람 10명 중 절반 이상은 30대 이상 성인입니다.',
                    contents: '피부 건강을 지키는 제1원칙은 바로 올바른 피부 관리 습관을 지키기라는 것, 말 안 해도 다들 아시지요? 말씀드린 홈케어 습관을 잘 지켜주세요. 만약 홍조가 오래 지속되었거나, 맨눈으로 보이는 혈관으로 인한 홍조가 있다면 일련의 레이저 치료가 필요할 수도 있어요. 만약 홍조, 붉기와 더불어 다른 증상이 수반되고 있다면 정확한 원인 진단을 위해 반드시 의료진과 상담해주세요. 이외에도 더 궁금한 점이 있다면, 의사 선생님과 영상통화로 진료 보고 피부에 대한 여러 가지 이야기를 나눠보세요!',
                    addInfoType: AddInfoTypeEnum.GRAPH,
                    precent: 50
                }
            ]
        },
        {
            category: 'blush',
            userType: 'A',
            doctor: {
                doctorImage: '',
                title: "자세한 상담과 적절한 치료로 홍조를 개선해보세요.",
                isName: true,
                contents: "현재 얼굴에 전반적으로 홍조가 있고, 조금씩 작은 혈관들도 보이는 상태인 것 같아요. 의료진과의 상담을 권유드리고, 경우에 따라 전문적인 치료가 필요할 수 있어요. 혹시나, 다른 불편한 증상이 있다면 진료 시 의사 선생님에게 꼭 이야기해주세요!",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '자외선 차단에 항상 신경 써주세요. 세안은 미지근한 물로 1~2분 이내로 마무리하는 것이 모두에게 좋지만, 홍조 피부에는 더욱 중요해요. 세안할 때는 약산성 계열의 성분이 순한 클렌저를 사용해 주세요. 클렌징 후 바로 무색무향의 순한 보습제 도포는 필수! 여러 가지 화장품을 섞어서 바르기보다는, 개수를 줄여주시는 것이 홍조 완화에는 더 도움이 됩니다. 짙은 화장이나, 이로 인한 이중 세안이나 필링도 삼가세요. 샤워나 목욕, 사우나도 10분 이내로 최대한 짧게 하는 것이 좋아요.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '홍조는 오랜 기간 잘못된 피부 관리 습관 때문에 악화가 되는 경우도 많아요. 이제부터라도 홍조를 더 심해지게 하는 생활 습관은 피해주세요. 맵거나 자극적인 음식은 홍조를 악화시키는 대표적인 식습관이므로 자제하시길 바랄게요. 술과 담배, 커피도 피해주시는 것이 좋아요. 급격한 온도 변화가 있는 환경에 자주 노출되지 않도록 주의해주세요. 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
                    }
                ]
            },
            honest: [
                {
                    title: '홍조는 전문적이고 올바른 치료로 개선될 수 있습니다.',
                    contents: '붉기와 홍조에는 잘못된 생활 습관뿐만 아니라 의학적으로 여러 가지 원인이 있을 수 있습니다. 따라서 의학적 소견에 따라 먹는 약이나 바르는 약이 필요할 수 있어요. 만약 붉기가 지속된 기간이 상당히 오래되었다거나 맨눈으로 보이는 혈관으로 인한 홍조가 있다면 대면 진료를 통해 일련의 레이저 치료를 받는 것을 권장합니다. 이외에도 더 궁금한 점이 있다면, 의사 선생님과 영상통화로 진료 보고 피부에 대한 여러 가지 이야기를 나눠보세요! 만약 홍조, 붉기와 더불어 다른 증상이 수반되고 있다면 정확한 원인 진단을 위해 통화 진료 시 반드시 의료진에게 알려주세요.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_blush_honest_lv2.png'
                },
                {
                    title: '홍조 질환을 가진 사람 10명 중 2명은 20대입니다.',
                    contents: '붉기와 홍조에는 잘못된 생활 습관뿐만 아니라 의학적으로 여러 가지 원인이 있을 수 있습니다. 따라서 의학적 소견에 따라 먹는 약이나 바르는 약이 필요할 수 있어요. 만약 붉기가 지속된 기간이 상당히 오래되었다거나 맨눈으로 보이는 혈관으로 인한 홍조가 있다면 대면 진료를 통해 일련의 레이저 치료를 받는 것을 권장합니다. 이외에도 더 궁금한 점이 있다면, 의사 선생님과 영상통화로 진료 보고 피부에 대한 여러 가지 이야기를 나눠보세요! 만약 홍조, 붉기와 더불어 다른 증상이 수반되고 있다면 정확한 원인 진단을 위해 통화 진료 시 반드시 의료진에게 알려주세요.',
                    addInfoType: AddInfoTypeEnum.GRAPH,
                    precent: 20,
                },
                {
                    title: '피부과에서 홍조를 치료하는 사람 10명 중 절반 이상은 30대 이상 성인입니다.',
                    contents: '붉기와 홍조에는 잘못된 생활 습관뿐만 아니라 의학적으로 여러 가지 원인이 있을 수 있습니다. 따라서 의학적 소견에 따라 먹는 약이나 바르는 약이 필요할 수 있어요. 만약 붉기가 지속된 기간이 상당히 오래되었다거나 맨눈으로 보이는 혈관으로 인한 홍조가 있다면 대면 진료를 통해 일련의 레이저 치료를 받는 것을 권장합니다. 이외에도 더 궁금한 점이 있다면, 의사 선생님과 영상통화로 진료 보고 피부에 대한 여러 가지 이야기를 나눠보세요! 만약 홍조, 붉기와 더불어 다른 증상이 수반되고 있다면 정확한 원인 진단을 위해 통화 진료 시 반드시 의료진에게 알려주세요.',
                    addInfoType: AddInfoTypeEnum.GRAPH,
                    precent: 50
                }
            ]
        },
        {
            category: 'blush',
            userType: 'B',
            doctor: {
                doctorImage: '',
                title: "의료진과의 치료 상담을 적극적으로 추천합니다",
                isName: true,
                contents: "맨눈으로 보이는 전반적인 홍조와 더불어 일상생활의 다른 불편한 증상이 있는 상태인 것 같아요. 붉기가 오래 지속될수록 치료가 어려워지는 경우가 많아요. 이른 시일 내에 의료진과 이야기를 나누고 전문적인 치료를 받는 것을 권장해요. 특히 붉기 이외에 다른 불편한 증상이 있다면 주요 원인의 정확한 진단을 위해 꼭 상담해주세요.",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '자외선 차단에 항상 신경 써주세요. 세안은 미지근한 물로 1~2분 이내로 마무리하는 것이 모두에게 좋지만, 홍조 피부에는 더욱 중요해요. 세안할 때는 약산성 계열의 성분이 순한 클렌저를 사용해 주세요. 클렌징 후 바로 무색무향의 순한 보습제 도포는 필수! 여러 가지 화장품을 섞어서 바르기보다는, 개수를 줄여주시는 것이 홍조 완화에는 더 도움이 됩니다. 짙은 화장이나, 이로 인한 이중 세안이나 필링도 삼가세요. 샤워나 목욕, 사우나도 10분 이내로 최대한 짧게 하는 것이 좋아요.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '홍조는 오랜 기간 잘못된 피부 관리 습관 때문에 악화가 되는 경우도 많아요. 이제부터라도 홍조를 더 심해지게 하는 생활 습관은 피해주세요. 맵거나 자극적인 음식은 홍조를 악화시키는 대표적인 식습관이므로 자제하시길 바랄게요. 술과 담배, 커피도 피해주시는 것이 좋아요. 급격한 온도 변화가 있는 환경에 자주 노출되지 않도록 주의해주세요. 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
                    }
                ]
            },
            honest: [
                {
                    title: '홍조는 전문적이고 올바른 치료로 개선될 수 있습니다.',
                    contents: '답변해주신 테스트의 내용으로 미뤄본다면, 지금의 상태는 약물 또는 레이저 치료 등 전문적인 치료가 필요한 단계로 보입니다. 홍조와 붉기의 주요한 의학적 원인에 따라 치료 방법이 달라질 수 있으므로, 시중의 무분별한 관리법을 시도해보기 이전에 반드시 의료진과 상담하시길 권장해 드립니다. 위에 제안한 홈케어 습관을 반드시 지켜주시고, 솔닥의 의사 선생님과 영상통화로 상담받아보세요. 테스트 결과와 영상통화로 보이는 피부 상태를 토대로 상담을 진행한 뒤에, 상급 병원의 방문 등에 대해 논의하시길 권장해 드립니다.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_blush_honest_lv3.png'
                },
                {
                    title: '홍조 질환을 가진 사람 10명 중 2명은 20대입니다.',
                    contents: '답변해주신 테스트의 내용으로 미뤄본다면, 지금의 상태는 약물 또는 레이저 치료 등 전문적인 치료가 필요한 단계로 보입니다. 홍조와 붉기의 주요한 의학적 원인에 따라 치료 방법이 달라질 수 있으므로, 시중의 무분별한 관리법을 시도해보기 이전에 반드시 의료진과 상담하시길 권장해 드립니다. 위에 제안한 홈케어 습관을 반드시 지켜주시고, 솔닥의 의사 선생님과 영상통화로 상담받아보세요. 테스트 결과와 영상통화로 보이는 피부 상태를 토대로 상담을 진행한 뒤에, 상급 병원의 방문 등에 대해 논의하시길 권장해 드립니다.',
                    addInfoType: AddInfoTypeEnum.GRAPH,
                    precent: 20,
                },
                {
                    title: '피부과에서 홍조를 치료하는 사람 10명 중 절반 이상은 30대 이상 성인입니다.',
                    contents: '답변해주신 테스트의 내용으로 미뤄본다면, 지금의 상태는 약물 또는 레이저 치료 등 전문적인 치료가 필요한 단계로 보입니다. 홍조와 붉기의 주요한 의학적 원인에 따라 치료 방법이 달라질 수 있으므로, 시중의 무분별한 관리법을 시도해보기 이전에 반드시 의료진과 상담하시길 권장해 드립니다. 위에 제안한 홈케어 습관을 반드시 지켜주시고, 솔닥의 의사 선생님과 영상통화로 상담받아보세요. 테스트 결과와 영상통화로 보이는 피부 상태를 토대로 상담을 진행한 뒤에, 상급 병원의 방문 등에 대해 논의하시길 권장해 드립니다.',
                    addInfoType: AddInfoTypeEnum.GRAPH,
                    precent: 50
                }
            ]
        }, // blush
        {
            category: 'brown',
            userType: 'A',
            doctor: {
                doctorImage: '',
                title: "전문 치료로 더 환하고 건강한 피부를 만들어보세요",
                isName: true,
                contents: "생긴 지 오래되지 않은 색소 침착이 있는 상태로 보여요. 색소 침착의 빠른 회복과 치료를 위해 적절한 약물의 사용, 시술과 관리가 도움이 될 수 있어요. 색소가 침착된 부위를 개선해주는 미백 약물로는 멜라논 크림과 도미나 크림이 대표적이에요. 시술로는 색소를 깨서 치료하는 레이저 토닝, 관리로는 색소를 완화하는 필링이 있어요. 다만 사람마다 피부 상태와 치료에 따른 반응이 다르기 때문에 전문가와의 상담을 통해 치료를 시작하는 것이 중요해요. 색소 침착 이외에도 불편한 증상이 있다면, 정확한 원인 파악을 위해 꼭 의료진과 상담해주세요.",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '자외선 차단에 항상 신경 써주세요. 얼굴을 손으로 자주 만지면 마찰로 인해 색소침착이 생길 수 있으니 주의해주세요. 세안은 미지근한 물로 1~2분 이내에 마무리해주시는 것이 좋아요. 세안 후 보습제를 아침, 저녁으로 2회 이상 꾸준히 도포해주세요. 피부가 건조해지면 피부 장벽이 약해지고, 이것이 결국 색소침착으로 이어질 수 있습니다. 필링 횟수는 주 2회 이하로 제한하세요. 필링을 너무 자주 하면 오히려 피부 색조가 더 진해질 우려가 있습니다. 여러 가지 화장품을 섞어서 바르기보다는 개수를 줄여주는 것이 더 좋습니다.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '피부 회복을 위해 충분한 휴식과 잠을 깊이 자주세요. 잠을 깊이 잘 수 있는 환경을 만들기 위해 적절한 수면 위생 환경을 조성해주세요. 미세먼지가 심한 날에는 외출을 삼가시기를 바랍니다. 미세먼지도 색소침착의 원인이 될 수 있답니다. 무엇보다 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
                    }
                ]
            },
            honest: [
                {
                    title: '색소침착, 무분별한 시도가 아닌 정확하고 전문적인 치료가 필요합니다.',
                    contents: '색소침착이 신체 건강과 직접적인 관련이 있는 경우는 흔하지 않아요. 그러나 색소침착은 우리의 자신감을 떨어뜨려 마음의 병을 만들기도 하죠. 하지만 평소 잘못된 피부 관리 습관들을 상담을 통해 개선하게 되면 얼굴이 점차 밝아질 수 있어요. 따라서 미백 약품을 사용하거나 시술을 진행하기에 앞서 꼭 의료진과 충분한 상담의 과정을 거치시길 바랍니다. 클럽 어니스트에서 제공하는 영상통화 진료를 통해 솔직하고 진솔한 상담을 받아보세요.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_brown_honest_lv1.png'
                }               
            ]
        },
        {
            category: 'brown',
            userType: 'B',
            doctor: {
                doctorImage: '',
                title: "의료진과의 상담을 적극적으로 권장해 드립니다",
                isName: true,
                contents: "전문적인 시술이나 관리가 필요한 색소 침착이 있는 상태로 보여요. 지금 상태에서는 약물 치료만으로 효과를 보기에는 한계가 있는 경우가 많습니다. 따라서 피부과에 방문하여 레이저 시술을 진행하고 전문 관리를 받는 것을 권장합니다. 대표적인 시술로는 색소를 깨서 치료하는 레이저 토닝, 관리로는 색소를 완화하는 필링이 있습니다. 다만 사람마다 피부 상태와 치료에 따른 반응이 다르기 때문에 전문가와의 상담을 통해 치료를 시작하는 것이 중요해요. 색소 침착 이외에도 불편한 증상이 동반된다면, 정확한 원인 파악을 위해 꼭 의료진과 상담해주세요.",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '자외선 차단에 항상 신경 써주세요. 얼굴을 손으로 자주 만지면 마찰로 인해 색소침착이 생길 수 있으니 주의해주세요. 세안은 미지근한 물로 1~2분 이내에 마무리해주시는 것이 좋아요. 세안 후 보습제를 아침, 저녁으로 2회 이상 꾸준히 도포해주세요. 피부가 건조해지면 피부 장벽이 약해지고, 이것이 결국 색소침착으로 이어질 수 있습니다. 필링 횟수는 주 2회 이하로 제한하세요. 필링을 너무 자주 하면 오히려 피부 색조가 더 진해질 우려가 있습니다. 여러 가지 화장품을 섞어서 바르기보다는 개수를 줄여주는 것이 더 좋습니다.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '피부 회복을 위해 충분한 휴식과 잠을 깊이 자주세요. 잠을 깊이 잘 수 있는 환경을 만들기 위해 적절한 수면 위생 환경을 조성해주세요. 미세먼지가 심한 날에는 외출을 삼가시기를 바랍니다. 미세먼지도 색소침착의 원인이 될 수 있답니다. 무엇보다 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
                    }
                ]
            },
            honest: [
                {
                    title: '색소침착, 무분별한 시도가 아닌 정확하고 전문적인 치료가 필요합니다.',
                    contents: '색소 질환은 병원 방문 진료가 필요한 경우가 대부분이에요. 색소 질환의 종류에 따라 치료 기간이나 치료의 효과가 상이할 수 있어요. 따라서 의사 선생님이 맨눈으로 색소를 직접 보고 상세한 치료 계획을 세우는 과정이 필요합니다. 클럽 어니스트가 제공하는 피부과 의료진과의 영상통화 진료를 통해 병원 방문 전 내 피부 상태의 이해를 높여보세요. <내 색소 파악하기>를 누르면 내 피부 상태를 더 구체적으로 파악할 수 있는 가이드라인을 확인할 수 있어요. 꼭 확인해 보시고, 의심되는 병변 치료를 위해 상급 병원 방문이 필요하다면 방문해 보시길 권장드립니다.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_brown_honest_lv2.png',
                    modalButtonText: '내 색소 파악하기',
                    modalContents: [
                        {
                            title: '주근깨 ・ 잡티',
                            imageInfos: [
                                {
                                    image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/freckles.png',
                                    infoList: [
                                        "주로 햇빛에 노출된 부위에 생겨요",
                                        "경계가 명확한 황갈생의 작은 색소 반점이에요",
                                        "주로 피부가 하얗고 건조한 사람에게 잘 생겨요",
                                        "10대 청소년부터 생기는 경우가 많아요"
                                    ],
                                }
                            ],
                            
                            contents: '피부상태에 따라 치료 기간이나 횟수가 조금씩 달라질 수 있지만, 대부분 2-3회 이내의 시술로 금방 호전이되는 색소 반점이므로 너무 걱정하지 마세요. 금방 좋아질 수 있어요!'
                        },
                        {
                            title: '기미',
                            imageInfos: [
                                {
                                    image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/agespot.png',
                                    infoList: [
                                        "주로 30~40대 여성에게서 흔히 관찰되어요",
                                        "경계가 불분명한 회갈색의 대칭적 색소 반점이에요",
                                        "광대, 볼, 턱, 눈가, 눈밑, 이마 등 얼굴 전체를 침범할 수 있어요",
                                        "원인을 정확히는 알 수 없지만 유전적 소인이 관련되어 있고, 자외선 노출, 호르몬 불균형, 스트레스, 잘못된 생활 습관 등 환경적 소인이 반복되어 생기는 것으로 알려져 있어요"
                                    ]
                                }
                            ],                            
                            contents: '레이저 토닝, 초음파/고주파 치료, 바르는 약, 먹는 약, 화학 박피술 등 다양한 방법들이 기미치료에 쓰이고 있습니다. 만성적인 피부 질환인 만큼 오랜 시간 증상의 호전과 약화가 반복될 수 있으며, 생활 습관 교정이 필수적이고 오랜 기간의 치료를 필요로 해요. 솔닥과 함께 한걸음씩 차근차근 노력해봐요.'
                        },
                        {
                            title: 'ABNOM (후천성 양측성 오타모양 모반 반점)',
                            imageInfos: [
                                {
                                    image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/abnom.png',
                                    infoList: [
                                        "ABNOM은 태어난 뒤에 생기는 후천성 질환이에요",
                                        "얼굴의 양측에 위치하고, 오타반점의 모양과 비슷하게 생긴 반점이에요",
                                        "색소를 만드는 멜라닌 세포의 이상으로 생기고 광대, 관자, 이마, 코 등에 주로 분포하며 갈색을 띄어요",
                                        "보통 20대 후반부터 가장 많이 관찰되지만, 10대 후반의 나이에도 생길 수 있어요"
                                    ]
                                }

                            ],                            
                            contents: '치료의 기본은 레이저 토닝이며, 최소 10회 이상의 반복적인 레이저 시술을 필요로 해요. 솔닥을 통해 정확한 진단과 치료를 받아보세요! 솔닥이 함께할게요.'
                        },
                        {
                            title: '착한 점 vs 나쁜 점',
                            imageInfos: [
                                {
                                    image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/good_mole.png',
                                    infoList: [
                                        "가운데를 기준으로 좌우의 모양이 대칭적이에요",
                                        "가장자리가 깔끔한 원 또는 타원 모양이에요",
                                        "색이 전체적으로 균일해요",
                                        "직경이 6mm보다 작아요",
                                        "점의 크기, 모양, 색이 변하지 않아요"
                                    ]
                                },
                                {
                                    image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/bad_mole.png',
                                    infoList: [
                                        "가운데를 기준으로 좌우의 대칭이 맞지 않아요",
                                        "가장자리가 울퉁불퉁하거나, 불균일하고 경계가 흐려요",
                                        "점의 색이 균일하지 않고 검갈색으로 번진 자국이나 불그스름하고 푸른 부분이 있어요",
                                        "직경이 6mm보다 커요",
                                        "점의 크기, 모양, 색이 변하기도 해요"
                                    ]
                                }
                            ],       
                            contents: '착한 점과 나쁜 점을 스스로 진단해보는 다섯 가지의 아주 간단한 기준이 있습니다. (위: 착한 점 - mole, 아래: 나쁜 점 - melanoma) 꼭 정독하시고 색소 병변이 \'나쁜 점\'에 부합하는 것 같다면 이른 시일 내에 반드시 상급병원에서 전문적인 진료를 받으시길 강력히 권장합니다.'
                        }                        
                    ]
                }               
            ]
        },
        {
            category: 'brown',
            userType: 'C',
            doctor: {
                doctorImage: '',
                title: "이른 시일 내에 상급 병원에 방문하여 정밀 검사를 받아보세요",
                isName: false,
                contents: "색소 침착 이외에도 불편한 증세가 함께 수반되고 있다면, 정확한 원인 파악을 위해 꼭 담당 의료진에게 이야기해주세요.",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '자외선 차단에 항상 신경 써주세요. 얼굴을 손으로 자주 만지면 마찰로 인해 색소침착이 생길 수 있으니 주의해주세요. 세안은 미지근한 물로 1~2분 이내에 마무리해주시는 것이 좋아요. 세안 후 보습제를 아침, 저녁으로 2회 이상 꾸준히 도포해주세요. 피부가 건조해지면 피부 장벽이 약해지고, 이것이 결국 색소침착으로 이어질 수 있습니다. 필링 횟수는 주 2회 이하로 제한하세요. 필링을 너무 자주 하면 오히려 피부 색조가 더 진해질 우려가 있습니다. 여러 가지 화장품을 섞어서 바르기보다는 개수를 줄여주는 것이 더 좋습니다.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '피부 회복을 위해 충분한 휴식과 잠을 깊이 자주세요. 잠을 깊이 잘 수 있는 환경을 만들기 위해 적절한 수면 위생 환경을 조성해주세요. 미세먼지가 심한 날에는 외출을 삼가시기를 바랍니다. 미세먼지도 색소침착의 원인이 될 수 있답니다. 무엇보다 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
                    }
                ]
            },
            honest: [
                {
                    title: '색소침착, 무분별한 시도가 아닌 정확하고 전문적인 치료가 필요합니다.',
                    contents: '착한 점과 나쁜 점을 스스로 진단해보는 다섯 가지의 아주 간단한 기준이 있습니다. 꼭 정독하시고 색소 병변이 해당 기준에 부합하는 것 같다면 이른 시일 내에 반드시 상급병원에서 전문적인 진료를 받으시길 강력히 권장합니다.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_brown_honest_lv3.png',
                    modalButtonText: '착한점 vs 나쁜점',
                    modalContents: [
                        {
                            title: '착한 점 vs 나쁜 점',
                            imageInfos: [
                                {
                                    image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/good_mole.png',
                                    infoList: [
                                        "가운데를 기준으로 좌우의 모양이 대칭적이에요",
                                        "가장자리가 깔끔한 원 또는 타원 모양이에요",
                                        "색이 전체적으로 균일해요",
                                        "직경이 6mm보다 작아요",
                                        "점의 크기, 모양, 색이 변하지 않아요"
                                    ]
                                },
                                {
                                    image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/bad_mole.png',
                                    infoList: [
                                        "가운데를 기준으로 좌우의 대칭이 맞지 않아요",
                                        "가장자리가 울퉁불퉁하거나, 불균일하고 경계가 흐려요",
                                        "점의 색이 균일하지 않고 검갈색으로 번진 자국이나 불그스름하고 푸른 부분이 있어요",
                                        "직경이 6mm보다 커요",
                                        "점의 크기, 모양, 색이 변하기도 해요"
                                    ]
                                }
                            ],       
                            contents: '착한 점과 나쁜 점을 스스로 진단해보는 다섯 가지의 아주 간단한 기준이 있습니다. (위: 착한 점 - mole, 아래: 나쁜 점 - melanoma) 꼭 정독하시고 색소 병변이 \'나쁜 점\'에 부합하는 것 같다면 이른 시일 내에 반드시 상급병원에서 전문적인 진료를 받으시길 강력히 권장합니다.'
                        }
                    ]
                }               
            ]
        }, // brown
        {
            category: 'dry',
            userType: 'C',
            doctor: {
                doctorImage: '',
                title: "꾸준한 관리로 현재의 피부를 유지하세요",
                isName: true,
                contents: "현재 건강한 피부를 잘 유지하고 있는 것 같아요. 칭찬해요! 지금처럼 건강한 피부 관리 습관을 계속 유지해주세요. 피부를 위한 올바른 생활 습관을 참고해주세요.",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '아침과 저녁, 하루에 보습제를 2회 도포하세요. 보습제는 세안 또는 샤워 후 물기를 건조한 이후 최대한 빨리 바르는 것이 좋아요. 시중의 일반 보습제보다는 병원에서 처방받을 수 있는 MD 보습제를 사용하면 보습감 유지에 더욱더 효과적입니다. 세안은 미지근한 물로 1~2분 이내로 마무리하시고, 얼굴을 너무 강하게 문지르지 말아 주세요. 필링이나 때밀이를 할 때는 피부 상태를 확인하며 자극이 되지 않는 선에서 진행해주세요.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '평소 수분은 충분히 섭취해주시고, 자극적인 음식은 피해주세요. 몸의 수분을 빼앗는 술담배와 커피도 자제하시는 것이 좋겠지요? 무엇보다 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
                    }
                ]
            },
            honest: [
                {
                    title: '피부 수분감은 꾸준한 관리로 좋은 컨디션을 유지할 수 있어요',
                    contents: '축하드려요. 건강한 피부를 가지고 계신 것 같아요. 지금처럼 건강한 피부를 꾸준히 유지할 수 있도록 제안한 올바른 생활 습관을 유지해주세요. 필링과 마스크팩은 성분과 본인의 피부타입에 따라 적당한 횟수로 하는 것을 권장해요. 목욕이나 사우나는 기분전환 겸해도 괜찮지만, 뭐든 과한 건 수분을 오히려 뺏길 수 있어서 좋지 않아요. 피부 건강을 개선할 수 있는 피부과 관리 시술을 비롯해 다양한 궁금증은 의료진과의 영상통화를 통해 상담받아보세요.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_dry_honest_lv1.png'
                }               
            ]
        },
        {
            category: 'dry',
            userType: 'A',
            doctor: {
                doctorImage: '',
                title: "적절한 치료를 통해 피부 건조함을 개선해보세요",
                isName: true,
                contents: "피부 건조증의 개선을 위해 보다 꼼꼼한 보습과 생활 습관 교정이 필요한 단계예요. 건조감 외에 다른 불편한 증상(붉기, 가려움증, 자극감 등)이 있다면, 생활 습관뿐 아니라 기타 원인이 있을 수 있어요. 정확한 진단을 위해 꼭 의료진과 상담하세요.",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '아침과 저녁, 하루에 보습제를 2회 도포하세요. 보습제는 세안 또는 샤워 후 물기를 건조한 이후 최대한 빨리 바르는 것이 좋아요. 시중의 일반 보습제보다는 병원에서 처방받을 수 있는 MD 보습제를 사용하면 보습감 유지에 더욱더 효과적입니다. 세안은 미지근한 물로 1~2분 이내로 마무리하시고, 얼굴을 너무 강하게 문지르지 말아 주세요. 필링이나 때밀이를 할 때는 피부 상태를 확인하며 자극이 되지 않는 선에서 진행해주세요.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '혹시 레티노이드 계열의 의약품을 사용 중이라면, 의사 선생님과 상의하여, 가능하다면 잠시 약물 사용을 중단해주세요. 평소 수분은 충분히 섭취해주시고, 자극적인 음식은 피해주세요. 몸의 수분을 빼앗는 술담배와 커피도 자제하시는 것이 좋겠지요? 무엇보다 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
                    }
                ]
            },
            honest: [
                {
                    title: '피부 건조증, 빠르고 올바른 치료가 무엇보다 중요합니다',
                    contents: '피부 장벽은 이름에서도 알 수 있는 것처럼, 우리의 피부 건강을 지켜주는 아주 중요한 구조물입니다. 피부가 건조해지면 피부 장벽이 손상되어 다양한 피부 질환의 가능성이 커지고 잔주름이 도드라져 보일 수 있습니다. 제로이드나 아토베리어 MD와 같은 병원용 보습제의 경우 일반 보습제들과 다르게 우리의 피부 장벽과 유사하게 만들어진 보습제로서 손상된 피부 장벽의 회복에 도움을 줍니다. 작은 습관부터 하나씩 교정하다 보면 건조증이 조금씩 개선될 거예요. 만약 건조증이 심하다면 피부과의 시술이나 관리도 도움이 될 수 있어요. 보습제 처방, 또는 시술에 대한 상담이 필요하다면 지금 솔닥 의료진과의 영상통화 진료를 예약해보세요.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_dry_honest_lv2.png'
                }               
            ]
        },
        {
            category: 'dry',
            userType: 'B',
            doctor: {
                doctorImage: '',
                title: "의료진과의 치료 상담을 적극적으로 추천합니다",
                isName: true,
                contents: "님의 현재 피부는 건조증이 심하고 피부 장벽이 다소 손상된 상태에요. 피부 건조증을 개선하기 위해, 그리고 피부 건조증으로 인해 발생할 수 있는 2차 질환을 예방하기 위해 보다 적극적이고 전문적인 치료가 필요합니다. 아래 조언하는 생활 습관을 지키고, 정확한 진단을 위해 꼭 의료진과 상담해 보시길 권장해 드립니다.",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '아침과 저녁, 하루에 보습제를 2회 도포하세요. 보습제는 세안 또는 샤워 후 물기를 건조한 이후 최대한 빨리 바르는 것이 좋아요. 시중의 일반 보습제보다는 병원에서 처방받을 수 있는 MD 보습제를 사용하면 보습감 유지에 더욱더 효과적입니다. 세안은 미지근한 물로 1~2분 이내로 마무리하시고, 얼굴을 너무 강하게 문지르지 말아 주세요. 필링이나 때밀이를 할 때는 피부 상태를 확인하며 자극이 되지 않는 선에서 진행해주세요.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '혹시 레티노이드 계열의 의약품을 사용 중이라면, 의사 선생님과 상의하여, 가능하다면 잠시 약물 사용을 중단해주세요. 평소 수분은 충분히 섭취해주시고, 자극적인 음식은 피해주세요. 몸의 수분을 빼앗는 술담배와 커피도 자제하시는 것이 좋겠지요? 무엇보다 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
                    }
                ]
            },
            honest: [
                {
                    title: '피부 건조증도 하나의 피부 질환입니다',
                    contents: '피부 건조증도 하나의 피부 질환이지만, 많은 사람이 건조증을 간과하는 경향이 있습니다. 특히 현재의 경우는 의료진과의 상담을 통한 세심한 치료와 관리가 필요한 단계로 생각됩니다. 건조증을 그대로 방치해두면 습진이나 드물게 감염성 질환 등으로 이어질 수 있습니다. 진단에 따라 병원용 보습제MD 뿐만 아니라 전문적인 약물 처방이나 시술이 필요할 수 있습니다. 클럽 어니스트가 제안하는 생활 습관 원칙을 반드시 지켜주세요. 건조한 증상 외에 가려움증, 자극감, 붉기, 하얀 인비늘 등이 있으면 정확한 원인 진단을 위해 반드시 의사와 진료, 상담을 진행하시길 권고드립니다.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_dry_honest_lv3.png'
                }               
            ]
        }, // dry
        {
            category: 'antiaging',
            userType: 'C',
            doctor: {
                doctorImage: '',
                title: "현재의 피부 나이를 오랜 시간 간직하는데 집중하세요",
                isName: true,
                contents: "현재 자기 나이에 맞는, 혹은 그 이상의 건강한 피부를 잘 유지하고 있는 것 같아요. 칭찬해요! 건강한 피부를 위해 솔닥이 제안하는 ‘슬로우에이징’ 생활 습관을 함께 따라해봐요.",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '우리의 나이를 완전히 되돌릴 수는 없지만, 노화의 속도를 천천히 하는 ‘슬로우 에이징(slow-aging)’은 솔닥과 함께 실천할 수 있어요. 솔닥이 제안하는 ‘슬로우에이징’ 습관을 하나씩 실천해보세요! 한 번에 모두를 실천하기는 어려워요. 솔닥과 함께 하나씩 개선해 나가요. 무의식적으로 인상을 자주 쓰지 마세요. 표정 근육을 반복적으로 사용하게 되면 주름이 깊어질 수 있어요. 선크림을 날씨와 무관하게 항상 발라주세요. 자외선은 비가 오는 날에도 우리의 피부에 도달하며, 자외선은 피부의 콜라겐과 엘라스틴을 망가뜨려요. 보습제를 아침/저녁으로 최소 2회 이상 바르세요. 건조한 피부는 피부를 나이 들어 보이게 해요. 필링이나 때밀이를 할 때는 피부 상태를 확인하며 자극이 되지 않는 선에서 진행하세요. 사우나나 목욕은 너무 오래하지 마세요. 오히려 수분을 뺏겨 피부 장벽이 손상될 수 있어요.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 안티에이징과 건강한 라이프스타일로 이어질 거예요. 술/담배를 하지 마세요. 담배에 포함된 수많은 화학물질과 술의 알코올은 우리의 피부를 상하게 해요. 1주일에 최소 3회 이상 유산소 운동을 하세요. 운동은 순환을 도와 피부를 건강하게 해요. 하루에 7시간 이상 충분히 잠을 자세요. 우리의 피부는 잠을 자는 동안 회복해요. 올바른 수면 위생 환경을 지켜주세요. 균형 잡힌 식단을 유지하세요. 신선한 과일과 채소를 골고루 섭취해주세요. 건강한 식생활은 건강한 피부의 영양분이 됩니다.'
                    }
                ]
            },
            honest: [
                {
                    title: '안티에이징 관리를 시작하기에 가장 빠른 시기는 바로 오늘이에요',
                    contents: '축하드려요. 건강한 피부를 유지하고 계신 것 같아요! 안티에이징에 미리 관심을 가지고 꼼꼼히 관리하는 습관을 들인다면 지금의 건강한 피부를 오래 유지할 수 있을거에요. 평소에 관심이 있었던 시술이나 관리 등에 대한 궁금증들을 ‘솔닥’과 상담해보세요. 어떠한 안티에이징 시술이 도움이 될지, 올바른 생활 습관을 유지하고 있는지 ‘솔닥’이 솔직하게 이야기해줄 거예요.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_anti_honest_lv1.png'
                }               
            ]
        },
        {
            category: 'antiaging',
            userType: 'A',
            doctor: {
                doctorImage: '',
                title: "안티에이징을 차근차근 시작해볼까요?",
                isName: true,
                contents: "노화의 증상들이 조금씩 피부에 나타나고 있는 상태로 보여요. 홈케어만으로는 안티에이징에 한계가 있기 때문에, 자세한 상담과 그에 따른 전문적인 시술과 관리가 안티에이징에 도움이 될 거에요. 아래 솔닥이 조언하는 생활 습관을 지켜주시고, 정확한 진단을 위해 꼭 의료진과 상담해 보시길 권장해 드립니다.",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '우리의 나이를 완전히 되돌릴 수는 없지만, 노화의 속도를 천천히 하는 ‘슬로우 에이징(slow-aging)’은 솔닥과 함께 실천할 수 있어요. 솔닥이 제안하는 ‘슬로우에이징’ 습관을 하나씩 실천해보세요! 한 번에 모두를 실천하기는 어려워요. 솔닥과 함께 하나씩 개선해 나가요. 무의식적으로 인상을 자주 쓰지 마세요. 표정 근육을 반복적으로 사용하게 되면 주름이 깊어질 수 있어요. 선크림을 날씨와 무관하게 항상 발라주세요. 자외선은 비가 오는 날에도 우리의 피부에 도달하며, 자외선은 피부의 콜라겐과 엘라스틴을 망가뜨려요. 보습제를 아침/저녁으로 최소 2회 이상 바르세요. 건조한 피부는 피부를 나이 들어 보이게 해요. 필링이나 때밀이를 할 때는 피부 상태를 확인하며 자극이 되지 않는 선에서 진행하세요. 사우나나 목욕은 너무 오래하지 마세요. 오히려 수분을 뺏겨 피부 장벽이 손상될 수 있어요.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 안티에이징과 건강한 라이프스타일로 이어질 거예요. 술/담배를 하지 마세요. 담배에 포함된 수많은 화학물질과 술의 알코올은 우리의 피부를 상하게 해요. 1주일에 최소 3회 이상 유산소 운동을 하세요. 운동은 순환을 도와 피부를 건강하게 해요. 하루에 7시간 이상 충분히 잠을 자세요. 우리의 피부는 잠을 자는 동안 회복해요. 올바른 수면 위생 환경을 지켜주세요. 균형 잡힌 식단을 유지하세요. 신선한 과일과 채소를 골고루 섭취해주세요. 건강한 식생활은 건강한 피부의 영양분이 됩니다.'
                    }
                ]
            },
            honest: [
                {
                    title: '\'노화\'는 시간이 흘러가며 신체의 기능이 점점 떨어지는 아주 자연스러운 현상이에요',
                    contents: '20대 중반부터 피부의 콜라겐 생성은 매년 약 1%씩 감소합니다. 노화에 따른 피부의 탄력 감소는 모두가 겪는 아주 자연스러운 현상이니 좌절하지 마세요. 노화의 증상들이 보다 더 깊어지기 전에 ‘안티에이징’ 관리를 일찍이 시작하면 노화의 속도를 효과적으로 늦출 수 있을 거에요. ‘노화’는 우리가 모두 겪는 자연스러운 신체의 변화임을 받아들이고, 개인의 피부에 따른 맞춤화된 시술과 ‘슬로우 에이징(slow-aging)’ 습관을 함께 실천해요!',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_anti_honest_lv2.png'
                }               
            ]
        },
        {
            category: 'antiaging',
            userType: 'B',
            doctor: {
                doctorImage: '',
                title: "의료진과의 치료 상담을 적극적으로 추천합니다",
                isName: true,
                contents: "현재 노화의 증상들이 피부에 어느 정도 나타나 있는 상태로 보여요. 지금의 노화 단계를 개선하기 위해서는 보다 적극적인 시술이나 생활 습관 개선이 필요할 수 있습니다. 비대면 진료를 통해 어떠한 전문적인 치료들이 필요할지 상담해보세요!",
                modalButtonText: '홈케어 솔루션',
                modalContents: [
                    {
                        title: '스킨케어',
                        contents: '우리의 나이를 완전히 되돌릴 수는 없지만, 노화의 속도를 천천히 하는 ‘슬로우 에이징(slow-aging)’은 솔닥과 함께 실천할 수 있어요. 솔닥이 제안하는 ‘슬로우에이징’ 습관을 하나씩 실천해보세요! 한 번에 모두를 실천하기는 어려워요. 솔닥과 함께 하나씩 개선해 나가요. 무의식적으로 인상을 자주 쓰지 마세요. 표정 근육을 반복적으로 사용하게 되면 주름이 깊어질 수 있어요. 선크림을 날씨와 무관하게 항상 발라주세요. 자외선은 비가 오는 날에도 우리의 피부에 도달하며, 자외선은 피부의 콜라겐과 엘라스틴을 망가뜨려요. 보습제를 아침/저녁으로 최소 2회 이상 바르세요. 건조한 피부는 피부를 나이 들어 보이게 해요. 필링이나 때밀이를 할 때는 피부 상태를 확인하며 자극이 되지 않는 선에서 진행하세요. 사우나나 목욕은 너무 오래하지 마세요. 오히려 수분을 뺏겨 피부 장벽이 손상될 수 있어요.'
                    },
                    {
                        title: '라이프스타일',
                        contents: '스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 안티에이징과 건강한 라이프스타일로 이어질 거예요. 술/담배를 하지 마세요. 담배에 포함된 수많은 화학물질과 술의 알코올은 우리의 피부를 상하게 해요. 1주일에 최소 3회 이상 유산소 운동을 하세요. 운동은 순환을 도와 피부를 건강하게 해요. 하루에 7시간 이상 충분히 잠을 자세요. 우리의 피부는 잠을 자는 동안 회복해요. 올바른 수면 위생 환경을 지켜주세요. 균형 잡힌 식단을 유지하세요. 신선한 과일과 채소를 골고루 섭취해주세요. 건강한 식생활은 건강한 피부의 영양분이 됩니다.'
                    }
                ]
            },
            honest: [
                {
                    title: '‘안티에이징’은 현재의 건강한 상태를 잘 유지하고, 질병이나 문제를 예방하고 치료하는 모든 과정입니다.',
                    contents: '‘노화’는 단순히 얼굴의 주름살과 쳐짐만을 의미하는 것이 아닙니다. 현재의 건강이 질병이나 문제로 이어지지 않도록 예방하는 것도 ‘안티에이징’입니다. ‘피부의 노화’는 때때로 ‘피부 질환’과 관련이 있을 수 있어 현재의 피부 상태는 보다 적극적인 관심과 전문적인 상담이 필요한 단계로 보입니다. ‘노화’는 우리가 모두 겪는 자연스러운 신체의 변화임을 받아들이고, 개인의 피부에 따른 맞춤화된 시술과 ‘슬로우 에이징(slow-aging)’ 습관을 함께 실천하세요.',
                    addInfoType: AddInfoTypeEnum.IMAGE,
                    imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/advice_anti_honest_lv3.png'
                }               
            ]
        }
    ]
})


// export const adviceState = atom<AdviceType[]>({
//     key: 'adviceState',
//     default: [
//         {
//             category: 'acne',
//             userType: 'C',
//             doctor: {
//                 doctorImage: '',
//                 title: "현재 상태를 꾸준히 유지해 주세요!",
//                 isName: true,
//                 contents: "건강한 피부를 잘 유지하고 있는 것 같아요. 칭찬합니다! 현재 여드름이 잘 올라오지 않고, 얼굴에 잡티나 붉기만 조금씩 남아있는 것이 고민이라면 피부과 상담 또는 시술을 받아보시는 것을 추천해 드려요. 가끔 한두 개씩 트러블이 올라올 때는 여드름 연고를 사용하거나, 스스로 압출하기보다는 병원에서 압출을 받고 염증 주사를 맞는 것이 좋아요. 갑자기 전체적으로 여드름이 심해질 때는 약물 치료를 미루지 않고 시작하는 것이 좋다는 사실을 꼭 기억하세요. 일찍 올바른 약물 치료를 시작할수록 치료의 효과도 우수하고, 흉터나 색소 침착의 가능성이 작아지기 때문에 이후에 피부과에 투자하는 시간과 비용을 확실하게 절약할 수 있어요.",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '피부 보습은 하루에 2회 이상 규칙적으로 하는 것을 권장해요. 세안 또는 샤워 직후에 보습해주세요. 지금의 건강한 피부를 잘 유지하기 위해서는 병원용 보습제나 솔닥의 클렌저, 로션처럼 저자극 제품을 꾸준히 사용하시는 게 좋아요. 외출을 하는 등 자외선이 노출되는 상황에서는 항상 자외선 차단제를 꼼꼼히 발라주세요. 세안이나 샤워, 사우나를 너무 오래 하는 것은 현재의 건강한 피부 장벽에 손상을 줄 수 있으니 주의해 주세요.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '규칙적이고 균형 잡힌 식사와 양질의 수면은 피부의 회복과 건강에 많은 도움이 돼요. 수면 패턴이 불규칙하거나 양질의 수면이 잘 유지가 되지 않는다면, 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '10대 청소년 절반 이상은 여드름으로 고민하고 있습니다.',
//                     contents: '많은 환자분들이 여드름의 약물 치료가 반드시 필요한 상황임에도 불구하고 각종 SNS에 떠돌아다니는 잘못된 정보에 노출되어 현혹당하고야 맙니다. 여러가지 부작용을 심각하게 걱정하며 약물 치료를 시작조차 해보지 못하고 값비싼 시술과 관리에 의존하시는 분들이 많습니다. 여드름은 반드시 의사와 환자와의 꼼꼼한 상담 후에 약물 치료가 우선적으로 고려되어야 합니다. 더 깊어지기 전에 전문적인 치료를 시작하세요.',
//                     addInfoType: AddInfoTypeEnum.GRAPH,
//                     precent: 50
//                 },
//                 {
//                     title: '20대는 전 연령 중 가장 적극적으로 여드름 치료를 진행하고 있습니다.',
//                     contents: '많은 환자분들이 여드름의 약물 치료가 반드시 필요한 상황임에도 불구하고 각종 SNS에 떠돌아다니는 잘못된 정보에 노출되어 현혹당하고야 맙니다. 여러가지 부작용을 심각하게 걱정하며 약물 치료를 시작조차 해보지 못하고 값비싼 시술과 관리에 의존하시는 분들이 많습니다. 여드름은 반드시 의사와 환자와의 꼼꼼한 상담 후에 약물 치료가 우선적으로 고려되어야 합니다. 더 깊어지기 전에 전문적인 치료를 시작하세요.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png',
//                 },
//                 {
//                     title: '여드름 진료 중 과반수 이상은 성인 여드름에 관한 치료입니다.',
//                     contents: '많은 환자분들이 여드름의 약물 치료가 반드시 필요한 상황임에도 불구하고 각종 SNS에 떠돌아다니는 잘못된 정보에 노출되어 현혹당하고야 맙니다. 여러가지 부작용을 심각하게 걱정하며 약물 치료를 시작조차 해보지 못하고 값비싼 시술과 관리에 의존하시는 분들이 많습니다. 여드름은 반드시 의사와 환자와의 꼼꼼한 상담 후에 약물 치료가 우선적으로 고려되어야 합니다. 더 깊어지기 전에 전문적인 치료를 시작하세요.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png',
//                 }
//             ]
//         },
//         {
//             category: 'acne',
//             userType: 'A',
//             doctor: {
//                 doctorImage: '',
//                 title: "여드름 치료, 더 이상 미루지 마세요",
//                 isName: true,
//                 contents: "현재 여드름이 주기적으로 올라오는 상태로 여드름의 꾸준한 관리와 조절이 필요한 상태입니다. 여드름이 오래 가면 씻어내기 어려운 상처가 되는 경우가 많아요. 특히, 염증성 여드름(붉게 커져있는 여드름)의 경우 큰 흉터를 만들 수 있고, 화이트헤드(좁쌀여드름)의 경우에는 염증성 여드름으로 발전할 수 있어 두 가지 모두 조기에 치료를 하는 것이 좋아요. 일찍 올바른 약물 치료를 시작할수록 치료의 효과도 우수하고, 흉터나 색소 침착의 가능성이 적어지기 때문에 나중에 피부과에 가는 비용을 절약할 수 있어요.",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '여드름 전용 화장품(논코메도제닉, non-comedogenic)과 병원용 보습제를 사용하는 것이 좋아요. 보습제는 꾸덕꾸덕한 크림 제형보다는 발림성이 좋은 로션이나 겔 타입을 추천해요. 자외선 차단제는 유기자차보다는 혼합자차 또는 무기자차 성분을 사용하는 것을 권장해요. 뜨거운 물보다는 미지근한 물로 여드름 전용 클렌저를 이용하여 세안이나 샤워를 하는 것이 좋고, 샤워나 사우나를 너무 오래 하는 것은 피부 장벽에 손상을 줄 수 있기 때문에 자제해 주세요.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '기름지고 단 음식을 피하고, 규칙적인 식사 패턴을 유지하고, 잠을 깊이 자야 하는 건 이미 다들 아시죠? 어떻게 그렇게 하냐고 물으신다면, 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '10대 청소년 절반 이상은 여드름으로 고민하고 있습니다.',
//                     contents: '많은 환자분들이 여드름의 약물 치료가 반드시 필요한 상황임에도 불구하고 각종 SNS에 떠돌아다니는 잘못된 정보에 노출되어 현혹당하고야 맙니다. 여러가지 부작용을 심각하게 걱정하며 약물 치료를 시작조차 해보지 못하고 값비싼 시술과 관리에 의존하시는 분들이 많습니다. 여드름은 반드시 의사와 환자와의 꼼꼼한 상담 후에 약물 치료가 우선적으로 고려되어야 합니다. 더 깊어지기 전에 전문적인 치료를 시작하세요.',
//                     addInfoType: AddInfoTypeEnum.GRAPH,
//                     precent: 50
//                 },
//                 {
//                     title: '20대는 전 연령 중 가장 적극적으로 여드름 치료를 진행하고 있습니다.',
//                     contents: '많은 환자분들이 여드름의 약물 치료가 반드시 필요한 상황임에도 불구하고 각종 SNS에 떠돌아다니는 잘못된 정보에 노출되어 현혹당하고야 맙니다. 여러가지 부작용을 심각하게 걱정하며 약물 치료를 시작조차 해보지 못하고 값비싼 시술과 관리에 의존하시는 분들이 많습니다. 여드름은 반드시 의사와 환자와의 꼼꼼한 상담 후에 약물 치료가 우선적으로 고려되어야 합니다. 더 깊어지기 전에 전문적인 치료를 시작하세요.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png',
//                 },
//                 {
//                     title: '여드름 진료 중 과반수 이상은 성인 여드름에 관한 치료입니다.',
//                     contents: '많은 환자분들이 여드름의 약물 치료가 반드시 필요한 상황임에도 불구하고 각종 SNS에 떠돌아다니는 잘못된 정보에 노출되어 현혹당하고야 맙니다. 여러가지 부작용을 심각하게 걱정하며 약물 치료를 시작조차 해보지 못하고 값비싼 시술과 관리에 의존하시는 분들이 많습니다. 여드름은 반드시 의사와 환자와의 꼼꼼한 상담 후에 약물 치료가 우선적으로 고려되어야 합니다. 더 깊어지기 전에 전문적인 치료를 시작하세요.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png',
//                 }
//             ]
//         },
//         {
//             category: 'acne',
//             userType: 'B',
//             doctor: {
//                 doctorImage: '',
//                 title: "전문적인 치료가 시급합니다",
//                 isName: true,
//                 contents: "현재 여드름이 반복적으로 올라오고 있거나, 여드름이 지나간 흔적들이 같이 보이는 상태에요. 의사의 전문적인 치료가 꼭 필요한 상태입니다. 그렇다고 섣불리 이런저런 시술이나 관리를 무턱대고 받는 것은 금물이에요. 잘못된 시술이나 관리를 받게 되면 오히려 여드름이 악화할 수도 있기 때문이죠. 시술이나 관리는 올바른 약물 치료가 더해져야만 더욱 큰 효과를 내어 빠른 회복에 도움이 될 수 있습니다.",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '여드름 전용 화장품(논코메도제닉, non-comedogenic)과 병원용 보습제를 사용하는 것이 좋아요. 보습제는 꾸덕꾸덕한 크림 제형보다는 발림성이 좋은 로션이나 겔 타입을 추천해요. 자외선 차단제는 유기자차보다는 혼합자차 또는 무기자차 성분을 사용하는 것을 권장해요. 뜨거운 물보다는 미지근한 물로 여드름 전용 클렌저를 이용하여 세안이나 샤워를 하는 것이 좋고, 샤워나 사우나를 너무 오래 하는 것은 피부 장벽에 손상을 줄 수 있기 때문에 자제해 주세요.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '기름지고 단 음식을 피하고, 규칙적인 식사 패턴을 유지하고, 잠을 깊이 자야 하는 건 이미 다들 아시죠? 어떻게 그렇게 하냐고 물으신다면, 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '10대 청소년 절반 이상은 여드름으로 고민하고 있습니다.',
//                     contents: '여드름 영역에서 새로운 의료 기기와 연구가 꾸준히 발표되면서 무분별한 정보의 홍수를 만들고, 이는 고스란히 환자들의 혼란을 가중하고 있습니다. 무분별한 시술이나 관리, 혹은 약물이 오히려 환자들에게 부작용을 일으키는 경우도 있죠. 때로는 다른 약물 치료가 필요한 때도 있으며, 통상적인 약물 치료가 아닌 전문 치료나 시술이 필요한 경우도 있습니다. 솔직한 닥터와의 전문적인 상담과 치료가 필요합니다.',
//                     addInfoType: AddInfoTypeEnum.GRAPH,
//                     precent: 50
//                 },
//                 {
//                     title: '20대는 전 연령 중 가장 적극적으로 여드름 치료를 진행하고 있습니다.',
//                     contents: '여드름 영역에서 새로운 의료 기기와 연구가 꾸준히 발표되면서 무분별한 정보의 홍수를 만들고, 이는 고스란히 환자들의 혼란을 가중하고 있습니다. 무분별한 시술이나 관리, 혹은 약물이 오히려 환자들에게 부작용을 일으키는 경우도 있죠. 때로는 다른 약물 치료가 필요한 때도 있으며, 통상적인 약물 치료가 아닌 전문 치료나 시술이 필요한 경우도 있습니다. 솔직한 닥터와의 전문적인 상담과 치료가 필요합니다.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png',
//                 },
//                 {
//                     title: '여드름 진료 중 과반수 이상은 성인 여드름에 관한 치료입니다.',
//                     contents: '여드름 영역에서 새로운 의료 기기와 연구가 꾸준히 발표되면서 무분별한 정보의 홍수를 만들고, 이는 고스란히 환자들의 혼란을 가중하고 있습니다. 무분별한 시술이나 관리, 혹은 약물이 오히려 환자들에게 부작용을 일으키는 경우도 있죠. 때로는 다른 약물 치료가 필요한 때도 있으며, 통상적인 약물 치료가 아닌 전문 치료나 시술이 필요한 경우도 있습니다. 솔직한 닥터와의 전문적인 상담과 치료가 필요합니다.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png',
//                 }
//             ]
//         },  // 여드름 
//         {
//             category: 'blush',
//             userType: 'C',
//             doctor: {
//                 doctorImage: '',
//                 title: "꾸준한 관리로 홍조를 개선해 나가세요",
//                 isName: true,
//                 contents: "현재 경미한 홍조가 있으나, 일상생활에는 지장이 없는 피부 상태인 것 같아요. 현재 피부 상태를 개선하고 또 유지해나가기 위해 클럽 어니스트가 제안하는 올바른 피부 습관을 꾸준히 실천해보세요!",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '자외선 차단에 항상 신경 써주세요. 세안은 미지근한 물로 3분 이내로 마무리하는 것이 모두에게 좋지만, 홍조 피부에는 더욱 중요해요. 세안할 때는 약산성 계열의 성분이 순한 클렌저를 사용해 주세요. 여러 가지 화장품을 섞어서 바르기보다는, 개수를 줄여주시는 것이 홍조 완화에는 더 도움이 됩니다. 짙은 화장이나, 이로 인한 이중 세안이나 필링도 삼가세요.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '홍조는 오랜 기간 잘못된 피부 관리 습관 때문에 악화가 되는 경우도 많아요. 이제부터라도 홍조를 더 심해지게 하는 생활 습관은 피해주세요. 맵거나 자극적인 음식은 홍조를 악화시키는 대표적인 식습관이므로 자제하시길 바랄게요. 술과 담배, 커피도 피해주시는 것이 좋아요. 급격한 온도 변화가 있는 환경에 자주 노출되지 않도록 주의해주세요. 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '홍조는 전문적이고 올바른 치료로 개선될 수 있습니다.',
//                     contents: '피부 건강을 지키는 제1원칙은 바로 올바른 피부 관리 습관을 지키기라는 것, 말 안 해도 다들 아시지요? 말씀드린 홈케어 습관을 잘 지켜주세요. 만약 홍조가 오래 지속되었거나, 맨눈으로 보이는 혈관으로 인한 홍조가 있다면 일련의 레이저 치료가 필요할 수도 있어요. 만약 홍조, 붉기와 더불어 다른 증상이 수반되고 있다면 정확한 원인 진단을 위해 반드시 의료진과 상담해주세요. 이외에도 더 궁금한 점이 있다면, 의사 선생님과 영상통화로 진료 보고 피부에 대한 여러 가지 이야기를 나눠보세요!',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png'
//                 },
//                 {
//                     title: '홍조 질환을 가진 사람 10명 중 2명은 20대입니다.',
//                     contents: '피부 건강을 지키는 제1원칙은 바로 올바른 피부 관리 습관을 지키기라는 것, 말 안 해도 다들 아시지요? 말씀드린 홈케어 습관을 잘 지켜주세요. 만약 홍조가 오래 지속되었거나, 맨눈으로 보이는 혈관으로 인한 홍조가 있다면 일련의 레이저 치료가 필요할 수도 있어요. 만약 홍조, 붉기와 더불어 다른 증상이 수반되고 있다면 정확한 원인 진단을 위해 반드시 의료진과 상담해주세요. 이외에도 더 궁금한 점이 있다면, 의사 선생님과 영상통화로 진료 보고 피부에 대한 여러 가지 이야기를 나눠보세요!',
//                     addInfoType: AddInfoTypeEnum.GRAPH,
//                     precent: 20,
//                 },
//                 {
//                     title: '피부과에서 홍조를 치료하는 사람 10명 중 6명은 30대 이상 성인입니다.',
//                     contents: '피부 건강을 지키는 제1원칙은 바로 올바른 피부 관리 습관을 지키기라는 것, 말 안 해도 다들 아시지요? 말씀드린 홈케어 습관을 잘 지켜주세요. 만약 홍조가 오래 지속되었거나, 맨눈으로 보이는 혈관으로 인한 홍조가 있다면 일련의 레이저 치료가 필요할 수도 있어요. 만약 홍조, 붉기와 더불어 다른 증상이 수반되고 있다면 정확한 원인 진단을 위해 반드시 의료진과 상담해주세요. 이외에도 더 궁금한 점이 있다면, 의사 선생님과 영상통화로 진료 보고 피부에 대한 여러 가지 이야기를 나눠보세요!',
//                     addInfoType: AddInfoTypeEnum.GRAPH,
//                     precent: 60
//                 }
//             ]
//         },
//         {
//             category: 'blush',
//             userType: 'A',
//             doctor: {
//                 doctorImage: '',
//                 title: "일상 속 적절한 관리로 홍조를 완화해보세요",
//                 isName: true,
//                 contents: "현재 얼굴에 전반적으로 붉기가 있지만 일상생활에는 큰 지장이 없는 상태인 것 같아요. 혹시나, 다른 불편한 증상이 있다면 진료 시 의사 선생님에게 꼭 이야기해주세요!",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '자외선 차단에 항상 신경 써주세요. 세안은 미지근한 물로 3분 이내로 마무리하는 것이 모두에게 좋지만, 홍조 피부에는 더욱 중요해요. 세안할 때는 약산성 계열의 성분이 순한 클렌저를 사용해 주세요. 여러 가지 화장품을 섞어서 바르기보다는, 개수를 줄여주시는 것이 홍조 완화에는 더 도움이 됩니다. 짙은 화장이나, 이로 인한 이중 세안이나 필링도 삼가세요.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '홍조는 오랜 기간 잘못된 피부 관리 습관 때문에 악화가 되는 경우도 많아요. 이제부터라도 홍조를 더 심해지게 하는 생활 습관은 피해주세요. 맵거나 자극적인 음식은 홍조를 악화시키는 대표적인 식습관이므로 자제하시길 바랄게요. 술과 담배, 커피도 피해주시는 것이 좋아요. 급격한 온도 변화가 있는 환경에 자주 노출되지 않도록 주의해주세요. 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '홍조는 전문적이고 올바른 치료로 개선될 수 있습니다.',
//                     contents: '붉기와 홍조에는 잘못된 생활 습관뿐만 아니라 의학적으로 여러 가지 원인이 있을 수 있습니다. 따라서 의학적 소견에 따라 먹는 약이나 바르는 약이 필요할 수 있어요. 만약 붉기가 지속된 기간이 상당히 오래되었다거나 맨눈으로 보이는 혈관으로 인한 홍조가 있다면 대면 진료를 통해 일련의 레이저 치료를 받는 것을 권장합니다. 이외에도 더 궁금한 점이 있다면, 의사 선생님과 영상통화로 진료 보고 피부에 대한 여러 가지 이야기를 나눠보세요! 만약 홍조, 붉기와 더불어 다른 증상이 수반되고 있다면 정확한 원인 진단을 위해 통화 진료 시 반드시 의료진에게 알려주세요.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png'
//                 },
//                 {
//                     title: '홍조 질환을 가진 사람 10명 중 2명은 20대입니다.',
//                     contents: '붉기와 홍조에는 잘못된 생활 습관뿐만 아니라 의학적으로 여러 가지 원인이 있을 수 있습니다. 따라서 의학적 소견에 따라 먹는 약이나 바르는 약이 필요할 수 있어요. 만약 붉기가 지속된 기간이 상당히 오래되었다거나 맨눈으로 보이는 혈관으로 인한 홍조가 있다면 대면 진료를 통해 일련의 레이저 치료를 받는 것을 권장합니다. 이외에도 더 궁금한 점이 있다면, 의사 선생님과 영상통화로 진료 보고 피부에 대한 여러 가지 이야기를 나눠보세요! 만약 홍조, 붉기와 더불어 다른 증상이 수반되고 있다면 정확한 원인 진단을 위해 통화 진료 시 반드시 의료진에게 알려주세요.',
//                     addInfoType: AddInfoTypeEnum.GRAPH,
//                     precent: 20,
//                 },
//                 {
//                     title: '피부과에서 홍조를 치료하는 사람 10명 중 6명은 30대 이상 성인입니다.',
//                     contents: '붉기와 홍조에는 잘못된 생활 습관뿐만 아니라 의학적으로 여러 가지 원인이 있을 수 있습니다. 따라서 의학적 소견에 따라 먹는 약이나 바르는 약이 필요할 수 있어요. 만약 붉기가 지속된 기간이 상당히 오래되었다거나 맨눈으로 보이는 혈관으로 인한 홍조가 있다면 대면 진료를 통해 일련의 레이저 치료를 받는 것을 권장합니다. 이외에도 더 궁금한 점이 있다면, 의사 선생님과 영상통화로 진료 보고 피부에 대한 여러 가지 이야기를 나눠보세요! 만약 홍조, 붉기와 더불어 다른 증상이 수반되고 있다면 정확한 원인 진단을 위해 통화 진료 시 반드시 의료진에게 알려주세요.',
//                     addInfoType: AddInfoTypeEnum.GRAPH,
//                     precent: 60
//                 }
//             ]
//         },
//         {
//             category: 'blush',
//             userType: 'B',
//             doctor: {
//                 doctorImage: '',
//                 title: "의료진과의 치료 상담을 적극적으로 추천합니다",
//                 isName: true,
//                 contents: "맨눈으로 보이는 전반적인 홍조와 더불어 일상생활의 다른 불편한 증상이 있는 상태인 것 같아요. 붉기가 오래 지속될수록 치료가 어려워지는 경우가 많아요. 이른 시일 내에 의료진과 이야기를 나누고 전문적인 치료를 받는 것을 권장해요. 특히 붉기 이외에 다른 불편한 증상이 있다면 주요 원인의 정확한 진단을 위해 꼭 상담해주세요.",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '자외선 차단에 항상 신경 써주세요. 세안은 미지근한 물로 3분 이내로 마무리하는 것이 모두에게 좋지만, 홍조 피부에는 더욱 중요해요. 세안할 때는 약산성 계열의 성분이 순한 클렌저를 사용해 주세요. 여러 가지 화장품을 섞어서 바르기보다는, 개수를 줄여주시는 것이 홍조 완화에는 더 도움이 됩니다. 짙은 화장이나, 이로 인한 이중 세안이나 필링도 삼가세요.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '홍조는 오랜 기간 잘못된 피부 관리 습관 때문에 악화가 되는 경우도 많아요. 이제부터라도 홍조를 더 심해지게 하는 생활 습관은 피해주세요. 맵거나 자극적인 음식은 홍조를 악화시키는 대표적인 식습관이므로 자제하시길 바랄게요. 술과 담배, 커피도 피해주시는 것이 좋아요. 급격한 온도 변화가 있는 환경에 자주 노출되지 않도록 주의해주세요. 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '홍조는 전문적이고 올바른 치료로 개선될 수 있습니다.',
//                     contents: '답변해주신 테스트의 내용으로 미뤄본다면, 지금의 상태는 약물 또는 레이저 치료 등 전문적인 치료가 필요한 단계로 보입니다. 홍조와 붉기의 주요한 의학적 원인에 따라 치료 방법이 달라질 수 있으므로, 시중의 무분별한 관리법을 시도해보기 이전에 반드시 의료진과 상담하시길 권장해 드립니다. 위에 제안한 홈케어 습관을 반드시 지켜주시고, 솔닥의 의사 선생님과 영상통화로 상담받아보세요. 테스트 결과와 영상통화로 보이는 피부 상태를 토대로 상담을 진행한 뒤에, 상급 병원의 방문 등에 대해 논의하시길 권장해 드립니다.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png'
//                 },
//                 {
//                     title: '홍조 질환을 가진 사람 10명 중 2명은 20대입니다.',
//                     contents: '답변해주신 테스트의 내용으로 미뤄본다면, 지금의 상태는 약물 또는 레이저 치료 등 전문적인 치료가 필요한 단계로 보입니다. 홍조와 붉기의 주요한 의학적 원인에 따라 치료 방법이 달라질 수 있으므로, 시중의 무분별한 관리법을 시도해보기 이전에 반드시 의료진과 상담하시길 권장해 드립니다. 위에 제안한 홈케어 습관을 반드시 지켜주시고, 솔닥의 의사 선생님과 영상통화로 상담받아보세요. 테스트 결과와 영상통화로 보이는 피부 상태를 토대로 상담을 진행한 뒤에, 상급 병원의 방문 등에 대해 논의하시길 권장해 드립니다.',
//                     addInfoType: AddInfoTypeEnum.GRAPH,
//                     precent: 20,
//                 },
//                 {
//                     title: '피부과에서 홍조를 치료하는 사람 10명 중 6명은 30대 이상 성인입니다.',
//                     contents: '답변해주신 테스트의 내용으로 미뤄본다면, 지금의 상태는 약물 또는 레이저 치료 등 전문적인 치료가 필요한 단계로 보입니다. 홍조와 붉기의 주요한 의학적 원인에 따라 치료 방법이 달라질 수 있으므로, 시중의 무분별한 관리법을 시도해보기 이전에 반드시 의료진과 상담하시길 권장해 드립니다. 위에 제안한 홈케어 습관을 반드시 지켜주시고, 솔닥의 의사 선생님과 영상통화로 상담받아보세요. 테스트 결과와 영상통화로 보이는 피부 상태를 토대로 상담을 진행한 뒤에, 상급 병원의 방문 등에 대해 논의하시길 권장해 드립니다.',
//                     addInfoType: AddInfoTypeEnum.GRAPH,
//                     precent: 60
//                 }
//             ]
//         }, // blush
//         {
//             category: 'brown',
//             userType: 'A',
//             doctor: {
//                 doctorImage: '',
//                 title: "전문 치료로 더 환하고 건강한 피부를 만들어보세요",
//                 isName: true,
//                 contents: "생긴 지 오래되지 않은 색소 침착이 있는 상태로 보여요. 색소 침착의 빠른 회복과 치료를 위해 적절한 약물의 사용, 시술과 관리가 도움이 될 수 있어요. 색소가 침착된 부위를 개선해주는 미백 약물로는 멜라논 크림과 도미나 크림이 대표적이에요. 시술로는 색소를 깨서 치료하는 레이저 토닝, 관리로는 색소를 완화하는 필링이 있어요. 다만 사람마다 피부 상태와 치료에 따른 반응이 다르기 때문에 전문가와의 상담을 통해 치료를 시작하는 것이 중요해요. 색소 침착 이외에도 불편한 증상이 있다면, 정확한 원인 파악을 위해 꼭 의료진과 상담해주세요.",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '자외선 차단에 항상 신경 써주세요. 얼굴을 손으로 자주 만지면 마찰로 인해 색소침착이 생길 수 있으니 주의해주세요. 세안은 미지근한 물로 3분 이내에 마무리해주시는 것이 좋아요. 보습제를 아침, 저녁으로 2회 이상 꾸준히 도포해주세요. 피부가 건조해지면 피부 장벽이 약해지고, 이것이 결국 색소침착으로 이어질 수 있습니다. 필링 횟수는 주 2회 이하로 제한하세요. 필링을 너무 자주 하면 오히려 피부 색조가 더 진해질 우려가 있습니다. 여러 가지 화장품을 섞어서 바르기보다는 개수를 줄여주는 것이 더 좋습니다.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '피부 회복을 위해 충분한 휴식과 잠을 깊이 자주세요. 잠을 깊이 잘 수 있는 환경을 만들기 위해 적절한 수면 위생 환경을 조성해주세요. 미세먼지가 심한 날에는 외출을 삼가시기를 바랍니다. 미세먼지도 색소침착의 원인이 될 수 있답니다. 무엇보다 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '색소침착, 무분별한 시도가 아닌 정확하고 전문적인 치료가 필요합니다.',
//                     contents: '색소침착이 신체 건강과 직접적인 관련이 있는 경우는 흔하지 않아요. 그러나 색소침착은 우리의 자신감을 떨어뜨려 마음의 병을 만들기도 하죠. 하지만 평소 잘못된 피부 관리 습관들을 상담을 통해 개선하게 되면 얼굴이 점차 밝아질 수 있어요. 따라서 미백 약품을 사용하거나 시술을 진행하기에 앞서 꼭 의료진과 충분한 상담의 과정을 거치시길 바랍니다. 클럽 어니스트에서 제공하는 영상통화 진료를 통해 솔직하고 진솔한 상담을 받아보세요.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png'
//                 }               
//             ]
//         },
//         {
//             category: 'brown',
//             userType: 'B',
//             doctor: {
//                 doctorImage: '',
//                 title: "의료진과의 상담을 적극적으로 권장해 드립니다",
//                 isName: true,
//                 contents: "전문적인 시술이나 관리가 필요한 색소 침착이 있는 상태로 보여요. 지금 상태에서는 약물 치료만으로 효과를 보기에는 한계가 있는 경우가 많습니다. 따라서 피부과에 방문하여 레이저 시술을 진행하고 전문 관리를 받는 것을 권장합니다. 대표적인 시술로는 색소를 깨서 치료하는 레이저 토닝, 관리로는 색소를 완화하는 필링이 있습니다. 다만 사람마다 피부 상태와 치료에 따른 반응이 다르기 때문에 전문가와의 상담을 통해 치료를 시작하는 것이 중요해요. 색소 침착 이외에도 불편한 증상이 동반된다면, 정확한 원인 파악을 위해 꼭 의료진과 상담해주세요.",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '자외선 차단에 항상 신경 써주세요. 얼굴을 손으로 자주 만지면 마찰로 인해 색소침착이 생길 수 있으니 주의해주세요. 세안은 미지근한 물로 3분 이내에 마무리해주시는 것이 좋아요. 보습제를 아침, 저녁으로 2회 이상 꾸준히 도포해주세요. 피부가 건조해지면 피부 장벽이 약해지고, 이것이 결국 색소침착으로 이어질 수 있습니다. 필링 횟수는 주 2회 이하로 제한하세요. 필링을 너무 자주 하면 오히려 피부 색조가 더 진해질 우려가 있습니다. 여러 가지 화장품을 섞어서 바르기보다는 개수를 줄여주는 것이 더 좋습니다.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '피부 회복을 위해 충분한 휴식과 잠을 깊이 자주세요. 잠을 깊이 잘 수 있는 환경을 만들기 위해 적절한 수면 위생 환경을 조성해주세요. 미세먼지가 심한 날에는 외출을 삼가시기를 바랍니다. 미세먼지도 색소침착의 원인이 될 수 있답니다. 무엇보다 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '색소침착, 무분별한 시도가 아닌 정확하고 전문적인 치료가 필요합니다.',
//                     contents: '색소 질환은 병원 방문 진료가 필요한 경우가 대부분이에요. 색소 질환의 종류에 따라 치료 기간이나 치료의 효과가 상이할 수 있어요. 따라서 의사 선생님이 맨눈으로 색소를 직접 보고 상세한 치료 계획을 세우는 과정이 필요합니다. 클럽 어니스트가 제공하는 피부과 의료진과의 영상통화 진료를 통해 병원 방문 전 내 피부 상태의 이해를 높여보세요. <내 색소 파악하기>를 누르면 내 피부 상태를 더 구체적으로 파악할 수 있는 가이드라인을 확인할 수 있어요. 꼭 확인해 보시고, 의심되는 병변 치료를 위해 상급 병원 방문이 필요하다면 방문해 보시길 권장드립니다.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png',
//                     modalButtonText: '내 색소 파악하기',
//                     modalContents: [
//                         {
//                             title: '주근깨 ・ 잡티',
//                             imageInfos: [
//                                 {
//                                     image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/freckles.png',
//                                     infoList: [
//                                         "주로 햇빛에 노출된 부위에 생겨요",
//                                         "경계가 명확한 황갈생의 작은 색소 반점이에요",
//                                         "주로 피부가 하얗고 건조한 사람에게 잘 생겨요",
//                                         "10대 청소년부터 생기는 경우가 많아요"
//                                     ],
//                                 }
//                             ],
                            
//                             contents: '피부상태에 따라 치료 기간이나 횟수가 조금씩 달라질 수 있지만, 대부분 2-3회 이내의 시술로 금방 호전이되는 색소 반점이므로 너무 걱정하지 마세요. 금방 좋아질 수 있어요!'
//                         },
//                         {
//                             title: '기미',
//                             imageInfos: [
//                                 {
//                                     image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/agespot.png',
//                                     infoList: [
//                                         "주로 30~40대 여성에게서 흔히 관찰되어요",
//                                         "경계가 불분명한 회갈색의 대칭적 색소 반점이에요",
//                                         "광대, 볼, 턱, 눈가, 눈밑, 이마 등 얼굴 전체를 침범할 수 있어요",
//                                         "원인을 정확히는 알 수 없지만 유전적 소인이 관련되어 있고, 자외선 노출, 호르몬 불균형, 스트레스, 잘못된 생활 습관 등 환경적 소인이 반복되어 생기는 것으로 알려져 있어요"
//                                     ]
//                                 }
//                             ],                            
//                             contents: '레이저 토닝, 초음파/고주파 치료, 바르는 약, 먹는 약, 화학 박피술 등 다양한 방법들이 기미치료에 쓰이고 있습니다. 만성적인 피부 질환인 만큼 오랜 시간 증상의 호전과 약화가 반복될 수 있으며, 생활 습관 교정이 필수적이고 오랜 기간의 치료를 필요로 해요. 솔닥과 함께 한걸음씩 차근차근 노력해봐요.'
//                         },
//                         {
//                             title: 'ABNOM (후천성 양측성 오타모양 모반 반점)',
//                             imageInfos: [
//                                 {
//                                     image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/abnom.png',
//                                     infoList: [
//                                         "ABNOM은 태어난 뒤에 생기는 후천성 질환이에요",
//                                         "얼굴의 양측에 위치하고, 오타반점의 모양과 비슷하게 생긴 반점이에요",
//                                         "색소를 만드는 멜라닌 세포의 이상으로 생기고 광대, 관자, 이마, 코 등에 주로 분포하며 갈색을 띄어요",
//                                         "보통 20대 후반부터 가장 많이 관찰되지만, 10대 후반의 나이에도 생길 수 있어요"
//                                     ]
//                                 }

//                             ],                            
//                             contents: '치료의 기본은 레이저 토닝이며, 최소 10회 이상의 반복적인 레이저 시술을 필요로 해요. 솔닥을 통해 정확한 진단과 치료를 받아보세요! 솔닥이 함께할게요.'
//                         },
//                         {
//                             title: '착한 점 vs 나쁜 점',
//                             imageInfos: [
//                                 {
//                                     image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/good_mole.png',
//                                     infoList: [
//                                         "가운데를 기준으로 좌우의 모양이 대칭적이에요",
//                                         "가장자리가 깔끔한 원 또는 타원 모양이에요",
//                                         "색이 전체적으로 균일해요",
//                                         "직경이 6mm보다 작아요",
//                                         "점의 크기, 모양, 색이 변하지 않아요"
//                                     ]
//                                 },
//                                 {
//                                     image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/bad_mole.png',
//                                     infoList: [
//                                         "가운데를 기준으로 좌우의 대칭이 맞지 않아요",
//                                         "가장자리가 울퉁불퉁하거나, 불균일하고 경계가 흐려요",
//                                         "점의 색이 균일하지 않고 검갈색으로 번진 자국이나 불그스름하고 푸른 부분이 있어요",
//                                         "직경이 6mm보다 커요",
//                                         "점의 크기, 모양, 색이 변하기도 해요"
//                                     ]
//                                 }
//                             ],       
//                             contents: '착한 점과 나쁜 점을 스스로 진단해보는 다섯 가지의 아주 간단한 기준이 있습니다. (위: 착한 점 - mole, 아래: 나쁜 점 - melanoma) 꼭 정독하시고 색소 병변이 \'나쁜 점\'에 부합하는 것 같다면 이른 시일 내에 반드시 상급병원에서 전문적인 진료를 받으시길 강력히 권장합니다.'
//                         }
//                     ]
//                 }               
//             ]
//         },
//         {
//             category: 'brown',
//             userType: 'C',
//             doctor: {
//                 doctorImage: '',
//                 title: "이른 시일 내에 상급 병원에 방문하여 정밀 검사를 받아보세요",
//                 isName: false,
//                 contents: "색소 침착 이외에도 불편한 증세가 함께 수반되고 있다면, 정확한 원인 파악을 위해 꼭 담당 의료진에게 이야기해주세요.",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '자외선 차단에 항상 신경 써주세요. 얼굴을 손으로 자주 만지면 마찰로 인해 색소침착이 생길 수 있으니 주의해주세요. 세안은 미지근한 물로 3분 이내에 마무리해주시는 것이 좋아요. 보습제를 아침, 저녁으로 2회 이상 꾸준히 도포해주세요. 피부가 건조해지면 피부 장벽이 약해지고, 이것이 결국 색소침착으로 이어질 수 있습니다. 필링 횟수는 주 2회 이하로 제한하세요. 필링을 너무 자주 하면 오히려 피부 색조가 더 진해질 우려가 있습니다. 여러 가지 화장품을 섞어서 바르기보다는 개수를 줄여주는 것이 더 좋습니다.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '피부 회복을 위해 충분한 휴식과 잠을 깊이 자주세요. 잠을 깊이 잘 수 있는 환경을 만들기 위해 적절한 수면 위생 환경을 조성해주세요. 미세먼지가 심한 날에는 외출을 삼가시기를 바랍니다. 미세먼지도 색소침착의 원인이 될 수 있답니다. 무엇보다 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '색소침착, 무분별한 시도가 아닌 정확하고 전문적인 치료가 필요합니다.',
//                     contents: '착한 점과 나쁜 점을 스스로 진단해보는 다섯 가지의 아주 간단한 기준이 있습니다. 꼭 정독하시고 색소 병변이 해당 기준에 부합하는 것 같다면 이른 시일 내에 반드시 상급병원에서 전문적인 진료를 받으시길 강력히 권장합니다.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png',
//                     modalButtonText: '착한점 vs 나쁜점',
//                     modalContents: [
//                         {
//                             title: '착한 점 vs 나쁜 점',
//                             imageInfos: [
//                                 {
//                                     image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/good_mole.png',
//                                     infoList: [
//                                         "가운데를 기준으로 좌우의 모양이 대칭적이에요",
//                                         "가장자리가 깔끔한 원 또는 타원 모양이에요",
//                                         "색이 전체적으로 균일해요",
//                                         "직경이 6mm보다 작아요",
//                                         "점의 크기, 모양, 색이 변하지 않아요"
//                                     ]
//                                 },
//                                 {
//                                     image: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/bad_mole.png',
//                                     infoList: [
//                                         "가운데를 기준으로 좌우의 대칭이 맞지 않아요",
//                                         "가장자리가 울퉁불퉁하거나, 불균일하고 경계가 흐려요",
//                                         "점의 색이 균일하지 않고 검갈색으로 번진 자국이나 불그스름하고 푸른 부분이 있어요",
//                                         "직경이 6mm보다 커요",
//                                         "점의 크기, 모양, 색이 변하기도 해요"
//                                     ]
//                                 }
//                             ],       
//                             contents: '착한 점과 나쁜 점을 스스로 진단해보는 다섯 가지의 아주 간단한 기준이 있습니다. (위: 착한 점 - mole, 아래: 나쁜 점 - melanoma) 꼭 정독하시고 색소 병변이 \'나쁜 점\'에 부합하는 것 같다면 이른 시일 내에 반드시 상급병원에서 전문적인 진료를 받으시길 강력히 권장합니다.'
//                         }
//                     ]
//                 }               
//             ]
//         }, // brown
//         {
//             category: 'dry',
//             userType: 'C',
//             doctor: {
//                 doctorImage: '',
//                 title: "꾸준한 관리로 현재의 피부를 유지하세요",
//                 isName: true,
//                 contents: "현재 건강한 피부를 잘 유지하고 있는 것 같아요. 칭찬해요! 지금처럼 건강한 피부 관리 습관을 계속 유지해주세요. 피부를 위한 올바른 생활 습관을 참고해주세요.",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '아침과 저녁, 하루에 최소 2회 이상 보습제를 도포하세요. 보습제는 세안 또는 샤워 후 물기를 건조한 이후 최대한 빨리 바르는 것이 좋아요. 시중의 일반 보습제보다는 병원에서 처방받을 수 있는 MD 보습제를 사용하면 보습감 유지에 더욱더 효과적입니다. 세안은 미지근한 물로 3분 이내로 마무리하시고, 얼굴을 너무 강하게 문지르지 말아 주세요. 필링이나 때밀이를 할 때는 피부 상태를 확인하며 자극이 되지 않는 선에서 진행해주세요.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '평소 수분은 충분히 섭취해주시고, 자극적인 음식은 피해주세요. 몸의 수분을 빼앗는 술담배와 커피도 자제하시는 것이 좋겠지요? 무엇보다 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '피부 수분감은 꾸준한 관리로 좋은 컨디션을 유지할 수 있어요',
//                     contents: '축하드려요. 건강한 피부를 가지고 계신 것 같아요. 지금처럼 건강한 피부를 꾸준히 유지할 수 있도록 제안한 올바른 생활 습관을 유지해주세요. 필링과 마스크팩은 성분과 본인의 피부타입에 따라 적당한 횟수로 하는 것을 권장해요. 목욕이나 사우나는 기분전환 겸해도 괜찮지만, 뭐든 과한 건 수분을 오히려 뺏길 수 있어서 좋지 않아요. 피부 건강을 개선할 수 있는 피부과 관리 시술을 비롯해 다양한 궁금증은 의료진과의 영상통화를 통해 상담받아보세요',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png'
//                 }               
//             ]
//         },
//         {
//             category: 'dry',
//             userType: 'A',
//             doctor: {
//                 doctorImage: '',
//                 title: "적절한 치료를 통해 피부 건조함을 개선해보세요",
//                 isName: true,
//                 contents: "피부 건조증의 개선을 위해 보다 꼼꼼한 보습과 생활 습관 교정이 필요한 단계예요. 건조감 외에 다른 불편한 증상(붉기, 가려움증, 자극감 등)이 있다면, 생활 습관뿐 아니라 기타 원인이 있을 수 있어요. 정확한 진단을 위해 꼭 의료진과 상담하세요.",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '아침과 저녁, 하루에 최소 2회 이상 보습제를 도포하세요. 보습제는 세안 또는 샤워 후 물기를 건조한 이후 최대한 빨리 바르는 것이 좋아요. 시중의 일반 보습제보다는 병원에서 처방받을 수 있는 MD 보습제 사용을 권장해 드려요. 세안은 미지근한 물로 3분 이내로 마무리하시고, 얼굴을 너무 강하게 문지르지 말아 주세요. 필링이나 때밀이도 당분간은 피해주시는 것이 좋아요.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '혹시 레티노이드 계열의 의약품을 사용 중이라면, 의사 선생님과 상의하여, 가능하다면 잠시 약물 사용을 중단해주세요. 평소 수분은 충분히 섭취해주시고, 자극적인 음식은 피해주세요. 몸의 수분을 빼앗는 술담배와 커피도 자제하시는 것이 좋겠지요? 무엇보다 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '피부 건조증, 빠르고 올바른 치료가 무엇보다 중요합니다',
//                     contents: '피부 장벽은 이름에서도 알 수 있는 것처럼, 우리의 피부 건강을 지켜주는 아주 중요한 구조물입니다. 피부가 건조해지면 피부 장벽이 손상되어 다양한 피부 질환의 가능성이 커지고 잔주름이 도드라져 보일 수 있습니다. 제로이드나 아토베리어 MD와 같은 병원용 보습제의 경우LEV 일반 보습제들과 다르게 우리의 피부 장벽과 유사하게 만들어진 보습제로서 손상된 피부 장벽의 회복에 도움을 줍니다. 작은 습관부터 하나씩 교정하다 보면 건조증이 조금씩 개선될 거예요. 만약 건조증이 심하다면 피부과의 시술이나 관리도 도움이 될 수 있어요. 보습제 처방, 또는 시술에 대한 상담이 필요하다면 지금 솔닥 의료진과의 영상통화 진료를 예약해보세요.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png'
//                 }               
//             ]
//         },
//         {
//             category: 'dry',
//             userType: 'B',
//             doctor: {
//                 doctorImage: '',
//                 title: "의료진과의 치료 상담을 적극적으로 추천합니다",
//                 isName: true,
//                 contents: "님의 현재 피부는 건조증이 심하고 피부 장벽이 다소 손상된 상태에요. 피부 건조증을 개선하기 위해, 그리고 피부 건조증으로 인해 발생할 수 있는 2차 질환을 예방하기 위해 보다 적극적이고 전문적인 치료가 필요합니다. 아래 조언하는 생활 습관을 지키고, 정확한 진단을 위해 꼭 의료진과 상담해 보시길 권장해 드립니다.",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '아침과 저녁, 하루에 최소 2회 이상 보습제를 도포하세요. 보습제는 세안 또는 샤워 후 물기를 건조한 이후 최대한 빨리 바르는 것이 좋아요. 시중의 일반 보습제보다는 병원에서 처방받을 수 있는 MD 보습제 사용을 권장해 드려요. 세안은 미지근한 물로 3분 이내로 마무리하시고, 얼굴을 너무 강하게 문지르지 말아 주세요. 필링이나 때밀이도 당분간은 피해주시는 것이 좋아요.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '혹시 레티노이드 계열의 의약품을 사용 중이라면, 의사 선생님과 상의하여, 가능하다면 잠시 약물 사용을 중단해주세요. 평소 수분은 충분히 섭취해주시고, 자극적인 음식은 피해주세요. 몸의 수분을 빼앗는 술담배와 커피도 자제하시는 것이 좋겠지요? 무엇보다 스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 건강한 라이프스타일로 이어질 거예요.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '피부 건조증도 하나의 피부 질환입니다',
//                     contents: '피부 건조증도 하나의 피부 질환이지만, 많은 사람이 건조증을 간과하는 경향이 있습니다. 특히 현재의 경우는 의료진과의 상담을 통한 세심한 치료와 관리가 필요한 단계로 생각됩니다. 건조증을 그대로 방치해두면 습진이나 감염성 질환 등으로 이어질 수 있어요. 우선 병원용 보습제 MD를 처방받아 사용하면서 경과를 지켜보시는 걸 권장해 드려요. 만약 이후에도 건조증이 너무 심하다면 피부과의 시술이나 관리가 도움이 될 수도 있어요. 클럽 어니스트가 제안하는 10가지 생활 습관 원칙을 반드시 지켜주세요. 건조한 증상 외에 가려움증, 자극감, 붉기, 하얀 인비늘 등이 있으면 정확한 원인 진단을 위해 반드시 의사와 진료, 상담을 진행하시길 권고드립니다.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png'
//                 }               
//             ]
//         }, // dry
//         {
//             category: 'antiaging',
//             userType: 'C',
//             doctor: {
//                 doctorImage: '',
//                 title: "현재의 피부 나이를 오랜 시간 간직하는데 집중하세요",
//                 isName: true,
//                 contents: "현재 자기 나이에 맞는, 혹은 그 이상의 건강한 피부를 잘 유지하고 있는 것 같아요. 칭찬해요! 건강한 피부를 위해 솔닥이 제안하는 ‘슬로우에이징’ 생활 습관을 함께 따라해봐요.",
//                 modalButtonText: '홈케어 습관 보러가기',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '우리의 나이를 완전히 되돌릴 수는 없지만, 노화의 속도를 천천히 하는 ‘슬로우 에이징(slow-aging)’은 솔닥과 함께 실천할 수 있어요. 솔닥이 제안하는 ‘슬로우에이징’ 습관을 하나씩 실천해보세요! 한 번에 모두를 실천하기는 어려워요. 솔닥과 함께 하나씩 개선해 나가요.무의식적으로 인상을 자주 쓰지 마세요. 표정 근육을 반복적으로 사용하게 되면 주름이 깊어질 수 있어요. 선크림을 날씨와 무관하게 항상 발라주세요. 자외선은 비가 오는 날에도 우리의 피부에 도달하며, 자외선은 피부의 콜라겐과 엘라스틴을 망가뜨려요. 보습제를 아침/저녁으로 최소 2회 이상 바르세요. 건조한 피부는 피부를 나이 들어 보이게 해요. 필링이나 때밀이를 할 때는 피부 상태를 확인하며 자극이 되지 않는 선에서 진행하세요. 사우나나 목욕은 너무 오래하지 마세요. 오히려 수분을 뺏겨 피부 장벽이 손상될 수 있어요.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 안티에이징과 건강한 라이프스타일로 이어질 거예요. 술/담배를 하지 마세요. 담배에 포함된 수많은 화학물질과 술의 알코올은 우리의 피부를 상하게 해요. 1주일에 최소 3회 이상 유산소 운동을 하세요. 운동은 순환을 도와 피부를 건강하게 해요. 하루에 7시간 이상 충분히 잠을 자세요. 우리의 피부는 잠을 자는 동안 회복해요. 올바른 수면 위생 환경을 지켜주세요. 균형 잡힌 식단을 유지하세요. 신선한 과일과 채소를 골고루 섭취해주세요. 건강한 식생활은 건강한 피부의 영양분이 됩니다.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '안티에이징 관리를 시작하기에 가장 빠른 시기는 바로 오늘이에요',
//                     contents: '축하드려요. 건강한 피부를 유지하고 계신 것 같아요! 안티에이징에 미리 관심을 가지고 꼼꼼히 관리하는 습관을 들인다면 지금의 건강한 피부를 오래 유지할 수 있을거에요. 평소에 관심이 있었던 시술이나 관리 등에 대한 궁금증들을 ‘솔닥’과 상담해보세요. 어떠한 안티에이징 시술이 도움이 될지, 올바른 생활 습관을 유지하고 있는지 ‘솔닥’이 솔직하게 이야기해줄 거예요.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png'
//                 }               
//             ]
//         },
//         {
//             category: 'antiaging',
//             userType: 'A',
//             doctor: {
//                 doctorImage: '',
//                 title: "안티에이징을 차근차근 시작해볼까요?",
//                 isName: true,
//                 contents: "노화의 증상들이 조금씩 피부에 나타나고 있는 상태로 보여요. 홈케어만으로는 안티에이징에 한계가 있기 때문에, 자세한 상담과 그에 따른 전문적인 시술과 관리가 안티에이징에 도움이 될 거에요. 아래 솔닥이 조언하는 생활 습관을 지켜주시고, 정확한 진단을 위해 꼭 의료진과 상담해 보시길 권장해 드립니다.",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '우리의 나이를 완전히 되돌릴 수는 없지만, 노화의 속도를 천천히 하는 ‘슬로우 에이징(slow-aging)’은 솔닥과 함께 실천할 수 있어요. 솔닥이 제안하는 ‘슬로우에이징’ 습관을 하나씩 실천해보세요! 한 번에 모두를 실천하기는 어려워요. 솔닥과 함께 하나씩 개선해 나가요.무의식적으로 인상을 자주 쓰지 마세요. 표정 근육을 반복적으로 사용하게 되면 주름이 깊어질 수 있어요. 선크림을 날씨와 무관하게 항상 발라주세요. 자외선은 비가 오는 날에도 우리의 피부에 도달하며, 자외선은 피부의 콜라겐과 엘라스틴을 망가뜨려요. 보습제를 아침/저녁으로 최소 2회 이상 바르세요. 건조한 피부는 피부를 나이 들어 보이게 해요. 필링이나 때밀이를 할 때는 피부 상태를 확인하며 자극이 되지 않는 선에서 진행하세요. 사우나나 목욕은 너무 오래하지 마세요. 오히려 수분을 뺏겨 피부 장벽이 손상될 수 있어요.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 안티에이징과 건강한 라이프스타일로 이어질 거예요. 술/담배를 하지 마세요. 담배에 포함된 수많은 화학물질과 술의 알코올은 우리의 피부를 상하게 해요. 1주일에 최소 3회 이상 유산소 운동을 하세요. 운동은 순환을 도와 피부를 건강하게 해요. 하루에 7시간 이상 충분히 잠을 자세요. 우리의 피부는 잠을 자는 동안 회복해요. 올바른 수면 위생 환경을 지켜주세요. 균형 잡힌 식단을 유지하세요. 신선한 과일과 채소를 골고루 섭취해주세요. 건강한 식생활은 건강한 피부의 영양분이 됩니다.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '\'노화\'는 시간이 흘러가며 자연스럽게 신체의 기능이 점점 떨어지는 현상이에요',
//                     contents: '20대 중반부터 피부의 콜라겐 생성은 매년 약 1%씩 감소합니다. 노화에 따른 피부의 탄력 감소는 모두가 겪는 아주 자연스러운 현상이니 좌절하지 마세요. 노화의 증상들이 보다 더 깊어지기 전에 ‘안티에이징’ 관리를 일찍이 시작하면 노화의 속도를 효과적으로 늦출 수 있을 거에요. ‘노화’는 우리가 모두 겪는 자연스러운 신체의 변화임을 받아들이고, 개인의 피부에 따른 맞춤화된 시술과 ‘슬로우 에이징(slow-aging)’ 습관을 함께 실천해요!',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png'
//                 }               
//             ]
//         },
//         {
//             category: 'antiaging',
//             userType: 'B',
//             doctor: {
//                 doctorImage: '',
//                 title: "의료진과의 치료 상담을 적극적으로 추천합니다",
//                 isName: true,
//                 contents: "현재 노화의 증상들이 피부에 어느 정도 나타나 있는 상태로 보여요. 지금의 노화 단계를 개선하기 위해서는 보다 적극적인 시술이나 생활 습관 개선이 필요할 수 있습니다. 비대면 진료를 통해 어떠한 전문적인 치료들이 필요할지 상담해보세요!",
//                 modalButtonText: '홈케어 솔루션',
//                 modalContents: [
//                     {
//                         title: '스킨케어',
//                         contents: '우리의 나이를 완전히 되돌릴 수는 없지만, 노화의 속도를 천천히 하는 ‘슬로우 에이징(slow-aging)’은 솔닥과 함께 실천할 수 있어요. 솔닥이 제안하는 ‘슬로우에이징’ 습관을 하나씩 실천해보세요! 한 번에 모두를 실천하기는 어려워요. 솔닥과 함께 하나씩 개선해 나가요.무의식적으로 인상을 자주 쓰지 마세요. 표정 근육을 반복적으로 사용하게 되면 주름이 깊어질 수 있어요. 선크림을 날씨와 무관하게 항상 발라주세요. 자외선은 비가 오는 날에도 우리의 피부에 도달하며, 자외선은 피부의 콜라겐과 엘라스틴을 망가뜨려요. 보습제를 아침/저녁으로 최소 2회 이상 바르세요. 건조한 피부는 피부를 나이 들어 보이게 해요. 필링이나 때밀이를 할 때는 피부 상태를 확인하며 자극이 되지 않는 선에서 진행하세요. 사우나나 목욕은 너무 오래하지 마세요. 오히려 수분을 뺏겨 피부 장벽이 손상될 수 있어요.'
//                     },
//                     {
//                         title: '라이프스타일',
//                         contents: '스트레스를 줄일 수 있는 자신만의 방법을 찾는 걸 추천해요. 건강한 취미가 곧 안티에이징과 건강한 라이프스타일로 이어질 거예요. 술/담배를 하지 마세요. 담배에 포함된 수많은 화학물질과 술의 알코올은 우리의 피부를 상하게 해요. 1주일에 최소 3회 이상 유산소 운동을 하세요. 운동은 순환을 도와 피부를 건강하게 해요. 하루에 7시간 이상 충분히 잠을 자세요. 우리의 피부는 잠을 자는 동안 회복해요. 올바른 수면 위생 환경을 지켜주세요. 균형 잡힌 식단을 유지하세요. 신선한 과일과 채소를 골고루 섭취해주세요. 건강한 식생활은 건강한 피부의 영양분이 됩니다.'
//                     }
//                 ]
//             },
//             honest: [
//                 {
//                     title: '\'안티에이징\'은  현재의 건강한 상태를 잘 유지하고, 질병이나 문제를 예방하고 치료하는 모든 과정입니다',
//                     contents: '\'노화\'는 단순히 얼굴의 주름살과 쳐짐만을 의미하는 것이 아닙니다. 현재의 건강을 질병으로 이어지지 않도록 예방하는 것도 ‘안티에이징’입니다. ‘피부의 노화’는 때때로 ‘피부 질환’과 관련이 있을 수 있어 현재의 피부 상태는 보다 적극적인 관심과 전문적인 상담이 필요한 단계로 보입니다. ‘노화’는 우리가 모두 겪는 자연스러운 신체의 변화임을 받아들이고, 개인의 피부에 따른 맞춤화된 시술과 ‘슬로우 에이징(slow-aging)’ 습관을 함께 실천하세요.',
//                     addInfoType: AddInfoTypeEnum.IMAGE,
//                     imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png'
//                 }               
//             ]
//         }
//     ]
// })


// imgUrl: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/advice/dry_home_level2.png'