import styled from "styled-components";
import * as htmlToImage from 'html-to-image';
import { toPng, toJpeg, toBlob, toPixelData, toSvg } from 'html-to-image';

import {
    gradientState
} from '../../../store'

import {
    FelixTitlingLabel,
    ModalButton
} from '../../../components/atoms';
import { useRecoilState } from "recoil";


const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Section = styled.div`
    min-width: 1200px;
    height: 753px;
    border-radius: 50px;
    background-color: #fff;
    overflow: hidden;
    color: #000000;
    padding: 93px 52px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const CloseButton = styled.img`
    position: absolute;
    right: 50px;
    top: 61px;
    width: 30px;
    height: 30px;
    cursor: pointer;
`;


const Title = styled.div`
    font-weight: 700;
    font-size: 65px;
    line-height: 74px;
    font-size: ${(props) => props.theme.bgColor};
    margin-top: 30px;
`;

const SubTitle = styled.div`
    font-weight: 400;
    font-size: 35px;
    line-height: 43px;
    font-size: ${(props) => props.theme.bgColor};
    margin-top: 60px;
    margin-bottom: 153px;
`;

interface ModalProps {
    open: boolean;
    onCloseButton: () => void;
}

export function SaveModal({
    open,
    onCloseButton
}:ModalProps){

    const [ isGradient, setIsGradient ] = useRecoilState(gradientState);

    const htmlToImage = () => {
        toJpeg(document.getElementById('share-club-honest') as HTMLElement, { quality: 1 })
        .then(function (dataUrl: any) {
            var link = document.createElement('a');
            link.download = '클럽어니스트.jpg';
            link.href = dataUrl;
            link.click();
        });
    }

    return (
        <>
            {
                open ? (
                    <Container>
                        <Section>
                            <CloseButton src={require("../../../styles/assets/common/btn_close_black.png")} 
                                onClick={() => {
                                        setIsGradient(true);
                                        onCloseButton()
                                    }
                                }
                            />
                            <FelixTitlingLabel text="CLUB HONEST" fontSize="60"/>
                            <Title>서베이 결과를 이미지로 저장하세요</Title>
                            <SubTitle>아래 버튼을 누르면 다운로드가 시작됩니다</SubTitle>
                            <ModalButton text="이미지로 저장하기" onClick={() => {
                                setIsGradient(false);
                                htmlToImage();
                            }}/>
                        </Section>
                    </Container>
                ) : (
                    null
                )
            }
        </>
    );
}