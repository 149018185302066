import { useEffect, useRef } from "react";
import styled from "styled-components";

const Circle = styled.div`
    position: relative;
    width: 160px;
    height: 160px;
    border-radius: 50%;
    transition: 0.3s;
    
    span {
        background-color: #ffffff;
        position: absolute;
        top: 50%;
        left: 50%;
        width: 80px;
        height: 80px;
        border-radius: 50%;
        text-align: center;
        line-height: 80px;
        font-size: 20px;
        transform: translate(-50%, -50%);

    }
`;

export function DonutGraph(){

    const chart1 = useRef(null);
    const chartData = {percent: 80}

    useEffect(() => {
        makeChart(80, chart1);
    })

    let i = 1;
    const makeChart = (percent: number, className: any) => {
        let func1 = setInterval(function() {
            if( i < percent) {
                if(className.current){
                    colorFn(i, className, '#F5D042');
                    i++;
                } else {
                    return;
                }
            } else {
                clearInterval(func1);
            }
        }, 10);   
    }

    const colorFn = (i: number, className: any, color: string) => {
        className.current.style.background = "conic-gradient("+color+ " 0% " + i + "%, #dedede " +i+ "% 100%)";
    }

    return (
        <div>
             <Circle ref={chart1}>
                <span>80%</span>
            </Circle>
        </div>
       
    );
}