import { useEffect, useState } from "react";
import styled from "styled-components";
import axios from "axios";
import { leadingZeros } from "../../../utilities/Convert";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

`;

const Section = styled.div`
    width: 100%;
    height: 753px;
    border-radius: 15px;
    background-color: #fff;
    overflow: hidden;
    color: #000000;
    padding: 34px 15px;
    position: relative;
    display: flex;
    align-items: flex-start;
    justify-content: flex-start;
    flex-direction: column;
`;

const DayWrapper = styled.div`
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    justify-content:center;
    align-items: center;
    width: 100%;
    padding: 0 10px;
    margin-bottom: 54px;
`;

const DayButton = styled.div<{active: boolean}>`
    cursor: pointer;
    width: 63px;
    height: 63px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    border: ${(props) => props.active ? '1px solid #50A0E0' : ''};
    background-color: ${(props) => props.active ? 'rgba(80, 160, 224, 0.09)': ''};
`;

const Line = styled.div`
    border-bottom: 1px solid #EDEEF0;
    width: 100%;
    margin-bottom: 28px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-size: 16px;
    color: #1E1E1E;
    line-height: 24px;
    font-weight: 400;
    span {
        font-size: 18px;
        font-weight: 700;
        color: #50A0E0;
    }
    margin-bottom: 55px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #1E1E1E;
    margin-bottom: 28px;
`;

const CloseButton = styled.img`
    position: absolute;
    right: 21px;
    top: 37px;
    width: 15px;
    height: 15px;
    cursor: pointer;
`;

const TimeWrapper = styled.div`
    /* width: 100%; */
    white-space: nowrap;
    overflow-x: auto; 
    overflow-y: hidden;
    width: 100%;
    height: 60px;
`;

const SubmitButton = styled.div<{active: boolean}>`
    width: 95%;
    background: ${(props) => props.active ? '#1E1E1E' : '#E8EAEE'};
    color: ${(props) => props.active ? '#FFFFFF' : '#B0B0B0'};
    border-radius: 6px;
    height: 55px;
    position: absolute;
    bottom: 51px;
    left: 50%;
    transform: translate(-50%, 0);
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const TimeButton = styled.button<{state: boolean, active: boolean}>`
    width: 67px;
    height: 40px;
    background: ${(props) => props.state ? props.active ? '#EFF6FC' : '#FFFFFF' : '#EDEEF0'};
    border: ${(props) => props.state ? '1px solid #EDEEF0' : 'none'};
    color: ${(props) => props.state ? '#1E1E1E' : '#B0B0B0'};
    font-weight: ${(props) => props.active ? '700' : '500'};
    border-radius: 6px;
    display: inline-block;
    align-items: center;
    justify-content: center;
    margin-left: 10px;
    cursor: pointer;
`;

interface SlotDataType {
    active: boolean;
    time: string;
}

interface SelecDataType {
    year: string;
    month: string;
    dow: string
    day: string;
    slot: SlotDataType []
}

interface ScheduleDataType {
    time: string;
    slot: number;
    active: boolean;
}


// const datas = [
//     {time:"2022-08-03 16:40:00",slot:4,active:true},
//]

interface SelectorProps {
    onCloseButton: () => void;
    onSelectButton: (year: string, month: string, day: string, time: string) => void;
    session: string;
    datas: ScheduleDataType[]
}

export function Selector({
    onCloseButton,
    onSelectButton,
    datas,
    session
}: SelectorProps){
    
    const [selectData, setSelectData] = useState<SelecDataType[]>();
    const [ selectedDay, setSelectedDay] = useState(0);
    const [ selectedTime, setSelectedTime ] = useState(-1);
    const [ submitButtonActive, setSubmitButtonActive ] = useState<boolean>(false);

    const WEEKDAY = ['일', '월', '화', '수', '목', '금', '토'];

    const makeSelectData = async (datas: ScheduleDataType[]) => {
      
        let t:SelecDataType[] = [];
        let tt:SelecDataType = {year: '', month: '', dow: '', day: '', slot: []};
        let week = ''
    

        datas.sort(function(a,b){
            const year1 = a.time.slice(0, 4);
            const month1 =  Number(a.time.slice(5, 7)).toString();
            const day1 = Number(a.time.slice(8, 10)).toString();
            
            const hour1 = Number(a.time.slice(11, 13)).toString();
            const minutes1 = Number(a.time.slice(14, 16)).toString();

            let date1 = new Date(Number(year1), Number(month1) - 1, Number(day1), Number(hour1), Number(minutes1), 0);

            const year2 = b.time.slice(0, 4);
            const month2 =  Number(b.time.slice(5, 7)).toString();
            const day2 = Number(b.time.slice(8, 10)).toString();

            const hour2 = Number(b.time.slice(11, 13)).toString();
            const minutes2 = Number(b.time.slice(14, 16)).toString();

            let date2 = new Date(Number(year2), Number(month2) - 1, Number(day2), Number(hour2), Number(minutes2), 0);

            if(date1 > date2)
            {
                return 1;
            }           
            else
            {
                return -1;
            }
        })


        datas.map((data, index) => {
            // alert(JSON.stringify(data))
            let slot:SlotDataType = {time: '', active: false};
            const year = data.time.slice(0, 4);
            const month =  Number(data.time.slice(5, 7)).toString();
            const day = Number(data.time.slice(8, 10)).toString();
            
            let now = new Date(Number(year), Number(month) - 1, Number(day));
            // console.log(year + month + day)
            let tweek = WEEKDAY[now.getDay()];
            // console.log(tweek)
            slot.active = data.active;
            slot.time = data.time.slice(11, 16);

            if(week !== tweek)
            {
                if(week !==  '')
                {
                    t.push(tt); 
                }
                week = tweek;
                tt = {year: '', month: '', dow: '', day: '', slot: []};   
                tt.year = data.time.slice(0, 4);
                tt.month =  Number(data.time.slice(5, 7)).toString();
                tt.dow = week;
                tt.day = Number(data.time.slice(8, 10)).toString();
                tt.slot.push(slot)
            }
            else
            {
                if(tt.slot.length > 0)
                {     
                    const ttt = tt.slot.find(function isSameTime(element)  {
                        if(element.time === slot.time)  {                            
                            return true;
                        }
                    })
                    
                }
                tt.slot.push(slot);
            }         
        })
        t.push(tt); 
        // alert(JSON.stringify(t))
        setSelectData(t);
    }

    // console.log(JSON.stringify(datas))

    const checkAPI = async (selectedTime: string) => {
        const url = `/v1/survey/booking/check/`;
        axios.defaults.withCredentials = true;

        try {
            const data = {
                session_id: session,
                request: {
                    department: "피부",
                    department_id: 1,
                    time: selectedTime
                }
            }

            const response = await axios.post(url, data);
            // console.log(response.data)
        }
        catch (err)
        {

        }
    }

    const checkTime = async (tselectedDay: number, tselectedTime: number) => {
        
        const year = (selectData as SelecDataType [])[tselectedDay].year;
        const month = leadingZeros((selectData as SelecDataType [])[tselectedDay].month, 2);
        const day = leadingZeros((selectData as SelecDataType [])[tselectedDay].day, 2);
        const time = (selectData as SelecDataType [])[tselectedDay].slot[tselectedTime].time;

        await checkAPI(year + "-" + month +"-" + day+ " "+ time);
    }

    const selectDay = (day:number) => {
        setSelectedDay(day);
    }

    

    const submit = () => {
        const year = (selectData as SelecDataType [])[selectedDay].year;
        const month = leadingZeros((selectData as SelecDataType [])[selectedDay].month, 2);
        const day = leadingZeros((selectData as SelecDataType [])[selectedDay].day, 2);
        const time = (selectData as SelecDataType [])[selectedDay].slot[selectedTime].time;

        onSelectButton(year, month, day, time+":00");
    }


    useEffect(() => {
        const loadData = async () => {
            // alert(JSON.stringify(datas))
            await makeSelectData(datas);
            // console.log("중간")
        }        
        loadData();
        // console.log("완료")
    }, [])

    return (
        <Container>
            <Section>
                <CloseButton src={require("../../../styles/assets/common/btn_close_black.png")} 
                    onClick={() => {
                        onCloseButton();
                    }}
                />
                <Title><span>진료 시간</span>을 선택해주세요</Title>
                <Label>날짜선택</Label>
                <DayWrapper>
                    {                  
                        selectData?.map((select, index) => {
                            return(
                                <DayButton 
                                    key={index} 
                                    active={selectedDay === index ? true : false}
                                    onClick={() => {
                                        selectDay(index);
                                    }}
                                ><span>{select.dow}</span><span>{select.day}</span></DayButton>
                            );
                        })
                    }
                </DayWrapper>
                <Line/>
                <Label>시간 선택</Label>
                <TimeWrapper>
                    {
                        selectData &&
                        selectData[selectedDay].slot.map((time, index) => {
                            return(
                                <TimeButton 
                                    onClick={() => {
                                        if(time.active)
                                        {
                                            setSelectedTime(index);
                                            setSubmitButtonActive(true);
                                            checkTime(selectedDay, index);
                                        }
                                    }}
                                    state={time.active} 
                                    active={selectedTime === index ? true : false}
                                    key={index}>{time.time}
                                </TimeButton>
                            );
                        })
                    }
                </TimeWrapper>
                <SubmitButton
                    active={submitButtonActive}
                    onClick={() => {
                        if(submitButtonActive)
                        {
                            submit();
                        }
                    }}
                >위 날짜와 시간으로 선택할게요!</SubmitButton>
            </Section>
        </Container>
    );
}