// 결과 가져오기 
// 결과 로직  (level 계산)
// 타입, 카테고리별 결과 데이터 정리 
// page 1: 결과 (level, 내가 한 서베이)
// page 2: advice
// page 3: solution
// page 4: share
import styled from "styled-components";
import qs from 'qs';
import Booking from "../booking";

import { 
    useRecoilState,
    useRecoilValue
} from "recoil";

import { 
    useEffect, 
    useState 
} from "react";

import { 
    useNavigate,
    useParams, 
    useLocation
} from "react-router-dom";

import { 
    ConditionTypeEnum,
    ForceMultiResultType,
    ResultCalcTypeEnum,
    ResultTypeEnum,
    UserAnswerType,
    AnswerType,
    ButtonTypeEnum,
    UserStateType,
    SurveyStateType,
    SurveyAnswerType,
    LoginStateType
} from "../../../types";

import {
    answerState,
    adviceState,
    solutionState,
    levelState,
    userState,
    lifeState,
    LevelStateType,
    AdviceType,
    loginState,
    surveyAtom,
    resultLoadingState
} from '../../../store'

import { Result } from "./Result";
import { Advice } from "./Advice";
import { Solution } from "./Solution";
import { Share } from "./Share";
import { ResultLoading } from "./ResultLoading";
import axios from "axios";

const Container = styled.div`
    width: 100%;
`;

export function SurveyResult(){
    // 부여된 survey id를 사용해서 db에서 정보를 읽는다. 
    const location = useLocation();
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const {type, category} = useParams();

    const [ login, setLogin ] = useRecoilState(loginState);
    const [ isLoading, setIsLoading ] = useState(true);

    const [ user, setUser ] = useRecoilState(userState);
    const [ survey, setSurvey ] = useRecoilState(surveyAtom);
    const [ life, setLife ] = useRecoilState(lifeState);

    let lUser = user;
    let lSurvey = survey;
    let lLife = life;

    const [ answers, setAnswers ] = useRecoilState(answerState);

    const level = useRecoilValue(levelState);
    const [ selectLevel, setSelectLevel ] = useState<LevelStateType>();
    const [ selectedType, setSelectedType ] = useState<string>('A');
    const advice = useRecoilValue(adviceState);
    const [ selectedAdvice, setSelectedAdvice ] = useState<AdviceType>();
    const solution = useRecoilValue(solutionState);
   
    const resultLoading = useRecoilValue(resultLoadingState);

    const navigate = useNavigate();


    const calUserType = (lsurvey: SurveyStateType) => {
        // 사용자 타입 분석 

        let levelACount= 0;
        let levelBCount = 0;
        let levelCCount = 0;

        lsurvey.question.map((question, indext) => {
            if(question.resultCalcType === ResultCalcTypeEnum.COUNT)
            {
                question.buttons.map((button, index2) => {
                    if(button.activate)
                    {
                        button.resultType.map((result, indext3) => {
                            if(result === ResultTypeEnum.NA)
                            {
                                
                            }
                            else if(result === ResultTypeEnum.A)
                            {
                                ++levelACount;
                            }
                            else if(result === ResultTypeEnum.B)
                            {
                                ++levelBCount;
                            }
                            else if(result === ResultTypeEnum.C)
                            {
                                ++levelCCount;
                            }
                            else if(result === ResultTypeEnum.FORCE_A)
                            {
                                levelACount = levelACount + 999;
                            }
                            else if(result === ResultTypeEnum.FORCE_B)
                            {
                                levelBCount = levelBCount + 999;
                            }
                            else if(result === ResultTypeEnum.FORCE_C)
                            {
                                levelCCount = levelCCount + 999;
                            }
                        })
                    }
                })
            }    
            else if(question.resultCalcType === ResultCalcTypeEnum.FORCE_ONE_RESULT)
            {
                // 결과는 하나 
                if((question.forceMultiResultCondition as ForceMultiResultType[])[0].condition === ConditionTypeEnum.GREATER)
                {
                    let count = 0;
                    question.buttons.map((button, index) => {
                        if(button.activate)
                        {
                            if(button.resultType[0] === (question.forceMultiResultCondition as ForceMultiResultType[])[0].resultType)
                            {
                                count++;
                            }   
                        }
                                             
                    })

                    if(count > (question.forceMultiResultCondition as ForceMultiResultType[])[0].startValue)
                    {
                        if((question.forceMultiResultCondition as ForceMultiResultType[])[0].resultType === ResultTypeEnum.FORCE_A)
                        {
                            levelACount = levelACount + 999;
                        }
                        else if((question.forceMultiResultCondition as ForceMultiResultType[])[0].resultType === ResultTypeEnum.FORCE_B)
                        {
                            levelBCount = levelBCount + 999;
                        }
                        else if((question.forceMultiResultCondition as ForceMultiResultType[])[0].resultType === ResultTypeEnum.FORCE_C)
                        {
                            levelCCount = levelCCount + 999;
                        }
                    }
                }     
            }
            else if(question.resultCalcType === ResultCalcTypeEnum.FORCE_MANY_RESULT)
            {
                // 결과는 하나 
                //FORCE_MANY_RESULT count를 센다 
                //선택값중 force 값이 있으면 선택 
                let forceManyCount = 0;
                question.buttons.map((button, index) => {
                    button.resultType.map((type, index) => {
                        if(button.activate)
                        {
                            if(type === ResultTypeEnum.FORCE_A)
                            {
                                levelACount = levelACount + 999;
                            }
                            else if(type === ResultTypeEnum.FORCE_B)
                            {
                                levelBCount = levelBCount + 999;
                            }
                            else if(type === ResultTypeEnum.FORCE_C)
                            {
                                levelCCount = levelCCount + 999;
                            }
                            else if(type === ResultTypeEnum.FORCE_MANY_RESULT)
                            {
                                forceManyCount++;
                            }
                        }                     
                    })
                })
                question.forceMultiResultCondition?.map((resultCondition, index) => {
                    if(resultCondition.condition === ConditionTypeEnum.RANGE)
                    {
                        if(resultCondition.startValue < forceManyCount && resultCondition.endValue as number > forceManyCount )
                        {
                            if(resultCondition.resultType === ResultTypeEnum.FORCE_A)
                            {
                                levelACount = levelACount + 999;
                            }
                            else if(resultCondition.resultType === ResultTypeEnum.FORCE_B)
                            {
                                levelBCount = levelBCount + 999;
                            }
                            else if(resultCondition.resultType === ResultTypeEnum.FORCE_C)
                            {
                                levelCCount = levelCCount + 999;
                            }
                        }
                    }
                })
            }
        })

        // console.log("A: " + levelACount + " B: " + levelBCount + " C: " + levelCCount)
        let types = [{type: 'A', count: levelACount}, {type: 'B', count: levelBCount}, {type: 'C', count: levelCCount}];
        const userType = types.reduce((prev, current) => (prev.count > current.count) ? prev : current);
        //동순위가 나올경우? 
        //type
        setSelectedType(userType.type);

        const returnValue = level.find(function(data){ return data.category === category});
        //level
        setSelectLevel(returnValue);
        const findAdvice = advice.find(function(data){ return data.category === category && data.userType === userType.type});
        //advice
        setSelectedAdvice(findAdvice);

    }

    const setUserAnswer = (lsurvey: SurveyStateType) => {
        // answer 채우기 
        let surveyAnswers:UserAnswerType[] = [];
        //profile
        let profileAnswer:UserAnswerType = {
            category: 'PROFILE',
            answers: [
                {
                    question: '성별',
                    answer: lUser.gender
                },
                {
                    question: '생년월일',
                    answer: lUser.birth
                },
                {
                    question: '피부고민',
                    answer: lUser.select
                }
            ]
        }
        surveyAnswers.push(profileAnswer);

        // survey
        let surveyAnswerList:AnswerType[] = [];

        lsurvey.question.map((question, qindex) => {
            if(question.resultView)
            {
                if(question.buttonType === ButtonTypeEnum.TEXT_SINGLE)
                {
                    question.buttons.map((button, bindex) => {
                        if(button.activate && button.resultView)
                        {
                            let tanswer:AnswerType = {
                                question: question.resultViewAltMsg as string,
                                answer: button.resultViewAltMsg as string
                            }
                            
                            surveyAnswerList.push(tanswer);
                        }                    
                    })
                }
                else if(question.buttonType === ButtonTypeEnum.TEXT_MULTI)
                {
                    let count = 0;
                    let first = '';

                    question.buttons.map((button, bindex) => {
                        if(button.activate && button.resultView && button.multiSelect)
                        {
                            count++;
                            if(first === '')
                            {
                                first = button.resultViewAltMsg as string;
                            }
                        }    
                        else if(button.activate && button.resultView && !button.multiSelect)
                        {
                            count++;
                            first = button.resultViewAltMsg as string;
                        }
                    })

                    if(count > 1)
                    {
                        let tanswer:AnswerType = {
                            question: question.resultViewAltMsg as string,
                            answer: first + " 등 " + count + "개"
                        }
                        surveyAnswerList.push(tanswer);
                    }
                    else if(count === 1)
                    {
                        let tanswer:AnswerType = {
                            question: question.resultViewAltMsg as string,
                            answer: first
                        }
                        surveyAnswerList.push(tanswer);
                    }    
                }
                else if(question.buttonType === ButtonTypeEnum.RANGE)
                {
                    let selectScore = '';
                    let totalScore = question.buttons[question.buttons.length - 1].resultViewAltMsg as string;

                    question.buttons.map((button, bindex) => {
                        if(button.activate && button.resultView)
                        {
                            selectScore = button.resultViewAltMsg as string;

                            let tanswer:AnswerType = {
                                question: question.resultViewAltMsg as string,
                                answer: selectScore + '점/'+totalScore+"점"
                            }
                            surveyAnswerList.push(tanswer);                            
                        }
                    })                    
                }
            }
        })

        if(surveyAnswerList.length > 0)
        {
            let surveyAnswer:UserAnswerType = {
                category: 'SKINCARE',
                answers: surveyAnswerList
            }
            surveyAnswers.push(surveyAnswer);
        }
        
        //lifestyle
        let lifeAnswerList:AnswerType[] = [];
        
        lLife.question.map((question) => {
            if(question.resultView)
            {
                if(question.buttonType === ButtonTypeEnum.TEXT_SINGLE)
                {
                    question.buttons.map((button, bindex) => {
                        if(button.activate && button.resultView)
                        {
                            let tanswer:AnswerType = {
                                question: question.resultViewAltMsg as string,
                                answer: button.resultViewAltMsg as string
                            }
                            
                            lifeAnswerList.push(tanswer);
                        }                    
                    })
                }
                else if(question.buttonType === ButtonTypeEnum.TEXT_MULTI)
                {
                    let count = 0;
                    let first = '';

                    question.buttons.map((button, bindex) => {
                        if(button.activate && button.resultView && button.multiSelect)
                        {
                            count++;
                            if(first === '')
                            {
                                first = button.resultViewAltMsg as string;
                            }
                        }    
                        else if(button.activate && button.resultView && !button.multiSelect)
                        {
                            count++;
                            first = button.resultViewAltMsg as string;
                        }
                    })

                    if(count > 1)
                    {
                        let tanswer:AnswerType = {
                            question: question.resultViewAltMsg as string,
                            answer: first + " 등 " + count + "개"
                        }
                        lifeAnswerList.push(tanswer);
                    }
                    else if(count === 1)
                    {
                        let tanswer:AnswerType = {
                            question: question.resultViewAltMsg as string,
                            answer: first
                        }
                        lifeAnswerList.push(tanswer);
                    }    
                }
                else if(question.buttonType === ButtonTypeEnum.RANGE)
                {
                    let selectScore = '';
                    let totalScore = question.buttons[question.buttons.length - 1].resultViewAltMsg as string;

                    question.buttons.map((button, bindex) => {
                        if(button.activate && button.resultView)
                        {
                            selectScore = button.resultViewAltMsg as string;

                            let tanswer:AnswerType = {
                                question: question.resultViewAltMsg as string,
                                answer: selectScore + '점/'+totalScore+"점"
                            }
                            lifeAnswerList.push(tanswer);                            
                        }
                    })                    
                }
            }
        })

        if(lifeAnswerList.length > 0)
        {
            let lifeAnswer:UserAnswerType = {
                category: 'LIFESTYLE',
                answers: lifeAnswerList
            }
            surveyAnswers.push(lifeAnswer);
        }
        

        setAnswers(surveyAnswers);
    }

    //data loading 
    const calAge = (birth: string) => {
        const year = birth.slice(0, 4);
        const month = birth.slice(5, 7);
        const day = birth.slice(8, 10);

        const today = new Date();
        const birthDate = new Date(Number(year), Number(month), Number(day)); // 2000년 8월 10일
        let age = today.getFullYear() - birthDate.getFullYear() + 1;   

        return {age: age, year: year, month: month, day: day};
        // console.log(year + month + day);
    }
    
    const parsingUserAnswerData = (data: SurveyAnswerType[]) => {
        let localUser:UserStateType = {
            birth: '',
            gender: '',
            age: 0,
            year: '',
            month: '',
            day: '',
            select: ''
        }
        data[0].question.map((q) => {
            if(q.questionIndex === 0)
            {
                q.answer.map((b) => {
                    localUser.birth = b.answerText;
                    const data = calAge(localUser.birth)
                    localUser.year = data.year;
                    localUser.month = data.month;
                    localUser.day = data.day;
                    localUser.age = data.age;
                })                
            }
            else if(q.questionIndex === 1)
            {
                q.answer.map((b) => {
                    localUser.gender = b.answerText;
                })
            }
            else if(q.questionIndex === 2)
            {
                q.answer.map((b) => {
                    localUser.select = b.answerText;
                })
            }
        })

        lUser = localUser;
        setUser(lUser);
    }

    const getUserAnswerFromServer = async (surveyId: string) => {
        try {
            axios.defaults.withCredentials = true;
            const url = `/v1/answer/${surveyId}/user`;
            const response = await axios.get(url)
            // parsing
            // console.log("userData Response true");
            parsingUserAnswerData(response.data);

            return true;
        }
        catch(err)
        {
            // console.log(err);
            return false;
        }
    }

    // 답변 세팅 
    const settingAnswer = (survey: SurveyStateType, answer: SurveyAnswerType) => {
        let tsurvey = survey;

        answer.question.map(q => {
            q.answer.map(a => {
                tsurvey.question[Number(q.questionIndex)].buttons[a.buttonIndex].activate = true;
                tsurvey.question[Number(q.questionIndex)].buttons[a.buttonIndex].text = a.answerText;
            })
            
        })

        return tsurvey;
    }

    const getSurveyTemplate = async (template: string) => {
        const url = `/v1/template/pc_${template}/`;
        axios.defaults.withCredentials = true;
        try{
            const response = await axios.get(url);
            const tsurvey:SurveyStateType = JSON.parse(response.data.template);
            return tsurvey;
        }
        catch(err)
        {
            return null;
        }

    } 

    const getSurveyAnswerFromServer = async (surveyId: string) => {
        try {
            axios.defaults.withCredentials = true;
            const url = `/v1/answer/${surveyId}/${category}`;
            const response = await axios.get(url)
            let tSurvey = await getSurveyTemplate(category as string);
            if(tSurvey)
            {
                tSurvey = settingAnswer(tSurvey, response.data[0]);
                lSurvey = tSurvey;
                setSurvey(lSurvey);
                return true;
            }
            else
            {
                return false;
            }
            // parsing
        }
        catch(err)
        {
            // console.log(err);
            return false;
        }
    }

    const getLifeStyleAnswerFromServer = async (surveyId: string) => {
        try {
            axios.defaults.withCredentials = true;
            const url = `/v1/answer/${surveyId}/lifestyle`;
            const response = await axios.get(url)
            // parsing
            let tSurvey = await getSurveyTemplate('lifestyle');
            if(tSurvey)
            {
                tSurvey = settingAnswer(tSurvey, response.data[0]);
                lLife = tSurvey;
                setLife(lLife);
                return true;
            }
            else
            {
                return false;
            }

        }
        catch(err)
        {
            // console.log(err);
            return false;
        }
    }

    const connectKakaoAndSurvey = (key: string, kakao_access_token: string) => {
        //surveyId, 
        //kakao
        axios({
            method: 'post',                 
            url: '/v1/survey/connect/',
            withCredentials: true,
            data: {
                kakao_access_token: kakao_access_token,
                key: key
            }

        }).then(async (response) => {


        }).catch((err) => {

            let tlogin: LoginStateType = JSON.parse(JSON.stringify(login));
            tlogin.accessToken = '';          
            tlogin.isLogin = false;
            tlogin.key = '';
            tlogin.userName = '';
            setLogin(tlogin);
        })
    }

    const getDataFromServer = async (surveyId: string) => {
        // axios get
        const response_user = await getUserAnswerFromServer(surveyId);
        const response_survey = await getSurveyAnswerFromServer(surveyId);
        const response_life = await getLifeStyleAnswerFromServer(surveyId);

        if(response_user && response_survey && response_life)
        {
            //login 이라면 결과와 kakao 아이디 연결 
            if(login.isLogin)
            {
                // localstorage 삭제 
                connectKakaoAndSurvey(query.surveyId as string, login.accessToken);
            }

            calUserType(lSurvey);
            setUserAnswer(lSurvey); 

            //loading 완료
            if(!resultLoading)
            {
                setIsLoading(false);
            }
        }
        else
        {
            alert("결과를 확인할수없습니다")
            navigate(`/skin/start/init`)
            return;
        }
    }


    // 결과 분석     
    useEffect(() => {

        const surveyId = query.surveyId;
        
        if(surveyId === undefined)
        {
            alert("잘못된 경로입니다.")    
            navigate(`/skin/start/init`)
            return;
        }
        else
        {
            getDataFromServer(surveyId as string);
            if(resultLoading)
            {          
                setTimeout(() => {                
                    setIsLoading(false);
                    // AnalyzeData();           
                }, 5000);
            }           
            // loadLocalStorage();
            
        }

    },[])
   
    return (
        <Container>    
            {
                isLoading ? (
                    <ResultLoading />
                ):
                (
                    <>
                        <div id="share-club-honest">  
                            <Result selectType={selectedType} level={selectLevel as LevelStateType} answer={answers} />
                            <Advice advice={selectedAdvice as AdviceType} />
                        </div>  
                        <Solution solutions={solution}/>
                        <Share /> 
                    </>                    
                )
            }
        </Container>
    );
}