import { atom } from "recoil";
import {  
    SurveyStateType
} from '../types'

export const lastSelectedLifeIndexState = atom<number[]>({
    key: 'lastSelectedLifeIndexState',
    default: []
})

export const lifeState = atom<SurveyStateType>({
    key: 'liftState',
    default: undefined
});

// export const lifeState = atom<SurveyStateType>({
//     key: 'liftState',
//     default: {
//         type: 'LIFESTYLE',
//         category: 'lifestyle',
//         question: [
//             {
//                 questionTitle: '임신 또는 모유수유의 가능성이 있으신가요',
//                 endLetter: '?',
//                 selectedButtonIndex: [],
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 jump: JumpTypeEnum.NOP,
//                 order: 1,
//                 grid: 1,  
//                 viewConditionType: ConditionTypeEnum.EQUAL,  // equal, lessthann, greaterthan
//                 viewConditionValue: "여자",  //target 비교 값 
//                 viewConditionTarget: TargetTypeEnum.GENDER,
//                 resultView: true,
//                 resultViewAltMsg: '임신 ・ 모유수유 가능성',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: false,
//                 buttons: [
//                     {
//                         text: '임신 중입니다',
//                         activate: false,                
//                         isInput: false,       
//                         width: "600",
//                         nextQuestionIndex: 0,  //conditional jump의 경우 넘어가는 질문 번호
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '임신중',
//                         multiSelect: false
//                     },
//                     {
//                         text: '모유수유 중입니다',
//                         activate: false,                
//                         isInput: false,       
//                         width: "600",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '모유수유중',
//                         multiSelect: false
//                     },
//                     {
//                         text: '임신 계획 중입니다',
//                         activate: false,                
//                         isInput: false,       
//                         width: "600",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '임신 계획중',
//                         multiSelect: false
//                     },
//                     {
//                         text: '해당사항 없습니다',
//                         activate: false,                
//                         isInput: false,       
//                         width: "600",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '해당사항 없음',
//                         multiSelect: false
//                     }
//                 ]
//             }, // q1
//             {
//                 questionTitle: '피임약을 복용하는 중이신가요',
//                 endLetter: '?',
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.NOP,
//                 order: 2,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.EQUAL,  // equal, lessthann, greaterthan
//                 viewConditionValue: "여자",
//                 viewConditionTarget: TargetTypeEnum.GENDER,
//                 resultView: true,
//                 resultViewAltMsg: '피임약 복용 여부',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: false,
//                 buttons: [
//                     {
//                         text: '네',
//                         activate: false,                
//                         isInput: false,       
//                         width: "500",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '네',
//                         multiSelect: false
//                     },
//                     {
//                         text: '아니요',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '아니요',
//                         multiSelect: false
//                     }                   
//                 ]
//             }, // q2
//             {
//                 questionTitle: '스테로이드성 약물을 복용한 적 있으신가요',
//                 endLetter: '?',
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.CONDITIONAL_JUMP,
//                 order: 3,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                 viewConditionValue: 0,
//                 viewConditionTarget: TargetTypeEnum.NOP,
//                 resultView: true,
//                 resultViewAltMsg: '스테로이드성 약물 복약 경험',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: false,
//                 buttons: [
//                     {
//                         text: '네',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 3,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '네',
//                         multiSelect: false
//                     },
//                     {
//                         text: '아니요',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 4,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '아니요',
//                         multiSelect: false
//                     },
//                     {                        
//                         text: '잘 모르겠어요',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 4,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '잘 모르겠어요',
//                         multiSelect: false
//                     }                   
//                 ]
//             },  //q3
//             {
//                 questionTitle: '복약한 스테로이드성 약물의 종류와 사용 기간을 알려주세요',
//                 endLetter: '.',
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.NOP,
//                 order: 4,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                 viewConditionValue: 0,
//                 viewConditionTarget: TargetTypeEnum.NOP,
//                 resultView: false,
//                 resultViewAltMsg: '',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: false,
//                 buttons: [
//                     {
//                         text: '',
//                         activate: false,
//                         isInput: true,
//                         width: "1000",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: false,
//                         resultViewAltMsg: '',
//                         multiSelect: false
//                     }                
//                 ]
//             },  //q4
//             {
//                 questionTitle: '알러지 질환을 갖고 계신가요',
//                 endLetter: '?',
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.CONDITIONAL_JUMP,
//                 order: 5,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                 viewConditionValue: 0,
//                 viewConditionTarget: TargetTypeEnum.NOP,
//                 resultView: true,
//                 resultViewAltMsg: '알러지 질환 여부',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: false,
//                 buttons: [
//                     {
//                         text: '네',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 5,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType:[ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '있음',
//                         multiSelect: false
//                     },
//                     {
//                         text: '아니요',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 6,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '없음',
//                         multiSelect: false
//                     },
//                     {
//                         text: '잘 모르겠어요',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 6,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '잘 모르겠어요',
//                         multiSelect: false
//                     }                  
//                 ]
//             }, //
//             {
//                 questionTitle: '알러지 질환에 대해 알려주세요',
//                 endLetter: '.',             
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.NOP,
//                 order: 6,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                 viewConditionValue: 0,
//                 viewConditionTarget: TargetTypeEnum.NOP,
//                 resultView: false,
//                 resultViewAltMsg: '',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: false,
//                 buttons: [
//                     {
//                         text: '',
//                         activate: false,
//                         isInput: true,
//                         width: "1000",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: false,
//                         resultViewAltMsg: '',
//                         multiSelect: false
//                     }                 
//                 ]
//             }, // q5
//             {
//                 questionTitle: '진단 받은 질환이나 질병이 있나요',
//                 endLetter: '?',             
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.CONDITIONAL_JUMP,
//                 order: 7,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                 viewConditionValue: 0,
//                 viewConditionTarget: TargetTypeEnum.NOP,
//                 resultView: true,
//                 resultViewAltMsg: '진단 받은 질병',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: false,
//                 buttons: [
//                     {
//                         text: '네',
//                         activate: false,
//                         isInput: false,
//                         width: "400",
//                         nextQuestionIndex: 7,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '있음',
//                         multiSelect: false
//                     },
//                     {
//                         text: '아니요',
//                         activate: false,
//                         isInput: false,
//                         width: "400",
//                         nextQuestionIndex: 8,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '없음',
//                         multiSelect: false
//                     } ,
//                     {
//                         text: '잘 모르겠어요',
//                         activate: false,
//                         isInput: false,
//                         width: "400",
//                         nextQuestionIndex: 8,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '잘 모르겠어요',
//                         multiSelect: false
//                     }              
//                 ]
//             }, // q7
//             {
//                 questionTitle: '진단 받은 질환 또는 질병을 알려주세요',
//                 endLetter: '.',             
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.NOP,
//                 order: 8,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                 viewConditionValue: 0,
//                 viewConditionTarget: TargetTypeEnum.NOP,
//                 resultView: false,
//                 resultViewAltMsg: '',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: false,
//                 buttons: [
//                     {
//                         text: '',
//                         activate: false,
//                         isInput: true,
//                         width: "1000",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: false,
//                         resultViewAltMsg: '',
//                         multiSelect: false
//                     }                 
//                 ]
//             }, // q8
//             {
//                 questionTitle: '현재 복용중인 약이 있나요',
//                 endLetter: '?',       
//                 questionSubTitle: '처방약, 일반의약품 모두 포함',  
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.CONDITIONAL_JUMP,
//                 order: 9,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                 viewConditionValue: 0,
//                 viewConditionTarget: TargetTypeEnum.NOP,
//                 resultView: false,
//                 resultViewAltMsg: '',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: false,
//                 buttons: [
//                     {
//                         text: '네',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 9,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: false,
//                         resultViewAltMsg: '',
//                         multiSelect: false
//                     },
//                     {
//                         text: '아니요',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 10,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: false,
//                         resultViewAltMsg: '',
//                         multiSelect: false
//                     } ,
//                     {
//                         text: '잘 모르겠어요',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 10,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: false,
//                         resultViewAltMsg: '',
//                         multiSelect: false
//                     }              
//                 ]
//             }, //q9
//             {
//                 questionTitle: '복용 중인 약을 알려주세요',
//                 endLetter: '.',             
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.NOP,
//                 order: 10,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                 viewConditionValue: 0,
//                 viewConditionTarget: TargetTypeEnum.NOP,
//                 resultView: false,
//                 resultViewAltMsg: '',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: false,
//                 buttons: [
//                     {
//                         text: '',
//                         activate: false,
//                         isInput: true,
//                         width: "1000",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: false,
//                         resultViewAltMsg: '',
//                         multiSelect: false
//                     }                 
//                 ]
//             },
//             {
//                 questionTitle: '술과 담배를 하나요',
//                 endLetter: '?',       
//                 buttonType: ButtonTypeEnum.TEXT_MULTI,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.NOP,
//                 order: 11,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                 viewConditionValue: 0,
//                 viewConditionTarget: TargetTypeEnum.NOP,
//                 resultView: true,
//                 resultViewAltMsg: '음주 흡연 여부',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: false,
//                 buttons: [
//                     {
//                         text: '술',
//                         activate: false,
//                         isInput: false,
//                         width: "400",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '술',
//                         multiSelect: true
//                     },
//                     {
//                         text: '담배',
//                         activate: false,
//                         isInput: false,
//                         width: "400",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '담배',
//                         multiSelect: true
//                     } ,
//                     {
//                         text: '모두 하지 않아요',
//                         activate: false,
//                         isInput: false,
//                         width: "400",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '모두 하지 않아요',
//                         multiSelect: false
//                     }              
//                 ]
//             }, //q 11
//             {
//                 questionTitle: '규칙적이고 균형잡힌 식사를 하는 편인가요',
//                 endLetter: '?',       
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.NOP,
//                 order: 12,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                 viewConditionValue: 0,
//                 viewConditionTarget: TargetTypeEnum.NOP,
//                 resultView: false,
//                 resultViewAltMsg: '',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: false,
//                 buttons: [
//                     {
//                         text: '네',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType:[ ResultTypeEnum.NA ],
//                         resultView: false,
//                         resultViewAltMsg: '',
//                         multiSelect: false
//                     },
//                     {
//                         text: '아니요',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: false,
//                         resultViewAltMsg: '',
//                         multiSelect: false
//                     } ,
//                     {
//                         text: '모르겠어요',
//                         activate: false,
//                         isInput: false,
//                         width: "500",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: false,
//                         resultViewAltMsg: '',
//                         multiSelect: false
//                     }              
//                 ]
//             }, //q12
//             {
//                 questionTitle: '최근 수술을 하셨거나, 병원 진료를 본 적이 있으신가요',
//                 endLetter: '?',       
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.CONDITIONAL_JUMP,
//                 order: 13,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                 viewConditionValue: 0,
//                 viewConditionTarget: TargetTypeEnum.NOP,
//                 resultView: true,
//                 resultViewAltMsg: '최근 시술 또는 수술 여부',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: true,
//                 buttons: [
//                     {
//                         text: '네',
//                         activate: false,
//                         isInput: false,
//                         width: "200",
//                         nextQuestionIndex: 13,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '있음',
//                         multiSelect: false
//                     },
//                     {
//                         text: '아니요',
//                         activate: false,
//                         isInput: false,
//                         width: "200",
//                         nextQuestionIndex: 14,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: true,
//                         resultViewAltMsg: '없음',
//                         multiSelect: false
//                     }                  
//                 ]
//             },  // q13
//             {
//                 questionTitle: '최근 진행한 수술, 병원 진료에 대해 자세히 알려주세요',
//                 endLetter: '.',             
//                 buttonType: ButtonTypeEnum.TEXT_SINGLE,
//                 selectedButtonIndex: [],
//                 jump: JumpTypeEnum.NOP,
//                 order: 14,
//                 grid: 1,
//                 viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                 viewConditionValue: 0,
//                 viewConditionTarget: TargetTypeEnum.NOP,
//                 resultView: false,
//                 resultViewAltMsg: '',
//                 resultCalcType: ResultCalcTypeEnum.COUNT,
//                 forceMultiResultCondition: [],
//                 isLast: true,
//                 buttons: [
//                     {
//                         text: '',
//                         activate: false,
//                         isInput: true,
//                         width: "1000",
//                         nextQuestionIndex: 0,
//                         viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
//                         viewConditionValue: 0,
//                         viewConditionTarget: TargetTypeEnum.NOP,
//                         resultType: [ ResultTypeEnum.NA ],
//                         resultView: false,
//                         resultViewAltMsg: '',
//                         multiSelect: false
//                     }                 
//                 ]
//             }
//         ]     // q list
//     } // survey
// });