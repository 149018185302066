import axios from "axios";
import { useEffect, useRef, useState } from "react";
import { useParams } from "react-router-dom";
import styled from "styled-components";
import API from '../../apis/SurveyApi'
import { motion } from "framer-motion";

import {
    SurveyStateType,
    UserStateType,
    SurveyAnswerType,
    DoctorViewType,
    QAType
} from '../../types'

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    padding: 10px;
    min-width: 100vw;
    max-width: 100vw;
    height: 100%;
`;

const Wrapper = styled.div<{gap: string}>`
    display: grid;
    grid-template-columns: 1fr;
    width: 100%;
    gap: ${(props) => props.gap}px;
`;

const CategoryButton = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #1E1E1E;
    cursor: pointer;
    height: 35px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
`;

const TitleBar = styled(motion.div)`
    border: 1px solid #D8D8D8;
    border-radius: 6px;
    height: 35px;
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    color: #1E1E1E;
    padding-left: 15px;
    margin-bottom: 10px;
    cursor: pointer;
    position: relative;
`;

const MoreButton = styled(motion.img)`
    position: absolute;
    right: 15px;
`;

const Contents = styled(motion.div)`
    width: 100%;
    background-color: #F0F0F0;
    height: 0; 
    padding: 0 12px;
    padding-right: 0;
    overflow: hidden;
 
`;

const InnerContents = styled.div`
    max-height: 200px;
    width: 100%;
    overflow-y: scroll;
    display: grid;
    grid-template-columns: 1fr;
    gap: 10px;
`;

const Q = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 12px;
    line-height: 14px;
    color: #1E1E1E;
`;

const A = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: #1E1E1E;
`;

const variants = {
    start: {
        height: '0',
        paddingRight: '0'
    },
    end: {
        height: 'auto',
        paddingTop: '10px',
        paddingBottom: '10px',
        paddingRight: '0',
        marginTop: '-10px',
        marginBottom: '10px'
    }
}

const imgVariants = {
    start: {

    },
    end: {
        transform: 'rotate(-180deg)'
    }
}

function DoctorView() {
    const { reception_number } = useParams();
    const [ qalist, setQalist ] = useState<DoctorViewType []>(); 
    const [ selected, setSelected ] = useState<number>(0);
    const [ important, setImportant ] = useState<QAType[]>([]);


    const importantQuestion = (category: string) => {
        if(category === 'acne')
        {

        }
        else if(category === 'brown')
        {

        }
        else if(category === 'antiaging')
        {
            
        }
        else if(category === 'blush')
        {
            
        }
        else if(category === 'dry')
        {
            
        }
    }

    // 답변 세팅 
    const settingAnswer = (survey: SurveyStateType, answer: SurveyAnswerType) => {
        let qaList:QAType[] = [];
        answer.question.map(q => {
            let qa:QAType = {
                question: survey.question[Number(q.questionIndex)].questionTitle,
                answers: []
            }
            q.answer.map(a => {
                qa.answers.push(a.answerText); 
            })
            qaList.push(qa);
        })

        return qaList;
    }

    const requestAnswerData = async (receptionNumber: string) => {
        try{
            const res = await API.get(`/v1/answer/${receptionNumber}`);
            let doctorViews:DoctorViewType[] = [];
            
            // console.log(res.data.length);
            for(let i = 0; i < res.data.length; i++)
            {
                let doctorView:DoctorViewType = {
                    time: '',
                    category: '',
                    qa: []
                };
                // console.log(res.data[i].user);
                // console.log(res.data[i].survey.category);
                let tsurvey:SurveyStateType = await requestSurveyTempate(res.data[i].survey.category) as SurveyStateType;
                let qaList:QAType[] = settingAnswer(tsurvey, res.data[i].survey);
                doctorView.time = res.data[i].time;
                doctorView.qa = qaList;
                doctorView.category = res.data[i].survey.category;
                doctorViews.push(doctorView);   
                // console.log(res.data[i].lifestyle);
            }

            setQalist(doctorViews);
        }
        catch(err)
        {
            
        }
    }

    //pc_template 5개, lifestyle
    const requestSurveyTempate = async (category: string) => {
        try{
            const res = await API.get(`/v1/template/pc_${category}`);
            const tsurvey:SurveyStateType = JSON.parse(res.data.template);
            return tsurvey;      
        }
        catch(err)
        {

        }
    }

    const convertTime = (time: string) => {
        const year = time.slice(0,4);
        const month = time.slice(5, 7);
        const day = time.slice(8, 10);
        return year + "/" + month + "/" + day;
    }

    const convertCategory = (category: string) => {
        
        let ret = '';

        if(category === 'acne')
        {
            ret = '여드름';
        }
        else if(category === 'blush')
        {
            ret = '홍조';
        }
        else if(category === 'dry')
        {
            ret = '피부건조증';
        }
        else if(category === 'brown')
        {
            ret = '색소침착';
        }
        else if(category === 'antiaging')
        {
            ret = '안티에이징';
        }
        
        
        return ret;
    }

    useEffect(() => {
        //data loading
        //http://127.0.0.1:8000/v1/answer/ALCK8021/ 
        requestAnswerData(reception_number as string);
        // requestSurveyTempate("acne");
        // requestSurveyTempate("brown");
        // requestSurveyTempate("dry");
        // requestSurveyTempate("antiaging");
        // requestSurveyTempate("blush");
        // requestSurveyTempate("lifestyle");
    }, [])

    return (
        <Container>
            <CategoryButton>클럽어니스트({qalist?.length})</CategoryButton>
            {
                qalist &&
                qalist.map((qas, index) => {
                    return (
                        <div key={index}>
                            <TitleBar
                                onClick={() => {
                                    setSelected(index);
                                }}
                            >{convertTime(qas.time)} - {convertCategory(qas.category)}
                                <MoreButton 
                                    src={require('../../styles/assets/common/btn_expand_more.png')}
                                    variants={selected !== index ? undefined : imgVariants}
                                    initial="start"
                                    animate="end"
                                    transition={{delay: 0, duration: 0.3}}
                                />
                            </TitleBar>                            
                            <Contents variants={selected !== index ? undefined : variants}
                                initial="start"
                                animate="end"
                                transition={{delay: 0, duration: 0.3}}
                            >
                                <InnerContents>
                                {
                                    qas.qa.map((qa, index1) => {
                                        return (
                                            <Wrapper gap="5" key={index1}>
                                                <Q>Q. {qa.question}</Q>

                                                {qa.answers.map((a, index2) => {
                                                    return (
                                                        <A key={index2}>{a}</A>
                                                    );                                                    
                                                })}
                                            </Wrapper>                                        
                                        );
                                    })
                                }
                                </InnerContents>                            
                            </Contents>                           
                        </div>
                    );
                })
            }
        </Container>
    );
}

export default DoctorView;