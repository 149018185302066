import styled from "styled-components";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
`;

const Header = styled.div<{color: string}>`
    border-top: 2px solid #1A1A1A;
    border-bottom: 2px solid #1A1A1A;
    height: 80px;
    width: 560px;
    text-align: left;
    color: ${(props) => props.color};
    display: flex;
    justify-content: flex-start;
    align-items: center;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
`;

const Body = styled.div<{color: string}>`
    border-bottom: 2px solid #1A1A1A;
    height: 280px;
    width: 560px;
    text-align: left;
    color: ${(props) => props.color};
    display: flex;
    justify-content: center;
    align-items: flex-start;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 30px;
    flex-direction: column;
`;

const ListWrapper = styled.div`
    display: flex;
    align-items: center;
    gap: 23px;
`;

const Image = styled.img`

`;

const Label = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 22px;
    line-height: 45px;  
    text-align: left;
`;

interface DetailTableProps {
    title: string;
    datas: string [];
    activate: boolean;
}

export function DetailTable({
    title,
    datas,
    activate
}: DetailTableProps){
    return (
        <Container>
            <Header color={activate ? "#1A1A1A" : "#777777"}>
                {title}
            </Header>
            <Body color={activate ? "#1A1A1A" : "#A1A1A1"}>
                {
                    datas.map((data, index) => {
                        return(
                            activate ? (
                                <ListWrapper key={index}>
                                    <Image src={require('../../../styles/assets/common/list-checked.png')}/>
                                    <Label>{data}</Label>
                                </ListWrapper>
                                
                            ) : (
                                <ListWrapper key={index}>
                                    <Image src={require('../../../styles/assets/common/list-unchecked.png')}/>
                                    <Label>{data}</Label>
                                </ListWrapper>
                            )                            
                        );
                    })
                }
            </Body>
        </Container>
    );
}