// 밑줄이 있는 라벨 
import styled from "styled-components";

const Text = styled.span<{fontSize: string, color: string}>`
    font-family: 'felixTitling';
    font-size: ${(props) => props.fontSize}px;
    color: ${(props) => props.color};
    border-bottom: 1px solid #7A7A7A;
    font-weight: 400;
    line-height: 30px;
`;

interface UserLineLabelProps {
    text: string;
    fontSize: string;
    color: string;
}

export function UnderLineLabel({
    text,
    fontSize,
    color
}: UserLineLabelProps){
    return <Text fontSize={fontSize} color={color}>{text}</Text>;
}