import styled from "styled-components"
import { 
    QuestionSubTitle,
    QuestionTitle,
    SurveyHeaderTitle, 
    LinearGradientLabel, 
    NextButton
} from "../../atoms"
import { AnswerTextButton, AnswerTextInputButton } from '../../molecules';
import { TextButtonProps } from "../../../types";
import { useEffect, useState } from "react";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    background-color: ${(props) => props.theme.bgColor};
    height: 100%;
`;

const QuestionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
`;

const AnswerWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    padding: 0 10%;
`;

interface SurveyTextSelectProps {
    surveyTitle: string;
    questionTitle: string;
    questionSubTitle?: string;
    endLetter: string;
    grid: number;
    buttons: TextButtonProps[],
    onClick: (index: number, text?: string) => void;
    buttonActive: boolean;
    onNextButtonClick: () => void;
}

export function SurveyTextSelect({
    surveyTitle,
    questionTitle,
    questionSubTitle,
    endLetter,
    grid,
    buttons,
    onClick,
    buttonActive,
    onNextButtonClick
}: SurveyTextSelectProps){
    
    const onButtonClick = (index: number, text?: string) => {
        onClick(index, text);
    }

    const [gridButtons, setGridButtons] = useState<TextButtonProps[][]>([]);

    useEffect(() => {
        const count = Math.ceil(buttons.length / grid); 
        // console.log(count)
        let tempGrid:TextButtonProps[][] = [];
        for(var i = 0; i < count; i++)
        {
            let temp:TextButtonProps[] = [];
            if((i + 1) == count)
            {
                temp = buttons.slice(i * grid)
                tempGrid = [...tempGrid, temp];
            }
            else
            {
                temp = buttons.slice(i * grid, grid * (i + 1))
                tempGrid = [...tempGrid, temp];

            }
        }
        setGridButtons(tempGrid);
    }, [buttons])

    return (
        <Container>
            <QuestionWrapper>
                <LinearGradientLabel fontSize="15" title={surveyTitle} />
                <QuestionTitle title={questionTitle}  endLetter={endLetter}/>
                {questionSubTitle && (<QuestionSubTitle title={questionSubTitle} />)}
                {
                    gridButtons.map((grids, index) => {
                        return (
                            <AnswerWrapper key={index}>
                                {grids.map((button, index2) => {
                                    return(                                        
                                        !button.isInput ? 
                                        (<AnswerTextButton key={index2} {...button} onClick={() => onButtonClick((index*grid) + index2)} />) 
                                        :
                                        (<AnswerTextInputButton key={index2} {...button} onClick={(text: string) => onButtonClick(((index*grid) + index2), text)} />)                                       
                                        
                                    );
                                })}
                            </AnswerWrapper>
                        );
                    })
                }
            </QuestionWrapper>
            {/* <NextButton activate={buttonActive} name="다음" onClick={onNextButtonClick} /> */}
        </Container>
    );
}
