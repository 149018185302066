// clubhonest
import styled from "styled-components"

const Title = styled.div`
    font-family: 'FelixTitling';
    font-size: 27px;
    font-weight: 400;
    line-height: 30px;
    color: ${(props) => props.theme.textColor};
`;

export function SurveyHeaderTitle(){
    return <Title>CLUB HONEST</Title>;
}

