import styled from "styled-components";

const Container = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    background-color: #1A1A1A;
    flex-direction: column;
    gap: 40px;
    padding: 10px 0 80px 0;
`;

const Button = styled.div`
    border: 1px solid #5471FF;
    border-radius: 120px;
    width: 120px;
    height: 48px;
    display: flex;  
    justify-content: center;
    align-items: center;
    color: #5471FF;
    font-family: 'felixTitling';
    font-size: 20px;
    line-height: 30px;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 16px;
`;

const Title = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 48px;
    line-height: 70px;
    color: ${(props) => props.theme.textColor};
`;

const SubTitle = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    /* or 150% */

    text-align: center;
    color: ${(props) => props.theme.textColor};
`;

const Image = styled.img`
    margin-top: 28px;
`;

export function Step1(){
    return (
        <Container>
            <Button>STEP 01</Button>
            <TitleWrapper>
                <Title>서베이로 시작하세요</Title>
                <SubTitle>솔닥의 방대한 DB와 의료진의 노하우를 집대성한 자체 서베이로<br/> 피부진단을 시작하세요</SubTitle>
            </TitleWrapper>
            <Image src={require('../../../styles/assets/skindetail/ex_survey.png')}/>
        </Container>
    );
}