//get /skin/level/2

import { atom } from "recoil";

export interface LevelContentType {
    title: string;
    contents: string;
}
export interface LevelStateType {
    category: string;
    types: string [];
    levelText: string [];
    lastLevel: number;
    levelContents: LevelContentType []
}

export const levelState = atom<LevelStateType[]>({
    key: 'levelState',
    default: [
        {
            category: 'acne',
            types: ['C', 'A', 'B'],
            levelText: ["꾸준한 관리로 피부 건강을 유지하세요", "전문적인 여드름 치료가 필요해요", "전문적인 진료와 병원 방문을 추천해요"],
            lastLevel: 3,
            levelContents: [
                {
                    title: '홈케어로 피부 관리를 시작하세요',
                    contents: '적절한 스킨 제품 사용과 생활습관 교정을 통해 피부 건강을 개선하세요.'
                },
                {
                    title: '비대면 진료를 통한 처방을 제안드려요',
                    contents: '솔닥 의료진에게 가장 올바른 의약품과<br/> 치료법, 피부 관리 방법을 상담 받으세요.'
                },
                {
                    title: '시술 및 대면 상담을 권장해요',
                    contents: '솔닥의 비대면 진료를 받으신 후, 눈에띄는 개선이나 보완을 원하시면 대면 진료를 추천드려요.'
                }
            ]
        },
        {
            category: 'blush',
            types: ['C', 'A', 'B'],
            levelText: ["일상 속 꾸준한 관리로 피부를 지키세요", "비대면 진료로 홍조를 개선해보세요", "피부과 의사와 반드시 상담하세요"],
            lastLevel: 3,
            levelContents: [
                {
                    title: '홈케어로 피부 관리를 시작하세요',
                    contents: '적절한 스킨 제품 사용과 생활습관 교정을 통해 피부 건강을 개선하세요.'
                },
                {
                    title: '비대면 진료를 통한 처방을 제안드려요',
                    contents: '솔닥 의료진에게 가장 올바른 의약품과<br/> 치료법, 피부 관리 방법을 상담 받으세요.'
                },
                {
                    title: '시술 및 대면 상담을 권장해요',
                    contents: '솔닥의 비대면 진료를 받으신 후, 눈에띄는 개선이나 보완을 원하시면 대면 진료를 추천드려요.'
                }
            ]
        },
        {
            category: 'brown',
            types: ['A', 'B', 'C'],
            levelText: ["전문적인 치료를 시작해보세요", "피부과 의사와 반드시 상담하세요", "반드시 병원에 방문하여 진료받아보세요"],
            lastLevel: 3,
            levelContents: [
                {
                    title: '홈케어로 피부 관리를 시작하세요',
                    contents: '적절한 스킨 제품 사용과 생활습관 교정을 통해 피부 건강을 개선하세요.'
                },
                {
                    title: '비대면 진료를 통한 처방을 제안드려요',
                    contents: '솔닥 의료진에게 가장 올바른 의약품과<br/> 치료법, 피부 관리 방법을 상담 받으세요.'
                },
                {
                    title: '시술 및 대면 상담을 권장해요',
                    contents: '솔닥의 비대면 진료를 받으신 후, 눈에띄는 개선이나 보완을 원하시면 대면 진료를 추천드려요.'
                }
            ]
        },
        {
            category: 'dry',
            types: ['C', 'A', 'B'],
            levelText: ["지금의 피부를 계속 유지하세요", "비대면 진료로 피부 건강을 지키세요", "피부과 의사와 반드시 상담하세요"],
            lastLevel: 3,
            levelContents: [
                {
                    title: '홈케어로 피부 관리를 시작하세요',
                    contents: '적절한 스킨 제품 사용과 생활습관 교정을 통해 피부 건강을 개선하세요.'
                },
                {
                    title: '비대면 진료를 통한 처방을 제안드려요',
                    contents: '솔닥 의료진에게 가장 올바른 의약품과<br/> 치료법, 피부 관리 방법을 상담 받으세요.'
                },
                {
                    title: '시술 및 대면 상담을 권장해요',
                    contents: '솔닥의 비대면 진료를 받으신 후, 눈에띄는 개선이나 보완을 원하시면 대면 진료를 추천드려요.'
                }
            ]
        },
        {
            category: 'antiaging',
            types: ['C', 'A', 'B'],
            levelText: ["지금의 피부 탄력을 유지하기 위해 노력하세요", "비대면 진료로 안티에이징을 시작하세요", "적극적인 안티에이징 치료가 필요합니다"],
            lastLevel: 3,
            levelContents: [
                {
                    title: '홈케어로 피부 관리를 시작하세요',
                    contents: '적절한 스킨 제품 사용과 생활습관 교정을 통해 피부 건강을 개선하세요.'
                },
                {
                    title: '비대면 진료를 통한 처방을 제안드려요',
                    contents: '솔닥 의료진에게 가장 올바른 의약품과<br/> 치료법, 피부 관리 방법을 상담 받으세요.'
                },
                {
                    title: '시술 및 대면 상담을 권장해요',
                    contents: '솔닥의 비대면 진료를 받으신 후, 눈에띄는 개선이나 보완을 원하시면 대면 진료를 추천드려요.'
                }
            ]
        }
    ]
});
