import styled from "styled-components";

import {
    QuestionTitle, 
    QuestionSubTitle, 
    NextButton, 
    LinearGradientLabel, 
    SurveyHeaderTitle 
} from '../../atoms';

import { 
    AnswerImageButton 
} from "../../molecules";

import { 
    ImageButtonProps 
} from "../../../types";

import { 
    useEffect, 
    useState 
} from "react";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    width: 100%;
    gap: 30px;
    background-color: ${(props) => props.theme.bgColor};
`;

const QuestionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 30px;
`;

const AnswerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 30px;
`;

interface SurveyImageSelectProps {
    surveyTitle: string;
    questionTitle: string;
    questionSubTitle?: string;
    endLetter: string;
    grid: number;
    buttons: ImageButtonProps[],
    onClick: (index: number) => void;
    buttonActive: boolean;
    onNextButtonClick: () => void;
}

export function SurveyImageSelect({
    surveyTitle, 
    questionTitle,
    questionSubTitle,
    endLetter,
    grid,
    buttons,
    onClick,
    buttonActive,
    onNextButtonClick,
}: SurveyImageSelectProps){

    const [gridButtons, setGridButtons] = useState<ImageButtonProps[][]>([]);

    const onButtonClick = (index: number) => {
        // console.log("index " + index)
        onClick(index);
    }

    useEffect(() => {
        const count = Math.ceil(buttons.length / grid); 
        // console.log(count)
        let tempGrid:ImageButtonProps[][] = [];
        for(var i = 0; i < count; i++)
        {
            let temp:ImageButtonProps[] = [];
            if((i + 1) == count)
            {
                temp = buttons.slice(i * grid)
                tempGrid = [...tempGrid, temp];
            }
            else
            {
                temp = buttons.slice(i * grid, grid * (i + 1))
                tempGrid = [...tempGrid, temp];

            }
        }
        setGridButtons(tempGrid)
    }, [buttons])

    return (
        <Container>
            <QuestionWrapper>                
                <LinearGradientLabel fontSize="15" title={surveyTitle}/>
                <QuestionTitle title={questionTitle} endLetter={endLetter}/>
                {questionSubTitle && (<QuestionSubTitle title={questionSubTitle} />)}
                {
                    gridButtons.map((g, index) => {
                        return (
                            <AnswerWrapper key={index}>
                                {g.map((button, index2) => {
                                    return(
                                        <AnswerImageButton key={index2} {...button} onClick={() => onButtonClick((index*grid) + index2)}/>
                                    );
                                })}
                            </AnswerWrapper>    
                        );
                    })
                }
                            
            </QuestionWrapper>            
            {/* <NextButton activate={buttonActive} name="다음" onClick={onNextButtonClick} /> */}
        </Container>
    );
}