/**
 * Gender에서 다음 진입 
 * user에게 해당 type의 category (ex: skin(type), acne(category)) 를 입력받아 설문지를 load  
 * 및 detail 설문으로 이동함 
 * @ param: x
 * @ input: category select 
 * @ return: 서베이시작하기 버튼 -> detail 로 이동 
 * @ exception: x
 * @ update: 2022.06.14
*/

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useRecoilState } from "recoil";
import styled from "styled-components";
import { SurveyImageSelect } from "../../../components/organisms";
import { ImageButtonProps, ResultTypeEnum } from "../../../types";

import { 
    userState,
} from "../../../store";

import { 
    setUserLocalStorage,
    getUserLocalStorage
} from "../../../utilities/localstorage";

import {
    UserStateType
} from "../../../types";

import {
    SurveyHeaderTitle,
    NextButton
} from '../../../components/atoms';

import {
    ProgressBar
} from '../../../components/molecules';


const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    min-height: calc(100vh - 90px);
    width: 100%;
    background-color: ${(props) => props.theme.bgColor};
    padding: 29px 0 45px 0;
    gap: 30px;
`;

const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 29px;
`;

function Select(){
    const { type } = useParams();
    const [ selected, setSelected ] = useState(-1);
    const navigate = useNavigate();
    const [ user, setUser ] = useRecoilState(userState);
    const [ select, setSelect ] = useState("여드름");
    const [ nextButton, setNextButton ] = useState(false);
    const [ buttons, setButtons ] = useState<ImageButtonProps []>(
        [
            {
                text:"여드름",
                img: { 
                    hover: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/acne-hover.png",
                    active: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/acne-active.png",
                    inactive: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/acne-inactive.png"
                },
                activate: false,
                width: "252",
                height: "218",          
                resultType: [ ResultTypeEnum.NA ],
                resultView: false,
                resultViewAltMsg: '',
                multiSelect: false
            },
            {
                text:"피부건조증",
                img: {
                    hover: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/dry-hover.png",
                    active: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/dry-active.png",
                    inactive: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/dry-inactive.png"
                },
                activate: false,
                width: "252",
                height: "218",
                resultType: [ ResultTypeEnum.NA ],
                resultView: false,
                resultViewAltMsg: '',
                multiSelect: false
            },
            {
                text:"갈색 색소",
                img: { 
                    hover: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/brown-hover.png",
                    active: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/brown-active.png",
                    inactive: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/brown-inactive.png"
                },
                activate: false,
                width: "252",
                height: "218",
                resultType: [ ResultTypeEnum.NA ],
                resultView: false,
                resultViewAltMsg: '',        
                multiSelect: false
            },
            {
                text:"안티에이징",
                img: { 
                    hover: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/antiaging-hover.png",
                    active: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/antiaging-active.png",
                    inactive: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/antiaging-inactive.png"
                },
                activate: false,
                width: "252",
                height: "218",
                resultType: [ ResultTypeEnum.NA ],
                resultView: false,
                resultViewAltMsg: '',
                multiSelect: false
            },
            {
                text:"홍조",
                img: { 
                    hover: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/flushing-hover.png",
                    active: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/flushing-active.png",
                    inactive: "https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/flushing-inactive.png"
                },
                activate: false,
                width: "252",
                height: "218",
                resultType: [ ResultTypeEnum.NA ],
                resultView: false,
                resultViewAltMsg: '',
                multiSelect: false
            }
        ]
    );

    const onNextButtonClick = () => {
        setUser({...user, select: select});
        setUserLocalStorage({...user, select: select});

        if(selected === 0)
        {
            navigate(`/${type}/detail/acne`)
        }
        else if(selected === 1)
        {
            navigate(`/${type}/detail/dry`)
        }
        else if(selected === 2)
        {
            navigate(`/${type}/detail/brown`)
        }
        else if(selected === 3)
        {
            navigate(`/${type}/detail/antiaging`)
        }
        else if(selected === 4)
        {
            navigate(`/${type}/detail/blush`)
        }
    }

    const onSingleSelectBoxResult = (selected: number) => {
        //activate 수정 
        const temp = buttons;
        temp.map((t, index) => {
            if(index !== selected)
            {
                t.activate = false;
            }
            else
            {
                t.activate = true;
                setSelect(t.text);
            }
        })
        //아이디값이 변화해야 rerendering 
        setButtons([...temp]);
        setSelected(selected);
        setNextButton(true);
    }

    const loadUserLocalStorage = () => {
        const localUserString = getUserLocalStorage();
        if(localUserString)
        {
            const userLocal:UserStateType = JSON.parse(localUserString);
            setUser(userLocal);
            settingSelect(userLocal);
        }       
        else
        {
            navigate(`/${type}/start/init`);
        }
    }

    const settingSelect = (userInfo: UserStateType) => {
        setSelect(userInfo.select);      

        const tbuttons = buttons;
        tbuttons.map((button, index) => {
            if(button.text === userInfo.select)
            {
                button.activate = true;
                setNextButton(true);
                setSelected(index);
            }
        })

        setButtons(tbuttons);
    }

    useEffect(() => {
        loadUserLocalStorage();
    }, [])
    
    return (
        <Container>     
            <HeaderWrapper>
                <ProgressBar 
                    leftTotalTaskCount={30} 
                    leftCompleteCount={3}
                    rightTotalTaskCount={10}
                    rightCompleteCount={0}
                />
                <SurveyHeaderTitle /> 
            </HeaderWrapper>      
            <SurveyImageSelect 
                surveyTitle="기본정보"
                questionTitle="현재 가장 큰 피부 고민은 무엇인가요"
                endLetter="?"
                grid={5}
                buttons={buttons}
                onClick={onSingleSelectBoxResult}
                buttonActive={nextButton}
                onNextButtonClick={onNextButtonClick}
            />           
            <NextButton activate={nextButton} name="다음" onClick={onNextButtonClick} />
        </Container>
    );
}
export default Select;