// logo size
import styled from "styled-components";

const LogoImage = styled.img`
    width: 100px;
    height: 100px;
`;

interface LogoProps {
    width: number;
    height: number;
}

export function Logo(){
    return ;
};