import { useRecoilValue } from "recoil";
import styled from "styled-components";
import { 
    DoctorAdvice,
    HonestAdvice
} from "../../../components/molecules";

import { 
    AdviceType, 
    userState,
    HonestAdviceType
} from "../../../store";

const Container = styled.div`
    height: 1364px;
    width: 100%;
    background-color: #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 160px;
`;

interface AdviceProps {
    advice: AdviceType
}

export function Advice({
    advice
}: AdviceProps){

    const userInfo = useRecoilValue(userState)

    let honest: HonestAdviceType;
    if(advice.honest.length > 1)
    {
        if(userInfo.age < 20)
        {
            honest = advice.honest[0];
        }
        else if(userInfo.age >= 20 && userInfo.age < 30)
        {
            honest = advice.honest[1];
        }
        else{
            honest = advice.honest[2];
        }
    }
    else
    {
        honest = advice.honest[0];
    }

    return (
        <Container>            
            <DoctorAdvice doctor={advice.doctor} />
            <HonestAdvice honest={honest}/>
        </Container>
    );
}