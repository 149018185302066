// linear gradient가 활성화시 적용된 버튼 
import styled from "styled-components";
import { VoidExpression } from "typescript";

const Button = styled.div<ButtonProps>`
    width: 185px;
    height: 70px;
    border: 1px solid #7A7A7A;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.activate ? "#020620" : "#FFFFFF"};
    font-family: 'felixTitling';
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    cursor: pointer;
    background: ${(props) => !props.activate ? "#020620" : "linear-gradient(52.08deg, #76BCC5 22.58%, #709EE0 55.66%, #075BD4 93.67%)"};
`;

interface ButtonProps {
    activate: boolean;
}

interface LinearGradientButtonProps {
    title: string;
    activate: boolean;
    onClick: () => void;
}

export function LinearGradientButton({
    title,
    activate,
    onClick
}: LinearGradientButtonProps){
    return (
        <Button activate={activate}
            onClick={onClick}
        >{title}</Button>
    );
}