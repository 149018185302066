import { 
    REDIRECT_URI, 
    REST_API_KEY 
} from '../../../constant';
import axios from "axios";
import InputInfo from "./InputInfo";
import Confirm from "./Confirm";
import styled from "styled-components";
import { useEffect, useState } from "react";
import qs from 'qs';

import CompleteModal from './components/CompleteModal';

import { 
    getLocalStorage 
} from "../../../utilities/localstorage";

import {
    BookingType
} from '../../../types'

import { useLocation } from "react-router-dom";

const Container = styled.div`
    font-family: 'Apple SD Gothic Neo';
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: #F9F9F9;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;



const Section = styled.div`
    width: 500px;
    height: 100%;
    background-color: #fff;
    overflow: hidden;
    color: #000000;
    z-index: 111;
    padding-top: 40px;
    left: 50%;
    position: absolute;
    transform: translate(-50%, 0);
    /* scale: 1.25; */
`;

const CloseButton = styled.img`
    position: absolute;
    right: 26px;
    top: 26px;
    width: 20px;
    height: 20px;
    cursor: pointer;
`;

interface BookingProps {
    surveyId: string;
    onClose: () => void;
}

function Booking({
    surveyId,
    onClose
}: BookingProps){

    const location = useLocation();
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });
    const [ isInputComplete, setIsInputComplete ] = useState<boolean>(false);
    const [ isComplete, setIsComplete ] = useState(false);
    const [ isDup, setIsDup ] = useState(false);
    const [ session, setSession ] = useState<string>("");
    const [ bookingInfo, setBookingInfo ] = useState<BookingType>(
        {
            data: {
                detail: '추가 사항 정보 없음',
                hospital_ids: [1607405803, 1937465460],
                user: {
                    add_info: {
                        birth: '',
                        drink: '',
                        gender_type: 0,
                        height: '',
                        name: '',
                        phone_number: '',
                        registration_number: '',
                        smoke: '',
                        type: 1,
                        weight: "",
                        pregnancy: ""
                    },
                    name: '',
                    phone_number: '',
                }
            }, 
            time: ""
        }
    );
    const completeInputInfo = (data: BookingType) => {
        setBookingInfo(data);
        setIsInputComplete(true);
    }

    const getKakaoTokenStorage = () => {
        const token = getLocalStorage('kakao_access_token')
        return token;
    }

    const bookingToServer = async (session: string) => {
        const url = `/v1/survey/booking/`;
        axios.defaults.withCredentials = true;

        // console.log(JSON.stringify(bookingInfo));

        try {
            const data = {
                survey_id: surveyId,
                session_id: session,
                request: bookingInfo
            }
            const response = await axios.post(url, data);
            // console.log(response.data.status);
            if(response.data.status === "exists")
            {
                setIsDup(true);
            }
            else{
                setIsComplete(true);
            }
        }
        catch(err)
        {
            
        }
    }

    const submitBooking = async () => {
        if(session !== "")
        {
            await bookingToServer(session);
            // onClose();
        }

    }

    const getTeleSessionByServer = async (token: string) => {
        const url = `/v1/auth/getsession/`;
        axios.defaults.withCredentials = true;
        try {
            const data = {
                kakao_access_token: token
            }
            const response = await axios.post(url, data);
            // console.log("session: " + response.data);
            setSession(response.data);
            return response.data;
        }
        catch(err)
        {
            //재로그인 
            const path = `${location.pathname}?surveyId=${query.surveyId}`;       
            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&prompt=login&state=${path}`;
            return null;
        }
    } 

    const verifyMedical = async (tsession: string) => {
        const url = `/v1/auth/verifymedical/`
        axios.defaults.withCredentials = true;
        try{
            const data = {
                session_id: tsession
            }
            const res = await axios.post(url, data);
            // console.log(res.data);
        }
        catch(err)
        {
            return null;
        }
    }
 
    const getTeleSession = async () => {
        // local storage
        // const session = getLocalStorage('tele_session');
        
        const kakao_token = getKakaoTokenStorage();

        if(kakao_token)
        {
            //check                 
            const session_res =  await getTeleSessionByServer(kakao_token);
            if(session_res)
            {
                await verifyMedical(session_res);
            }
            // 에러 재로그인 
        }
        else
        {
            // 재 로그인

        }
    }
    
    

    useEffect(() => {
        document.body.style.overflowY = 'hidden';
        // get kakao token 
        // getKakaoTokenStorage();
        // get session
        getTeleSession()
        // verify medical
        // 재발급  

    }, [])

    return (
        <Container>
            <Section>
                {
                    isComplete && <CompleteModal 
                    message="비대면 진료 예약이 완료되었어요."
                    subMessage="지금 카카오톡 메시지를 통해,<br/>비대면 진료 정보를 확인하세요."
                    onClick={() => {
    
                        setIsComplete(false);
                        onClose()
                    }}
                />

                }
                {
                    isDup && <CompleteModal 
                    message="이미 예약하신 내역이 있어요."
                    subMessage="카카오 솔닥 채널에서<br/>예약 내역을 확인하세요."
                    onClick={() => {
    
                        setIsDup(false);
                        onClose()
                    }}
                />

                }
                <CloseButton src={require('../../../styles/assets/common/btn_close_black.png')}
                    onClick={onClose}
                />
                {
                    isInputComplete ? (
                        <Confirm 
                            bookingInfo={bookingInfo}
                            onCancel={() => {
                                setIsInputComplete(false);
                            }}
                            onSubmit={() => {
                                submitBooking();
                            }}
                        />
                    ) : (
                        session !== '' &&
                        <InputInfo
                            session={session}
                            bookingInfo={bookingInfo}
                            onSubmit={(data: BookingType) => {
                                completeInputInfo(data);                            
                            }}
                        />
                    )
                }
            </Section>
        </Container>      
    );
}

export default Booking;