import { useEffect } from "react";
import styled from "styled-components";
import { 
    ModalImageContents 
} from "../../../store";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;

`;

const Section = styled.div`
    width: 1200px;   
    border-radius: 50px;
    background-color: #fff;
    color: #000000;
    padding: 91px 0px 91px 51px;
    position: relative;
`;

const Wrapper = styled.div`
    width: 100%;
    max-height: 763px;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    overflow-y: scroll;
    padding-right: 82px;
    gap: 70px;
`;

const CloseButton = styled.img`
    position: absolute;
    right: 50px;
    top: 61px;
    width: 30px;
    height: 30px;
    cursor: pointer;
`;

const ContentsWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 60px;
    flex-direction: column;
`;

const Title = styled.span`
    font-family: 'Apple SD Gothic Neo Bold';
    font-weight: 400;
    font-size: 30px;
    line-height: 23px;
    color: #000000;
`;

const ImageInfosWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-direction: column;
    gap: 56px;
`;

const Image = styled.img`

`;

const InfoWrapper = styled.ul`

`;

const Info = styled.li`
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
`;

const Contents = styled.span`
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    width: 1048px;
`;


interface ModalProps {
    open: boolean;
    modalContents: ModalImageContents [] | undefined;
    onCloseButton: () => void;
}

export function ImageAdviceModal({
    open,
    modalContents,
    onCloseButton
}: ModalProps){

    return (
        <>
        {open? (
            <Container>
                <Section>
                    <CloseButton src={require("../../../styles/assets/common/btn_close_black.png")} 
                        onClick={onCloseButton}
                    />
                    <Wrapper>                 
                    {
                        modalContents?.map((contents, index) => {
                            return (
                                <ContentsWrapper key={index}>
                                
                                    <Title>{contents.title}</Title>
                                        {
                                            contents.imageInfos.map((infos, index2) => {
                                                return (
                                                    <ImageInfosWrapper key={index2}>
                                                        <Image src={infos.image} /> 
                                                        <InfoWrapper>
                                                        {
                                                            infos.infoList?.map((info, index3) => {
                                                                return (
                                                                    <Info key={index3}>• {info}</Info>
                                                                );
                                                            })
                                                        }
                                                        </InfoWrapper>
                                                    </ImageInfosWrapper>
                                                );
                                                
                                                    
                                            })
                                        }
                                    <Contents>{contents.contents}</Contents>                                               
                                </ContentsWrapper>
                            );                            
                        })
                    }                  
          
                    </Wrapper>
                </Section>
            </Container>
        ) : (
            null
        )}
        </>
       
    );
}