import styled from "styled-components";
import { motion, AnimatePresence } from 'framer-motion';

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    background-color: ${(props) => props.theme.textColor};
`;

const FirstSliderWrapper = styled.div`
    position: relative;
    height: 170px;
`;
const SecondSliderWrapper = styled.div`
    position: relative;
    height: 172px;
`;
const ThirdSliderWrapper = styled.div`
    position: relative;
    height: 212px;
    margin-top: -14px;    
`;

const Row = styled(motion.div)`
    position: absolute;
`;

const Box = styled(motion.div)`    
    font-size: 50px;
    color: black;
    display: flex;
`;

const InitBox = styled(motion.div)`
    position: absolute;
    font-size: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: black;
`;

//first
const FirstImage = styled.img`
    height: 170px;
    width: 3320px;
`;

//second
const SecondImage = styled.img`
    height: 172px;
    width: 2826px;
`;

//third
const ThirdImage = styled.img`
    height: 212px;
    width: 3326px;
`;

// variants
const rowFirstVariants = {
    hidden: {
      x: 3320,
    },
    visible: {
      x: 0,
    },
    exit: {
      x: -3320,
    },
};

const initFirstVariants = {
    hidden: {
    },
    visible: {
        x: -3320,
    },
    exit: {
        
    },    
}

const rowThirdVariants = {
    hidden: {
      x: -3326,
    },
    visible: {
      x: 0,
    },
    exit: {
      x: 3326,
    },
};

const initThirdVariants = {
    hidden: {
    },
    visible: {
        x: 3326,
    },
    exit: {
        
    },    
}

export function HeroSlider(){
    return (
        <Container>
            <FirstSliderWrapper>
                <InitBox 
                    variants={initFirstVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    transition={{ ease: "linear", duration: 150, repeat: Infinity }}
                >
                    <FirstImage src={require('../../../styles/assets/landing/slider01.png')}/>
                    <FirstImage src={require('../../../styles/assets/landing/slider01.png')}/>
                    <FirstImage src={require('../../../styles/assets/landing/slider01.png')}/>
                </InitBox>
                <AnimatePresence>
                    <Row 
                        variants={rowFirstVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ ease: "linear", duration: 150, repeat: Infinity }}
                    >             
                        <Box>
                            <FirstImage src={require('../../../styles/assets/landing/slider01.png')}/>
                            <FirstImage src={require('../../../styles/assets/landing/slider01.png')}/>
                            <FirstImage src={require('../../../styles/assets/landing/slider01.png')}/>
                        </Box>                        
                    </Row>
                </AnimatePresence>
            </FirstSliderWrapper>
            <SecondSliderWrapper>
                <InitBox>
                    <SecondImage src={require('../../../styles/assets/landing/slider02.png')}/>
                    <SecondImage src={require('../../../styles/assets/landing/slider02.png')}/>
                    <SecondImage src={require('../../../styles/assets/landing/slider02.png')}/>
                </InitBox>               
            </SecondSliderWrapper>
            <ThirdSliderWrapper>
                <InitBox 
                    variants={initThirdVariants}
                    initial="hidden"
                    animate="visible"
                    exit="exit"
                    transition={{ ease: "linear", duration: 150, repeat: Infinity }}
                >
                    <ThirdImage src={require('../../../styles/assets/landing/slider03.png')}/>
                    <ThirdImage src={require('../../../styles/assets/landing/slider03.png')}/>
                    <ThirdImage src={require('../../../styles/assets/landing/slider03.png')}/>
                </InitBox>
                <AnimatePresence>
                    <Row 
                        variants={rowThirdVariants}
                        initial="hidden"
                        animate="visible"
                        exit="exit"
                        transition={{ ease: "linear", duration: 150, repeat: Infinity }}
                    >             
                        <Box>
                            <ThirdImage src={require('../../../styles/assets/landing/slider03.png')}/>
                            <ThirdImage src={require('../../../styles/assets/landing/slider03.png')}/>
                            <ThirdImage src={require('../../../styles/assets/landing/slider03.png')}/>
                        </Box>                        
                    </Row>
                </AnimatePresence>
            </ThirdSliderWrapper>
        </Container>
    );
}