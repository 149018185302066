import styled from "styled-components";

const Button = styled.div`
    width: 446px;
    height: 139px;
    color: ${(props) => props.theme.textColor};
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    background: linear-gradient(52.08deg, #76BCC5 22.58%, #709EE0 55.66%, #075BD4 93.67%);
    border-radius: 30px;
`;

interface ButtonProps {
    text: string;
    onClick: () => void;
}

export function ModalButton({
    text,
    onClick
}: ButtonProps){
    return <Button onClick={onClick}>{text}</Button>
}