import axios from "axios";
import { useEffect, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useRecoilValue, useRecoilState } from "recoil";
import styled from "styled-components";
import { NextButton, SurveyHeaderTitle } from "../../../components/atoms";

import {
    ProgressBar
} from '../../../components/molecules';

import {
    ContinueModal
} from '../../../components/organisms'

import { 
    surveyState,
    lifeState,
    surveyAtom,
} from "../../../store";

import { 
    getLocalStorage,
    setLocalStorage 
} from "../../../utilities/localstorage";

import {
    ButtonTypeEnum,
    SurveyStateType,
    TextButtonProps
} from '../../../types';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    height: calc(100vh - 90px);
    width: 100%;
    gap: 30px;
    background-color: ${(props) => props.theme.bgColor};
    padding: 29px 0 45px 0;
`;

const HeaderWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 29px;
`;

const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
`;

const MainTitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
`;

const Title = styled.span`
    height: 101px;
    width: 100%;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 98px;
    text-align: center;
    color: #FFFFFF;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

const EndLetter = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 60px;
    line-height: 98px;
    text-align: center;
    margin-left: 2px;
    color: #4C4C4C;
`;

const SubTitle = styled.h2`
    font-family: 'Apple SD Gothic Neo';
    font-size: 25px;
    font-weight: 700;
    background: linear-gradient(90deg, #76BCC5 0%, #709EE0 49.77%, #075BD4 101.67%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    background-clip: text;
    text-align: center;
    position: relative;

`;

const Image = styled.img`
    position: absolute;
    right: -32px;
    top: 1px;
`;

function Detail(){
    const {type, category, question} = useParams();
    const [ title1, setTitle1 ] = useState("");
    const [ title2, setTitle2 ] = useState("");
    const [ title3, setTitle3 ] = useState("");
    const [ endLetter, setEndLetter ] = useState("");
    const [ subTitle, setSubTitle ] = useState("");
    const navigate = useNavigate();
    const [ surveys, setSurveys ] = useRecoilState(surveyState);  
    const [ life, setLife ] = useRecoilState(lifeState);

    const [ sv, setSv ] = useRecoilState(surveyAtom);

    const [ isModalOpen, setIsModalOpen ] = useState(false);

    const lifeStyleInit = () => {
        //라이프스타일 recoil, localstorage 초기화
        // lifestyle 초기화 
        let tlife:SurveyStateType = JSON.parse(JSON.stringify(life));
        tlife.question.map((q) => {
            q.buttons.map((b) => {
                b.activate = false;

                if(q.buttonType === ButtonTypeEnum.TEXT_MULTI || q.buttonType === ButtonTypeEnum.TEXT_SINGLE)
                {
                    if((b as TextButtonProps).isInput)
                    {
                        (b as TextButtonProps).text = '';
                    }
                }
            })
        })
        setLife(tlife);
    }

    const surveysInit = () => {
        const tsurvey:SurveyStateType = JSON.parse(JSON.stringify(sv));

        tsurvey.question.map((q) => {
            q.buttons.map((button) => {
                
                button.activate = false;                            

                if(q.buttonType === ButtonTypeEnum.TEXT_MULTI || q.buttonType === ButtonTypeEnum.TEXT_SINGLE)
                {
                    if((button as TextButtonProps).isInput)
                    {
                        (button as TextButtonProps).text = '';
                    }                                
                }
            })
        })

        setSv(tsurvey);
    }

    const onConfirm = (value: boolean) => {
        setIsModalOpen(false);
        // const localSurveyString = getSurveyLocalStorage();
        // const localLifeString = getLifeLocalStorage();

        if(!value)
        {
             // survey lifestyle 초기화 
             if(sv !== undefined)
             {
                surveysInit();
             }

             if(life !== undefined)
             {
                lifeStyleInit();
             }
             setLocalStorage(`answer_${category}`);
             setLocalStorage('answer_lifestyle');
            // if(localSurveyString)
            // {
            //     surveysInit();
            //     // lifestyle 초기화 
            //     lifeStyleInit();
            // }
        }
        // else
        // {
        //     // if(localSurveyString)
        //     // {
        //     //     const localSurveys:SurveyStateType[] = JSON.parse(localSurveyString);
        //     //     setSurveys(localSurveys);
        //     // }
        // }
        navigate(`/${type}/detail/${category}/0`)
    }

    const getSurveyTemplate = async () => {
        const url = `/v1/template/pc_${category}/`;
        axios.defaults.withCredentials = true;
        try{
            const response = await axios.get(url);
            const tsurvey:SurveyStateType = JSON.parse(response.data.template);
            return tsurvey;
        }
        catch(err)
        {
            return null;
        }

    } 

    const onNextButtonClick = async () => {
        const surveyLocalStorageString = getLocalStorage(`answer_${category}`) 
        if(surveyLocalStorageString)
        {
            setIsModalOpen(true)
        }
        else
        {
            // survey lifestyle 초기화 
            if(sv !== undefined)
            {
               surveysInit();
            }

            if(life !== undefined)
            {
               lifeStyleInit();
            }
            setLocalStorage(`answer_${category}`);
            setLocalStorage('answer_lifestyle');

            navigate(`/${type}/detail/${category}/0`)
        }
        // navigate(`/${type}/detail/${category}/0`)
        // const localSurveyString = getSurveyLocalStorage();

        // if(localSurveyString)
        // {
        //     const localSurveys:SurveyStateType[] = JSON.parse(localSurveyString);

        //     if(localSurveys === null || localSurveys === undefined)
        //     {
        //         surveysInit();
        //         // lifestyle 초기화 
        //         lifeStyleInit();
        //         navigate(`/${type}/detail/${category}/0`)
        //     }
        //     else
        //     {
        //         const selectSurvey:SurveyStateType = localSurveys.find(function(data){
        //             return data.category === category;
        //         }) as SurveyStateType;
    
        //         let flag = false;
        //         if(selectSurvey !== undefined)
        //         {
        //             selectSurvey.question.map((q)=> {
        //                 q.buttons.map((b) => {
        //                     if(b.activate)
        //                     {
        //                         flag = true;
        //                     }
        //                 })
        //             })
        
        //             if(flag)
        //             {
        //                 setIsModalOpen(true);   
        //             }
        //             else
        //             {
        //                 surveysInit();
        //                 // lifestyle 초기화 
        //                 lifeStyleInit();
        //                 navigate(`/${type}/detail/${category}/0`)
        //             }
        //         }
        //         else
        //         {
        //             surveysInit();
        //             // lifestyle 초기화 
        //             lifeStyleInit();
        //             navigate(`/${type}/detail/${category}/0`)
        //         }
        //     }

          
        // }
        // else
        // {
        //     surveysInit();
        //     // lifestyle 초기화 
        //     lifeStyleInit();
        //     navigate(`/${type}/detail/${category}/0`)
        // }
    }

    useEffect(() => {

        if(question === undefined)
        {
            //init 
            if(category === 'acne')
            {
                setTitle1("여드름은 인구의 85% 이상이");
                setTitle2("경험하는 아주 흔한");
                setTitle3("피부 질환이에요");
                setEndLetter(".");
                setSubTitle("너무 걱정하지 마세요, 이제부터 클럽어니스트와 함께 시작해봐요");
            }
            else if(category === 'brown')
            {
                setTitle1("갈색색소는 인구의 80% 이상이");
                setTitle2("경험하는 아주 흔한");
                setTitle3("피부 질환이에요");
                setEndLetter(".");
                setSubTitle("너무 걱정하지 마세요, 이제부터 클럽어니스트와 함께 시작해봐요");
            }
            else if(category === 'dry')
            {
                setTitle1("피부 건조증은");
                setTitle2("꾸준하고 지속적인");
                setTitle3("관리가 필수에요");
                setEndLetter(".");
                setSubTitle("너무 걱정하지 마세요, 이제부터 클럽어니스트와 함께 시작해봐요");
            }
            else if(category === 'blush')
            {
                setTitle1("홍조는");
                setTitle2("남녀노소 누구에게나");
                setTitle3("나타날 수 있는 증상이에요");
                setEndLetter(".");
                setSubTitle("너무 걱정하지 마세요, 이제부터 클럽어니스트와 함께 시작해봐요");
            }
            else if(category === 'antiaging')
            {
                setTitle1("안티에이징,");
                setTitle2("서두르지 말고 천천히");
                setTitle3("개선해나가요");
                setEndLetter(".");
                setSubTitle("너무 걱정하지 마세요, 이제부터 클럽어니스트와 함께 시작해봐요");
            }
        }
        else
        {
            //loading 
            //이동 
        }
    })

    return (        
        question === undefined ? (
            <Container>
                <ContinueModal 
                    open={isModalOpen}
                    onCloseButton={() => setIsModalOpen(false)}
                    onConfirm={onConfirm}
                />
                <HeaderWrapper>
                    <ProgressBar 
                        leftTotalTaskCount={30} 
                        leftCompleteCount={4}
                        rightTotalTaskCount={10}
                        rightCompleteCount={0}
                    />
                    <SurveyHeaderTitle />    
                </HeaderWrapper>
                  
                <TitleWrapper>
                    <SubTitle>{subTitle} <Image src={require('../../../styles/assets/survey/icn_gradient_smile.png')} /></SubTitle>
                    <MainTitleWrapper>
                        <Title>{title1}</Title>
                        <Title>{title2}
                            {title3 === "" && <EndLetter>{endLetter}</EndLetter>}
                        </Title>
                        { title3 !== "" && <Title>{title3}<EndLetter>{endLetter}</EndLetter></Title>}
                    </MainTitleWrapper>
                </TitleWrapper>       
                <NextButton activate={true} name="좋아요" onClick={onNextButtonClick} />
            </Container>
        ):(
            <Outlet />
        )
    );
}

export default Detail;