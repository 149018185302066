import styled from "styled-components";

import {
    QALabel
} from "../../atoms"

import {
    AnswerType
} from "../../../types"

const Container = styled.div`
    width: 1200px;
    padding: 80px 73px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    border: 1px solid #7A7A7A;
    border-radius: 50px;
    flex-wrap: wrap;
    gap: 50px;
`;

interface AnswerListProps {
    answers: AnswerType[]
}
export function AnswerList({
    answers
}: AnswerListProps){

    return (
        <Container>
            {
                answers.map((answer, index) => {
                    return <QALabel key={index} question={answer.question} answer={answer.answer}/>
                })
            }
        </Container>
    );
}