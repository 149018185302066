import {
    BookingType
} from '../../../types';

import styled from "styled-components";
import { useEffect } from 'react';

import BookingCardSlider from './components/BookingCardSlider';

const Container = styled.div`
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 40px 10px;
`;

const Title = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-size: 24px;
    line-height: 33px;
    color: #1E1E1E;
    span{
        font-weight: 700;
    }
`;

const Line = styled.div`
    width: 100%;
    border-bottom: 1px solid #EDEEF0;
    margin-top: 30px;
    margin-bottom: 30px;
`;

const Label = styled.span`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 17px;
    color: #1E1E1E;
    margin-bottom: 15px;
`;

const ButtonWrapper = styled.div`
    display: grid;
    justify-content: center;
    align-items: center;
    grid-template-columns: 1fr 1fr;
    gap: 19px;
    width: 100%;
    position: absolute;
    bottom: 60px;
`;

const CancelButton = styled.div`
    background: #FFFFFF;
    border: 1px solid #EDEEF0;
    border-radius: 6px;
    width: 95%;
    height: 55px;
    color: #1E1E1E;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;

const ConfirmButton = styled.div`
    background: #1E1E1E;
    border-radius: 6px;
    width: 95%;
    height: 55px;
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 22px;
    color: #FFFFFF;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`
    margin-right: 13px;
`;

interface ConfirmProps{
    onSubmit: () => void;
    onCancel: () => void;
    bookingInfo: BookingType;
}

function Confirm({
    onSubmit,
    onCancel,
    bookingInfo
}: ConfirmProps){

    const onSubmitButton = () => {
        //call api
        //success 
        onSubmit();
    }

    const convertTime = (time: string) => {

        const year = time.slice(0,4);
        const month = time.slice(5, 7);
        const day = time.slice(8, 10);
        const hour = time.slice(11, 13);
        const minute = time.slice(14, 16);
        
        return year + "년 " + month + "월 " + day + "일 | " + hour + ":"+ minute;
    }

    useEffect(() => {
        //time sparse

    }, [])

    return (
        <Container>
            <Title>진료를 위해<br/> <span>입력하신 정보</span>를<br/>다시 한번 확인해주세요</Title>
            <Line />
            <Label>
                <Image src={require('../../../styles/assets/common/icn_check.png')} />
                {convertTime(bookingInfo.time)}
            </Label>
            <Label>
                <Image src={require('../../../styles/assets/common/icn_check.png')} />
                {bookingInfo.data.user.add_info.name}
            </Label>
            <Label>
                <Image src={require('../../../styles/assets/common/icn_check.png')} />
                {bookingInfo.data.user.add_info.birth + "-" +bookingInfo.data.user.add_info.gender_type+ "******"}
            </Label>
            <Label>
                <Image src={require('../../../styles/assets/common/icn_check.png')} />
                {bookingInfo.data.user.phone_number}
            </Label>
            <BookingCardSlider />
            <ButtonWrapper>
                <CancelButton
                    onClick={onCancel}
                >수정</CancelButton>
                <ConfirmButton
                    onClick={onSubmitButton}
                >네! 예약할게요!</ConfirmButton>
            </ButtonWrapper>
        </Container>
    );
}

export default Confirm;