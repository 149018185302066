/**
 * landing services에서 초기 진입시
 * survey type "skin", "diet" 구분하여 contents 구성 
 * 
 * @ param: useParam type 
 * @ return: 시작하기 버튼 -> birth로 이동 
 * @ exception: x
 * @ update: 2022.06.14
*/

import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import styled from "styled-components";
import { motion } from 'framer-motion';

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: calc(100vh - 90px);
    width: 100%;
    flex-direction: column;
`;

const IntroWrapper = styled.div`
    background-color: ${(props) => props.theme.bgColor};
    width: 100%;
    height: 70%;
    min-height: 600px;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative;
`;

const TitleContainer = styled.div`
    width: 100%;
    position: relative;
    border-top: 1px solid rgba(255, 255, 255, 0.3);
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    padding: 10px;
    height: 109px;
`;

const ExtraLine = styled(motion.div)`
    width: 100%;
    position: relative;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    height: 109px;
`;

const TitleWrapper = styled.div`
    position: absolute;
    width: 1350px;
    left: 50%;
    top: 0;
    transform: translate(-50%, -50%);
`;

const TitleWrapper1 = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 9px;
    left: 420px;
`;

const TitleWrapper2 = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 115px;
    left: 550px;
    /* transform: translate(-50%, -50%); */
`;

const TitleWrapper3 = styled(motion.div)`
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 225px;
    left: 524px;
    /* transform: translate(-50%, -85%); */
`;

const Title = styled.div`
    font-size: 80px;
    font-weight: 700;
    text-align: center;
    color: ${(props) => props.theme.textColor};

    span {
        background: linear-gradient(90deg, rgba(118,188,197,1)  33%, rgba(112,158,224,1) 66%, rgba(7,91,212,1) 100%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
        position: relative;
        margin-right: 40px;
    }
    margin-right: 20px;
`;


const TitleIcon = styled.img`
    width: 66px;
    height: 66px;
    margin-top: 10px;
`;

const RICon = styled.img`
    width: 32px;
    height: 32px;
    position: absolute;
    top: 22px;
`;

const Contents = styled(motion.div)`
    width: 100%;
    line-height: 50px;
    text-align: center;
    font-size: 30px;
    color: ${(props) => props.theme.textColor};
    position: absolute;
    top: 130px;
    padding-bottom: 30px;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
`;

const StartButtonWrapper = styled.div`
    position: absolute;
    width: 198px;
    height: 198px;
    background-color: #075BD4;
    bottom: -95px;
    left: 70%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    cursor: pointer;
    z-index: 2;
`;

const StartButton = styled(motion.div)`
    width: 170.37px;
    height: 170.37px;
    background-color: #075BD4;
    border: 1px solid rgba(255, 255, 255, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    border-radius: 50%;
    font-size: 40px;
    font-weight: 200;
    font-family: 'FelixTitling';
`;

const Footer = styled.div`
    background-color: ${(props) => props.theme.textColor};
    width: 100%;
    height: 20vh;
    min-height: 235px;
    position: relative;
    z-index: -1;
`;

interface ImageProps {
    top: string;
    left: string;
}

const RunnerIcon = styled(motion.img)<ImageProps>`
    position: absolute;
    top: ${(props) => props.top}%;
    left: ${(props) => props.left}%;
    transform: translate(-50%, -50%);
`;


const buttonVariants = {
    hover: {
        rotateZ: 25,

    }
}

const contentsVariants = {
    start: {
        opacity: 0
    },
    end: {
        opacity: 1
    }
}

const lineVariants = {
    start: {
        opacity: 1
    },
    end: {
        opacity: 0
    }
}

const runnerVariants = {
    none: {

    },
    animate: {
        top: '90%',
        left: '-10%'
    }
}

enum SurveyEnum {
    "SKIN" = "skin",
    "DIET" = "diet"
}


function Init(){
    const { type } = useParams();
    const [ gradientTitle, setGradientTitle ] = useState<string>("");
    const [ whiteTitle1, setWhite1Title ] = useState<string>("");
    const [ whiteTitle2, setWhite2Title ] = useState<string>("");
    const [ contents, setContents ] = useState<string>("<p></p>");
    const [ hovered, setHovered ] = useState(false);
    const navigate = useNavigate();

    const onStart = () => {
        navigate(`/${type}/start/birth`);
    }

    useEffect(() => {
        if(type === SurveyEnum.SKIN)
        {
            setGradientTitle("스킨 어니스트");
            setWhite1Title("오신 것을");
            setWhite1Title("환영합니다.");
            setContents(`<p>내 피부, 의사는 어떻게 말할까?</p><p>의사가 알려주는 솔직한 솔루션을 확인하세요.</p><br/><p>(*약 3분정도 소요됩니다)</p>`);
        }
        else if(type === SurveyEnum.DIET)
        {

        }
    }, [])

    return (
        <Container>
            <IntroWrapper>
                <TitleContainer>
                    <TitleWrapper>
                        <TitleWrapper1 
                            animate={{left: '-15px', top: '9px'}}
                            transition={{ type: "spring", duration: 2, delay: 0.5}}
                        >
                            <Title><span>{gradientTitle}<RICon src={require("../../../styles/assets/survey/rText.png")} /></span>에                       
                            </Title>
                        </TitleWrapper1>
                        <TitleWrapper2 
                            animate={{top: '9px' }}
                            transition={{ type: "spring", duration: 2, delay: 0.5}}
                        >
                        <Title>오신 것을 </Title>
                        </TitleWrapper2>
                        <TitleWrapper3 
                            animate={{right: '-290px', top: '9px' }}
                            transition={{ type: "spring", duration: 2, delay: 0.5}}
                        >
                            <Title> 환영합니다</Title><TitleIcon src={require("../../../styles/assets/survey/smile.png")} />
                        </TitleWrapper3>
                    </TitleWrapper>      
                    <Contents dangerouslySetInnerHTML={{__html:contents}}
                        variants={contentsVariants}
                        initial="start"
                        animate="end"
                        transition={{ type: "spring", duration: 2, delay: 1.2}}
                    ></Contents>          
                </TitleContainer>
                <ExtraLine 
                    variants={lineVariants}
                    initial="start"
                    animate="end"
                    transition={{ type: "spring", duration: 2, delay: 0.5}}
                />
                <ExtraLine 
                    variants={lineVariants}
                    initial="start"
                    animate="end"
                    transition={{ type: "spring", duration: 2, delay: 0.5}}
                />


                <StartButtonWrapper onClick={onStart}
                ><StartButton
                    variants={buttonVariants}
                    whileHover="hover"
                    transition={{ type: "tween", duration: 0.5, delay: 0}}
                    onMouseEnter={() => setHovered(true)}
                    onMouseLeave={() => setHovered(false)}
                >START</StartButton>
                    <RunnerIcon top="135" left="-30"
                    variants={runnerVariants}
                    animate={hovered ? "animate" : "none"}
                    transition={{ type: "tween", duration: 0.5, delay: 0}}
                    src={require("../../../styles/assets/survey/startrunner1.png")}/>
                </StartButtonWrapper>
            </IntroWrapper>
            <Footer>
                <RunnerIcon top="100" left="42" 
                    src={require("../../../styles/assets/survey/startrunner2.png")}
                />
                
            </Footer>

        </Container>
    );
}

export default Init;