import styled from "styled-components";

import {
    AccordionList
} from '../../../components/organisms'

export function FaQ(){
    return (
        <AccordionList />
    );
}