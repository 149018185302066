import { useEffect, useState } from "react";
import styled from "styled-components";

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Section = styled.div`
    position: absolute;
    bottom: 0;
    width: 100%;
    height: 488.5px;
    border-top-left-radius: 15px;
    border-top-right-radius: 15px;
    background-color: #fff;
    overflow: hidden;
    color: #000000;
    padding: 34px 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
`;

const CloseButton = styled.img`
    position: absolute;
    right: 21px;
    top: 37px;
    width: 15px;
    height: 15px;
    cursor: pointer;
`;

const Title = styled.div`
    font-size: 16px;
    font-weight: 500;
    line-height:  24px;
    color: #1E1E1E;

    span{
        color: #50A0E0;
        font-weight: 700;
        font-size: 18px;
    }
`;

const DotWrapper = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 20px;
    margin-top: 50px;
    width: 100%;
`;

const LineWrapper = styled.div`
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 100%;
    margin-top: 20px;
`;

const Button = styled.div`
    width: 105px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    cursor: pointer;
`;

const SubmitButton = styled.div<{acitve: boolean}>`
    width: 105px;
    height: 55px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 16px;
    background: ${(props) => props.acitve ? '#1E1E1E' : '#EDEEF0'};
    color: ${(porps) => porps.acitve ? '#FFFFFF' : '#B0B0B0'};
    border-radius: 6px;
    cursor: pointer;
`;

const Image = styled.img`
    width: 16px;
`;

const ViewButton = styled.img`
    cursor: pointer;
    margin-top: -1px;
`;

const Number = styled.div`
    font-family: 'Spoqa Han Sans Neo';
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    width: 14px;
    height: 14px;
    margin-right: 20px;
    margin-top: -7px;
`;

const Dot = styled.div<{active: boolean}>`
    width: 14px;
    height: 14px;
    background-color: ${(props) => props.active ? '#1E1E1E' : '#E8EAEE'};
    border-radius: 50%;
    margin-right: 20px;
`;

const numberButtons = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9];
const emptys = [-1, -1, -1, -1, -1, -1, -1];
interface RegistrationNumberProps {
    onCloseButton: () => void;
    onSubmit: (value: string) => void;
}

export function RegistrationNumber({
    onCloseButton,
    onSubmit
}: RegistrationNumberProps){
    const [buttons, setButtons] = useState<number[]>();
    const [inputId, setInputId] = useState<number[]>([]);
    const [isView, setIsView] = useState<boolean>(false);
    const [ isError, setIsError ] = useState<boolean>(false);
    const [ isActive, setIsActive ] = useState<boolean>(false);

    function shuffle(array: number[]) {
        setButtons(array.sort(() => Math.random() - 0.5))
    }

    const buttonClick = (value: number) => {       
        
        if(inputId)
        {
            if(inputId?.length < 7)
            {
                setIsError(false);
                setIsActive(false)
                
                if(inputId.length === 6)
                {
                    setIsActive(true);
                }      
                setInputId([...inputId, value])                
            }
          
        }
        else
        {
            setIsActive(false);
            setIsError(false);
            setInputId([value])
        }
    }

    const deleteNumber = () => {   
        if(inputId && inputId.length > 0)
        {
            setIsActive(false);
            setIsError(false);
            setInputId(inputId.slice(0, inputId.length - 1))
        }
    }

    const submit = () => {
        // validation
        // 7자리 확인
        if(isActive)
        {
            // return 
            onSubmit(inputId.join(''));
        }
        else
        {
            setIsError(true);
        }
        // submit
    }

    useEffect(() => {
        shuffle(numberButtons);
    }, [])

    return (
        <Container>
            <Section>
                <CloseButton src={require("../../../styles/assets/common/btn_close_black.png")} 
                    onClick={() => {
                        onCloseButton();
                    }}
                />
                <Title>주민번호 <span>뒷번호</span>를 입력해 주세요</Title>
                <DotWrapper>
                    {inputId &&
                        inputId.map((input, index) => {
                            return(
                                isView ? (
                                    isError ? (
                                        <Dot key={index} active={true} />
                                    ) : (
                                        <Number key={index}>{input}</Number>
                                    )
                         
                                ) : (
                                    isError ? (
                                        index + 1 === inputId.length ?  index + 1 === 7 ? <Dot key={index} active={true} /> : (<Number key={index}>{input}</Number>) : <Dot key={index} active={true} />
                                    ):
                                    (
                                        index + 1 === inputId.length ?  index + 1 === 7 ? <Dot key={index} active={true} /> : (<Number key={index}>{input}</Number>) : <Dot key={index} active={true} />
                                    )
                                )                                
                            );
                        })
                    }     
                    
                    {
                        inputId &&                        
                        emptys.map((i, index) => {
                            return (
                                index < (7 - inputId.length) && <Dot key={index} active={false} /> 
                            );
                        })      
                    }               
                    
                    {
                        isView ? (
                            <ViewButton 
                                src={require('../../../styles/assets/common/icn_number_open_selected.png')}
                                onClick={() => {
                                    setIsView(prev => !prev)
                                }}
                            />
                        ): (
                            <ViewButton 
                                src={require('../../../styles/assets/common/icn_number_open_normal.png')}
                                onClick={() => {
                                    setIsView(prev => !prev)
                                }}
                            />
                        )
                    }
                    
                </DotWrapper>
                {
                    buttons &&
                    <>
                        <LineWrapper>
                            <Button
                                onClick={() => {
                                    buttonClick(buttons[0])
                                }}
                            >{buttons[0]}</Button>  
                            <Button
                                onClick={() => {
                                    buttonClick(buttons[1])
                                }}
                            >{buttons[1]}</Button> 
                            <Button
                                onClick={() => {
                                    buttonClick(buttons[2])
                                }}
                            >{buttons[2]}</Button> 
                        </LineWrapper>
                        <LineWrapper>
                            <Button
                                onClick={() => {
                                    buttonClick(buttons[3])
                                }}
                            >{buttons[3]}</Button>  
                            <Button
                                onClick={() => {
                                    buttonClick(buttons[4])
                                }}
                            >{buttons[4]}</Button> 
                            <Button
                                onClick={() => {
                                    buttonClick(buttons[5])
                                }}
                            >{buttons[5]}</Button> 
                        </LineWrapper>
                        <LineWrapper>
                            <Button
                                onClick={() => {
                                    buttonClick(buttons[6])
                                }}
                            >{buttons[6]}</Button>  
                            <Button
                                onClick={() => {
                                    buttonClick(buttons[7])
                                }}
                            >{buttons[7]}</Button> 
                            <Button
                                onClick={() => {
                                    buttonClick(buttons[8])
                                }}
                            >{buttons[8]}</Button> 
                        </LineWrapper>
                        <LineWrapper>
                            <Button
                                onClick={deleteNumber}
                            >
                                <Image src={require('../../../styles/assets/common/icn_delete_arrow_left.png')}
                                   
                                />  
                            </Button>
                
                            <Button
                                onClick={() => {
                                    buttonClick(buttons[9])
                                }}
                            >{buttons[9]}</Button> 
                            <SubmitButton acitve={isActive}
                                onClick={submit}
                            >선택완료</SubmitButton> 
                        </LineWrapper>
                    </>                   
                }
            </Section>
        </Container>
    );
}