// answer button
// normal, hover, click
// text, vector, onclick, activate, width, height
// motion을 사용하기위해 lable, vector, box를 나누지 않고 모두 합쳐 molecules로 이용함 

import styled from "styled-components";
import { motion } from 'framer-motion';
import { useState } from "react";
import { ImageProps } from "../../../types";

const Container = styled(motion.div)<ButtonProps>`
    border-radius: 30px;
    width: ${(props) => props.width}px;
    height: ${(props) => props.height}px;
    background: ${(props) => props.activate ==='false' ? "#000000" : "linear-gradient(52.08deg, #76BCC5 22.58%, #709EE0 55.66%, #075BD4 93.67%)"};
    box-sizing: border-box;
    border: 1px solid rgba(122, 122, 122, 1);
    cursor: pointer;
`;

const TextWrapper = styled.div`    
    width: 100%;
    height: 40%;
    display: flex;
    justify-content: center;
    align-items: flex-end;
`;

const Text = styled(motion.span)`
    font-family: "Apple SD Gothic Neo";
    font-weight: 700;
    line-height: 60px;
    color: rgba(122, 122, 122, 1);    
    font-size: 30px;
`;

const ActiveText = styled.span`
    font-family: "Apple SD Gothic Neo";
    font-weight: 700;
    line-height: 60px;
    color: rgba(30, 30, 30, 1);    
    font-size: 35px;
`;

const HoverText = styled.span`
    font-family: "Apple SD Gothic Neo";
    font-weight: 700;
    line-height: 60px;
    color: #075BD4;
    font-size: 35px;
`

const ImageWrapper = styled.div`
    width: 100%;
    height: 60%;    
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Image = styled.img`

`;

const containerVariants = {
    hover: {
        border: "1.5px solid #FFFFFF",
    }
}

interface ButtonProps {
    width?: string;
    height?: string;
    activate: string;
}

interface AnswerButtonProps {
    text: string;
    img: ImageProps;
    activate: boolean;
    width: string;
    height: string;
    onClick: () => void;
}

export function AnswerImageButton({text, img, activate, width, height, onClick}: AnswerButtonProps){
    const [ hovered, setHovered ] = useState(false);
    
    return(
        <Container 
            width={width} 
            height={height} 
            activate={!activate ? 'false' : 'true'} 
            variants={containerVariants}
            whileHover={!activate ? "hover" : "none"}
            onClick={onClick}
            onMouseEnter={() => setHovered(true)}
            onMouseLeave={() => setHovered(false)}
        >
            <TextWrapper>
                {hovered ? (
                    !activate ? 
                        <HoverText>{text}</HoverText>
                        : 
                        <ActiveText>{text}</ActiveText>
                    ) 
                    : 
                    (
                        !activate ? 
                        <Text>{text}</Text>
                        : 
                        <ActiveText>{text}</ActiveText>
                    )
                }    
            </TextWrapper>
            <ImageWrapper>
                {hovered ? (
                    !activate ? 
                    <Image src={img.hover} />
                    : 
                    <Image src={img.active} />
                ) 
                : 
                (
                    !activate ? 
                    <Image src={img.inactive} />
                    : 
                    <Image src={img.active} />
                )
                }    
            </ImageWrapper>    
        </Container>
    );
}
