import styled from "styled-components";
import { 
    ModalContents 
} from "../../../store";

import {
    UnderLineLabel
} from "../../atoms"

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    z-index: 99;
    background-color: rgba(0,0,0,0.8);
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
`;

const Section = styled.div`
    min-width: 1200px;
    min-height: 763px;
    max-height: 950px;
    border-radius: 50px;
    background-color: #fff;
    overflow: hidden;
    color: #000000;
    padding: 91px 51px;
    position: relative;
    display: flex;
    flex-direction: column;
    gap: 47px;
`;

const CloseButton = styled.img`
    position: absolute;
    right: 50px;
    top: 61px;
    width: 30px;
    height: 30px;
    cursor: pointer;
`;

const ContentsContainer = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 90px;
`;

const ContentsWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: flex-start;
    flex-direction: column;
    gap: 35px;
`;

const Title = styled.span`
    font-weight: 700;
    font-size: 30px;
    line-height: 23px;
    color: ${(props) => props.theme.bgColor};
`;

const Contents = styled.span`
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    color: ${(props) => props.theme.bgColor};
    width: 1048px;
`;

interface ModalProps {
    open: boolean;
    modalContents: ModalContents [] | undefined;
    onCloseButton: () => void;
}

export function DoctorAdviceModal({
    open,
    modalContents,
    onCloseButton
}: ModalProps){

    return (
        <>
        {open ? (
            <Container>
                <Section>
                    <CloseButton src={require("../../../styles/assets/common/btn_close_black.png")} 
                        onClick={onCloseButton}
                    />
                    <UnderLineLabel text="HOMECARE SOLUTION" fontSize="25" color="#000000"/>
                    <ContentsContainer>
                    {
                        modalContents && 
                        modalContents.map((content, index) => {
                            return (
                                <ContentsWrapper key={index}>
                                    <Title>{content.title}</Title>
                                    <Contents>{content.contents}</Contents>
                                </ContentsWrapper>

                            );
                        })
                    }
                    </ContentsContainer>
                </Section>
            </Container>
        ) : (
           null
        )}
        </>
        
    );
}