import styled from "styled-components";

const Container = styled.div`
    display: flex;
    width: 100%;
    height: 550px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: ${(props) => props.theme.textColor};
    background-color: #1A1A1A;
`;

const TitleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 25px;
`;

const Title = styled.p`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 60px;
    line-height: 70px;

    span {
        /* background: linear-gradient(90deg, #FFFFFF 0%, #FFFFFF 39.06%, #5472FF 39.07%, #57B6FF 70.31%, #51F7FF 100%); */
        background: linear-gradient(90deg, #5472FF 33%, #57B6FF 66%, #51F7FF 100%);
        background-clip: text;
        -webkit-background-clip: text;
        color: transparent;
    }
`;

const SubTitle = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    ${(props) => props.theme.textColor};
`;

export function CheerUp(){
    return (
        <Container>
            <TitleWrapper>
                <Title>당신에게 <span>필요한 솔직함</span></Title>
                <SubTitle>솔직한 닥터는 지금 당신에게 필요한 것이 무엇인지 말할 수 있는 용기가 있습니다</SubTitle>
            </TitleWrapper>
        </Container>
    );  
}