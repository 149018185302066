// 사용자가 답변한 설문을 보여주는 결과지 
import styled from "styled-components";

import {
    LinearGradientButton
} from "../../atoms";

import {
    AnswerList
} from "../../molecules";

import { 
    UserAnswerType 
} from "../../../types";

import { useState } from "react";

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 90px;
    width: 100px;
    flex-direction: column;
    z-index: 2;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 80px;
`;

interface AnswerViewProps {
    answers: UserAnswerType[];
}

export function AnswerView({
    answers
}:AnswerViewProps){
    // button
    // Answers
    const [ selected, setSelected ] = useState(0);
    const onButtonClick = (index: number) => {
        setSelected(index)
    }

    return (
        <Container>
            <ButtonWrapper>
                {answers.map((button, index) => {
                    return(
                        <LinearGradientButton 
                            key={index} title={button.category} onClick={() => {
                                onButtonClick(index)
                            }}
                            activate={selected === index ? true : false}
                        />
                    );
                })}
            </ButtonWrapper>
            <AnswerList answers={answers[selected].answers}/>
        </Container>

    );
}