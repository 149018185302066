import styled from "styled-components";

interface ButtonProps {
    activate: boolean;
}

const Button = styled.button<ButtonProps>`
    background-color: ${(props) => !props.activate ? "#141416" : "#FFFFFF"};
    border: 0;
    border-radius: 100px;
    color:  ${(props) => !props.activate ? "#47494B" : "#000000"};
    font-family: 'Apple SD Gothic Neo';
    font-size: 30px;
    font-weight: 400;
    width: 210px;
    min-height: 75px;
    cursor: ${(props) => !props.activate ? "normal" : "pointer"};
`;

interface NextButtonProps {
    name: string;
    activate: boolean;
    onClick: () => void;
}

export function NextButton({name, activate, onClick}: NextButtonProps){

    const onButtonClick = () => {
        if(activate)
        {
            onClick()
        }
    }

    return (
        <Button activate={activate} onClick={onButtonClick}>{name}</Button>
    );
};