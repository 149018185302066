import styled from "styled-components";

const Title = styled.p<{fontSize: string}>`
    font-size: ${(props) => props.fontSize}px;
    font-weight: 700;
    background: linear-gradient(90deg, rgba(118,188,197,1)  33%, rgba(112,158,224,1) 66%, rgba(7,91,212,1) 100%);
    background-clip: text;
    -webkit-background-clip: text;
    color: transparent;
    text-align: center;
`;

interface SurveyTitleProps {
    title: string;
    fontSize: string;
}

export function LinearGradientLabel({title, fontSize}: SurveyTitleProps){

    return <Title fontSize={fontSize}>{title}</Title>;
}