export enum SurveyType {
    "SKIN",
    "LINE"
}

export enum AddInfoTypeEnum{
    "GRAPH",
    "IMAGE"
}

export enum SurveyCategoryEnum {
    "ACNE",
    "DRY",
    "BROWN",
    "ANTIAGING",
    "BLUSH"
}

export enum ResultTypeEnum {
    "NA",
    "A",
    "B",
    "C",
    "FORCE_A", 
    "FORCE_B", 
    "FORCE_C",
    "FORCE_MANY_RESULT"
}

export enum JumpTypeEnum {
    "NOP",
    "CONDITIONAL_JUMP",   // 특정 답을 선택했을때 답에 따라 점프
    "SURVEY_JUMP"
}

export enum SelectedEnum { // select type
    "MULTI",
    "SINGLE",
    "CONDITION_JUMP",  // question number 로 이동
}

export enum ConditionTypeEnum {
    "NOP",
    "EQUAL",
    "LESS", 
    "GREATER",
    "RANGE"
}

export enum TargetTypeEnum {
    "NOP",
    "AGE", 
    "BMI",
    "GENDER"
}

export enum ResultCalcTypeEnum {  // 모든 결과 확정은 결과 배열에 999로 큰 값을 넣는다
    "COUNT", // result type [] 에 들어있는 결과를 합산하여 배열에 넣는다. 
    // "FORCE_SINGLE", // 선택한 결과 (single select) 에 들어있는 result type을 9999로 바꿔 넣는다. 
    "FORCE_ONE_RESULT", // 지정한 갯수 이상의 ResultType이 NA가 아닌 결과를 만족한다면 결과가 된다. 갈색색소 1-a
    "FORCE_MANY_RESULT" // 1~2 A, 3~5 B,    안티에이징 1
}

export interface ForceMultiResultType {
    condition: ConditionTypeEnum;
    startValue: number;
    endValue?: number;
    resultType: ResultTypeEnum;
}

//types export
export interface ImageProps {
    active: string;
    inactive: string;
    hover: string;
}

export interface ImageButtonProps{
    text: string;
    img: ImageProps;
    activate: boolean;
    width: string;
    height: string;
    nextQuestionIndex?: number;
    viewConditionType? : ConditionTypeEnum;
    viewConditionValue? : number | string;
    viewConditionEndValue?: number | string;
    viewConditionTarget?: TargetTypeEnum;
    resultType: ResultTypeEnum [];
    resultView: boolean; // 결과지에 보여지는 여부 
    resultViewAltMsg?: string; 
    jumpSurvey? : SurveyCategoryEnum;
    multiSelect: boolean; // 이버튼과 함께 멀티 선택 금지 
    forceJump?: boolean;
}

export interface TextButtonProps {
    text: string;
    activate: boolean;
    width: string;
    nextQuestionIndex?: number;
    isInput: boolean;
    viewConditionType? : ConditionTypeEnum;
    viewConditionValue? : number | string;
    viewConditionEndValue?: number | string;
    viewConditionTarget?: TargetTypeEnum;
    resultType: ResultTypeEnum [];
    resultView: boolean; // 결과지에 보여지는 여부 
    resultViewAltMsg?: string; 
    jumpSurvey? : SurveyCategoryEnum;
    multiSelect: boolean; // 이버튼과 함께 멀티 선택 금지 
    forceJump?: boolean; // 우선순위를 가지는 강제 점프 (멀티셀렉에서 해당 버튼이 포함되면 해당 버튼의 conditionJump로 이동함)
}

export interface RangeButtonProps {
    text: string;
    activate: boolean;
    nextQuestionIndex?: number;
    viewConditionType? : ConditionTypeEnum;
    viewConditionValue? : number | string;
    viewConditionEndValue?: number | string;
    viewConditionTarget?: TargetTypeEnum;
    resultType: ResultTypeEnum [];
    resultView: boolean; // 결과지에 보여지는 여부 
    resultViewAltMsg?: string; 
    jumpSurvey? : SurveyCategoryEnum;
    multiSelect: boolean; // 이 버튼과 함께 멀티 선택 금지 
    forceJump?: boolean;
}

export enum ButtonTypeEnum {
    "IMAGE_SINGLE",
    "IMAGE_MULTI",
    "TEXT_SINGLE", 
    "TEXT_MULTI", 
    "TEXT_INPUT_SINGLE", 
    "TEXT_INPUT_MULTI",
    "RANGE", 
    "DATE",
    "EMAIL",
    "BIRTH"
}

export interface QuestionType {
    questionTitle: string;
    endLetter: string;
    questionSubTitle?: string;
    selectedButtonIndex: number [];
    buttonType: ButtonTypeEnum;
    jump: JumpTypeEnum,
    order: number;
    grid: number;
    viewConditionType: ConditionTypeEnum;
    viewConditionValue: number | string;
    viewConditionEndValue?: number | string;
    viewConditionTarget: TargetTypeEnum;
    rangeStartLabel?: string;
    rangeEndLabel?: string;
    resultView: boolean; // 결과지에 보여지는 여부 
    resultViewAltMsg?: string; 
    resultCalcType: ResultCalcTypeEnum,
    forceMultiResultCondition?: ForceMultiResultType[] 
    isLast: boolean,
    buttons: ImageButtonProps[] | TextButtonProps[] | RangeButtonProps [];
}

export interface SurveyStateType {
    type: string;
    category: string;
    question: QuestionType[]
}


export interface AnswerType {
    question: string;
    answer: string;
}

export interface UserAnswerType {
    category: string; // profil, skincare, lifestyle ...
    answers: AnswerType []
}


export interface UserStateType {
    birth: string;
    age: number;
    gender: string;
    select: string;
    year: string;
    month: string;
    day: string;
}

export interface LoginStateType {
    accessToken: string;
    refreshToken: string;
    isLogin: boolean;
    userName: string;
    key: string
}

export interface SurveyAnswerButtonType {
    buttonIndex: number;
    answerText: string;
}

export interface SurveyAnswerQuestionType {
    questionIndex: number;
    answer: SurveyAnswerButtonType []
}

export interface SurveyAnswerType {
    category: string; // acne
    question: SurveyAnswerQuestionType []
}

interface AddUserInfo {
    birth: string;
    drink: string;
    gender_type: number;
    height: string;
    name: string;
    phone_number: string;
    registration_number: string;
    smoke: string;
    type: number;   // 본인과의 관계
    weight: string;
    pregnancy: string;
}

export interface BookingType {
    data: {
        detail: string;
        hospital_ids: number[],
        user: {
            add_info: AddUserInfo;
            name: string;
            phone_number: string;
        };
    },
    time: string;
}

export interface QAType {
    question: string;
    answers: string[];
}

export interface DoctorViewType {
    time: string;
    category: string;
    qa: QAType[]
}