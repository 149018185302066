import styled from "styled-components";
import qs from 'qs';

import {
    useLocation,
    useParams
} from "react-router-dom";

import {
    RoundImageButton
} from "../../../components/atoms"

import {
    SaveModal
} from "../../../components/organisms"
import { useState } from "react";

const Container = styled.div`
    background-color: #F3F5F8;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding-top: 151px;
    padding-bottom: 135px;
    flex-direction: column;
    gap: 300px;
`;

const ShareWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 80px;
`;

const Title = styled.div`
    font-weight: 700;
    font-size: 70px;
    line-height: 70px;
    color: #000000;
    span{
        font-weight: 400;
        font-family: 'felixTitling';
    }
`;

const SubTitle = styled.div`
    font-weight: 400;
    font-size: 50px;
    line-height: 50px;
    color: #000000;
`;

const Image = styled.img`

`;

const ButtonWrapper = styled.div<{gap: string}>`
    display: flex;
    gap: ${(props) => props.gap}px;
    justify-content: center;
    align-items: center;
    width: 100%;
`;

const ButtonTextWrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 40px;
    cursor: pointer;
`;

const ButtonLabel = styled.div`
    font-weight: 400;
    font-size: 50px;
    line-height: 50px;
    color: #000000;
    text-align: center;
`;

const SaveLink = styled.div`
    position: fixed;
    height: 221px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    color: ${(props) => props.theme.textColor};
    background-color: ${(props) => props.theme.bgColor};
    font-size: 50px;
    font-weight: 400;
    font-family: 'Apple SD Gothic Neo';
    line-height: 20px;
    bottom: 0;
    left: 0;
    z-index: 30;
`;

export function Share(){
    const location = useLocation();
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const surveyId = query.surveyId;

    const { type, category, question } = useParams();    
    const [ modalOpen, setModalOpen ] = useState(false);    
    const [ isSave, setIsSave ] = useState(false);

    const handleCopyClipBoard = async () => {
        try {
          await navigator.clipboard.writeText(`https://clubhonest.co.kr${location.pathname}?surveyId=${surveyId}`);
          setIsSave(true);
          setTimeout(() => setIsSave(false), 1000);
            
        } catch (error) {

        }
      };  

    return (
        <Container>
            { isSave && <SaveLink>URL 복사가 완료되었습니다.</SaveLink>}
            <SaveModal open={modalOpen} onCloseButton={() => setModalOpen(false)} />
            <ShareWrapper>
                <Title>함께 <span>CLUB HONEST</span> 하세요</Title>
                <ButtonWrapper gap="88">
                    <RoundImageButton onClick={() => {
                        console.log("test")
                        if(window.Kakao)
                        {
                                // 3b1f51e5f77353b96b98d26211d6ffc9
                            const kakao = window.Kakao;
                            if (!kakao.isInitialized()) {
                                // 두번째 step 에서 가져온 javascript key 를 이용하여 initialize
                                // kakao.init(process.env.REACT_APP_KAKAO_KEY)
                                kakao.init("135078c5864bd266f4116febc317d279");
                            }
                            kakao.Link.sendDefault({
                                objectType: 'feed',
                                content: {
                                    title: '클럽어니스트 서베이 결과보기',
                                    imageUrl:
                                        'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/result/kakao_share.png',   //순서 날짜만 바꿔서 
                                    link: {   //move 
                                        webUrl:`https://clubhonest.co.kr/${type}/result/${category}?surveyId=${surveyId}`,
                                        mobileWebUrl:`https://m.clubhonest.co.kr/${type}/result/${category}?surveyId=${surveyId}`
                                    },
                                },
                                buttons: [
                                    {
                                        title: '결과보기',   // page 이동 and auto play
                                        link: {
                                            webUrl:`https://clubhonest.co.kr/${type}/result/${category}?surveyId=${surveyId}`,
                                            mobileWebUrl: `https://m.clubhonest.co.kr/${type}/result/${category}?surveyId=${surveyId}`
                                        },
                                    }                                   
                                ]
                            });
                        
                        }
                    }} bgColor="#F6E44C" url="https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/kakao.png" />
                    <RoundImageButton onClick={() => {
                        window.open(`https://www.facebook.com/sharer/sharer.php?u=https://clubhonest.co.kr/${type}/result/${category}?surveyId=${surveyId}`)
                    }} bgColor="#1977F3" url="https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/facebook.png" />
                    <RoundImageButton onClick={() => {
                            handleCopyClipBoard()
                    }} bgColor="#000000" url="https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/share.png" />
                </ButtonWrapper>
            </ShareWrapper>
            <ShareWrapper>
                <SubTitle>서베이 결과를 저장하고 싶으신가요?</SubTitle>
                <ButtonWrapper gap="205">
                    <ButtonTextWrapper
                        onClick={() => setModalOpen(true)}
                    >
                        <RoundImageButton onClick={() => {}} bgColor="#000000" url="https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/result/btn_export_image.png"/>
                        <ButtonLabel>전체 이미지<br/>저장하기</ButtonLabel>
                    </ButtonTextWrapper>
                </ButtonWrapper>
            </ShareWrapper>
            <ShareWrapper>
                <Image src={require('../../../styles/assets/result/footer_logo.png')} />
            </ShareWrapper>
        </Container>

    );
}