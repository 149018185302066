import { useState } from "react";
import styled from "styled-components";

import { 
    HonestAdviceType 
} from "../../../store";

import { 
    UnderLineLabel,
    CircleImage 
} from "../../atoms";

import {
    DoughnutGraph
} from "../../molecules"

import {
    AddInfoTypeEnum
} from '../../../types';
import { 
    ImageAdviceModal
 } from "../../organisms";

const Container = styled.div`
    width: 1214px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;
`;

const ContentsWrapper = styled.div`
    min-width: 746px;
    display: flex;
    flex-direction: column;
    gap: 35px;
    justify-content: center;
    align-items: flex-start;
`;

const Title = styled.span`
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: ${(props) => props.theme.textColor};
`;

const Contents = styled.span`
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    color: #C1C1C1;
    width: 746px;
`;

const Button = styled.div`
    height: 89px;
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    color: ${(props) => props.theme.textColor};
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    cursor: pointer;
    padding-left: 50px;
    padding-right: 50px;
`;

interface DoctorAdviceProps {
    honest: HonestAdviceType;
}

export function HonestAdvice({
    honest
}: DoctorAdviceProps){
    const [ modalOpen, setModalOpen ] = useState(false);

    return (
        <Container>
            <ImageAdviceModal open={modalOpen} modalContents={honest.modalContents} onCloseButton={() => setModalOpen(false)} />
            <ContentsWrapper>
                <UnderLineLabel text="CLUBHONEST ADVICE" fontSize="25" color="#ffffff"/>
                <Title dangerouslySetInnerHTML={{__html: honest.title}} />
                <Contents>{honest.contents}</Contents>
                {
                    honest.modalContents && 
                    <Button
                        onClick={()=>{
                            setModalOpen(true)
                        }}
                    >{honest.modalButtonText}</Button>
                }
            </ContentsWrapper>    
            {honest.addInfoType === AddInfoTypeEnum.GRAPH ? (
                <DoughnutGraph percent={honest.precent as number} />
            ) : (
                <CircleImage url={honest.imgUrl as string} size="374" />
            )}
           
        </Container>
       
    );
}