import { useState } from "react";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { motion } from 'framer-motion';

import {
    SurveyCategoryEnum
} from '../../../types'

const Container = styled.div`
    position: relative;
    width: 100%;
    display: flex;
    height: 700px;
    background-color: #4F6A9F;
`;

const Back = styled.div`
    position: absolute;
    width: 100%;
    width: 2000px;
    height: 700px;
    justify-content: flex-start;
    align-items: flex-start;
    padding: 176px 400px;
    flex-direction: column;
    gap: 60px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
`;

const BackImage = styled.img`
    position: absolute;
    width: 100%;
    width: 2000px;
    height: 700px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0%);
`;

const Symbol = styled(motion.img)`    
    position: fixed;
    width: 172px;
    height: 172px;
    bottom: 30px;
    right: 30px;
    z-index: 999;
    cursor: pointer;
`;

const TitleWrapper = styled.div`
    display: flex;
    gap: 26px;
    flex-direction: column;
`;

const Title = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 56px;
    line-height: 74px;
    color: ${(props) => props.theme.textColor};
    text-align: left;
    z-index: 2;
`;

const SubTitle = styled.span`
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 30px;
    color: ${(props) => props.theme.textColor};
    text-align: left;
    z-index: 2;
`;

const ButtonWrapper = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 8px;
    margin-top: 67px;
`;

const ButtomImage = styled.img`
    z-index: 2;
    cursor: pointer;
`;

const buttonVariants = {
    hover: {
        rotateZ: 25,

    }
}

export function Hero(){

    const navigate = useNavigate();
    
    const [ hover1, setHover1 ] = useState(false);
    const [ hover2, setHover2 ] = useState(false);
    const [ hover3, setHover3 ] = useState(false);
    const [ hover4, setHover4 ] = useState(false);
    const [ hover5, setHover5 ] = useState(false);
 
    const onButtonClick = (category: SurveyCategoryEnum) => {

        if(category === SurveyCategoryEnum.ACNE)
        {
            navigate('/skin/start/init')
        }
        else if(category === SurveyCategoryEnum.BLUSH)
        {
            navigate('/skin/start/init')
        }
        else if(category === SurveyCategoryEnum.BROWN)
        {
            navigate('/skin/start/init')
        }
        else if(category === SurveyCategoryEnum.DRY)
        {
            navigate('/skin/start/init')
        }
        else if(category === SurveyCategoryEnum.ANTIAGING)
        {
            navigate('/skin/start/init')
        }
    }

    return (
        <Container>
            <Symbol src={require("../../../styles/assets/survey/survey-start.png")}
                    variants={buttonVariants}
                    whileHover="hover"
                    transition={{ type: "tween", duration: 0.5, delay: 0}}
                    onClick={
                        () => {
                            navigate("/skin/start/init")
                        }
                    }
            />
            <BackImage src={require('../../../styles/assets/skindetail/skin_detail_banner.png')}/>
            <Back>                
                <TitleWrapper>
                    <Title dangerouslySetInnerHTML={{__html: '당신이 생각하는<br/>아름다움'}} />
                    <SubTitle dangerouslySetInnerHTML={{__html: '당신의 마음, 당신의 피부 모든것에<br/>걱정이 없는 순간이 아름답다고 믿습니다.'}} />
                </TitleWrapper>
                <ButtonWrapper>
                    {
                        hover1 ? (
                            <ButtomImage src={require('../../../styles/assets/skindetail/acne_on.png')}
                                onMouseEnter={() => setHover1(true)}
                                onMouseLeave={() => setHover1(false)}
                                onClick={() => onButtonClick(SurveyCategoryEnum.ACNE)}
                            />

                        ):
                        (
                            <ButtomImage src={require('../../../styles/assets/skindetail/acne_off.png')} 
                                onMouseEnter={() => setHover1(true)}
                                onMouseLeave={() => setHover1(false)}
                                onClick={() => onButtonClick(SurveyCategoryEnum.ACNE)}
                            />
                        )
                    }
                    {
                        hover2 ? (
                            <ButtomImage src={require('../../../styles/assets/skindetail/flu_on.png')}
                                onMouseEnter={() => setHover2(true)}
                                onMouseLeave={() => setHover2(false)}
                                onClick={() => onButtonClick(SurveyCategoryEnum.BLUSH)}
                            />

                        ):
                        (
                            <ButtomImage src={require('../../../styles/assets/skindetail/flu_off.png')} 
                                onMouseEnter={() => setHover2(true)}
                                onMouseLeave={() => setHover2(false)}
                                onClick={() => onButtonClick(SurveyCategoryEnum.BLUSH)}
                            />
                        )
                    }
                    {
                        hover3 ? (
                            <ButtomImage src={require('../../../styles/assets/skindetail/mel_on.png')}
                                onMouseEnter={() => setHover3(true)}
                                onMouseLeave={() => setHover3(false)}
                                onClick={() => onButtonClick(SurveyCategoryEnum.BROWN)}
                            />

                        ):
                        (
                            <ButtomImage src={require('../../../styles/assets/skindetail/mel_off.png')} 
                                onMouseEnter={() => setHover3(true)}
                                onMouseLeave={() => setHover3(false)}
                                onClick={() => onButtonClick(SurveyCategoryEnum.BROWN)}
                            />
                        )
                    }
                    {
                        hover4 ? (
                            <ButtomImage src={require('../../../styles/assets/skindetail/dry_on.png')}
                                onMouseEnter={() => setHover4(true)}
                                onMouseLeave={() => setHover4(false)}
                                onClick={() => onButtonClick(SurveyCategoryEnum.DRY)}
                            />

                        ):
                        (
                            <ButtomImage src={require('../../../styles/assets/skindetail/dry_off.png')} 
                                onMouseEnter={() => setHover4(true)}
                                onMouseLeave={() => setHover4(false)}
                                onClick={() => onButtonClick(SurveyCategoryEnum.DRY)}
                            />
                        )
                    }   
                    {
                        hover5 ? (
                            <ButtomImage src={require('../../../styles/assets/skindetail/wrin_on.png')}
                                onMouseEnter={() => setHover5(true)}
                                onMouseLeave={() => setHover5(false)}
                                onClick={() => onButtonClick(SurveyCategoryEnum.ANTIAGING)}
                            />

                        ):
                        (
                            <ButtomImage src={require('../../../styles/assets/skindetail/wrin_off.png')} 
                                onMouseEnter={() => setHover5(true)}
                                onMouseLeave={() => setHover5(false)}
                                onClick={() => onButtonClick(SurveyCategoryEnum.ANTIAGING)}
                            />
                        )
                    }               
                </ButtonWrapper>

            </Back>
           
        </Container>
    );
}