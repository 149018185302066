import styled from "styled-components";
import {
    motion
} from 'framer-motion';
import { useState } from "react";

const Container = styled(motion.div)`
    height: 112px;
    width: 1200px;
    border-bottom: 1px solid #1A1A1A;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 600;
    font-size: 38px;
    line-height: 70px;
    color: #1A1A1A;
    overflow: hidden;
    cursor: pointer;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 112px;
    width: 100%;
`;

const Image = styled(motion.img)`

`;

const Contents = styled.div`
    width: 1200px;
    height: 155px;
    background: #FFFFFF;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    padding: 45px 35px;
    font-family: 'Apple SD Gothic Neo';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 36px;
    color: #1A1A1A;
    border-radius: 30px;
`;

interface AccordionProps {
    title: string;
    contents: string;
}

const variants = {
    start: {
        height: '112px'
    },
    end: {
        height: '300px'
    }
}


export function Accordion({
    title,
    contents
}: AccordionProps){
    const [activate, setActivate] = useState(false);
    return (
        <Container variants={activate ? variants : undefined} initial="start" animate="end" 
            transition={{delay: 0, duration: 0.5}}
            onClick={() => setActivate(prev => !prev)}
        >
            <Wrapper>
                {title}
                {activate ? <Image src={require('../../../styles/assets/common/minus.png')} /> 
                : <Image src={require('../../../styles/assets/common/plus.png')} />}
                
            </Wrapper>            
            <Contents>{contents}</Contents>
        </Container>
    );
}