import styled from "styled-components";
import {
    QuestionSubTitle,
    QuestionTitle,
    SurveyHeaderTitle, 
    LinearGradientLabel, 
    NextButton
} from '../../atoms';

import {
    AnswerRangeButton
} from '../../molecules';

import { RangeButtonProps } from "../../../types";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 60px;
    background-color: ${(props) => props.theme.bgColor};
    height: 100%;
`;

const QuestionWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 30px;
`;

const AnswerWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    flex-direction: column;
    gap: 29px;
`;

const RangeWrapper = styled.div`
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 18px;
    height: 200px;
`;

const RangeLabel = styled.div`
    width: 1202px;
    display: flex;
    justify-content: space-between;
    align-items: center;
`;

const Label = styled.div`
    font-style: normal;
    font-weight: 700;
    font-size: 30px;
    line-height: 60px;
    color: #FFFFFF;
`;

interface SurveyRangeSelectProps {
    surveyTitle: string;
    questionTitle: string;
    questionSubTitle?: string;
    endLetter: string;
    grid: number;
    rangeStartLabel: string;
    rangeEndLabel: string;
    buttons: RangeButtonProps[],
    onClick: (index: number) => void;
    buttonActive: boolean;
    onNextButtonClick: () => void;
}

export function SurveyRangeSelect({
    surveyTitle,
    questionTitle,
    questionSubTitle,
    endLetter,
    grid,
    rangeStartLabel,
    rangeEndLabel,
    buttons,
    onClick,
    buttonActive,
    onNextButtonClick
}: SurveyRangeSelectProps){

    const onButtonClick = (index: number) => {
        onClick(index);
    }

    return (
        <Container>
            <QuestionWrapper>     
                <LinearGradientLabel fontSize="15" title={surveyTitle} />
                <QuestionTitle title={questionTitle}  endLetter={endLetter}/>
                {questionSubTitle && (<QuestionSubTitle title={questionSubTitle} />)}                
            </QuestionWrapper>
            <AnswerWrapper>
                <RangeLabel>
                    <Label>{rangeStartLabel}</Label>
                    <Label>{rangeEndLabel}</Label>
                </RangeLabel>
                <RangeWrapper>
                    {buttons.map((button, index) => {
                        return(
                            <AnswerRangeButton key={index} {...button} onClick={() => onButtonClick(index)}/>
                        );
                    })}
                </RangeWrapper>
            </AnswerWrapper>
            {/* <NextButton activate={buttonActive} name="다음" onClick={onNextButtonClick} /> */}
        </Container>
    );
}