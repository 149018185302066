// 설문지, 답, 결과 
// user

// type: skin, line
// category: acne, dry, brown, antiaging, blush
// survey
// question: 
// {title: "현재 가장 큰 피부 고민은 무엇인가요", "description": 해당되는 부위를 모두 골라주세요.,  select: multi, single, "buttons": [
//    {type: image, text-input, text, slider,  url: '', value: "" label: ""}
//]}
// endof question -> next step -> 
// health info
// upload info
// result

import { atom } from "recoil";
import { 
    ConditionTypeEnum, 
    TargetTypeEnum, 
    ResultTypeEnum,
    ButtonTypeEnum,
    ResultCalcTypeEnum,
    SurveyStateType,
    JumpTypeEnum,
    SurveyCategoryEnum
} from '../types'

export const resultLoadingState = atom<boolean>({
    key: 'resultLoadingState',
    default: false
})

export const lastSelectedSurveyIndexState = atom<number[]>({
    key: 'lastSelectedSurveyIndexState',
    default: []
})

export const surveyAtom = atom<SurveyStateType>({
    key: 'surveyAtom',
    default: undefined
})

// 질문과 답변 대체 word가 추가 되어야 한다. 
export const surveyState = atom<SurveyStateType[]>({
    key: 'surveyState',
    default: [
        {
            type: 'SKINCARE',
            category: 'acne',
            question: [
                {
                    questionTitle: '여드름이 주로 나는 부위를 알려주세요',
                    endLetter: '.',
                    questionSubTitle: '해당되는 부위를 모두 골라주세요.(중복선택 가능)',
                    selectedButtonIndex: [],
                    buttonType: ButtonTypeEnum.IMAGE_MULTI,
                    jump: JumpTypeEnum.NOP,
                    order: 1,
                    grid: 3,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,  //target 비교 값 
                    // viewConditionEndValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '세부고민',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '얼굴',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/face-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/face-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/face-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '얼굴',
                            // jumpSurvey: SurveyCategoryEnum.ACNE,
                            multiSelect: true
                        },
                        {
                            text: '가슴/등/어깨',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/body-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/body-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/body-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //정답을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.B ],
                            resultView: true,
                            resultViewAltMsg: '가슴/등/어깨',
                            multiSelect: true
                        },
                        {
                            text: '기타',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/etc-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/etc-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/etc-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //정답을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.C ],
                            resultView: true,
                            resultViewAltMsg: '기타',
                            multiSelect: true
                        }
                    ]
                }, // q 0
                // {
                //     questionTitle: '모양은 어떤가요',
                //     questionSubTitle: '해당되는 설명을 모두 골라주세요',
                //     endLetter: '?',
                //     buttonType: ButtonTypeEnum.TEXT_MULTI,
                //     selectedButtonIndex: [],
                //     jump: JumpTypeEnum.NOP,
                //     order: 1,
                //     grid: 1,
                //     viewConditionType: ConditionTypeEnum.LESS,  // equal, lessthann, greaterthan
                //     viewConditionValue: 18,
                //     viewConditionTarget: TargetTypeEnum.AGE,
                //     resultView: false,
                //     resultViewAltMsg: '',
                //     resultCalcType: ResultCalcTypeEnum.FORCE_ONE_RESULT,
                //     forceMultiResultCondition: [{condition: ConditionTypeEnum.GREATER, startValue: 2, resultType: ResultTypeEnum.FORCE_C}],
                //     buttons: [
                //         {
                //             text: '결계가 뚜렸하고 모양이 대칭적이에요',
                //             activate: false,                
                //             isInput: false,       
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.NA ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: true
                //         },
                //         {
                //             text: '결계가 불분명하고 모양이 비대칭적이에요',
                //             activate: false,
                //             isInput: false,
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.FORCE_C ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: true
                //         },
                //         {                       
                //             text: '색이 균일해보여요',
                //             activate: false,
                //             isInput: false,
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.NA ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: true
                //         },
                //         {                       
                //             text: '색이 불균일해보여요',
                //             activate: false,
                //             isInput: false,
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.FORCE_C ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: true
                //         },
                //         {                       
                //             text: '크기가 6mm보다 작아요',
                //             activate: false,
                //             isInput: false,
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.NA ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: true
                //         },
                //         {                       
                //             text: '크기가 6mm보다 커요',
                //             activate: false,
                //             isInput: false,
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.FORCE_C ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: true
                //         },
                //         {                       
                //             text: '최근에 크기가 갑자기 커진 것 같아요',
                //             activate: false,
                //             isInput: false,
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.NA ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: true
                //         },
                //         {                       
                //             text: '기타',
                //             activate: false,
                //             isInput: false,
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.NA ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: true
                //         }
                //     ]
                // }, // q2
                // {
                //     questionTitle: '현재 크게 고민되는 것을 골라주세요',
                //     endLetter: '.',
                //     buttonType: ButtonTypeEnum.TEXT_MULTI,
                //     selectedButtonIndex: [],
                //     jump: JumpTypeEnum.NOP,
                //     order: 1,
                //     grid: 1,
                //     viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //     viewConditionValue: 0,
                //     viewConditionTarget: TargetTypeEnum.AGE,
                //     resultView: false,
                //     resultViewAltMsg: '',
                //     resultCalcType: ResultCalcTypeEnum.FORCE_MANY_RESULT,
                //     forceMultiResultCondition: [
                //         {condition: ConditionTypeEnum.RANGE, startValue: 1, endValue: 2, resultType: ResultTypeEnum.FORCE_A},
                //         {condition: ConditionTypeEnum.RANGE, startValue: 3, endValue: 5, resultType: ResultTypeEnum.FORCE_B}
                //     ],
                //     buttons: [
                //         {
                //             text: '한눈에 보이는 깊은 주름',
                //             activate: false,                
                //             isInput: false,       
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.FORCE_MANY_RESULT ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: true
                //         },
                //         {
                //             text: '가까이서 도드라지는 잔주름',
                //             activate: false,
                //             isInput: false,
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.FORCE_MANY_RESULT ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: true
                //         },
                //         {                       
                //             text: '피부탄력과 피부결',
                //             activate: false,
                //             isInput: false,
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.FORCE_MANY_RESULT ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: true
                //         },
                //         {                       
                //             text: '피부의 볼륨감',
                //             activate: false,
                //             isInput: false,
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.FORCE_MANY_RESULT ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: true
                //         },
                //         {                       
                //             text: '특별한 고민은 없지만 꾸준한 관리를 원해요',
                //             activate: false,
                //             isInput: false,
                //             width: "600",
                //             nextQuestionIndex: 0,
                //             viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                //             viewConditionValue: 0,
                //             viewConditionTarget: TargetTypeEnum.NOP,
                //             resultType: [ ResultTypeEnum.FORCE_C ],
                //             resultView: false,
                //             resultViewAltMsg: '',
                //             multiSelect: false
                //         }
                //     ]
                // },
                {
                    questionTitle: '여드름에 대한 고민이 언제부터 시작되었나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 2,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.GREATER,  // equal, lessthann, greaterthan
                    viewConditionValue: 17,
                    viewConditionTarget: TargetTypeEnum.AGE,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '청소년기 이전부터 고질적으로 발생',
                            activate: false,                
                            isInput: false,       
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '청소년기에 시작',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '성인이 되고 나서 시작',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, // q1
                {
                    questionTitle: '여드름 고민은 언제부터 시작되었나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 3,
                    grid: 2,
                    viewConditionType: ConditionTypeEnum.LESS,  // equal, lessthann, greaterthan
                    viewConditionValue: 18,
                    viewConditionTarget: TargetTypeEnum.AGE,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '1-3개월 전부터',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '3-6개월 전부터',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                        
                            text: '6개월-1년 전부터',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '1년 이상 지속',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                },  //q2
                {
                    questionTitle: '여드름이 어느 정도로 나는 편인가요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 3,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.EQUAL,  // equal, lessthann, greaterthan
                    viewConditionValue: '여자',
                    viewConditionTarget: TargetTypeEnum.GENDER,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '한 달에 1-2개 또는 그보다 적게 생겨요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.C ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '1-2주 사이에 몇 개의 여드름이 올라와요.',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '자주 여기저기 여러 개의 여드름이 올라오고 색소침착과 붉기가 있어요.',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '항상 새로운 여드름이 올라오고 여드름 흉터가 있어서 한눈에 보기에도 심한 편이에요.',
                            activate: false,
                            isInput: false,
                            width: "1200",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '생리 때마다 주기적으로 올라와요.',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.EQUAL,  // equal, lessthann, greaterthan
                            viewConditionValue: '여자',
                            viewConditionTarget: TargetTypeEnum.GENDER,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        }
                    ]
                },  //q3
                {
                    questionTitle: '여드름이 어느 정도로 나는 편인가요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 3,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.EQUAL,  // equal, lessthann, greaterthan
                    viewConditionValue: '남자',
                    viewConditionTarget: TargetTypeEnum.GENDER,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '한 달에 1-2개 또는 그보다 적게 생겨요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '1-2주 사이에 몇 개의 여드름이 올라와요.',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '자주 여기저기 여러 개의 여드름이 올라오고 색소침착과 붉기가 있어요.',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '항상 새로운 여드름이 올라오고 여드름 흉터가 있어서 한눈에 보기에도 심한 편이에요.',
                            activate: false,
                            isInput: false,
                            width: "1200",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                    
                    ]
                },  //q4
                {
                    questionTitle: '화장품에 대해 얼마나 알고 있나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.RANGE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 4,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    rangeStartLabel: '거의 몰라요',
                    rangeEndLabel: '매우 잘 알아요',
                    resultView: true,
                    resultViewAltMsg: '제품 이해도',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '1',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '1',
                            multiSelect: false
                        },
                        {
                            text: '2',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '2',
                            multiSelect: false
                        },
                        {
                            text: '3',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '3',
                            multiSelect: false
                        },
                        {
                            text: '4',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '4',
                            multiSelect: false
                        },
                        {
                            text: '5',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '5',
                            multiSelect: false
                        },
                        {
                            text: '6',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '6',
                            multiSelect: false
                        },
                        {
                            text: '7',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '7',
                            multiSelect: false
                        },
                        {
                            text: '8',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '8',
                            multiSelect: false
                        },
                        {
                            text: '9',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '9',
                            multiSelect: false
                        },
                        {
                            text: '10',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '10',
                            multiSelect: false
                        }
                    ]
                }, // q5
                {
                    questionTitle: '평소에 사용하는 제품군을 알려주세요',
                    endLetter: '.',             
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 1,
                    grid: 4,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '제품군',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '클렌저',
                            activate: false,
                            isInput: false,
                            width: "200",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '클렌저',
                            multiSelect: true
                        },
                        {                     
                            text: '수분 크림',
                            activate: false,
                            isInput: false,
                            width: "250",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '수분 크림',
                            multiSelect: true
                        },
                        {
                            text: '토너',
                            activate: false,
                            isInput: false,
                            width: "150",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '토너',
                            multiSelect: true
                        },
                        {                     
                            text: '각질 제거제',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '각질 제거제',
                            multiSelect: true
                        },
                        {
                            text: '세럼',
                            activate: false,
                            isInput: false,
                            width: "150",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '세럼',
                            multiSelect: true
                        },
                        {
                            text: '아이크림',
                            activate: false,
                            isInput: false,
                            width: "200",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType:[ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '아이크림',
                            multiSelect: true
                        },
                        {
                            text: '마스크팩',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '마스크팩',
                            multiSelect: true
                        },
                        {
                            text: '립밤',
                            activate: false,
                            isInput: false,
                            width: "150",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '메이크업',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '메이크업',
                            multiSelect: true
                        },
                        {
                            text: '자외선차단제',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '자외선차단제',
                            multiSelect: true
                        },
                        {
                            text: '바르지 않아요',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '바르지 않아요',
                            multiSelect: false   // 선택한 경우에는 바르지 않아요 표시
                        }
                    ]
                }, // q6
                {
                    questionTitle: '평소 세안은 어떻게 하고 있나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 1,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '세안습관',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '일반 비누로 세안',
                            activate: false,                
                            isInput: false,       
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '일반 비누로 세안',
                            multiSelect: false
                        },
                        {
                            text: '전용 클렌저로 세안',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '전용 클렌저로 세안',
                            multiSelect: false
                        },
                        {                       
                            text: '물로만 세안',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '물로만 세안',
                            multiSelect: false
                        },
                        {                       
                            text: '잘 하지 않아요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '잘 하지 않음',
                            multiSelect: false
                        }
                    ]
                }, // q7
                {
                    questionTitle: '세안은 얼마나 오래 하나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 7,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '세안시간',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '1분 미만',
                            activate: false,                
                            isInput: false,       
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '1분 미만',
                            multiSelect: false
                        },
                        {
                            text: '1-3분',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '1-3분',
                            multiSelect: false
                        },
                        {                       
                            text: '3-5분',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '3-5분',
                            multiSelect: false
                        },
                        {                       
                            text: '5분 이상',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '5분 이상',
                            multiSelect: false
                        }
                    ]
                },   //q8
                {
                    questionTitle: '관리 기관에서 스킨케어를 받아보신 적이 있나요',
                    endLetter: '?',
                    questionSubTitle: '(피부과, 한의원, 에스테틱 등)',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 8,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '관리경험',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '네',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 10,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '있음',
                            multiSelect: false
                        },
                        {
                            text: '아니요',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 11,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '없음',
                            multiSelect: false
                        },
                        {                       
                            text: '잘 모르겠어요',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 11,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: true,
                            resultViewAltMsg: '잘 모르겠어요',
                            multiSelect: false
                        }                  
                    ]
                }, //q8
                {
                    questionTitle: '여드름 치료를 위해 진행한 스킨케어에 대해 알려주세요',
                    endLetter: '.',
                    questionSubTitle: '중복선택 가능',
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 9,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '의약품 처방(이소티논, 미노씬, 스티바에이, 디페린등)',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '간단한 시술 (압출, 염증주사)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '피부과의 다회에 걸친 장기간의 시술 및 관리 (여드름관리 패키지 등)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '화장품 업체의 관리 프로그램 혹은 에스테틱 샵 방문',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '한방 및 전통 치료 (한약, 허브, 약침, 새살침 등)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '잘 모르겠어요',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.C ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '기타',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                
                    ]
                },  // 9
                {
                    questionTitle: '여드름 외에 또 다른 고민이 있나요',
                    endLetter: '?',
                    questionSubTitle: '중복선택 가능',
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 10,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '이외 고민',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: true,
                    buttons: [
                        {
                            text: '여드름 후의 색소침착',
                            activate: false,                
                            isInput: false,       
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.B ],
                            resultView: true,
                            resultViewAltMsg: '여드름 후의 색소침착',
                            multiSelect: true
                        },
                        {
                            text: '여드름 후의 붉기',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.B ],
                            resultView: true,
                            resultViewAltMsg: '여드름 후의 붉기',
                            multiSelect: true
                        },
                        {                       
                            text: '여드름 후의 모공 및 흉터',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.B ],
                            resultView: true,
                            resultViewAltMsg: '여드름 후의 모공 및 흉터',
                            multiSelect: true
                        },
                        {                       
                            text: '없어요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '없음',
                            multiSelect: false
                        }                          
                    ]
                },
            ]     // q list
        },  //acne
        {
            type: 'SKINCARE',
            category: 'blush',
            question: [
                {
                    questionTitle: '지금의 피부 상태와 가장 가까운 모습을 선택해주세요',
                    endLetter: '.',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 0,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '실핏줄이 눈에 보여요',
                            activate: false,                
                            isInput: false,       
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '나비모양의 홍조가 있어요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_B ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '노란 딱지와 붉기가 같이 있어요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_B ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '여드름이 지나간 붉은 자국이 있어요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '누가 봐도 전체적인 붉기가 있어요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_B ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '경미한 붉기가 있어요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_C ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                      
                    ]
                },  // blush 0
                {
                    questionTitle: '얼굴 이외에도 홍조가 생기는 부위가 있나요',
                    endLetter: '?',
                    questionSubTitle: '해당되는 부위를 모두 골라주세요',
                    buttonType: ButtonTypeEnum.IMAGE_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 1,
                    grid: 4,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '두피',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-scalp-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-scalp-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-scalp-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '눈과 눈 주변',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-eye-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-eye-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-eye-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '목',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-neck-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-neck-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-neck-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '팔과 다리',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-armleg-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-armleg-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-armleg-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '배와 등',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-vellyback-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-vellyback-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-vellyback-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '없어요',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-no-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-no-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-no-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '기타',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-etc-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-etc-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-etc-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        }
                    ]
                }, //blush q1
                {
                    questionTitle: '홍조 증상이 언제부터 시작되었나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 2,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.LESS,  // equal, lessthann, greaterthan
                    viewConditionValue: 20,
                    viewConditionTarget: TargetTypeEnum.AGE,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '10세 이전부터',
                            activate: false,                
                            isInput: false,       
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '10세 이후부터',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                            
                    ]
                }, // blush q2
                {
                    questionTitle: '홍조 증상이 언제부터 시작되었나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 3,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.RANGE,  // equal, lessthann, greaterthan
                    viewConditionValue: 19,
                    viewConditionEndValue: 30,
                    viewConditionTarget: TargetTypeEnum.AGE,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '10세 이전부터',
                            activate: false,                
                            isInput: false,       
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '10대 때 시작',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '20대 때 시작',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                        
                    ]
                }, //blush q3
                {
                    questionTitle: '홍조 증상이 언제부터 시작되었나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 4,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.GREATER,  // equal, lessthann, greaterthan
                    viewConditionValue: 29,
                    viewConditionTarget: TargetTypeEnum.AGE,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '10세 이전부터',
                            activate: false,                
                            isInput: false,       
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '10대 때 시작',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '20대 때 시작',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '30대 때 또는 그 이후에 시작',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                       
                    ]
                }, //blush q4
                {
                    questionTitle: '어떤 경우에 홍조가 더 심해지나요',
                    endLetter: '?',
                    questionSubTitle: '해당되는 상황을 모두 골라주세요 (중복선택 가능)',
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 5,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '스트레스, 분노 등 감정 변화가 있을 때',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 7,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '더운 공간에 있거나 매운 음식을 먹을 때',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 7,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '술이나 커피를 마실 때',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 7,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '자외선에 오래 노출되었을 때',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 7,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '화장품이나 약물을 바를 때',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 6,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '상황에 관계 없이 거의 일정해요',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 7,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '잘 모르겠어요',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 7,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '',
                            activate: false,                
                            isInput: true,       
                            width: "1000",
                            nextQuestionIndex: 7,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        }                    
                    ]
                }, //blush q5
                {
                    questionTitle: '어떤 화장품이나 약물을 사용할 때 홍조가 더 심해지나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 6,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {
                            text: '',
                            activate: false,                
                            isInput: true,       
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                    
                    ]
                }, //blush q6
                {
                    questionTitle: '평소에 사용하는 제품군을 알려주세요',
                    endLetter: '.',
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 7,
                    grid: 4,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '제품군',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '클렌저',
                            activate: false,                
                            isInput: false,       
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '클렌저',
                            multiSelect: true
                        },
                        {
                            text: '보습제',
                            activate: false,                
                            isInput: false,       
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '보습제',
                            multiSelect: true
                        },  
                        {
                            text: '토너',
                            activate: false,                
                            isInput: false,       
                            width: "200",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '토너',
                            multiSelect: true
                        },
                        {
                            text: '각질제거제',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '각질제거제',
                            multiSelect: true
                        },
                        {
                            text: '세럼',
                            activate: false,                
                            isInput: false,       
                            width: "200",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '세럼',
                            multiSelect: true
                        },
                        {
                            text: '아이크림',
                            activate: false,                
                            isInput: false,       
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '아이크림',
                            multiSelect: true
                        },
                        {
                            text: '마스크',
                            activate: false,                
                            isInput: false,       
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '마스크',
                            multiSelect: true
                        },                        
                        {
                            text: '립밤',
                            activate: false,                
                            isInput: false,       
                            width: "200",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '립밤',
                            multiSelect: true
                        },
                        {
                            text: '메이크업',
                            activate: false,                
                            isInput: false,       
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '메이크업',
                            multiSelect: true
                        },
                        {
                            text: '사용하지 않아요',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '사용하지 않아요',
                            multiSelect: false
                        }             
                    ]
                }, //blush q7
                {
                    questionTitle: '홍조 외에 다른 불편한 증상은 없나요',
                    endLetter: '?',
                    questionSubTitle: '해당되는 증상을 모두 골라주세요',
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 8,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '이외 증상',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '건조해요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 10,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '건조해요',
                            multiSelect: true
                        },
                        {
                            text: '햇빛을 받으면 피부가 쉽게 예민해져요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 10,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '햇빛을 받으면 피부가 쉽게 예민해져요',
                            multiSelect: true
                        },
                        {
                            text: '피부 가려움증이 있어요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '피부에 노랗고 하얀 딱지가 있어요',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '피부 가려움증이 있어요',
                            multiSelect: true
                        },
                        {
                            text: '두피가 푸석하고 비듬이 있어요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '두피가 푸석하고 비듬이 있어요',
                            multiSelect: true
                        },
                        {
                            text: '피로를 쉽게 느껴요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 10,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '피로를 쉽게 느껴요',
                            multiSelect: true
                        },
                        {
                            text: '열과 땀이 많아요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 10,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '열과 땀이 많아요',
                            multiSelect: true
                        },
                        {
                            text: '심장이 자주 두근거려요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 9,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '심장이 자주 두근거려요',
                            multiSelect: true
                        },
                        {
                            text: '구내염이 있어요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 9,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '구내염이 있어요',
                            multiSelect: true
                        },
                        {
                            text: '관절통이 있어요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 10,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '관절통이 있어요',
                            multiSelect: true
                        },                     
                        {
                            text: '다른 불편 증상은 없어요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 10,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '다른 불편 증상은 없어요',
                            multiSelect: false
                        },
                        {
                            text: '',
                            activate: false,                
                            isInput: true,       
                            width: "800",
                            nextQuestionIndex: 10,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '기타',
                            multiSelect: true
                        },
                    ]
                }, //blush q8
                {
                    questionTitle: '원인의 정확한 진단을 위해 병원에 방문하시길 권장드립니다',
                    endLetter: '.',
                    questionSubTitle: '정확한 진단을 위해 빠른 시일 내에 병원 검사를 진행해 주세요',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 9,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        
                        {
                            text: '확인했습니다',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                    
                    ]
                }, //blush q9
                {
                    questionTitle: '홍조를 치료하기 위해 약을 사용한 적이 있나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 10,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '복약경험',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {
                            text: '네',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 11,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '있음',
                            multiSelect: false
                        },
                        {
                            text: '아니요',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 12,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '없음',
                            multiSelect: false
                        },
                        {
                            text: '잘 모르겠어요',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 12,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: 'N/A',
                            multiSelect: false
                        }                   
                    ]
                }, //blush q10
                {
                    questionTitle: '어떤 약물을 사용했나요',
                    endLetter: '?',
                    questionSubTitle: '사용해본 약물을 모두 골라주세요',
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 11,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {
                            text: '테트라싸이클린 (미노씬/바이독시 등)',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '레티노이드 (이소티논/스티바에이/디페린/에피듀오 등)',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '벤조일 퍼옥사이드 (듀악겔/에피듀오 등)',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '이버멕틴 (수란트라크림 등)',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '메트로니다졸 (로섹스겔/후라시닐 등)',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '면역억제제 (프로토픽연고/타크로리무스 등)',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 11,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '약용 샴푸(니조랄/클로벡스/케이프록스 등)',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 11,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '스테로이드/항히스타민제',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '잘 모르겠어요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '',
                            activate: false,                
                            isInput: true,       
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        }               
                    ]
                }, //blush q11
                {
                    questionTitle: '어떤 피부 타입을 가지고 있나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 12,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '피부타입',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {
                            text: '극건성',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '극건성',
                            multiSelect: false
                        },
                        {
                            text: '건성',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '건성',
                            multiSelect: false
                        },
                        {
                            text: '중성',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '중성',
                            multiSelect: false
                        },
                        {
                            text: '지성',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '지성',
                            multiSelect: false
                        },
                        {
                            text: '극지성',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '극지성',
                            multiSelect: false
                        }                  
                    ]
                }, //blush q12
                {
                    questionTitle: '지난 일주일 간 새로 생긴 여드름은 몇 개 정도 되나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 13,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {
                            text: '0개',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '1-2개',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '3-4개',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '5개 이상',
                            activate: false,                
                            isInput: false,       
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }             
                    ]
                }, //blush q13
                {
                    questionTitle: '피부가 얼마나 예민한 편인가요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 14,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {
                            text: '항상 쉽게 예민해지는 편이에요',
                            activate: false,                
                            isInput: false,       
                            width: "1100",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '예민하지 않은 편이에요',
                            activate: false,                
                            isInput: false,       
                            width: "1100",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '특정 환경에 노출되면 예민해져요. (애완동물/꽃가루/온도변화/감정변화 등)',
                            activate: false,                
                            isInput: false,       
                            width: "1100",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '특정 화장품을 발랐을 때만 예민해져요',
                            activate: false,                
                            isInput: false,       
                            width: "1100",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '잘 모르겠어요',
                            activate: false,                
                            isInput: false,       
                            width: "1100",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }           
                    ]
                }, //blush q14
                {
                    questionTitle: '자외선 차단제를 잘 바르는 편인가요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 15,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {
                            text: '항상 바르고 다녀요',
                            activate: false,                
                            isInput: false,       
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '야외활동이 많은 날에만 발라요',
                            activate: false,                
                            isInput: false,       
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '누군가 권유해 줄 때 종종 발라요',
                            activate: false,                
                            isInput: false,       
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '바르지 않아요',
                            activate: false,                
                            isInput: false,       
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                     
                    ]
                }, //blush q15
                {
                    questionTitle: '평소 세안은 어떻게 하고 있나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 16,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '세안습관',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {
                            text: '일반 비누 사용',
                            activate: false,                
                            isInput: false,       
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '일반 비누로 세안',
                            multiSelect: false
                        },
                        {
                            text: '세안 전용 클렌저 사용',
                            activate: false,                
                            isInput: false,       
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '전용 클렌저로 세안',
                            multiSelect: false
                        },
                        {
                            text: '물로만 세안',
                            activate: false,                
                            isInput: false,       
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '물로만 세안',
                            multiSelect: false
                        },
                        {
                            text: '세안을 잘 하지 않아요',
                            activate: false,                
                            isInput: false,       
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '잘 하지 않음',
                            multiSelect: false
                        }                     
                    ]
                }, //blush q16
                {
                    questionTitle: '세안은 얼마나 오래 하나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 17,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '세안시간',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: true,
                    buttons: [                        
                        {
                            text: '1분 미만',
                            activate: false,                
                            isInput: false,       
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '1분 미만',
                            multiSelect: false
                        },
                        {
                            text: '1-3분',
                            activate: false,                
                            isInput: false,       
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '1-3분',
                            multiSelect: false
                        },
                        {
                            text: '3-5분',
                            activate: false,                
                            isInput: false,       
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '3-5분',
                            multiSelect: false
                        },
                        {
                            text: '5분 이상',
                            activate: false,                
                            isInput: false,       
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '5분 이상',
                            multiSelect: false
                        }                     
                    ]
                }, //blush q17
            ]
        }, // blush
        {
            type: 'SKINCARE',
            category: 'brown',
            question: [
                {
                    questionTitle: '지금의 피부 상태와 가장 가까운 설명을 선택해주세요',
                    endLetter: '.',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 0,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {
                            text: '경계가 명확한 작은 갈색 반점들이 광대나 코쪽에 있어요',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 3,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_B ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '경계가 불분명한 회색 또는 갈색 색소가 광범위하게 퍼져 있어요',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 3,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_B ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '여드름이 지나가고 갈색 색소가 남았어요',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 1,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '밀크커피색의 연한 반점이 있어요',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 3,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_B ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '자그마한 잡티들이 산재해있어요',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 3,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '까만 점들이 있어요',
                            activate: false,                
                            isInput: false,       
                            width: "1000",
                            nextQuestionIndex: 2,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.B ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                     
                    ]
                }, //brown q0
                {
                    questionTitle: '현재 피부의 근본적인 문제는 갈색색소가 아닌 여드름일 수 있어요',
                    endLetter: '.',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.SURVEY_JUMP,
                    order: 1,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {
                            text: '여드름 서베이로 이동할게요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false,
                            jumpSurvey: SurveyCategoryEnum.ACNE
                        },
                        {
                            text: '갈색색소 서베이를 계속 진행할게요',
                            activate: false,                
                            isInput: false,       
                            width: "800",
                            nextQuestionIndex: 3,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, //brown q1
                {
                    questionTitle: '모양은 어떤가요',
                    questionSubTitle: '해당되는 설명을 모두 골라주세요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 1,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.FORCE_ONE_RESULT,
                    forceMultiResultCondition: [{condition: ConditionTypeEnum.GREATER, startValue: 1, resultType: ResultTypeEnum.FORCE_C}],
                    isLast: false,
                    buttons: [
                        {
                            text: '결계가 뚜렸하고 모양이 대칭적이에요',
                            activate: false,                
                            isInput: false,       
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '결계가 불분명하고 모양이 비대칭적이에요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_C ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '색이 균일해보여요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '색이 불균일해보여요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_C ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '크기가 6mm보다 작아요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '크기가 6mm보다 커요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_C ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '최근에 크기가 갑자기 커진 것 같아요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '기타',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        }
                    ]
                }, // brown q2
                {
                    questionTitle: '얼굴에 갈색색소가 있나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 3,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '발생부위(얼굴)',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {                       
                            text: '네',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 4,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '있음',
                            multiSelect: true
                        },
                        {                       
                            text: '아니요',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 5,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '없음',
                            multiSelect: true
                        },  
                    ]
                }, //brown q3
                {
                    questionTitle: '얼굴의 어느 부위에 색소가 있나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.IMAGE_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 4,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {
                            text: '이마/관자',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-scalp-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-scalp-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-scalp-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '눈가',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-eye-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-eye-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-eye-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },                      
                        {
                            text: '뺨/광대',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-cheek-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-cheek-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-cheek-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '입과 입술 주변',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-mouse-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-mouse-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-mouse-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '턱과 턱라인',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-chin-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-chin-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-chin-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '기타',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-etc-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-etc-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-etc-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        }
                    ]
                }, //brown q4
                {
                    questionTitle: '얼굴의 아닌 몸에 갈색색소가 있나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 5,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '발생부위(몸)',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {                       
                            text: '네',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 6,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '있음',
                            multiSelect: false
                        },
                        {                       
                            text: '아니요',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 7,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '없음',
                            multiSelect: false
                        }
                    ]
                }, //brown q5
                {
                    questionTitle: '몸의 어느 부위에 색소가 있나요',
                    endLetter: '?',
                    questionSubTitle: '해당되는 부위를 모두 골라주세요',
                    buttonType: ButtonTypeEnum.IMAGE_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 6,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {
                            text: '목과 어깨',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-neck-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-neck-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-neck-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '팔과 다리',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-armleg-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-armleg-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-armleg-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },                      
                        {
                            text: '배와 등',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-vellyback-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-vellyback-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-vellyback-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '기타',
                            img: {
                                active: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-etc-active.png',
                                inactive: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-etc-inactive.png',
                                hover: 'https://clubhonest-survey.s3.ap-northeast-2.amazonaws.com/survey-icon/pc-etc-hover.png'
                            },
                            activate: false,
                            width: "252",
                            height: "218",
                            nextQuestionIndex: 0,  //해당 답변을 선택했을때 넘어가는 번호 
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        }                         
                    ]
                }, //brown q6
                {
                    questionTitle: '색소침착 고민은 언제부터 시작되었나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 7,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {                       
                            text: '1-3개월 전부터',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '3개월-1년 전부터',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '1년 이상 지속',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, //brown q7
                {
                    questionTitle: '갈색 색소의 원인으로 짐작되는 것이 있나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 8,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {                       
                            text: '여드름 흉터',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '자외선 노출',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '호르몬 변화 (임신/피임약 복용 등)',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '생활 습관 (화장품/필링/잦은 클렌징 등)',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '약물 또는 질병 (스테로이드 / 알레르기 등)',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {                       
                            text: '물리적 손상 (상처/화상 등)',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        }
                    ]
                }, //brown q8
                {
                    questionTitle: '어떤 피부 타입을 가지고 있나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 9,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '피부타입',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {                       
                            text: '극건성',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '극건성',
                            multiSelect: false
                        },
                        {                       
                            text: '건성',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '건성',
                            multiSelect: false
                        },
                        {                       
                            text: '중성',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '중성',
                            multiSelect: false
                        },                     
                        {                       
                            text: '지성',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '지성',
                            multiSelect: false
                        },
                        {                       
                            text: '극지성',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '극지성',
                            multiSelect: false
                        }
                    ]
                }, //brown q9               
                {
                    questionTitle: '피부가 얼마나 예민한 편인가요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 10,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {                       
                            text: '항상 쉽게 예민해지는 편이에요',
                            activate: false,
                            isInput: false,
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '예민하지 않은 편이에요',
                            activate: false,
                            isInput: false,
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '특정 환경에 노출되면 예민해져요. (애완동물/꽃가루/온도변화/감정변화 등)',
                            activate: false,
                            isInput: false,
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '특정 화장품을 발랐을 때만 예민해져요',
                            activate: false,
                            isInput: false,
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '잘 모르겠어요',
                            activate: false,
                            isInput: false,
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                       
                    ]
                }, //brown q10
                {
                    questionTitle: '지난 일주일 간 새로 생긴 여드름은 몇 개 정도 되나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 11,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {                       
                            text: '0개',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 13,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '1-2개',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 13,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '3-4개',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 13,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '5개 이상',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 12,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                 
                    ]
                }, //brown q11
                {
                    questionTitle: '현재 피부의 근본적인 문제는 색소침착이 아닌 여드름일 수 있어요',
                    endLetter: '.',
                    questionSubTitle: '더 정확한 진단을 위해 여드름 서베이로 이동하시겠어요?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.SURVEY_JUMP,
                    order: 12,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {                       
                            text: '여드름 서베이로 이동할게요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false,
                            jumpSurvey: SurveyCategoryEnum.ACNE
                        },
                        {                       
                            text: '갈색색소 서베이를 계속 진행할게요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 13,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                                        
                    ]
                }, //brown q12
                {
                    questionTitle: '자외선 차단제를 잘 바르는 편인가요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 13,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [                        
                        {                       
                            text: '항상 바르고 다녀요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '야외활동이 많은 날에만 발라요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '누군가 권유해 줄 때 종종 발라요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {                       
                            text: '바르지 않아요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                                      
                    ]
                }, //brown q13
                {
                    questionTitle: '평소에 사용하는 제품군을 알려주세요',
                    endLetter: '.',             
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 14,
                    grid: 4,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '제품군',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '클렌저',
                            activate: false,
                            isInput: false,
                            width: "200",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '클렌저',
                            multiSelect: true
                        },
                        {                     
                            text: '보습제',
                            activate: false,
                            isInput: false,
                            width: "250",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '보습제',
                            multiSelect: true
                        },
                        {
                            text: '토너',
                            activate: false,
                            isInput: false,
                            width: "150",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '토너',
                            multiSelect: true
                        },
                        {                     
                            text: '각질 제거제',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '각질 제거제',
                            multiSelect: true
                        },
                        {
                            text: '세럼',
                            activate: false,
                            isInput: false,
                            width: "150",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '세럼',
                            multiSelect: true
                        },
                        {
                            text: '아이크림',
                            activate: false,
                            isInput: false,
                            width: "200",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType:[ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '아이크림',
                            multiSelect: true
                        },
                        {
                            text: '마스크팩',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '마스크팩',
                            multiSelect: true
                        },
                        {
                            text: '립밤',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '립밤',
                            multiSelect: true
                        },
                        {
                            text: '메이크업',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '메이크업',
                            multiSelect: true
                        },                        
                        {
                            text: '사용하지 않아요',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '바르지 않아요',
                            multiSelect: false   // 선택한 경우에는 바르지 않아요 표시
                        }
                    ]
                }, // brown q14
                {
                    questionTitle: '피부와 관련한 약을 사용해본 적 있나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 15,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '복약경험',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '네',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 16,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '있음',
                            multiSelect: false
                        },
                        {                     
                            text: '아니요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 18,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '없음',
                            multiSelect: false
                        },
                        {
                            text: '잘 모르겠어요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 18,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: 'N/A',
                            multiSelect: false
                        }                       
                    ]
                }, // brown q15
                {
                    questionTitle: '이중에서 사용해본 약이 있나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 16,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '레티노이드 계열 (이소티논/스티바에이/디페린/에피듀오 등)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 17,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '미백 크림 (멜라논 크림/도미나 크림/아젤리아크림 등)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 17,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '도란사민 캡슐 (트라넥삼산)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 17,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '각질 제거제 (AHA/BHA/PHA 등)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 17,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '잘 모르겠어요',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 18,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '없어요',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 18,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }              
                    ]
                }, // brown q16
                {
                    questionTitle: '약을 복약한 뒤 경과는 어땠나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 17,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '좋아졌어요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '오히려 악화되었어요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '부작용 등의 이유로 중단했어요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '잘 모르겠어요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }            
                    ]
                }, // brown q17
                {
                    questionTitle: '과거에 진단받은 피부 색소 질환이 있나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 18,
                    grid: 3,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: true,
                    buttons: [
                        {
                            text: '기미',
                            activate: false,
                            isInput: false,
                            width: "200",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '주근깨',
                            activate: false,
                            isInput: false,
                            width: "250",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '후천성 오타모반',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '흑자/검버섯',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '잘 모르겠어요',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '없어요',
                            activate: false,
                            isInput: false,
                            width: "250",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '',
                            activate: false,
                            isInput: true,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        }          
                    ]
                }, // brown q18
            ]
        }, // brown
        {
            type: 'SKINCARE',
            category: 'dry',
            question: [
                {
                    questionTitle: '평소 피부 상태는 어떤가요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 0,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '매우 건조하여 피부가 붉고, 하얗게 일어나기도 해요',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_B ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '눈으로 보이게도 피부가 거칠어 보여요',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '거칠어 보이지는 않지만 자주 당김을 느껴요',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_A ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '거칠어 보이지는 않지만 종종 당김을 느껴요',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_C ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                          
                    ]
                }, // dry q0
                {
                    questionTitle: '평소 세안은 어떻게 하고 있나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 1,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '세안습관',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '일반 비누로 사용',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '일반 비누로 세안',
                            multiSelect: false
                        },
                        {
                            text: '세안 전용 클렌저 사용',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '전용 클렌저로 세안',
                            multiSelect: false
                        },
                        {
                            text: '물로만 세안',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '물로만 세안',
                            multiSelect: false
                        },
                        {
                            text: '세안을 잘 하지 않아요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '잘 하지 않음',
                            multiSelect: false
                        }  
                    ]
                }, // dry q1
                {
                    questionTitle: '세안은 얼마나 오래 하나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 2,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '세안시간',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '1분 미만',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '1분 미만',
                            multiSelect: false
                        },
                        {
                            text: '1-3분',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '1-3분',
                            multiSelect: false
                        },
                        {
                            text: '3-5분',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '3-5분',
                            multiSelect: false
                        },
                        {
                            text: '5분 이상',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '5분 이상',
                            multiSelect: false
                        },
                    ]
                }, // dry q2
                {
                    questionTitle: '필링은 얼마나 하는 편인가요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 3,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '1주에 1-2회 정도',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 4,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '1주에 3회 이상',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 4,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '한 달에 한 번 정도',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 4,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '거의 하지 않아요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 5,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, // dry q3
                {
                    questionTitle: '필링을 할 때 어떤 제품을 사용하나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 4,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '',
                            activate: false,
                            isInput: true,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, // dry q4
                {
                    questionTitle: '얼굴에 보습제를 바르는 횟수는 어떻게 되나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 5,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '하루에 한 번 정도',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '하루에 두 번 이상',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '바르지 않아요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, // dry q5
                {
                    questionTitle: '몸의 어느 부위에 보습제를 사용하고 있나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 6,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '전신',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 7,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '건조한 부위에만',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 7,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '바르지 않아요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 8,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, // dry q6
                {
                    questionTitle: '어떤 종류의 보습제를 사용하나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 7,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '나에게 맞는 성분의 제품',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '의사의 처방전이 필요한 제품(제로이드/아토베리어 등)',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '종류에 신경쓰지 않고 사용해요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '보습제를 사용하지 않아요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                    ]
                }, // dry q7
                {
                    questionTitle: '자외선 차단제를 잘 바르는 편인가요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 8,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '항상 바르고 다녀요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '야외활동이 필요한 날에만 발라요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '누군가 권유해줄 때 종종 발라요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '거의 또는 전혀 바르지 않아요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, // dry q8
                {
                    questionTitle: '피부건조증 개선을 목표로 피부과에 방문한 적이 있나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 9,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '주기적으로 방문해요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '가끔 필요성을 느낄 때 가요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '전혀 다니지 않아요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, // dry q9
                {
                    questionTitle: '건조증 이외에 또 다른 불편감은 없나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 10,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '이외 증상',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '가렵고 따갑기도 해요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '가렵고 따갑기도 해요',
                            multiSelect: true
                        },
                        {
                            text: '하얀 각질이 올라와요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '하얀 각질이 올라와요',
                            multiSelect: true
                        },
                        {
                            text: '진물이 나와요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '진물이 나와요',
                            multiSelect: true
                        },
                        {
                            text: '붉기가 있어요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 11,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '붉기가 있어요',
                            multiSelect: true
                        },
                        {
                            text: '다른 불편감은 없어요',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '다른 불편감은 없어요',
                            multiSelect: false
                        }
                    ]
                }, // dry q10
                {
                    questionTitle: '현재 피부의 근본적인 문제는 색소침착이 아닌 홍조일 수 있어요',
                    endLetter: '.',             
                    questionSubTitle: '더 정확한 진단을 위해 홍조 서베이로 이동하시겠어요?',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.SURVEY_JUMP,
                    order: 11,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '홍조 서베이로 이동할게요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false,
                            jumpSurvey: SurveyCategoryEnum.BLUSH
                        },
                        {
                            text: '피부건조증 서베이를 계속 진행할게요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 12,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                        
                    ]
                }, // dry q11
                {
                    questionTitle: '화장품에 대해 얼마나 알고 있나요',
                    endLetter: '?',
                    buttonType: ButtonTypeEnum.RANGE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 12,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    rangeStartLabel: '거의 몰라요',
                    rangeEndLabel: '매우 잘 알아요',
                    resultView: true,
                    resultViewAltMsg: '제품 이해도',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: true,
                    buttons: [
                        {
                            text: '1',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '1',
                            multiSelect: false
                        },
                        {
                            text: '2',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '2',
                            multiSelect: false
                        },
                        {
                            text: '3',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '3',
                            multiSelect: false
                        },
                        {
                            text: '4',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '4',
                            multiSelect: false
                        },
                        {
                            text: '5',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '5',
                            multiSelect: false
                        },
                        {
                            text: '6',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '6',
                            multiSelect: false
                        },
                        {
                            text: '7',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '7',
                            multiSelect: false
                        },
                        {
                            text: '8',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '8',
                            multiSelect: false
                        },
                        {
                            text: '9',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '9',
                            multiSelect: false
                        },
                        {
                            text: '10',
                            activate: false,
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '10',
                            multiSelect: false
                        }
                    ]
                }, //dry q12
            ]
        }, // dry
        {
            type: 'SKINCARE',
            category: 'antiaging',
            question: [
                {
                    questionTitle: '현재 크게 고민되는 것을 골라주세요',
                    endLetter: '.',
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 0,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.AGE,
                    resultView: true,
                    resultViewAltMsg: '고민부위',
                    resultCalcType: ResultCalcTypeEnum.FORCE_MANY_RESULT,
                    forceMultiResultCondition: [
                        {condition: ConditionTypeEnum.RANGE, startValue: 0, endValue: 3, resultType: ResultTypeEnum.FORCE_A},
                        {condition: ConditionTypeEnum.RANGE, startValue: 2, endValue: 5, resultType: ResultTypeEnum.FORCE_B}
                    ],
                    isLast: false,
                    buttons: [
                        {
                            text: '한눈에 보이는 깊은 주름',
                            activate: false,                
                            isInput: false,       
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_MANY_RESULT ],
                            resultView: true,
                            resultViewAltMsg: '깊은 주름',
                            multiSelect: true
                        },
                        {
                            text: '가까이서 도드라지는 잔주름',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_MANY_RESULT ],
                            resultView: true,
                            resultViewAltMsg: '잔주름',
                            multiSelect: true
                        },
                        {                       
                            text: '피부탄력과 피부결',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_MANY_RESULT ],
                            resultView: true,
                            resultViewAltMsg: '피부탄력/피부결',
                            multiSelect: true
                        },
                        {                       
                            text: '피부의 볼륨감',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_MANY_RESULT ],
                            resultView: true,
                            resultViewAltMsg: '피부의 볼륨감',
                            multiSelect: true
                        },
                        {                       
                            text: '특별한 고민은 없지만 꾸준한 관리를 원해요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.FORCE_C ],
                            resultView: true,
                            resultViewAltMsg: '꾸준한관리',
                            multiSelect: false
                        }
                    ]
                },  //antiaging 0
                {
                    questionTitle: '언제부터 안티에이징 고민을 시작했나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 1,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '20대 이하 때부터',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '20-29세 때부터',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '30-39세 때부터',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '40세 이후부터',
                            activate: false,
                            isInput: false,
                            width: "500",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }                      
                    ]
                }, // antiaging q1
                {
                    questionTitle: '피부과에서 안티에이징 시술을 받아본 적 있나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 2,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '네',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 3,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '아니요',
                            activate: false,
                            isInput: false,
                            width: "300",
                            nextQuestionIndex: 5,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }           
                    ]
                }, // antiaging q2
                {
                    questionTitle: '어떤 시술을 받아보셨나요',
                    endLetter: '?',             
                    questionSubTitle: '받아본 시술을 모두 골라주세요',
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 3,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '초음파 리프팅 (슈링크/울쎄라/더블로 등)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 4,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '고주파 리프팅 (인모드/써마지/올리지오 등)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 4,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '미백 관리 (LDM/이온토/필링 등)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 4,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '보톡스/필러',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 4,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '바늘고주파 (시크릿/포텐자/인트라셀 등)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 4,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '프락셀 (CO2/피코/어븀 등)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '스킨부스터 (리쥬란/물광/쥬베룩/울트라콜 등)',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '윤곽/조각주사',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '실리프팅',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 4,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '잘 모르겠어요',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 5,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '',
                            activate: false,
                            isInput: true,
                            width: "1000",
                            nextQuestionIndex: 4,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        }
                    ]
                }, // antiaging q3
                {
                    questionTitle: '받아본 시술의 경과는 어땠나요?',
                    endLetter: '?',             
                    questionSubTitle: '받아본 시술을 모두 골라주세요',
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 4,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '많이 좋아졌어요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '일시적으로 좋아졌어요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '오히려 악화되었어요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '잘 모르겠어요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '',
                            activate: false,
                            isInput: true,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, // antiaging q4
                {
                    questionTitle: '어떤 피부 타입을 가지고 있나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 5,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '피부타입',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '극건성',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '극건성',
                            multiSelect: false
                        },
                        {
                            text: '건성',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '건성',
                            multiSelect: false
                        },
                        {
                            text: '중성',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '중성',
                            multiSelect: false
                        },
                        {
                            text: '지성',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '지성',
                            multiSelect: false
                        },
                        {
                            text: '극지성',
                            activate: false,
                            isInput: false,
                            width: "400",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '극지성',
                            multiSelect: false
                        }     
                    ]
                }, // antiaging q5
                {
                    questionTitle: '평소 세안은 어떻게 하고 있나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 6,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '세안습관',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '일반 비누로 사용',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '일반 비누로 세안',
                            multiSelect: false
                        },
                        {
                            text: '세안 전용 클렌저 사용',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '전용 클렌저로 세안',
                            multiSelect: false
                        },
                        {
                            text: '물로만 세안',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '물로만 세안',
                            multiSelect: false
                        },
                        {
                            text: '세안을 잘 하지 않아요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '잘 하지 않음',
                            multiSelect: false
                        }
                    ]
                }, // antiaging q6
                {
                    questionTitle: '세안은 얼마나 오래 하나요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 7,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: true,
                    resultViewAltMsg: '세안시간',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '1분 미만',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '1분 미만',
                            multiSelect: false
                        },
                        {
                            text: '1-3분',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '1-3분',
                            multiSelect: false
                        },
                        {
                            text: '3-5분',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '3-5분',
                            multiSelect: false
                        },
                        {
                            text: '5분 이상',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: true,
                            resultViewAltMsg: '5분 이상',
                            multiSelect: false
                        }
                    ]
                }, // antiaging q7
                {
                    questionTitle: '자외선 차단제를 잘 바르는 편인가요',
                    endLetter: '?',             
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 8,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: false,
                    buttons: [
                        {
                            text: '항상 바르고 다녀요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '야외활동이 필요한 날에만 발라요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '누군가 권유해줄 때 종종 발라요',
                            activate: false,
                            isInput: false,
                            width: "700",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '거의 또는 전혀 바르지 않아요',
                            activate: false,
                            isInput: false,
                            width: "1000",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, // antiaging q8
                {
                    questionTitle: '이 중에서 사용했거나 사용 중인 약이 있나요',
                    endLetter: '?',             
                    questionSubTitle: '해당되는 항목을 모두 골라주세요',
                    buttonType: ButtonTypeEnum.TEXT_MULTI,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.CONDITIONAL_JUMP,
                    order: 9,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: true,
                    buttons: [
                        {
                            text: '종합 영양제 (비타민/무기질 등)',
                            activate: false,
                            isInput: false,
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '콜라겐 제품',
                            activate: false,
                            isInput: false,
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '레티노이드 계열 (스티바에이/디페린 등)',
                            activate: false,
                            isInput: false,
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '각질 제거제 (AHA/BHA/PHA 등)',
                            activate: false,
                            isInput: false,
                            width: "800",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: true
                        },
                        {
                            text: '없어요',
                            activate: false,
                            isInput: false,
                            width: "800",
                            nextQuestionIndex: 11,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '잘 모르겠어요',
                            activate: false,
                            isInput: false,
                            width: "800",
                            nextQuestionIndex: 11,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, // antiaging q9
                {
                    questionTitle: '약물의 경과는 어땠나요',
                    endLetter: '?',         
                    buttonType: ButtonTypeEnum.TEXT_SINGLE,
                    selectedButtonIndex: [],
                    jump: JumpTypeEnum.NOP,
                    order: 10,
                    grid: 1,
                    viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                    viewConditionValue: 0,
                    viewConditionTarget: TargetTypeEnum.NOP,
                    resultView: false,
                    resultViewAltMsg: '',
                    resultCalcType: ResultCalcTypeEnum.COUNT,
                    forceMultiResultCondition: [],
                    isLast: true,
                    buttons: [
                        {
                            text: '많이 좋아졌어요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '일시적으로 좋아졌어요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '오히려 악화되었어요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '잘 모르겠어요',
                            activate: false,
                            isInput: false,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        },
                        {
                            text: '',
                            activate: false,
                            isInput: true,
                            width: "600",
                            nextQuestionIndex: 0,
                            viewConditionType: ConditionTypeEnum.NOP,  // equal, lessthann, greaterthan
                            viewConditionValue: 0,
                            viewConditionTarget: TargetTypeEnum.NOP,
                            resultType: [ ResultTypeEnum.NA ],
                            resultView: false,
                            resultViewAltMsg: '',
                            multiSelect: false
                        }
                    ]
                }, // antiaging q10
            ]
        } // antiaging
    ] // survey
});

// survey list 
// js filter 기능사용하여 type에 따라 구분 
// acne,  간의 이동이 발생하고 정보가 각각 저장되어야함 
