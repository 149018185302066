import { useRecoilValue } from "recoil";
import styled from "styled-components";
import {
    LinearGradientLabel,
    ChangableGradientLabel
} from "../../../components/atoms";
import { 
    LevelTitle 
} from "../../../components/molecules";
import { 
    LevelView,
    AnswerView
} from "../../../components/organisms";

import { 
    LevelStateType, 
    loginState,
    gradientState      
} from '../../../store';

import {
    UserAnswerType
} from '../../../types';

const Container = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 130px;
    background: #020620;
    padding: 173px 0 200px 0;
    position: relative;
    overflow: hidden;
`;

const BackLeftImage = styled.img`
    position: absolute;
    left: 0;
    z-index: 0;
`;

const BackRightImage = styled.img`
    position: absolute;
    right: -300px;
    top: 700px;
`;

const TitleWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 30px;
`;

const LevelWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 82px;
`;

interface ResultProps{
    selectType: string;
    level: LevelStateType;
    answer: UserAnswerType [];
}

export function Result({
    selectType, 
    level,
    answer
}: ResultProps){

    const login = useRecoilValue(loginState);
    const isGradient = useRecoilValue(gradientState);
    // LinearGradientLabel
    // Level Title
    // Level View
    let userLevel = 0;
    level.types.map((type, index) => {
        if(type === selectType)
        {
            userLevel = index + 1;
        }
    })

    return (
        <Container>
            <BackLeftImage src={require('../../../styles/assets/result/back_ellipse_left.png')} />
            <BackRightImage src={require('../../../styles/assets/result/back_ellipse_right.png')} />
            <LevelWrapper>
                <TitleWrapper>
                    {
                        login.isLogin && <ChangableGradientLabel isGradient={isGradient} title={`${login.userName}님의 결과`} fontSize="30"/>                      
                    }                    
                    <LevelTitle level={userLevel} title={level.levelText[userLevel - 1]} />
                </TitleWrapper>
                <LevelView curLevel={userLevel} lastLevel={level.lastLevel} levelContents={level.levelContents} />
            </LevelWrapper>   
            <AnswerView answers={answer}/>        
        </Container>
    );
}