import styled from "styled-components";

const FooterWrapper = styled.div`
    width: 100%;
    background: #1A1A1A;
    height: 380px;
    display: flex;
    justify-content:center;
    align-items: flex-start;
    padding-top: 71px;
    gap: 68px;
`;

const Logo = styled.span`
    font-family: 'felixTitling';
    font-style: normal;
    font-weight: 400;
    font-size: 26px;
    line-height: 23px;
    color: #FFFFFF;
    position: absolute;
    left: -280px;
`;

const Wrapper = styled.div<{gap: string}>`
    display: flex;
    flex-direction: column;
    gap: ${(props) => props.gap}px;
    position: relative;
`;

const Text = styled.span<{weight: string}>`
    font-weight: ${(props) => props.weight};
    font-size: 16px;
    line-height: 23px;
    letter-spacing: 0.5px;
    color: #FFFFFF;
    font-family: 'Apple SD Gothic Neo';
    span {
        cursor: pointer;
    }
`;

function Footer(){

    return (
        <FooterWrapper>
            <Wrapper gap="22">
                <Wrapper gap="6">
                    <Logo>CLUB HONEST</Logo>
                    <Text weight="600">(주)아이케어닥터</Text>
                    <Text weight="400">대표 김민승, 이호익  주소 서울특별시 강남구 논현동 7-14 (정안빌딩)   Tel 02-6954-7676<br/>
                        사업자등록번호 284-87-00967 개인정보관리자 김민승 E-mail info@soldoc.co.kr<br/>
                        <span
                            onClick={() => {
                                window.open(`https://soldoc.co.kr/service/private.php`, '_blank');
                            }}
                        >개인정보처리방침 |</span>&nbsp;
                        <span
                            onClick={() => {
                                window.open(`https://soldoc.co.kr/service/agreement.php`, '_blank');
                            }}
                        >이용약관</span>

                    </Text>
                </Wrapper>
                <Text weight="400">@clubhonest.co.kr All rights reserved</Text>
            </Wrapper>
        </FooterWrapper>
    );
}

export default Footer;