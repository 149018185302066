import { useState } from "react";
import { useRecoilValue } from "recoil";
import styled from "styled-components";

import {
    gradientState
} from '../../../store'

import {
    LevelContentType
} from "../../../store";

import {
    ChangableGradientLabel
} from "../../atoms"

import {
    LevelToggle
} from "../../molecules"

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: 60px;
`;

const ContentsWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 30px;
`;

const Contents = styled.div`
    width: 421px;
    font-size: 20px;
    text-align: center;
    line-height: 33px;
    font-weight: 400;
    color: #FFFFFF;
`;

const Label = styled.div`
    font-weight: 700;
    font-size: 35px;
    color: #7A7A7A;
`;

interface LevelViewProps {
    curLevel: number;
    lastLevel: number;
    levelContents: LevelContentType[]
}


export function LevelView({
    curLevel,
    lastLevel,
    levelContents
}: LevelViewProps){
    const [ tlevel, setTLevel ] = useState(1);
    const isGradient = useRecoilValue(gradientState);
    const onLevelChange = (level: number) => {
        setTLevel(level);
    }
    
    return (
        <Container>
            <LevelToggle curLevel={curLevel} lastLevel={lastLevel} viewLevel={tlevel}
                onLevelChange={onLevelChange}
            />
            <ContentsWrapper>
                {
                    curLevel === tlevel ? 
                    (
                        <ChangableGradientLabel isGradient={isGradient} title={levelContents[tlevel - 1].title} fontSize="35"/>
                    )
                    :
                    (
                        <Label>{levelContents[tlevel - 1].title}</Label>
                    )
                }
 
                <Contents dangerouslySetInnerHTML={{__html: levelContents[tlevel - 1].contents}}></Contents>  
            </ContentsWrapper>
        </Container>
    );
}