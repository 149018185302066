import { useState } from "react";
import styled from "styled-components";

import {
    DoctorAdviceType, loginState
} from "../../../store"

import {
    UnderLineLabel
} from "../../atoms"

import {
    DoctorAdviceModal
} from "../../../components/organisms";
import { useRecoilValue } from "recoil";


const Container = styled.div`
    width: 1214px;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 64px;
`;

const ContentsWrapper = styled.div`
    min-width: 746px;
    display: flex;
    flex-direction: column;
    gap: 35px;
    justify-content: center;
    align-items: flex-start;
`;

const DoctorImage = styled.img`
    width: 390px;
    height: 390px;
    border-radius: 50%;
`;

const Title = styled.span`
    font-weight: 700;
    font-size: 30px;
    line-height: 38px;
    color: ${(props) => props.theme.textColor};
`;

const Contents = styled.span`
    font-weight: 400;
    font-size: 25px;
    line-height: 35px;
    color: #C1C1C1;
    width: 746px;
`;

const Button = styled.div`
    height: 89px;
    border: 1px solid #FFFFFF;
    border-radius: 100px;
    color: ${(props) => props.theme.textColor};
    display: flex;
    justify-content: center;
    align-items: center;
    font-style: normal;
    font-weight: 400;
    font-size: 25px;
    line-height: 30px;
    cursor: pointer;
    padding-left: 50px;
    padding-right: 50px;
`;

interface DoctorAdviceProps {
    doctor: DoctorAdviceType;
};

export function DoctorAdvice({
    doctor
}: DoctorAdviceProps){

    const [ modalOpen, setModalOpen ] = useState(false);
    const login = useRecoilValue(loginState);

    const openModal = () => {
        setModalOpen(true)
    }
    return (
        <Container>
            <DoctorAdviceModal open={modalOpen} onCloseButton={() => {
                    setModalOpen(false)
                }} 
                modalContents={doctor.modalContents}/>
            <DoctorImage src={require("../../../styles/assets/result/doctor_chang.png")}/>
            <ContentsWrapper>
                <UnderLineLabel text="DOCTOR'S ADVICE" fontSize="25" color="#ffffff"/>
                <Title dangerouslySetInnerHTML={{__html: doctor.title}} />
                <Contents>{doctor.isName === true && login.isLogin && login.userName + '님은 ' }{doctor.contents}</Contents>
                {doctor.modalContents &&
                    <Button
                        onClick={openModal}
                    >{doctor.modalButtonText}</Button>
                }
                
            </ContentsWrapper>
        </Container>
    );
}