import {
    SurveyStateType,
    UserStateType
} from '../types'

export function setSurveyLocalStorage(surveys: SurveyStateType[]){
    // console.log(JSON.stringify(surveys))
    localStorage.setItem('survey', JSON.stringify(surveys));
}

export function getSurveyLocalStorage(){
    const surveys = localStorage.getItem('survey');
    return surveys;
    // console.log(surveys);
}

export function setLifeLocalStorage(life: SurveyStateType){
    localStorage.setItem('life', JSON.stringify(life));
}

export function getLifeLocalStorage(){
    const life = localStorage.getItem('life');
    // console.log(life);
    return life;
}

export function setUserLocalStorage(user: UserStateType){
    localStorage.setItem('user', JSON.stringify(user));
}

export function getUserLocalStorage(){
    const user = localStorage.getItem('user');
    // console.log(user);
    return user;
}

export function setLocalStorage(key: string, data?: string){
    if(data)
    {
        localStorage.setItem(key, data);
    }
    else
    {
        localStorage.removeItem(key);
    }

}

export function getLocalStorage(key: string){
    return localStorage.getItem(key);
}

