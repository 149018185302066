/**
 * Birth에서 시작하기 진입 
 * user에게 성별을 입력받아 저장(state) 
 * 
 * @ param: x
 * @ input: gender  
 * @ return: 다음 버튼 -> Select로 이동 
 * @ exception: x
 * @ update: 2022.06.14
*/
import { 
    useEffect,
    useState
} from "react";

import { useRecoilState } from "recoil";

import { 
    useNavigate, 
    useParams 
} from "react-router-dom";

import styled from "styled-components";

import { 
    userState
} from "../../../store";

import { 
    setUserLocalStorage, 
    getUserLocalStorage 
} from "../../../utilities/localstorage";

import {
    UserStateType
} from "../../../types";

import {
    SurveyHeaderTitle,
    NextButton
} from '../../../components/atoms';

import {
    ProgressBar
} from '../../../components/molecules';

import { 
    SurveyImageSelect 
} from "../../../components/organisms";

import { 
    ImageButtonProps,
    ResultTypeEnum 
} from "../../../types";

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: column;
    min-height: calc(100vh - 90px);
    width: 100%;
    background-color: ${(props) => props.theme.bgColor};
    padding: 29px 0 45px 0;
    gap: 30px;
`;


const HeaderWrapper = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    gap: 29px;
`;

function Gender(){
    const { type } = useParams();
    const navigate = useNavigate();
    const [ nextButton, setNextButton ] = useState(false);
    const [ user, setUser ] = useRecoilState(userState);
    const [ gender, setGender ] = useState("여자");
    const [ buttons, setButtons ] = useState<ImageButtonProps []>(
        [
            {
                text:"여자",
                img: { 
                    hover: "https://soldoc-prescription-dev.s3.ap-northeast-2.amazonaws.com/medicine/drug_pic/girl-hover.png",
                    active: "https://soldoc-prescription-dev.s3.ap-northeast-2.amazonaws.com/medicine/drug_pic/girl-active.png",
                    inactive: "https://soldoc-prescription-dev.s3.ap-northeast-2.amazonaws.com/medicine/drug_pic/girl-inactive.png"
                },
                activate: false,
                width: "252",
                height: "218",
                resultType: [ ResultTypeEnum.NA ],
                resultView: false,
                resultViewAltMsg: '',
                multiSelect: false
            },
            {
                text:"남자",
                img: {
                    hover: "https://soldoc-prescription-dev.s3.ap-northeast-2.amazonaws.com/medicine/drug_pic/man-hover.png",
                    active: "https://soldoc-prescription-dev.s3.ap-northeast-2.amazonaws.com/medicine/drug_pic/man-active.png",
                    inactive: "https://soldoc-prescription-dev.s3.ap-northeast-2.amazonaws.com/medicine/drug_pic/man-inactive.png"
                },
                activate: false,
                width: "252",
                height: "218",
                resultType: [ ResultTypeEnum.NA ],
                resultView: false,
                resultViewAltMsg: '',
                multiSelect: false
            }
        ]
    );
    const onNextButtonClick = () => {
        setUser({...user, gender: gender});
        setUserLocalStorage({...user, gender: gender});
        navigate(`/${type}/start/select`);
    }

    const onSingleSelectBoxResult = (selected: number) => {
        //activate 수정 
        const temp = buttons;
        temp.map((t, index) => {
            if(index !== selected)
            {
                t.activate = false;
            }
            else
            {
                t.activate = true;
                setGender(t.text);
            }
        })
        //아이디값이 변화해야 rerendering 
        setButtons([...temp]);
        setNextButton(true);
    }

    const loadUserLocalStorage = () => {
        const localUserString = getUserLocalStorage();
        if(localUserString)
        {
            const userLocal:UserStateType = JSON.parse(localUserString);
            setUser(userLocal);
            settingGender(userLocal);
        }       
        else
        {
            navigate(`/${type}/start/init`);
        }
    }

    const settingGender = (userInfo: UserStateType) => {
        setGender(userInfo.gender);      

        const tbuttons = buttons;
        tbuttons.map(button => {
            if(button.text === userInfo.gender)
            {
                button.activate = true;
                setNextButton(true);
            }
        })

        setButtons(tbuttons);
    }

    useEffect(() => {
        loadUserLocalStorage();
    }, []);

    return (
        <Container>
            <HeaderWrapper>
                <ProgressBar 
                    leftTotalTaskCount={30} 
                    leftCompleteCount={2}
                    rightTotalTaskCount={10}
                    rightCompleteCount={0}
                />
                <SurveyHeaderTitle /> 
            </HeaderWrapper>            
            <SurveyImageSelect 
                surveyTitle="기본정보"
                questionTitle="성별을 알려주세요"
                questionSubTitle="같은 피부 고민이라도 성별에 따라 원인이 다를 수 있어요"
                endLetter="."
                grid={2}
                buttons={buttons}
                onClick={onSingleSelectBoxResult}
                buttonActive={nextButton}
                onNextButtonClick={onNextButtonClick}
            />
            <NextButton activate={nextButton} name="다음" onClick={onNextButtonClick} />
        </Container>
    );
}

export default Gender;