import { useNavigate } from "react-router-dom";
import styled from "styled-components";
import { ServiceImageButton, ServiceTypeEnum } from "../../components/organisms";

const ServicesWrapper = styled.div`
    width: 100%;
    background-color: ${(props) => props.theme.textColor};
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 200px 20px 100px 20px;
    flex-direction: column;
    gap: 20px;
    z-index: 10;
    position: relative;
`;

const Title = styled.p`
    font-family: 'Apple SD Gothic Neo Bold';
    text-align: center;
    font-size: 72px;
    line-height: 108px;
    margin-bottom: 140px;
`;

const ButtonWrapper = styled.div`
    display: inline-block;
    display: flex;
    width: 100%;
    align-items: center;
    justify-content: center;
    gap: 20px;
`;

function Services(){
    const navigate = useNavigate();


    return (
        <ServicesWrapper>
            <Title>본 적 없는 새로운 케어<br/>완벽히 개인화된 프로그램</Title>
            <ButtonWrapper>
                <ServiceImageButton 
                    title="SKIN HONEST" 
                    description="아크네 케어를 시작으로 내 피부를 관리하는 참 쉬운 방법" 
                    type={ServiceTypeEnum.SKIN} 
                    onSurveyClick={() => {
                        navigate("skin/start/init")
                    }} 
                    onDetailClick={() => {
                        navigate("skinhonest")
                    }}
                />
                <ServiceImageButton 
                    title="SKIN HONEST" 
                    description="아크네 케어를 시작으로 내 피부를 관리하는 참 쉬운 방법" 
                    type={ServiceTypeEnum.DIET} 
                    onSurveyClick={() => {
                        
                    }} 
                    onDetailClick={() => {
                        
                    }}
                />
               
            </ButtonWrapper>
            <ButtonWrapper>
                <ServiceImageButton 
                    title="SKIN HONEST" 
                    description="아크네 케어를 시작으로 내 피부를 관리하는 참 쉬운 방법" 
                    type={ServiceTypeEnum.SOLDOC} 
                    onSurveyClick={() => {
                        window.open('https://dr.soldoc.co.kr', '_blank')
                    }} 
                    onDetailClick={() => {
                        
                    }}
                />
                <ServiceImageButton 
                    title="SKIN HONEST" 
                    description="아크네 케어를 시작으로 내 피부를 관리하는 참 쉬운 방법" 
                    type={ServiceTypeEnum.KIDOC} 
                    onSurveyClick={() => {

                    }} 
                    onDetailClick={() => {
                        
                    }}
                />
            </ButtonWrapper>           
        </ServicesWrapper>
    );
}

export default Services;