import styled from "styled-components";

import {
    motion,
    useMotionValue,
    useTransform
} from "framer-motion";
import { useEffect, useRef, useState } from "react";


const Wrapper = styled.div`
    display: flex;
    width: 933px;
    height: 135px;
    background: #1D1D1D;
    border-radius: 100px;
    overflow: hidden;
    z-index: 2;
    position: relative;
`;

const InnerBar = styled(motion.div)`
    background: linear-gradient(270deg, #94DEE8 4.49%, #709EE0 52.44%, #075BD4 96.56%);
    height: 135px;
    box-shadow: 21px 0px 37px #000000;border-radius: 100px; 
`;

const LevelButton = styled(motion.div)`
    position: absolute;
    width: 135px;
    height: 135px;
    background: #FFFFFF;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'felixTitling';
    font-size: 30px;
`;

interface LevelToggleProps{
    curLevel: number;
    lastLevel: number;
    viewLevel:number;
    onLevelChange: (level: number) => void
}

export function LevelToggle({
    curLevel,
    lastLevel,
    viewLevel,
    onLevelChange
}:LevelToggleProps){
    
    const biggerBoxRef = useRef<HTMLDivElement>(null);
    const x = useMotionValue(0);
    const [level, setLevel] = useState(curLevel);

   
    let xStart = 0;
    let xEnd = 0;
    let xPosition = 0;

    if(curLevel === 1)
    {
        xStart = -135;
        xEnd = 800;
        xPosition = 0;
    }
    else if(curLevel === 2)
    {
        xStart = -535;
        xEnd = 400;
        xPosition = 400;
    }
    else if(curLevel === 3)
    {
        xStart = -935;
        xEnd = 0;
        xPosition = 800;
    }
    
    const width = useTransform(x, [xStart, xEnd], [0, 935]);
    let tlevel = curLevel;

    useEffect(() => {      
        width.onChange(() =>
            {    

                if(width.get() < 300)
                {
                    if(tlevel !== 1)
                    {
                        tlevel = 1;
                        onLevelChange(1);
                    }
                }
                else if(width.get() >= 300 &&  width.get() <= 700)
                {
                    if(tlevel !== 2)
                    {
                        tlevel = 2;
                        onLevelChange(2);
                    }
                }
                else if(width.get() > 700)
                {
                    if(tlevel !== 3)
                    {
                        tlevel = 3;
                        onLevelChange(3);
                    }
                }
                
            }
        )
    }, [width])

    return (
        <Wrapper ref={biggerBoxRef}>
            <InnerBar 
                initial={{width: 135}}
                animate={{width: xPosition + 135}}
                transition= {{duration: 1.5}}
                style={{width}}                
                >
                    
                <LevelButton 
                    initial={{left: 0}}
                    animate={{left: xPosition}}
                    // width={xPosition.toString()}
                    style={{x}}
                    // dragConstraints={biggerBoxRef}
                    drag="x"                    
                    dragSnapToOrigin 
                    dragElastic={1}
                    transition= {{duration: 1.5}}
                >LV.{viewLevel}</LevelButton>
            </InnerBar>
        </Wrapper>
    );
}