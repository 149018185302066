import { useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import qs from 'qs';
import axios from "axios";

import {
    setLocalStorage
} from '../../utilities/localstorage';

import {
    REST_API_KEY,
    REDIRECT_URI
} from '../../constant';

type KakaoTokenType = {
    access_token: string;
};

function Login(){
    const location = useLocation();
    const navigate = useNavigate();
    
    const query = qs.parse(location.search, {
        ignoreQueryPrefix: true
    });

    const code = query.code;
    const state = query.state;
    const access_token = query.access_token;
    let kkoAccessToken = access_token;
    
    const checkKakaoToken = () => {
        // 솔닥 서버에 kakao access token 을 검증한다. 
        
        // 저장된 access_token을 검증하여 error 면 code부터 수행 

        navigate(`${state}`);
        
        // 정상호출되면 state로 이동 
    }

    const getKakaoToken = () => {
        axios({
            method: 'post',
            params: {
                client_id: REST_API_KEY,
                code,
                grant_type: 'authorization_code'
            },
            url: 'https://kauth.kakao.com/oauth/token',
            withCredentials: false,

        }).then((response) => {
            const { access_token: kkoAccessToken }: KakaoTokenType =
                response.data;

            //token localStorage에 저장 
            setLocalStorage('kakao_access_token', kkoAccessToken);
            //server 호출
            checkKakaoToken();        
        
        }).catch(() => {
            // console.log("error")
        })
    }

    useEffect(() => {

        if(!code && kkoAccessToken)
        {
            // soldoc access token 발급
            // kkoAccessToken 검증 후 error 라면 code 부터 재발급
        }

        if(code)
        {
            getKakaoToken();
        }
       
    }, [])

    return <></>;
}

export default Login;