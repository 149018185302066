import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import styled from "styled-components";
import axios from "axios";

import {
    loginState
} from '../../store'

import { 
    getLocalStorage, 
    setLocalStorage 
} from "../../utilities/localstorage";

import {
    LoginStateType
} from '../../types'

import { useRecoilState } from "recoil";

import {
    REST_API_KEY,
    REDIRECT_URI
} from '../../constant';

const BarWrapper = styled.div`
    position: fixed;
    top: 0;
    background: #1A1A1A;
    color: ${(props) => props.theme.textColor};
    height: 90px;
    width: 100%;
    display: flex;
    border-bottom: 1px solid rgba(255, 255, 255, 0.3);
    z-index: 20;
    display: flex;
    justify-content: center;
    align-items: center;
`;

const Bar = styled.div`
    width: 2000px;
    display: flex;
    height: 100%;
    align-items: center;
    min-width: 2000px;
    padding-left: 400px;
`;

const Left = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
`;

const Logo = styled.span`
    font-family: 'felixTitling';
    font-weight: 400;
    font-size: 28px;
    line-height: 30px;
    cursor: pointer;
`;

const Right = styled.div`
    width: 50%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: 21px;
    padding-left: 400px;
`;

const Button = styled.div`
    font-family: 'felixTitling';
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 20px;
    background-color: #075BD4;
    width: 181px;
    height: 48px;
    border-radius: 54px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    margin-left: 748px;
`;

const LabelButton = styled.span`
    font-family: 'Apple SD Gothic Neo Bold';
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    color: ${(props) => props.theme.textColor};
    cursor: pointer;
    margin-left: 26px;
`;

function Header(){
    const navigate = useNavigate();
    const location = useLocation()
    
    const [ isLogin, setIsLogin ] = useState(false);
    const [login, setLogin] = useRecoilState(loginState);

    const onLoginButtonClick = () => {
        //3b1f51e5f77353b96b98d26211d6ffc9
        if(!isLogin)
        {
            const path = `${location.pathname}`;       
            window.location.href = `https://kauth.kakao.com/oauth/authorize?client_id=${REST_API_KEY}&redirect_uri=${REDIRECT_URI}&response_type=code&prompt=login&state=${path}`;
        }
        else
        {
            //로그아웃
            setIsLogin(false);
            setLocalStorage('kakao_access_token');
        }
    }   

    const postTest = () => {
        axios({
            method: 'post',                 
            url: '/v1/answer/',
            withCredentials: true,
            data: {
                survey: 0,
                kko_access_token: '',
                category: "acne",
                questions: [
                    {
                        question_index: 0,
                        buttons: [
                            {
                                button_index: 0,
                                answer_text: '얼굴'
                            },
                            {
                                button_index: 1,
                                answer_text: '가슴/등/어깨'
                            }
                        ]
                    }
                ]
            }

        }).then((response) => {
            // console.log(response.data);
            // setIsLogin(true);
        }).catch(() => {
            // console.log("error")
            // setIsLogin(false);
        })
    }

    const checkKakaoToken = (kakao_access_token: string) => {
       
        axios({
            method: 'post',                 
            url: '/v1/auth/check/',
            withCredentials: true,
            data: {
                access_token: kakao_access_token
            }

        }).then((response) => {

            setIsLogin(true);
            let tlogin: LoginStateType = JSON.parse(JSON.stringify(login));
            tlogin.accessToken = kakao_access_token;          
            tlogin.isLogin = true;
            tlogin.key = '';
            tlogin.userName = response.data.nickName;
            setLogin(tlogin);
            
        }).catch((err) => {

            setIsLogin(false);
            let tlogin: LoginStateType = JSON.parse(JSON.stringify(login));
            tlogin.accessToken = '';          
            tlogin.isLogin = false;
            tlogin.key = '';
            tlogin.userName = '';
            setLogin(tlogin);
        })
    }

    useEffect(() => {
        const kko = getLocalStorage('kakao_access_token');
        if(kko)
        {
            checkKakaoToken(kko);
        }
        // navigate(`/auth/callback?state=${location.pathname}&`)
        //check token
    }, [])

    return (
        <BarWrapper>     
            <Bar>
              
                <Logo
                    onClick={() => {navigate("/")}}
                >CLUB HONEST</Logo>   
               
        
            <Button
                onClick={() => {navigate("/skinhonest")}}
            >SKIN HONEST</Button>

            <LabelButton
            onClick={() => {
                postTest()
            }}
            ></LabelButton>
            <LabelButton onClick={onLoginButtonClick}>{isLogin ? login.userName + '님 로그아웃' : '로그인'}</LabelButton>        
            </Bar>
         
        </BarWrapper>
    );
}

export default Header;