import styled from "styled-components";
import { motion } from "framer-motion";

const Circle = styled.div`
    height: 386px;
    width: 386px;
    border-radius:50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: linear-gradient(52.08deg, #76BCC5 22.58%, #709EE0 55.66%, #075BD4 93.67%);
`;

const InnerCircle = styled(motion.div)`
    height: 386px;
    width: 386px;
    background-color: #000000;
    border-radius:40%;
`;

const circleVariants = {
    start: {
        scale: 1,
    },
    end: {
        scale: [1, 0.895, 0.805, 0.895, 1],
        rotateZ: [0, 0, 300, 360, 360],
        borderRadius: ['50%', '50%', '30%', '50%', '50%']       
    }    
}

export function LoadingCircle(){
    return (
        <Circle>
            <InnerCircle 
                variants={circleVariants} 
                initial="start" 
                animate="end" 
                transition={{ type: "tween", duration: 4, ease: "linear",
                    times: [0, 0.5, 0.6, 0.7, 1],
                    repeat: Infinity }}
            />
        </Circle>
    );

}