import { 
    domAnimation, 
    useViewportScroll, 
    useMotionValue,
    useTransform
} from "framer-motion";
import { useEffect, useState, useRef } from "react";
import styled from "styled-components";

const Wrapper = styled.div`
    width: 390px;
    height: 390px;
    position: relative;
`;
const Svg = styled.svg`
    position: absolute;
    z-index: 1;
    display: flex;
    justify-content: center;
    align-items: center;   
    transform: rotateZ(-90deg);
    top: 0;
    left: 0;
`;

const Circle = styled.circle`
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 25;
    stroke-linecap: round;
    stroke-dasharray: 440;
    stroke-dashoffset:  calc(440 - (440 *  1 ) / 100);
    stroke:url(#SVGID_1_);
    stroke-miterlimit:10;
`;

const InnerCircle = styled.circle`
    width: 100%;
    height: 100%;
    fill: none;
    stroke-width: 25;
    stroke-linecap: round;
    stroke-dasharray: 440;
    stroke-dashoffset:  calc(440 - (440 *  100 ) / 100);
    stroke: #1D1D1D;
    stroke-miterlimit:10;
`;

const Percent = styled.span`
    position: absolute;
    top: 44%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-size: 80px;
    font-weight: 700;
    color: ${(props) => props.theme.textColor};
`;

interface GraphProps {
    percent: number;
}

export function DoughnutGraph({
    percent
}: GraphProps){
    const chart1 = useRef(null);
    const { scrollYProgress, scrollY } = useViewportScroll();
    const y = useMotionValue(0);
    const height = useTransform(y, [0, 100], [0, 935]);

    useEffect(() => {
        let flag = false;

        scrollY.onChange(() => {            
            // console.log(scrollY.get())
            if(!flag)
            {
                if(scrollY.get() > 1700)
                {
                    flag = true;
                    let i = 1;
    
                    let func1 = setInterval(function() {
                        if( i < percent) {
                            if(chart1.current){
                                colorFn(i, chart1);
                                i++;
                            } else {
                                return;
                            }
                        } else {
                            clearInterval(func1);
                        }
                    }, 15);   
                }            
            }
           
        })
        
    }, [])

    // useEffect(() => {
    //     let i = 1;

    //     let func1 = setInterval(function() {
    //         if( i < percent) {
    //             if(chart1.current){
    //                 colorFn(i, chart1);
    //                 i++;
    //             } else {
    //                 return;
    //             }
    //         } else {
    //             clearInterval(func1);
    //         }
    //     }, 10);   
    // }, [percent])

    const colorFn = (i: number, className: any) => {
        className.current.style.strokeDashoffset = `calc(440 - (440 *  ${i} ) / 100)`;
    }

    return (
        <Wrapper>
            <Svg viewBox="0 0 200 200">
                <InnerCircle cx="100" cy="100" r="70"/>
                <Circle ref={chart1} cx="100" cy="100" r="70" />  
                
                <linearGradient id="SVGID_1_" x1="224.92" y1="0" x2="224.92" y2="200"  gradientUnits="userSpaceOnUse">
                <stop  offset="0.15" stopColor="#075BD4"/>
                <stop  offset="0.25" stopColor="#075BD4"/>
                <stop  offset="0.40" stopColor="#709EE0"/>
                <stop  offset="0.50" stopColor="#709EE0"/>
                <stop  offset="0.60" stopColor="#709EE0"/>
                <stop  offset="0.80" stopColor="#94DEE8"/>
                <stop  offset="1" stopColor="#94DEE8"/>
                </linearGradient>            
            </Svg>
            <Percent>{percent}%</Percent>
        </Wrapper>
  
    );
}