import axios from "axios";

const API = axios.create({
    
})

API.interceptors.response.use(
    function (response) {
        // console.log(response);
        return response;
    },
    function (error) {
        console.log(error.response.status);
        return Promise.reject(error);
    }
)

export default API;


//error modal message, action 
// save survey result
// booking 
// list
