
import styled from 'styled-components';
import {
    SurveyHeaderTitle,
    LoadingCircle,
    LinearGradientLabel
} from '../../../components/atoms'

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: column;
    width: 100%;
    gap: 78px;
    background-color: ${(props) => props.theme.bgColor};
    height: 100vh;
    padding-top: 58px;
`;

const Wrapper = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    gap: 20px;
    margin-bottom: 17px;
`;

const Text1 = styled.span`
    color: ${(props) => props.theme.textColor};
    font-weight: 700;
    font-size: 50px;
    line-height: 60px;
`;

const Text2 = styled.span`
    color: ${(props) => props.theme.textColor};
    font-weight: 700;
    font-size: 30px;
    line-height: 60px;
`;


const Text3 = styled.span`
    color: ${(props) => props.theme.textColor};
    font-weight: 400;
    font-size: 25px;
    line-height: 20px;
`;

export function ResultLoading(){
    return(
       <Container>
            <SurveyHeaderTitle />
            <Wrapper>
                <Text1>결과를 도출하고 있습니다.</Text1>
                <Text2>잠시만 기다려주세요</Text2>
                <LoadingCircle />
            </Wrapper>
            <Wrapper>
                <LinearGradientLabel title='서베이 분석중...' fontSize='25' />
                <Text3>응답한 내용을 종합하여 분석하고 있어요</Text3>
            </Wrapper>
        </Container>
    );
}